import React, {useEffect} from 'react'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import { closeAccountModal, updateAccount } from 'redux/actions/transactions'
import AccountForm from 'components/Transactions/form-account'

const AccForm = ({history, form, loading, transaction, transactions, updateAccount, typeAccountModal, works }) => {
  const { register, reset, setError, handleSubmit, errors } = useForm()

  useEffect(() => {
    reset(form)
  }, [form, reset])

  return(
    <>{!loading && (
      <AccountForm
        onSubmit={handleSubmit(params => updateAccount({ ...params }, history, setError))}
        history={history}
        register={register}
        errors={errors}
        transactions={transactions}
        transaction={transaction}
        type={typeAccountModal}
      />
    )}
    {loading && (
      <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
    )}</>
  )
}

const AccountModal = ({ isOpen, closeModal, transaction, transactions, history, updateAccount, accountForm, loading, typeAccountModal, works, neodatas }) => !transaction ?
<Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
  {
    typeAccountModal === 1 ? <>
      <ModalHeader toggle={closeModal}><h3>Realizar retiro</h3></ModalHeader>
      <ModalBody>
        <AccForm
          history={history}
          form={accountForm}
          loading={loading}
          updateAccount={updateAccount}
          transactions={transactions}
          transaction={transaction}
          typeAccountModal={typeAccountModal}
          works={works}
          neodatas={neodatas}
        />
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </>:
    null
  }
</Modal>
:
<Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
  {
    typeAccountModal === 0 ? <>
      <ModalHeader toggle={closeModal}>Realizar depósito a {transaction.name}</ModalHeader>
      <ModalBody>
        <AccForm
          history={history}
          form={accountForm}
          loading={loading}
          updateAccount={updateAccount}
          transactions={transactions}
          transaction={transaction}
          typeAccountModal={typeAccountModal}
          works={works}
          neodatas={neodatas}
        />
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </>:
    null
  }
</Modal>

AccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  trasaction: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  source: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
}

const mapStateToProps = ({ transactions: { source, openedAccountModal, selectedTransaction , transactions, accountForm, typeAccountModal, loading }, works }) => {
  return ({
    source,
    isOpen: openedAccountModal,
    transaction: selectedTransaction,
    accountForm: accountForm,
    transactions: transactions,
    typeAccountModal: typeAccountModal,
    loading,
    works: works,
  })
}

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeAccountModal()),
  updateAccount: (values, history) => dispatch(updateAccount(values, history)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountModal))
