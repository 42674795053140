import {
  REPORT_CREATION_BEGIN,
  REPORT_CREATION_ERROR,
  REPORT_CREATION_FINISHED,
} from '../actions/reports'

export default (state = {
  loadingReport: false,
  error: {},
  selectedPanel: 0,
}, action) => {
  switch(action.type) {
    case REPORT_CREATION_BEGIN:
      return {
        ...state,
        loadingReport: true
      }
    case REPORT_CREATION_ERROR:
      return {
        ...state,
        error: {
          report: action.error
        },
        loadingReport: false,
        selectedPanel: action.selectedPanel,
      }
    case REPORT_CREATION_FINISHED:
      return {
        ...state,
        loadingReport: false,
      }
    default:
      return {
        ...state,
      }
  }
}
