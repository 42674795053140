import { toast } from 'react-toastify'
import get from 'lodash/get'
import instance, { setToken } from '../../axios'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_STARTED = 'LOGIN_STARTED'
export const LOGOUT = 'LOGOUT'

export const USER_PROFILE_UPDATE_START = 'USER_PROFILE_UPDATE_START'
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS'
export const USER_PROFILE_UPDATE_ERROR = 'USER_PROFILE_UPDATE_ERROR'

export const autoLogin = (token, dispatch) => {
  dispatch({
    type: LOGIN_STARTED
  })

  setToken(token)
  instance.post('/auth/me')
    .then(({ data }) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LOGIN_ERROR,
        payload: err
      })
    })
}

export const login = (userInfo, history) => dispatch => {
  dispatch({
    type: LOGIN_STARTED
  })

  instance.post('/auth/login', userInfo)
    .then(({ data }) => {
      localStorage.setItem('token', data.token)
      setToken(data.token)
      if (history) {
        history.push('/admin')
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: data.user
      })
    })
    .catch(err => {
      dispatch({
        type: LOGIN_ERROR,
        payload: err
      })
    })
}

export const logout = history => dispatch => {
  localStorage.removeItem('token')
  dispatch({
    type: LOGOUT
  })
  history.push('/')
}

export const updateProfile = (values) => dispatch => {
  dispatch({
    type: USER_PROFILE_UPDATE_START
  })

  return instance.patch(`/users/update-profile`, values)
    .then(({ data }) => {
      dispatch({
        type: USER_PROFILE_UPDATE_SUCCESS,
        payload: data
      })
      toast(`Su contraseña se ha actualizado correctamente`, {
        position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch(err => {
      const msg = get(err, 'response.data.message', '');
      toast(`Ocurrió un error al actualizar el perfil ${msg}`, {
        position: toast.POSITION.TOP_CENTER,
      })
      dispatch({
        type: USER_PROFILE_UPDATE_ERROR,
        payload: err
      })
    })
}
