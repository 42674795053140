import { toast } from 'react-toastify'
import instance from '../../axios'
import fileDownload from 'js-file-download'
import { isUndefined } from 'lodash'
import { getPagination } from 'utils/pagination'
import { get } from 'lodash'

export const LOAD_NOTES_START = 'LOAD_NOTES_START'
export const LOAD_NOTES_SUCCESS = 'LOAD_NOTES_SUCCESS'
export const LOAD_NOTES_ERROR = 'LOAD_NOTES_ERROR'
export const LOAD_NOTES_WITHOUT_INVOICE_START = 'LOAD_NOTES_WITHOUT_INVOICE_START'
export const LOAD_NOTES_WITH_INVOICE_START = 'LOAD_NOTES_WITH_INVOICE_START'

export const NOTE_CREATE_START = 'NOTE_CREATE_START'
export const NOTE_CREATE_SUCCESS = 'NOTE_CREATE_SUCCESS'
export const NOTE_CREATE_ERROR = 'NOTE_CREATE_ERROR'

export const NOTE_EDIT_START = 'NOTE_EDIT_START'
export const NOTE_EDIT_SUCCESS = 'NOTE_EDIT_SUCCESS'
export const NOTE_EDIT_ERROR = 'NOTE_EDIT_ERROR'

export const NOTE_FETCH_START = 'NOTE_FETCH_START'
export const NOTE_FETCH_SUCCESS = 'NOTE_FETCH_SUCCESS'
export const NOTE_FETCH_ERROR = 'NOTE_FETCH_ERROR'

export const NOTE_UPDATE_START = 'NOTE_UPDATE_START'
export const NOTE_UPDATE_SUCCESS = 'NOTE_UPDATE_SUCCESS'
export const NOTE_UPDATE_ERROR = 'NOTE_UPDATE_ERROR'

export const OPEN_DELETE_NOTE_MODAL = 'OPEN_DELETE_NOTE_MODAL'
export const CLOSE_DELETE_NOTE_MODAL = 'CLOSE_DELETE_NOTE_MODAL'
export const DELETE_NOTE_START = 'DELETE_NOTE_START'
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS'
export const DELETE_NOTE_ERROR = 'DELETE_NOTE_ERROR'

export const GENERATE_REPORT_START = 'GENERATE_REPORT_START'
export const GENERATE_REPORT_FINISHED = 'GENERATE_REPORT_FINISHED'
export const GENERATE_REPORT_ERROR = 'GENERATE_REPORT_ERROR'

export const openDeleteNoteModal = payload => ({
  type: OPEN_DELETE_NOTE_MODAL,
  payload
})

export const closeDeleteNoteModal = () => ({
  type: CLOSE_DELETE_NOTE_MODAL,
})

export const deleteNote = ({ note, source, history }) => dispatch => {
  dispatch({
    type: DELETE_NOTE_START
  })

  return instance.delete(`/notes/${note.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_NOTE_SUCCESS,
        payload: data
      })

      toast('Se ha eliminado la nota', {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin/notes')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_NOTE_ERROR,
        payload: err
      })
    })
}

export const fetchNote = id => dispatch => {
  dispatch({
    type: NOTE_FETCH_START
  })

  return instance.get(`/notes/${id}`)
    .then(({ data }) => {
      dispatch({
        type: NOTE_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: NOTE_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateNote = (values, history) => dispatch => {
  dispatch({
    type: NOTE_UPDATE_START
  })

  return instance.patch(`/notes/${values.id}`, values)
    .then(({ data }) => {
      dispatch({
        type: NOTE_UPDATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/notes/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: NOTE_UPDATE_ERROR,
        payload: err
      })
    })
}

export const createNote = (values, action, history) => dispatch => {
  dispatch({
    type: NOTE_CREATE_START
  })

  return instance.post('/notes', values)
    .then(({ data }) => {
      dispatch({
        type: NOTE_CREATE_SUCCESS,
        payload: data
      })

      // history.push(`/admin/notes/${data.id}`)
      if (action === 'new') {
        history.push(`/admin/notes/`)
        history.push(`/admin/notes/new/`)
        toast('Se ha creado el gasto exitosamente', {
          position: toast.POSITION.TOP_CENTER,
        })
      } else {
        history.push(`/admin/notes/${data.id}`)
      }
    })
    .catch(err => {
      let msg = '';
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (err.response.data.incorrectAmount) {
          msg = err.response.data.incorrectAmount;
        }
      }
      dispatch({
        type: NOTE_CREATE_ERROR,
        payload: msg
      })
    })
}

export const editNote = (id, values) => dispatch => {
  dispatch({
    type: NOTE_EDIT_START
  })

  delete values['project']

  return instance.patch(`/notes/${id}`, values)
    .then(({ data }) => {
      dispatch({
        type: NOTE_EDIT_SUCCESS,
        payload: data
      })
      dispatch(fetchNote(id))
    })
    .catch(err => {
      dispatch({
        type: NOTE_EDIT_ERROR,
        payload: err
      })
    })
}

export const loadNotes = (params, paginationOpts) => dispatch => {
  let where = {}

  if (params && params.supplier) {
    where.supplier = params.supplier
  }

  let NOTE_FILTER_TYPE = LOAD_NOTES_START
  const noteTypeInfo = {
    note_type: 'notes',
    loading_note_type: 'loading',
    note_type_pagination: 'pagination',
  }

  if(!isUndefined(params.invoice) && params.invoice) {
    NOTE_FILTER_TYPE = LOAD_NOTES_WITH_INVOICE_START
    where.invoice = {'!=': null}
    noteTypeInfo.note_type = 'billedNotes'
    noteTypeInfo.loading_note_type = 'loadingNotesWithInvoice'
    noteTypeInfo.note_type_pagination = 'billedPagination'
  } else {
    NOTE_FILTER_TYPE = LOAD_NOTES_WITHOUT_INVOICE_START
    where.invoice = null
  }

  dispatch({
    type: NOTE_FILTER_TYPE
  })

  const pagination = getPagination(paginationOpts)
  const sortStr = JSON.stringify([
    { arrivalDate: "DESC" },
  ])

  return instance.get(`/notes`, {
    params: {
      sort: sortStr,
      skip: pagination.skip,
      limit: pagination.perPage,
      ...(Object.keys(where).length > 0 ? {where} : {}),
    }
  }).then(({ data }) => {
      dispatch({
        type: LOAD_NOTES_SUCCESS,
        payload: data,
        pagination,
        ...noteTypeInfo,
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_NOTES_ERROR,
        payload: err
      })
    })
}

export const loadPendingNotes = params => dispatch => {
  const pagination = getPagination({perPage: 1000})

  dispatch({
    type: LOAD_NOTES_START
  })

  return instance.get(`/notes`, {
    params: {
      ...get(params, 'supplier') && { where: { supplier: params.supplier, invoice: null }},
    }
  })
    .then(({ data }) => {
      dispatch({
        type: LOAD_NOTES_SUCCESS,
        payload: data,
        note_type: 'notes',
        loading_note_type: 'loading',
        note_type_pagination: 'pagination',
        pagination,
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_NOTES_ERROR,
        payload: err
      })
    })
}

export const generateNotesReport = billed => dispatch => {
  dispatch({
    type: GENERATE_REPORT_START,
  })

  return instance.get(`/notes/report?billed=${billed}`, { responseType: 'arraybuffer' }).then(response => {
    const { data } = response;
    fileDownload(data, 'report.xlsx');
    dispatch({
      type: GENERATE_REPORT_FINISHED,
    })
  }).catch(err => {
    console.error(err);
    dispatch({
      type: GENERATE_REPORT_ERROR,
      error: err
    })
  })
}

export const changePage = (billed, page, pagination) => {
  const newPagination = getPagination({...pagination, current: page})
  return loadNotes({invoice: billed}, newPagination)
}
