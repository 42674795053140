import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import ReactLoading from 'react-loading'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import instance from '../../axios'
import fileDownload from 'js-file-download'
import { formatCurrency } from 'utils/numberFormat'

import {
  Alert,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormText,
  Progress,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import moment from 'moment'
import ReactDatetime from 'react-datetime'

const tomorrow = moment().add(1, 'day')

const LandForm = ({
  onSubmit,
  register,
  shopper,
  model,
  block,
  errors,
  limit,
  loading,
  loadingLand,
  blocks,
  models,
  shoppers,
  id,
  readOnly,
  paid_amount,
  predial_date,
  predial_document,
}) => {

  const downloadFile = (land_id) => {
    instance.get(`lands/predial-document/${land_id}`, {
      responseType: 'arraybuffer',
    })
      .then(({ data }) => {
        fileDownload(data, `${predial_document}.pdf`);
      })
      .catch(err => console.error(err))
  }

  return (<Form onSubmit={onSubmit} noValidate autoComplete="off" className={loadingLand ? 'invisible' : ''}>
    <Input name="id" type="hidden" innerRef={register} />
    <div className="pl-lg-4">
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-number">
              Número de lote
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly}
              id="input-number"
              autoComplete="off"
              placeholder="Número de lote"
              type="text"
              name="number"
              innerRef={register({ required: true })}
            />
            {errors.number && (
              <FormText color="primary">
                Ingrese un número para el lote
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-street">
              Calle
            </Label>
            <Input
              className="form-control-alternative"
              id="input-street"
              disabled={readOnly}
              autoComplete="off"
              placeholder="Calle"
              type="textarea"
              name="street"
              innerRef={register}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="select-license">
              Licencia de construcción
            </Label>
            <Input
              type="select"
              className="form-control-alternative"
              disabled={readOnly}
              id="select-license"
              autoComplete="off"
              placeholder="Licencia"
              name="has_license"
              innerRef={ register }
            >
              <option value="false">No</option>
              <option value="true">Si</option>
            </Input>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-date"
            >
              Predial pagado
            </Label>
            {!readOnly && <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                defaultValue={(predial_date === '' || predial_date === null)? '': moment(predial_date).utc()}
                className="form-control-alternative"
                id="input-predial-date"
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
                closeOnSelect={true}
                inputProps={{
                  autoComplete: 'off',
                  placeholder: 'Fecha',
                  name: 'is_predial_paid',
                  ref: register({
                    validate: value => value === '' || moment(value).isValid()
                  }),
                }}
                isValidDate={current => current.isBefore(tomorrow)}
              />
            </InputGroup>}
            {readOnly && (
              <Input
                value={(predial_date === null || predial_date === '')? 'NO PAGADO' : moment(predial_date).utc().format('DD-MM-YYYY')}
                disabled
              />
            )}
            {errors.is_predial_paid && errors.is_predial_paid.type === 'validate' && (
              <FormText color="primary">
                Ingrese una fecha válida o seleccione una en el calendario.
              </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-block">
              Manzana
            </Label>
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3 d-block mb-3" />}
            {!readOnly && <Input
              className="form-control-alternative"
              id="input-block"
              autoComplete="off"
              placeholder="Manzana"
              type="select"
              disabled={loading}
              name="block"
              innerRef={register({
                required: true,
                validate: value => value !== 'Seleccione una opción'
              })}
            >
              {[<option key={'emptyValue'}>Seleccione una opción</option>]
                .concat(blocks
                  .map(block => <option value={block.id} key={block.id}>{block.project? block.project.name : 'Sin proyecto asignado'} - {block.name}</option>)
                )
              }
            </Input>}
            {errors.block && (
              <FormText color="primary">
                Seleccione una manzana para el lote.
              </FormText>
            )}
            {readOnly && !loading && <div>
              <Button tag={Link} to={`/admin/blocks/${get(block, 'id')}`} className="text-sm btn btn-sm btn-outline-primary">{get(block, 'name', '')}</Button>
            </div>}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-model">
              Modelo
            </Label>
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3 d-block mb-3" />}
            {!readOnly && <Input
              className="form-control-alternative"
              id="input-model"
              autoComplete="off"
              placeholder="Modelo"
              disabled={loading}
              type="select"
              name="model"
              innerRef={register({
                required: true,
                validate: value => value !== 'Seleccione una opción'
              })}
            >
              {[<option key={'emptyValue'}>Seleccione una opción</option>]
                .concat(models
                  .map(model => <option value={model.id} key={model.id}>{model.name}</option>)
                )
              }
            </Input>}
            {errors.model && (
              <FormText color="primary">
                Seleccione un modelo para el lote.
              </FormText>
            )}
            {readOnly && !loading && <div>
              <Button tag={Link} to={`/admin/models/${get(model, 'id')}`} className="text-sm btn btn-sm btn-outline-primary">{get(model, 'name', '')}</Button>
            </div>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-shopper">
              Comprador
            </Label>
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3 d-block mb-3" />}
            {!readOnly && <Input
              className="form-control-alternative"
              id="input-shopper"
              autoComplete="off"
              placeholder="Comprador"
              type="select"
              name="shopper"
              disabled={loading}
              innerRef={register}
            >
              {[<option key={'emptyValue'}>Seleccione una opción</option>]
                .concat(shoppers
                  .map(shopper => <option value={shopper.id} key={shopper.id}>{shopper.name}</option>)
                )
              }
            </Input>}
            {readOnly && !loading && shopper && shopper.id && <div>
              <Button tag={Link} to={`/admin/shoppers/${shopper.id}`} className="text-sm btn btn-sm btn-outline-primary">{get(shopper, 'name', '')}</Button>
            </div>}
            {readOnly && !loading && !shopper && (
              <div>
                <span className="text-sm">Aún no se ha comprado este lote. Asigna uno <Link to={`/admin/lands/${id}/edit`}>aquí</Link></span>
              </div>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-predial-document">
              Documento de pago de predial {(!readOnly && predial_document)? ' - Reemplazar documento: "' + predial_document + '"':''}
            </Label>
            {readOnly && predial_document && <Alert color="info">
              <Button
                color="primary"
                className="mr-3"
                type="button"
                title={predial_document}
                onClick={() => downloadFile(id)}
              >Descargar</Button>
              {predial_document}
            </Alert>}
            {readOnly && !predial_document && (
              <div>
                <span className="text-sm">Aún no se ha cargado ningun archivo referente. Carga uno <Link to={`/admin/lands/${id}/edit`}>aquí</Link></span>
              </div>
            )}
            <Input
              type="file"
              name="predial_document"
              id="input-predial-document"
              accept=".pdf"
              hidden={readOnly}
              innerRef={register}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center" lg="12">
          <Label className="form-control-label">
            Limite total
          </Label> 
          <br />
          { formatCurrency({amount: paid_amount}) }/{ formatCurrency({amount: limit}) } 
          <Progress value={parseInt((limit === 0? 0 : paid_amount/limit) * 100)} />
        </Col>
      </Row>
    </div>
    {!readOnly && (
      <>
        <hr className="my-4" />
        <Button disabled={loading} color="primary">Guardar</Button>
      </>
    )}
  </Form>)
}

LandForm.propTypes = {
  block: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ]),
  model: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ]),
  shopper: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ]),
  errors: PropTypes.shape({
    block: PropTypes.shape({}),
    model: PropTypes.shape({}),
    number: PropTypes.shape({}),
  }),
  models: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  shoppers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  blocks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  id: PropTypes.number,
  loading: PropTypes.bool,
  loadingLand: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  predial_date: PropTypes.string,
  predial_document: PropTypes.string,
}

LandForm.defaultProps = {
  loading: false,
  loadingLand: false,
  readOnly: false,
  onSubmit: noop,
  errors: {},
  models: [],
  shoppers: [],
  blocks: [],
  predial_date: null,
  predial_document: null,
}

export default LandForm
