import React from 'react'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import ReactLoading from 'react-loading'
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormText,
} from 'reactstrap'

const BlockForm = ({
  onSubmit,
  loading,
  loadingBlock,
  register,
  errors,
  readOnly,
  projects,
  project,
  inProject,
}) => (
  <Form onSubmit={onSubmit} noValidate autoComplete="off" className={loadingBlock ? 'invisible' : ''}>
    <Input name="id" type="hidden" innerRef={register} />
    <div className="pl-lg-4">
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-blockname"
            >
              Nombre de la manzana
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly}
              id="input-blockname"
              autoComplete="off"
              placeholder="Nombre de la manzana"
              type="text"
              name="name"
              innerRef={register({ required: true })}
            />
            {errors.name && (
              <FormText color="primary">
                Ingrese un nombre para la manzana
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-description"
            >
              Descripción
            </Label>
            <Input
              className="form-control-alternative"
              id="input-description"
              disabled={readOnly}
              autoComplete="off"
              placeholder="Descripción"
              type="textarea"
              name="description"
              innerRef={register}
            />
          </FormGroup>
        </Col>
      </Row>
      {!inProject ? (
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label
                className="form-control-label"
                htmlFor="input-project"
              >
                Proyecto
              </Label>
              {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
              {!readOnly && !loading && <Input
                className="form-control-alternative"
                id="input-project"
                autoComplete="off"
                placeholder="Proyecto"
                type="select"
                name="project"
                innerRef={register({
                  validate: value => value !== 'Seleccione una opción'
                })}
              >
                {[<option key={'emptyValue'}>Seleccione una opción</option>]
                  .concat(projects
                    .map(project => <option value={project.id} key={project.id}>{project.name}</option>)
                  )
                }
              </Input>}
              {readOnly && !loading && <>
                <br />
                <Button
                  to={`/admin/projects/${get(project, 'id')}`}
                  className="text-sm btn btn-sm btn-outline-primary"
                  tag={Link}
                >
                  {get(project, 'name', '')}
                </Button>
              </>}
              {errors.project && (
                <FormText color="primary">
                  Seleccione el proyecto de la manzana
                </FormText>
              )}
            </FormGroup>
          </Col>
        </Row>
      ) : <Input type="hidden" name="project" innerRef={register} />}
    </div>
    {inProject && <hr className="my-4" />}
    {!readOnly && !inProject && (
      <>
        <hr className="my-4" />
        <Button disabled={loading} color="primary">Guardar</Button>
      </>
    )}
  </Form>
)

BlockForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.shape({
      type: PropTypes.string
    }),
    project: PropTypes.shape({
      type: PropTypes.string
    }),
    description: PropTypes.shape({
      type: PropTypes.string
    }),
  }),
  projects: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  project: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  loading: PropTypes.bool,
  loadingBlock: PropTypes.bool,
  readOnly: PropTypes.bool,
  inProject: PropTypes.bool,
  register: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

BlockForm.defaultProps = {
  inProject: false,
  loading: false,
  loadingBlock: false,
  readOnly: false,
  onSubmit: noop,
  projects: [],
  errors: {}
}

export default BlockForm
