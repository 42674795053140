import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import ReactLoading from 'react-loading'
import { Link } from 'react-router-dom'
import {
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  FormText,
} from 'reactstrap'

const ModelCategoryForm = ({
  categories,
  readOnly,
  loading,
  closeModal,
  edit,
  register,
  errors,
}) => (
  <div className="pl-lg-4">
    <Input name="id" type="hidden" innerRef={register} />
    <Input name="model" type="hidden" innerRef={register} />
    <Row>
      <Col lg="6">
        <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="input-category"
          >
            Categoría
          </Label>
          {get(categories, 'length', 0) === 0 && !loading && !edit && (
            <>
              <br />
              <span className="text-mute">No hay categorías disponibles para agregar a este modelo, puedes editar las existentes o <Link to="/admin/categories/new" className="text-underline" onClick={closeModal}>añadir una nueva</Link>.</span>
            </>
          )}
          {loading && (
            <span className="text-sm text-muted">
              <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block mr-2 mb-2" />
              Cargando Categorías
            </span>
          )}
          {!loading && get(categories, 'length', 0) > 0 && <Input
            className="form-control-alternative"
            id="input-category"
            autoComplete="off"
            placeholder="Categoría"
            type="select"
            name="category"
            disabled={readOnly || edit}
            innerRef={register({
              required: true,
              validate: value => value !== 'Seleccione una opción'
            })}
          >
            {[<option key={'emptyValue'}>Seleccione una opción</option>]
              .concat(categories
                .filter(category => category && category.id !== undefined)
                .map(category => <option value={category.id} key={category.id}>{category.description} - {category.name}</option>)
              )
            }
          </Input>}
          {errors.category && (
            <FormText color="primary">
              Seleccione una categoría
            </FormText>
          )}
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="input-limit"
          >
            Límite de presupuesto
          </Label>
          <Input
            className="form-control-alternative"
            disabled={readOnly}
            id="input-limit"
            autoComplete="off"
            placeholder="Límite de presupuesto"
            type="text"
            name="limit"
            innerRef={register({
              required: true,
              pattern: /^\d+(\.\d)?/,
            })}
          />
          {errors.limit && errors.limit.type === 'required' && (
            <FormText color="primary">
              Ingrese un límite de presupuesto.
            </FormText>
          )}
          {errors.limit && errors.limit.type === 'pattern' && (
            <FormText color="primary">
              Ingrese sólo dígitos.
            </FormText>
          )}
        </FormGroup>
      </Col>
    </Row>
  </div>
)

ModelCategoryForm.propTypes = {
  errors: PropTypes.shape({
    category: PropTypes.shape({
      type: PropTypes.string
    }),
    limit: PropTypes.shape({
      type: PropTypes.string
    })
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
}

ModelCategoryForm.defaultProps = {
  readOnly: false,
  edit: false,
  categories: [],
  errors: {}
}

export default ModelCategoryForm
