import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import fileDownload from 'js-file-download'
import PaginationComponent from 'react-reactstrap-pagination'

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Button,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { loadNotes, openDeleteNoteModal, changePage } from 'redux/actions/notes'
import classnames from 'classnames'

import instance from '../../axios'
import TableList from 'components/Notes/TableList'


class Notes extends Component {
  state = {
    file: {},
    activeTab: 1
  }

  componentDidMount() {
    this.props.loadInfo(this.props.pagination)
    this.props.loadBilledNotes(this.props.billedPagination)
  }

  handleFile = (e) => {
    this.setState({
      file: e.target.files[0],
    })
  }

  uploadFile = (id) => {
    const { file } = this.state
    const data = new FormData()
    data.append('file', file)

    instance.post(`/file/note/${id}`, data)
      .then(res => console.log(res))
      .catch(err => console.error(err))
  }

  downloadFile = (id) => {
    instance.get(`file/note/${id}`, {
      responseType: 'arraybuffer',
    })
      .then(({ data }) => fileDownload(data, 'nota.pdf'))
      .catch(err => console.error(err))
  }

  toggle = tab => {
    const { activeTab } = this.state
    if (activeTab !== tab) this.setState({ activeTab: tab });
  }

  render() {
    const { activeTab } = this.state
    const {
      notes,
      billedNotes,
      loading,
      loadingNotesWithInvoice,
      openDeleteNoteModal,
      pagination,
      billedPagination,
      changeNotesPage,
    } = this.props
    var notesWithInvoice = billedNotes

    var notesWithoutInvoice = notes

    if (loading || loadingNotesWithInvoice) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Notas
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Notas
                  <Button tag={Link} to="/admin/notes/new" className="float-right text-sm btn btn-sm btn-outline-primary btn-sm">
                    <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
                    Nueva Nota
                    <FontAwesomeIcon icon={faPlus} className="ml-2" />
                  </Button>
                </h3>
              </CardHeader>
              {(((!notes && !billedNotes) || (notes.length === 0 && billedNotes.length === 0))) && (
                <CardBody>No hay Notas por mostrar, <Link to="/admin/notes/new" className="text-primary">crea una</Link>.</CardBody>
              )}
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 1 })}
                    onClick={() => { this.toggle(1); }}
                  >
                    Notas sin Facturar
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 2 })}
                    onClick={() => { this.toggle(2); }}
                  >
                    Notas Facturadas
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <Row className="mt-2">
                    <Col>
                      <TableList
                        notes={notesWithoutInvoice}
                        openDeleteNoteModal={openDeleteNoteModal}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-5" >
                    <Col>
                      {pagination.total > 0 && <PaginationComponent
                        totalItems={pagination.total}
                        pageSize={pagination.perPage}
                        onSelect={page => changeNotesPage(false, page, pagination)}
                        firstPageText="<<"
                        previousPageText="<"
                        nextPageText=">"
                        lastPageText=">>"
                        defaultActivePage={pagination.current}
                      />}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={2}>
                  <Row className="pt-4">
                    <Col>
                      <TableList
                        notes={notesWithInvoice}
                        openDeleteNoteModal={openDeleteNoteModal}
                        readOnly
                      />
                    </Col>
                  </Row>
                  <Row className="mt-5" >
                    <Col>
                      {billedPagination.total > 0 && <PaginationComponent
                        totalItems={billedPagination.total}
                        pageSize={billedPagination.perPage}
                        onSelect={page => changeNotesPage(true, page, billedPagination)}
                        firstPageText="<<"
                        previousPageText="<"
                        nextPageText=">"
                        lastPageText=">>"
                        defaultActivePage={billedPagination.current}
                      />}
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Row>
      </Container >
    )
  }
}

Notes.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openDeleteNoteModal: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.shape({}),
}

Notes.defaultProps = {
  notes: [],
  permissions: {},
  loadingReport: false,
}

const mapStateToProps = ({ notes }) => ({
  notes: notes.notes,
  billedNotes: notes.billedNotes,
  loading: notes.loading,
  loadingNotesWithInvoice: notes.loadingNotesWithInvoice,
  loadingReport: notes.loadingReport,
  errors: notes.errors,
  pagination: notes.pagination,
  billedPagination: notes.billedPagination,
})

const mapDispatchToProps = dispatch => ({
  loadInfo: pagination => dispatch(loadNotes({invoice: false}, pagination)),
  loadBilledNotes: pagination => dispatch(loadNotes({invoice: true}, pagination)),
  openDeleteNoteModal: note => dispatch(openDeleteNoteModal({ note, source: 'list' })),
  changeNotesPage: (billed, page, pagination) => dispatch(changePage(billed, page, pagination))
})

export default connect(mapStateToProps, mapDispatchToProps)(Notes)
