import React, { useEffect } from 'react'
import get from 'lodash/get'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import LandCategoryForm from 'components/LandCategories/form'
import { createLandCategory, closeAddLandCategoryModal } from 'redux/actions/landCategories'
import loadCategories from 'components/HOC/loadCategories'

const CreateModal = ({
  isOpen,
  closeModal,
  createLandCategory,
  loading,
  loadingCategories,
  form,
  categories
}) => {
  const { register, handleSubmit, errors, reset } = useForm()

  useEffect(() => {
    reset({ land: get(form, 'land.id', get(form, 'land')) })
  }, [form, reset])

  return !form.land ? null : (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Añadir Categoría a Lote</ModalHeader>
      <Form onSubmit={handleSubmit(createLandCategory)} noValidate autoComplete="off">
        <ModalBody>
          <LandCategoryForm
            register={register}
            errors={errors}
            closeModal={closeModal}
            loading={loadingCategories}
            categories={categories}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading || get(categories, 'length', 0) === 0} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Añadir Categoría
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

CreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  loadingCategories: PropTypes.bool,
  form: PropTypes.shape({
    category: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    land: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    limit: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ])
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number
  })),
  closeModal: PropTypes.func.isRequired,
  createLandCategory: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  categories: { categories, loading: loadingCategories },
  landCategories: { landCategories: currentCategories, loading: loadingLandCategories, openedAddCategoryToLandModal, form }
}) => ({
  categories: currentCategories && categories && categories.filter(category => !currentCategories.find(current => current.category.id === category.id)),
  isOpen: openedAddCategoryToLandModal,
  form,
  loading: loadingLandCategories,
  loadingCategories: loadingCategories
})

const mapDispatchToProps = dispatch => ({
  createLandCategory: info => dispatch(createLandCategory(info)),
  closeModal: () => dispatch(closeAddLandCategoryModal()),
})

export default withRouter(loadCategories(connect(mapStateToProps, mapDispatchToProps)(CreateModal), {perPage: 1000}))
