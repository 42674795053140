import {
  LOAD_NOTES_START,
  LOAD_NOTES_ERROR,
  LOAD_NOTES_SUCCESS,
  NOTE_FETCH_START,
  NOTE_FETCH_SUCCESS,
  NOTE_FETCH_ERROR,
  OPEN_DELETE_NOTE_MODAL,
  CLOSE_DELETE_NOTE_MODAL,
  DELETE_NOTE_START,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_ERROR,
  GENERATE_REPORT_START,
  GENERATE_REPORT_ERROR,
  GENERATE_REPORT_FINISHED,
  NOTE_EDIT_START,
  NOTE_EDIT_SUCCESS,
  NOTE_EDIT_ERROR,
  NOTE_CREATE_START,
  NOTE_CREATE_SUCCESS,
  NOTE_CREATE_ERROR,
  LOAD_NOTES_WITHOUT_INVOICE_START,
  LOAD_NOTES_WITH_INVOICE_START,
} from '../actions/notes'

const defaultForm = {
  landNotes: []
}

const defaultPagination = {
  current: 1,
  perPage: 30,
  total: 0,
  skip: 0,
}

export default (state = {
  notes: [],
  billedNotes: [],
  pagination: {...defaultPagination},
  billedPagination: {...defaultPagination},
  loadingNotesWithInvoice: false,
  loading: false,
  openedDeleteNoteModal: false,
  form: {...defaultForm},
  loadingReport: false,
  errors: null,
}, action) => {
  switch (action.type) {
  case OPEN_DELETE_NOTE_MODAL:
    return {
      ...state,
      openedDeleteNoteModal: true,
      source: action.payload.source,
      selectedNote: action.payload.note,
    }
  case CLOSE_DELETE_NOTE_MODAL:
    return {
      ...state,
      openedDeleteNoteModal: false,
      source: null,
      selectedNote: null
    }
  case DELETE_NOTE_START:
    return {
      ...state,
      deletingNote: true,
    }
  case DELETE_NOTE_SUCCESS:
    return {
      ...state,
      deletingNote: false,
      form: {...defaultForm},
      notes: state.notes.filter(u => u.id !== state.selectedNote.id),
      source: null,
      openedDeleteNoteModal: false,
    }
  case DELETE_NOTE_ERROR:
    return {
      ...state,
    }
  case LOAD_NOTES_ERROR:
    return {
      ...state,
      notes: [],
      billedNotes: [],
      loading: false,
      loadingNotesWithInvoice: false,
      error: action.payload
    }
  case LOAD_NOTES_START:
    return {
      ...state,
      notes: [],
      loading: true
    }
  case LOAD_NOTES_WITHOUT_INVOICE_START:
    return {
      ...state,
      notes: [],
      loading: true,
    }
  case LOAD_NOTES_WITH_INVOICE_START:
    return {
      ...state,
      billedNotes: [],
      loadingNotesWithInvoice: true,
    }
  case LOAD_NOTES_SUCCESS:
    return {
      ...state,
      [action.note_type]: action.payload.results,
      [action.loading_note_type]: false,
      [action.note_type_pagination]: {...action.pagination, total: action.payload.totalCount},
    }
  case NOTE_FETCH_START:
    return {
      ...state,
      loadingNote: true,
      form: defaultForm
    }
  case NOTE_FETCH_SUCCESS:
    return {
      ...state,
      loadingNote: false,
      form: action.payload
    }
  case NOTE_FETCH_ERROR:
    return {
      ...state,
      loadingNote: false,
      form: action.payload
    }
  case GENERATE_REPORT_START:
    return {
      ...state,
      loadingReport: true,
    }
  case GENERATE_REPORT_FINISHED:
    return {
      ...state,
      loadingReport: false,
    }
  case GENERATE_REPORT_ERROR:
    return {
      ...state,
      loadingReport: false,
      errors: {
        report: action.error,
      }
    }
  case NOTE_CREATE_START:
    return {
      ...state,
      loadingNote: true,
      error: null,
    }
  case NOTE_CREATE_SUCCESS:
    return {
      ...state,
      loadingNote: false,
      form: action.payload,
      error: null,
    }
  case NOTE_CREATE_ERROR:
    return {
      ...state,
      loadingNote: false,
      error: action.payload,
    }
  case NOTE_EDIT_START:
    return {
      ...state,
      loadingNote: true,
    }
  case NOTE_EDIT_SUCCESS:
    return {
      ...state,
      loadingNote: false,
    }
  case NOTE_EDIT_ERROR:
    return {
      ...state,
      loadingNote: false,
      error: action.payload,
    }
  default:
    return state
  }
}
