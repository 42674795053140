import {
  LOAD_PERMISSIONS_START,
  LOAD_PERMISSIONS_ERROR,
  LOAD_PERMISSIONS_SUCCESS,
} from '../actions/permissions'

export default (state = {
  permissionsList: [],
  permissionsObj: {},
  loading: false,
}, action) => {
  const permissionsObj = {}
  switch (action.type) {
  case LOAD_PERMISSIONS_ERROR:
    return {
      permissionsList: [],
      permissionsObj: {},
      error: action.payload
    }
  case LOAD_PERMISSIONS_START:
    return {
      permissionsList: [],
      permissionsObj: {},
      loading: true
    }
  case LOAD_PERMISSIONS_SUCCESS:
    action.payload.forEach(permission => {
      if (!permissionsObj[permission.module]) {
        permissionsObj[permission.module] = []
      }

      permissionsObj[permission.module].push(permission)
    })

    return {
      permissionsList: action.payload,
      permissionsObj,
      loading: false
    }
  default:
    return state
  }
}
