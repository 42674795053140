import {
  LOAD_USERS_START,
  LOAD_USERS_ERROR,
  LOAD_USERS_SUCCESS,
  EDIT_PERMISSION_ERROR,
  EDIT_PERMISSION_START,
  EDIT_PERMISSION_SUCCESS,
  USER_FETCH_START,
  USER_FETCH_SUCCESS,
  USER_FETCH_ERROR,
  OPEN_DELETE_USER_MODAL,
  CLOSE_DELETE_USER_MODAL,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from '../actions/users'

export default (state = {
  users: [],
  loading: false,
  openedDeleteUserModal: false,
}, action) => {
  switch (action.type) {
  case OPEN_DELETE_USER_MODAL:
    return {
      ...state,
      openedDeleteUserModal: true,
      source: action.payload.source,
      selectedUser: action.payload.user,
    }
  case CLOSE_DELETE_USER_MODAL:
    return {
      ...state,
      openedDeleteUserModal: false,
      source: null,
      selectedUser: null
    }
  case DELETE_USER_START:
    return {
      ...state,
      deletingUser: true,
    }
  case DELETE_USER_SUCCESS:
    return {
      ...state,
      deletingUser: false,
      users: state.users.filter(u => u.id !== state.selectedUser.id),
      source: null,
      openedDeleteUserModal: false,
    }
  case DELETE_USER_ERROR:
    return {
      ...state,
    }
  case LOAD_USERS_ERROR:
    return {
      ...state,
      users: [],
      error: action.payload
    }
  case LOAD_USERS_START:
    return {
      ...state,
      users: [],
      loading: true
    }
  case LOAD_USERS_SUCCESS:
    return {
      ...state,
      users: action.payload,
      loading: false
    }
  case EDIT_PERMISSION_ERROR:
    return {
      ...state,
      error: action.payload
    }
  case EDIT_PERMISSION_START:
    return {
      ...state,
      loadingUser: action.payload,
    }
  case EDIT_PERMISSION_SUCCESS:
    return {
      ...state,
      loadingUser: null,
      users: state.users.map(u => action.payload.id === u.id ? action.payload : u)
    }
  case USER_FETCH_START:
    return {
      ...state,
      loadingUser: true
    }
  case USER_FETCH_SUCCESS:
    return {
      ...state,
      loadingUser: false,
      form: action.payload
    }
  case USER_FETCH_ERROR:
    return {
      ...state,
      loadingUser: false,
    }
  default:
    return state
  }
}
