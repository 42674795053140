import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faTrash, faPen, faFileAlt, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import moment from 'moment'

import {
  Table,
  Button,
  UncontrolledTooltip,
} from 'reactstrap'

import { updateSingleField } from 'redux/actions/invoices'
import EditableField from 'components/Helpers/EditableField'
import { formatCurrency } from 'utils/numberFormat'

const TableList = props => {
  const [editingColumn, setEditingColumn] = useState(null);
  const { updateInvoice } = props;
  return (
    <Table className="align-items-center table-compact" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col" className="text-center">Fecha</th>
          <th scope="col" className="text-center">Folio</th>
          <th scope="col" className="text-center">No. de Aut</th>
          <th scope="col" className="text-center">Cuenta</th>
          <th scope="col" className="text-center">Proveedor</th>
          <th scope="col" className="text-center">Subtotal</th>
          <th scope="col" className="text-center">IVA</th>
          <th scope="col" className="text-center">Total</th>

          <th scope="col" className="text-center">Estatus SAT</th>
          <th scope="col" className="text-center">Estatus pago</th>
          <th scope="col" className="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {props.invoices.map(invoice => {
          const paid = get(invoice, 'paid', true)
          const id = `invoice_${invoice.id}`
          let invoiceFolio = (invoice.folio && (invoice.folio.startsWith('F-') ? invoice.folio : `F-${invoice.folio}`)) || '';
          invoiceFolio = (
            get(editingColumn, '[0]') === invoice.id &&
            get(editingColumn, '[1]') === 'folio'
          ) ? get(editingColumn, '[2]', invoiceFolio) : invoiceFolio;
          let invoiceAuth = (invoice.authNumber && (invoice.authNumber.startsWith('A-') ? invoice.authNumber : `A-${invoice.authNumber}`)) || '-';
          invoiceAuth = (
            get(editingColumn, '[0]') === invoice.id &&
            get(editingColumn, '[1]') === 'authNumber'
          ) ? get(editingColumn, '[2]', invoiceAuth) : invoiceAuth;
          return (
            <tr key={invoice.id} color={invoice.paid? 'paid': ''}>
              <td className="border-left text-sm text-center">
                {invoice.date ? moment(invoice.date).utc().format('DD/MMM/YYYY') : '---'}

              </td>
              <EditableField
                classes='border-left text-sm text-center'
                fieldClasses="form-control"
                fieldStyles={{width: '100%'}}
                value = {invoiceFolio}
                inputChange = {(e) => setEditingColumn([invoice.id, 'folio', e.target.value])}
                doubleClick = {() => setEditingColumn([invoice.id, 'folio', invoiceFolio])}
                blur = {(e) => updateInvoice(invoice.id, 'folio', e.target.value)}
                active = {invoice.id === get(editingColumn, '[0]') && get(editingColumn, '[1]') === 'folio'} />
              <EditableField
                classes='border-left text-sm text-center'
                fieldClasses="form-control"
                fieldStyles={{width: '100%'}}
                value = {invoiceAuth}
                inputChange = {(e) => setEditingColumn([invoice.id, 'authNumber', e.target.value])}
                doubleClick = {() => setEditingColumn([invoice.id, 'authNumber', invoiceAuth])}
                blur = {(e) => updateInvoice(invoice.id, 'authNumber', e.target.value)}
                active = {invoice.id === get(editingColumn, '[0]') && get(editingColumn, '[1]') === 'authNumber'} />
              <td className="border-left text-sm text-center">{
                get(props.accounts.filter(account => account.id === get(invoice, 'notes[0].account', null)), '[0].name', '')
              }</td>
              <td className="border-left text-sm text-center">
                {invoice.supplier && <Button
                  tag={Link}
                  color="primary"
                  to={`/admin/suppliers/${invoice.supplier.id}`}
                  className="btn btn-sm mr-0"
                >
                  {invoice.supplier && invoice.supplier.alias && <>{invoice.supplier.alias}</>}
                </Button>}
                {!invoice.supplier && <>Sin Proveedor asignado</>}
              </td>
              <td className="border-left text-sm">
                {formatCurrency({ amount: invoice.subtotal })}
              </td>
              <td className="border-left text-sm">
                {formatCurrency({ amount: invoice.iva })}
              </td>
              <td className="border-left text-sm">
                {formatCurrency({ amount: invoice.total })}
              </td>
              <td className="border-left text-sm text-center" style={{textTransform: 'capitalize'}}>
                {invoice.status === 'pending'? 'Pendiente': invoice.status}
              </td>
              <td className="border-left text-sm text-center">
                {invoice.paid? 'Pagada': 'Pendiente'}
              </td>
              <th scope="row" className="border-left text-center text-sm" id={`${id}_container`}>
              <Button
                  color="warning"
                  disabled={invoice.paid}
                  onClick={() => props.openPayInvoiceModal(invoice)}
                  size="sm"
                >
                  <FontAwesomeIcon icon={faCreditCard} />
                </Button>
                <Button
                    tag={Link}
                    size="sm"
                    to={`/admin/invoices/${invoice.id}`}
                >
                  <FontAwesomeIcon icon={faFileAlt} />
                </Button>
                <Button
                  tag={Link}
                  color="primary"
                  size="sm"
                  to={`/admin/invoices/${invoice.id}/edit`}
                >
                  <FontAwesomeIcon icon={faPen} />
                </Button>
                {paid && (
                  <UncontrolledTooltip target={id} boundariesElement={`${id}_container`} >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="mr-2"
                    />
                    No se puede eliminar esta factura porque ya se encuentra PAGADA
                    </UncontrolledTooltip>
                )}
                <span id={id}>
                  <Button
                    color="warning"
                    disabled={paid}
                    onClick={() => props.openDeleteInvoiceModal(invoice)}
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </span>
              </th>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}


TableList.propTypes = {
  openDeleteInvoiceModal: PropTypes.func.isRequired,
  openPayInvoiceModal: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  accounts: PropTypes.arrayOf(PropTypes.shape({}))
}

TableList.defaultProps = {
  invoices: [],
  permissions: {},
  accounts: []
}

const mapDispatchToProps = dispatch => {
  return {
    updateInvoice: (invoiceId, field, newValue) => dispatch(updateSingleField(invoiceId, field, newValue))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(TableList))
