import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt, faHome, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import PaginationComponent from 'react-reactstrap-pagination'

import {
  Card,
  CardHeader,
  Table,
  Container,
  Button,
  Row,
  UncontrolledTooltip,
  Col,
} from 'reactstrap'
import { loadLands, openDeleteLandModal, changePage } from 'redux/actions/lands'
import { formatCurrency } from 'utils/numberFormat'

class Lands extends Component {
  componentDidMount() {
    this.props.loadInfo({
      perPage: 10000,
    })
  }

  render() {
    const { lands, loading, openDeleteLandModal, pagination, changeNotesPage } = this.props

    if (loading) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Lotes
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Lotes
                  <Button tag={Link} to="/admin/lands/new" className="float-right text-sm btn btn-sm btn-outline-primary btn-sm">
                    <FontAwesomeIcon icon={faHome} className="mr-2" />
                    Nuevo Lote
                    <FontAwesomeIcon icon={faPlus} className="ml-2" />
                  </Button>
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">Calle y Número</th>
                    <th scope="col" className="text-center">Licencia de construcción</th>
                    <th scope="col" className="text-center">Predial pagado</th>
                    <th scope="col" className="text-center">Presupuesto</th>
                    <th scope="col" className="text-center" style={{width: '25%'}}>Comprador</th>
                  </tr>
                </thead>
                <tbody>
                  {lands.map(land => {
                    const id = `land_${land.id}`
                    const containerId = `container_${land.id}`
                    return (
                      <tr key={land.id}>
                        <td className="text-center m-0 text-sm">
                          <Button
                            tag={Link}
                            to={`/admin/lands/${land.id}`}
                            className="btn btn-sm btn-outline-primary mr-0"
                          >
                            {land.number} {land.street}
                          </Button>
                          <Button
                            tag={Link}
                            color="primary"
                            to={`/admin/lands/${land.id}/edit`}
                            size="sm"
                            className=""
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                          {land.categories && land.categories.length > 0 && <UncontrolledTooltip boundariesElement={containerId} placement="top" target={id} >Este lote no se puede eliminar por que tiene categorías asociadas a el.</UncontrolledTooltip>}
                          <span id={id}>
                            <Button
                              color="warning"
                              onClick={() => openDeleteLandModal(land)}
                              size="sm"
                              className=""
                              disabled={land.categories.length > 0}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </span>
                        </td>
                        <td className="text-center">
                          {(land.has_license && <label>Con licencia</label>) ||
                            <label>Sin licencia</label>
                          }
                        </td>
                        <td className="text-center">
                          {(land.is_predial_paid && <label>Pagado</label>) ||
                            <label>No pagado</label>
                          }
                        </td>
                        <td className="text-center">
                          {formatCurrency({amount: land.limit})}
                        </td>
                        <td className="border-left mb-0 text-sm text-center">
                          {land.shopper && land.shopper.id && (
                            <Button
                              tag={Link}
                              to={`/admin/shoppers/${land.shopper.id}`}
                              className="btn btn-sm btn-outline-primary mr-0"
                            >
                              {land.shopper.name} {land.shopper.lastName} {land.shopper.mLastName}
                            </Button>
                          )}
                          {!land.shopper && <span>Aún no se ha comprado este lote</span>}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card>
            <Row className="mt-5" >
              <Col>
                {pagination && pagination.total > 0 && <PaginationComponent
                  totalItems={pagination.total}
                  pageSize={pagination.perPage}
                  onSelect={page => changeNotesPage(page, pagination)}
                  firstPageText="<<"
                  previousPageText="<"
                  nextPageText=">"
                  lastPageText=">>"
                  defaultActivePage={pagination.current}
                />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

Lands.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openDeleteLandModal: PropTypes.func.isRequired,
  lands: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
}

Lands.defaultProps = {
  lands: [],
  permissions: {},
}

const mapStateToProps = ({ lands }) => ({
  lands: lands.lands,
  loading: lands.loading,
  pagination: lands.pagination,
})

const mapDispatchToProps = dispatch => ({
  loadInfo: pagination => dispatch(loadLands(pagination)),
  openDeleteLandModal: land => dispatch(openDeleteLandModal({ land, source: 'list' })),
  changeNotesPage: (page, pagination) => dispatch(changePage(page, pagination))
})

export default connect(mapStateToProps, mapDispatchToProps)(Lands)
