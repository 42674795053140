import {
  LOAD_WORKS_START,
  LOAD_WORKS_ERROR,
  LOAD_WORKS_SUCCESS,
  SET_NEODATA,
} from '../actions/works'

const defaultForm = {}

export default (state = {
  works: [],
  loading: false,
  form: { ...defaultForm },
  neodatas: [],
}, action) => {
  switch (action.type) {

    case LOAD_WORKS_ERROR:
      return {
        ...state,
        works: [],
        error: action.payload
      }
    case LOAD_WORKS_START:
      return {
        ...state,
        works: [],
        loading: true
      }
    case LOAD_WORKS_SUCCESS:
      return {
        ...state,
        works: action.payload,
        loading: false
      }
    case SET_NEODATA:
      return {
        ...state,
        neodatas: action.neodatas,
      }
    default:
      return state
  }
}
