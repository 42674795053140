import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from 'reactstrap'
import { connect } from 'react-redux'
import { login } from 'redux/actions/session'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: '',
      password: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    })
  }

  handleSubmit(evt) {
    evt.preventDefault()
    if (!this.props.loading) {
      this.props.userLogin(this.state, this.props.history)
    }
  }

  render() {
    const { user, password } = this.state
    const { loggedIn, loading, error } = this.props

    if (loggedIn) {
      this.props.history.push('/admin')
      return null
    }

    return (
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Ingresa tu usuario y contraseña</small>
            </div>
            <Form role="form" onSubmit={this.handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    invalid={error.message !== undefined}
                    placeholder="Usuario"
                    type="text"
                    value={user}
                    onChange={this.handleChange}
                    name="user"
                    autoComplete="user"
                  />
                  <FormFeedback className="text-danger">{error.response && [404,401].indexOf(error.response.status) >=0 ? 'Usuario no encontrado': 'Verifica que el usuario y contraseña sean correctos'}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Contraseña"
                    type="password"
                    value={password}
                    onChange={this.handleChange}
                    name="password"
                    autoComplete="password"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" disabled={loading}>
                  Iniciar sesión
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

const mapStateToProps = ({ session: { loggedIn, loading, error }}) => ({
  loggedIn,
  loading,
  error,
})

const mapDispatchToProps = dispatch => ({
  userLogin: (userInfo, history) => dispatch(login(userInfo, history))
})

Login.propTypes = {
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  userLogin: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string
  })
}

Login.defaultProps = {
  loggedIn: false,
  loading: false,
  error: {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
