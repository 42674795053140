import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt, faHome, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'

import {
  Card,
  CardHeader,
  Table,
  UncontrolledTooltip,
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap'
import { loadModels, openDeleteModelModal } from 'redux/actions/models'
import PaginationComponent from 'react-reactstrap-pagination'

class Models extends Component {
  componentDidMount() {
    this.props.loadInfo()
  }

  render() {
    const { models, loading, openDeleteModelModal, loadInfo, pagination } = this.props

    if (loading) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Modelos
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Modelos
                  <Button tag={Link} to="/admin/models/new" className="float-right text-sm btn btn-sm btn-outline-primary btn-sm">
                    <FontAwesomeIcon icon={faHome} className="mr-2" />
                    Nuevo Modelo
                    <FontAwesomeIcon icon={faPlus} className="ml-2" />
                  </Button>
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">Nombre</th>
                    <th scope="col" className="text-center">Dirección</th>
                  </tr>
                </thead>
                <tbody>
                  {models.map(model => {
                    const id = `model_${model.id}`
                    const containerId = `container_${model.id}`
                    return (
                      <tr key={model.id}>
                        <th scope="row" className="text-center mb-0 text-sm">
                          <Button
                            tag={Link}
                            to={`/admin/models/${model.id}`}
                            className="btn btn-sm btn-outline-primary"
                          >
                            {model.name}
                          </Button>
                          <br/>
                          <Button
                            tag={Link}
                            color="primary"
                            to={`/admin/models/${model.id}/edit`}
                            size="sm"
                            className="mr-2 mt-2"
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                          {model.categories.length > 0 && <UncontrolledTooltip boundariesElement={containerId} placement="top" target={id} >Este modelo no se puede eliminar por que tiene categorías asociadas a el.</UncontrolledTooltip>}
                          <span id={id}>
                            <Button
                              color="warning"
                              onClick={() => openDeleteModelModal(model)}
                              size="sm"
                              className="mt-2"
                              disabled={model.categories.length > 0}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </span>
                        </th>
                        <td className="border-left mb-0 text-sm">
                          {model.description}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            {pagination.total > 0 && <PaginationComponent
              totalItems={pagination.total}
              pageSize={pagination.perPage}
              onSelect={page => loadInfo({...pagination, current: page})}
              firstPageText="<<"
              previousPageText="<"
              nextPageText=">"
              lastPageText=">>"
              defaultActivePage={pagination.current}
            />}
          </Col>
        </Row>
      </Container>
    )
  }
}

Models.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openDeleteModelModal: PropTypes.func.isRequired,
  models: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}),
}

Models.defaultProps = {
  models: [],
  permissions: {},
}

const mapStateToProps = ({ models }) => ({
  models: models.models,
  loading: models.loading,
  pagination: models.pagination,
})

const mapDispatchToProps = dispatch => ({
  loadInfo: pagination => dispatch(loadModels(pagination)),
  openDeleteModelModal: model => dispatch(openDeleteModelModal({ model, source: 'list' })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Models)
