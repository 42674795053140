import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactLoading from 'react-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faFileInvoice, faTrash, faAngleLeft, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
  UncontrolledTooltip,
} from 'reactstrap'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { fetchNote, openDeleteNoteModal, editNote } from 'redux/actions/notes'
import { loadSuppliers } from 'redux/actions/suppliers'
import NoteForm from 'components/Notes/form'
import { loadTransactions } from 'redux/actions/transactions'

class NoteDetail extends Component {

  componentDidMount() {
    this.props.fetchNote(this.props.match.params.id)
    this.props.loadTransactions()
  }

  render() {
    const { form, loading, openDeleteNoteModal, hasDependencies, suppliers, editNote, landNotes, match, transactions } = this.props
    const id = `note_${form.id}`

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row className="pb-3">
          <Col>
            <Button
              tag={Link}
              color="primary"
              to="/admin/notes"
              size="sm"
              className="mt-2"
            >
              <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
              Regresar a Lista
              </Button>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="12">
                    <h3 className="mb-0">
                      <FontAwesomeIcon icon={faFileInvoice} className="mr-1" /> Detalle de Nota<br />
                      {hasDependencies && (
                        <UncontrolledTooltip target={id}>
                          No se puede editar esta nota porque tiene una factura asociada a ella.
                        </UncontrolledTooltip>
                      )}
                      <Button
                        tag={Link}
                        color="primary"
                        disabled={hasDependencies}
                        to={`/admin/notes/${match.params.id}/edit`}
                        size="sm"
                        className="mt-2"
                      >
                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                        Editar Nota
                        {hasDependencies && (
                          <FontAwesomeIcon
                            id={id}
                            icon={faInfoCircle}
                            className="ml-2"
                          />
                        )}
                      </Button>
                      <Button
                        color="warning"
                        disabled={hasDependencies}
                        onClick={() => openDeleteNoteModal(form)}
                        size="sm"
                        className="mt-2"
                      >
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        Borrar Nota
                        {hasDependencies && (
                          <FontAwesomeIcon
                            id={id}
                            icon={faInfoCircle}
                            className="ml-2"
                          />
                        )}
                      </Button>
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {!loading && (
                  <NoteForm
                    readOnly
                    onSubmit={data => editNote(form.id, data)}
                    supplier={form.supplier}
                    invoice={form.invoice}
                    project={form.project}
                    suppliers={suppliers}
                    loading={loading}
                    landNotes={landNotes}
                    form={form}
                    transactions={transactions}
                  />
                )}
                {loading && (
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = props => {
  const { notes: { form, loadingNote }, suppliers: { suppliers }, landNotes: { landNotes }, transactions } = props

  return {
    form,
    loading: loadingNote,
    hasDependencies: get(form, 'invoice.paid'),
    suppliers,
    landNotes,
    transactions
  }
}

const mapDispatchToProps = dispatch => ({
  fetchNote: id => dispatch(fetchNote(id)),
  openDeleteNoteModal: note => dispatch(openDeleteNoteModal({ note, source: 'detail' })),
  loadSuppliers: () => dispatch(loadSuppliers()),
  editNote: (id, values) => dispatch(editNote(id, values)),
  loadTransactions: () => dispatch(loadTransactions())
})

NoteDetail.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    landNotes: PropTypes.arrayOf(PropTypes.shape({
      note: PropTypes.number,
      land: PropTypes.number,
      category: PropTypes.number,
      amount: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })),
    supplier: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number,
        alias: PropTypes.string,
      }),
      PropTypes.string,
      PropTypes.number
    ]),
    invoice: PropTypes.shape({
      id: PropTypes.number,
    }),
    fileUrl: PropTypes.string
  }),
  loading: PropTypes.bool,
  openDeleteNoteModal: PropTypes.func.isRequired,
  fetchNote: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  hasDependencies: PropTypes.bool
}

NoteDetail.defaultProps = {
  loading: false,
  form: {
    address: '',
    name: '',
  },
  transactions: []
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteDetail)
