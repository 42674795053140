import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faHome, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { fetchModel, updateModel } from 'redux/actions/models'
import ModelForm from 'components/Models/form'
import BackButton from 'components/BackButton'

const EditModel = ({ fetchModel, updateModel, form, loadingModel, history, match }) => {
  const { register, reset, handleSubmit, errors } = useForm()
  useEffect(() => {
    fetchModel(match.params.id)
  }, [fetchModel, match])

  useEffect(() => {
    reset(form)
  }, [form, reset])

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faHome} className="mr-1"/> Editar Modelo<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to="/admin/models"
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                      Regresar a Lista
                    </Button>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/models/${match.params.id}`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                      Cancelar
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loadingModel && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
              <ModelForm
                onSubmit={handleSubmit(params => updateModel({ ...params }, history))}
                register={register}
                errors={errors}
                loadingModel={loadingModel}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({ models: { form, loadingModel } }) => ({
  form,
  loadingModel,
})

const mapDispatchToProps = dispatch => ({
  fetchModel: id => dispatch(fetchModel(id)),
  updateModel: (values, history) => dispatch(updateModel(values, history)),
})

EditModel.propTypes = {
  form: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  loadingModel: PropTypes.bool,
  fetchModel: PropTypes.func.isRequired,
  updateModel: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
}

EditModel.defaultProps = {
  loadingModel: false,
  form: {
    description: '',
    name: '',
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditModel))
