import React from 'react'
import PropTypes from 'prop-types'
import useForm from 'react-hook-form'
import ReactLoading from 'react-loading'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import ReactDatetime from 'react-datetime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import moment from 'moment'

import SelectNotes from './SelectNotes'
import { formatCurrency } from 'utils/numberFormat'


const tomorrow = moment().add(1, 'day').startOf('day')

const InvoiceForm = props => {
  const {
    readOnly,
    loading,
    loadingNote,
    suppliers,
    supplier,
    onChangeSupplier,
    supplierId,
    landNotes,
    form,
    onSubmit,
    notes,
    onSelectNote,
    totales,
  } = props


  const { register, errors, handleSubmit, setValue, getValues } = useForm()

  const landNoteList = get(landNotes, 'landNotes', [])
  const hasLandNotes = landNoteList.length > 0 ? true : false
  const { total } = getValues()

  let values = form && form.total ? form : totales
  if (!readOnly && formatCurrency({ amount: values.total }) !== formatCurrency({ amount: total })) {
    setValue('subtotal', formatCurrency({ amount: values.subtotal }), true)
    setValue('iva', formatCurrency({ amount: values.iva }))
    setValue('total', formatCurrency({ amount: values.total }))
  } else if (form && form.id && formatCurrency({ amount: form.total }) !== formatCurrency({ amount: total })) {
    setValue('subtotal', formatCurrency({ amount: form.subtotal }), true)
    setValue('iva', formatCurrency({ amount: form.iva }), true)
    setValue('total', formatCurrency({ amount: form.total }), true)
  }

  return (
    <Form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label className="form-control-label" htmlFor="date">Fecha</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    className="form-control-alternative"
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    closeOnSelect={true}
                    defaultValue={get(form, 'date') ? moment(get(form, 'date')).utc() : ''}
                    isValidDate={current => current.isBefore(tomorrow)}
                    inputProps={{
                      autoComplete: 'off',
                      placeholder: 'Fecha',
                      name: 'date',
                      ref: register,
                      disabled: readOnly || form.paid
                    }}
                  />
                </InputGroup>
                {errors.date && errors.date.type === 'required' && (
                  <FormText color="primary">
                    Seleccione una fecha.
                  </FormText>
                )}
                {errors.date && errors.date.type === 'validate' && (
                  <FormText color="primary">
                    Ingrese una fecha válida o seleccione una en el calendario.
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label className="form-control-label" htmlFor="folio">Folio</Label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  name="folio"
                  defaultValue={get(form, 'folio')}
                  disabled={readOnly}
                  innerRef={register({
                    required: false
                  })}
                  placeholder="Puede omitir 'F-', escribir solo el número"
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <Label className="form-control-label">Estatus SAT</Label>
                <p style={{textTransform: 'capitalize'}}>{get(form, 'status', 'pending') === 'pending'? 'Pendiente': get(form, 'status')}</p>
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <Label className="form-control-label">Estatus de pago</Label>
                <p>
                  {get(form, 'paid')? 'Pagada': 'Pendiente'}{' '}
                  {get(form, 'paid') && <FontAwesomeIcon
                    icon={faCheck}
                    className="text-success"
                  />}
                </p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label className="form-control-label" htmlFor="supplier">Proveedor <span className="text-danger">*</span></Label>
                {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
                {!get(form, 'id') && !loading && suppliers && <Input
                  className="form-control-alternative"
                  readOnly={hasLandNotes}
                  id="supplier"
                  autoComplete="off"
                  type="select"
                  defaultValue={get(supplier, 'id')}
                  name="supplier"
                  onChange={e => onChangeSupplier(e.target.value)}
                  innerRef={register({
                    required: true,
                    validate: value => value !== 'Seleccione una opción' && get(totales, 'total', 0) > 0,
                  })}
                >
                  {[<option key={'emptyValue'}>Seleccione una opción</option>]
                    .concat(suppliers
                      .map(supplier => <option value={supplier.id} key={supplier.id}>{supplier.alias}</option>)
                    )
                  }
                </Input>}
                {get(form, 'id') && <>
                  <br />
                  <Button tag={Link} to={`/admin/suppliers/${get(supplier, 'id')}`} className="text-sm btn btn-sm btn-outline-primary">{get(supplier, 'alias', '')}</Button>
                </>}
                {errors.supplier && (
                  <FormText color="primary">
                    Seleccione un proveedor para la Factura y al menos una Nota
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label className="form-control-label" htmlFor="xml">Factura XML</Label>
                <Input
                  id="xml"
                  type="file"
                  accept=".xml"
                  className="form-control-alternative"
                  name="xml"
                  disabled={readOnly}
                  innerRef={register({
                    required: false
                  })}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label className="form-control-label" htmlFor="authNumber">Número de Autorización / Cheque</Label>
                  <Input
                    id="authNumber"
                    type="text"
                    defaultValue={get(form, 'authNumber')}
                    className="form-control-alternative"
                    name="authNumber"
                    innerRef={register}
                    placeholder="Puede omitir 'A-', escriba solo el número"
                    disabled={readOnly}
                  />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label className="form-control-label" htmlFor="subtotal">Subtotal <span className="text-danger">*</span></Label>
                <Input
                  type="text"
                  readOnly
                  name="subtotal"
                  className="form-control-alternative text-right"
                  innerRef={register}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label className="form-control-label" htmlFor="iva">IVA <span className="text-danger">*</span></Label>
                <Input
                  type="text"
                  readOnly
                  name="iva"
                  className="form-control-alternative text-right"
                  innerRef={register}
                // value={formatCurrency({ amount: get(totales, 'iva', 0) })}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label className="form-control-label" htmlFor="total">Total <span className="text-danger">*</span></Label>
                <Input
                  type="text"
                  readOnly
                  name="total"
                  className="form-control-alternative text-right"
                  innerRef={register}
                // value={formatCurrency({ amount: get(totales, 'total', 0) })}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <hr className="my-4" />
      {supplierId && supplierId !== 'Seleccione una opción' &&
        <>
          <SelectNotes
            readOnly={readOnly}
            notes={notes}
            totales={values}
            register={register}
            onSelectNote={onSelectNote}
            suppliers={suppliers}
          />
          <hr className="my-4" />
        </>
      }
      {!readOnly && <Button disabled={loading || loadingNote} color="primary">Guardar</Button>}
    </Form>
  )
}


InvoiceForm.propTypes = {
  errors: PropTypes.shape({
    supplier: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  suppliers: PropTypes.arrayOf(PropTypes.shape({
    alias: PropTypes.string,
    id: PropTypes.number,
  })),
  supplier: PropTypes.shape({
    alias: PropTypes.string,
    id: PropTypes.number,
  }),
  loading: PropTypes.bool,
  loadingInvoice: PropTypes.bool,
  readOnly: PropTypes.bool,
  register: PropTypes.func,
  onSubmit: PropTypes.func,
}

InvoiceForm.defaultProps = {
  loading: false,
  loadingInvoice: false,
  readOnly: false,
  suppliers: [],
  errors: {},
  form: {
    readOnly: false,
    paid: false,
    subtotal: 0,
    iva: 0,
    total: 0,

  }
}


export default InvoiceForm
