import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import {
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  UncontrolledTooltip
} from 'reactstrap'
import { addPermission, removePermission } from 'redux/actions/users'

const Permission = ({ hasPermission, permission, user, isLoading, addPermission, removePermission, permissionId }) => {
  return (
    <div>
      <Badge color={hasPermission ? 'success' : 'danger'} className="badge-dot mr-4">
        <i className={hasPermission ? 'bg-success' : 'bg-danger'} />
      </Badge>
      {user.isSuper && !isLoading && (
        <UncontrolledTooltip placement="top" target={permissionId}>
          No se pueden modificar permisos de super admin
        </UncontrolledTooltip>
      )}
      {!isLoading && (
        <UncontrolledDropdown id={permissionId}>
          <DropdownToggle
            disabled={user.isSuper}
            className="btn-icon-only text-light btn-outline-secondary"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              disabled={hasPermission}
              onClick={e => {
                e.preventDefault()
                addPermission({ user, permission, permissionId })
              }}
            >
              Conceder permiso
            </DropdownItem>
            <DropdownItem
              disabled={!hasPermission}
              onClick={e => {
                e.preventDefault()
                removePermission({ user, permission, permissionId })
              }}
            >
              Revocar permiso
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
      {isLoading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
      {permission.action}
    </div>
  )
}

Permission.propTypes = {
  addPermission: PropTypes.func.isRequired,
  removePermission: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    isSuper: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  permission: PropTypes.shape({
    action: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    identifier: PropTypes.string.isRequired
  }).isRequired,
  permissionId: PropTypes.string.isRequired,
}

const mapDispatchToProps = dispatch => ({
  addPermission: info => dispatch(addPermission(info)),
  removePermission: info => dispatch(removePermission(info))
})

const mapStateToProps = ({ users }, { user, permission }) => {
  const permissionId = `permission_${user.id}_${permission.id}`
  return ({
    permissionId,
    isLoading: users.loadingUser === permissionId
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(Permission)