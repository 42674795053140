import React from 'react'
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faUserPlus, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { createUser } from 'redux/actions/users'
import UserForm from 'components/Users/form'
import BackButton from 'components/BackButton'

const NewUser = ({ createUser, loading, history }) => {
  const { register, handleSubmit, errors, setError } = useForm()
  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faUserPlus} className="mr-1"/> Nuevo Usuario<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to="/admin/users"
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                      Regresar a Lista
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <UserForm
                register={register}
                onSubmit={handleSubmit(params => createUser(params, history, setError))}
                history={history}
                loading={loading}
                errors={errors}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({ users: { form, loading } }) => ({
  form,
  loading
})

const mapDispatchToProps = dispatch => ({
  createUser: (values, history, setError) => dispatch(createUser(values, history, setError))
})

NewUser.propTypes = {
  form: PropTypes.shape({
    user: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isSuper: PropTypes.bool.isRequired,
  }),
  loading: PropTypes.bool,
  createUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
}

NewUser.defaultProps = {
  loading: false,
  form: {
    user: '',
    name: '',
    email: '',
    isSuper: false,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewUser))
