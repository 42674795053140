import { toast } from 'react-toastify'
import instance from '../../axios'
import fileDownload from 'js-file-download'
import { getPagination } from 'utils/pagination'

export const LOAD_EXPENSES_START = 'LOAD_EXPENSES_START'
export const LOAD_EXPENSES_SUCCESS = 'LOAD_EXPENSES_SUCCESS'
export const LOAD_EXPENSES_ERROR = 'LOAD_EXPENSES_ERROR'

export const EXPENSE_CREATE_START = 'EXPENSE_CREATE_START'
export const EXPENSE_CREATE_SUCCESS = 'EXPENSE_CREATE_SUCCESS'
export const EXPENSE_CREATE_ERROR = 'EXPENSE_CREATE_ERROR'

export const EXPENSE_EDIT_START = 'EXPENSE_EDIT_START'
export const EXPENSE_EDIT_SUCCESS = 'EXPENSE_EDIT_SUCCESS'
export const EXPENSE_EDIT_ERROR = 'EXPENSE_EDIT_ERROR'

export const EXPENSE_FETCH_START = 'EXPENSE_FETCH_START'
export const EXPENSE_FETCH_SUCCESS = 'EXPENSE_FETCH_SUCCESS'
export const EXPENSE_FETCH_ERROR = 'EXPENSE_FETCH_ERROR'

export const EXPENSE_UPDATE_START = 'EXPENSE_UPDATE_START'
export const EXPENSE_UPDATE_SUCCESS = 'EXPENSE_UPDATE_SUCCESS'
export const EXPENSE_UPDATE_ERROR = 'EXPENSE_UPDATE_ERROR'

export const OPEN_DELETE_EXPENSE_MODAL = 'OPEN_DELETE_EXPENSE_MODAL'
export const CLOSE_DELETE_EXPENSE_MODAL = 'CLOSE_DELETE_EXPENSE_MODAL'

export const OPEN_PAY_EXPENSE_MODAL = 'OPEN_PAY_EXPENSE_MODAL'
export const CLOSE_PAY_EXPENSE_MODAL = 'CLOSE_PAY_EXPENSE_MODAL'

export const DELETE_EXPENSE_START = 'DELETE_EXPENSE_START'
export const DELETE_EXPENSE_SUCCESS = 'DELETE_EXPENSE_SUCCESS'
export const DELETE_EXPENSE_ERROR = 'DELETE_EXPENSE_ERROR'

export const MULTIPLE_EXPENSE_CREATE_START = 'MULTIPLE_EXPENSE_CREATE_START'
export const MULTIPLE_EXPENSE_SUCCESS = 'MULTIPLE_EXPENSE_SUCCESS'
export const MULTIPLE_EXPENSE_ERROR = 'MULTIPLE_EXPENSE_ERROR'

export const UPDATE_EXPENSE_START = 'UPDATE_EXPENSE_START'
export const UPDATE_EXPENSE_SUCCESS = 'UPDATE_EXPENSE_SUCCESS'


export const openDeleteExpenseModal = payload => ({
  type: OPEN_DELETE_EXPENSE_MODAL,
  payload
})

export const closeDeleteExpenseModal = () => ({
  type: CLOSE_DELETE_EXPENSE_MODAL,
})

export const openPayExpenseModal = payload => ({
  type: OPEN_PAY_EXPENSE_MODAL,
  payload
})

export const closePayExpenseModal = () => ({
  type: CLOSE_PAY_EXPENSE_MODAL,
})

export const deleteExpense = ({ expense, source, history }) => dispatch => {
  dispatch({
    type: DELETE_EXPENSE_START
  })

  return instance.delete(`/expenses/${expense.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_EXPENSE_SUCCESS,
        payload: data
      })

      toast('Se ha eliminado el gasto', {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin/expenses')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_EXPENSE_ERROR,
        payload: err
      })
    })
}

export const fetchExpense = id => dispatch => {
  dispatch({
    type: EXPENSE_FETCH_START
  })

  return instance.get(`/expenses/${id}`)
    .then(({ data }) => {
      dispatch({
        type: EXPENSE_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: EXPENSE_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateExpense = (values, history) => dispatch => {
  dispatch({
    type: EXPENSE_UPDATE_START
  })

  return instance.patch(`/expenses/${values.id}`, values)
    .then(({ data }) => {
      dispatch({
        type: EXPENSE_UPDATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/expenses/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: EXPENSE_UPDATE_ERROR,
        payload: err
      })
    })
}

export const createExpense = (values, action, history) => dispatch => {
  dispatch({
    type: EXPENSE_CREATE_START
  })

  return instance.post('/expenses', values)
    .then(({ data }) => {
      dispatch({
        type: EXPENSE_CREATE_SUCCESS,
        payload: data
      })
      if (action === 'new') {
        history.push(`/admin/expenses/${data.id}`)
        history.push(`/admin/expenses/new/`)
        toast('Se ha creado el gasto exitosamente', {
          position: toast.POSITION.TOP_CENTER,
        })
      }else {
        history.push(`/admin/expenses/${data.id}`)
      }
    })
    .catch(err => {
      dispatch({
        type: EXPENSE_CREATE_ERROR,
        payload: err
      })
    })
}

export const editExpense = (id, values, action, history) => dispatch => {
  dispatch({
    type: EXPENSE_EDIT_START
  })

  return instance.patch(`/expenses/${id}`, values)
    .then(({ data }) => {
      dispatch({
        type: EXPENSE_EDIT_SUCCESS,
        payload: data
      })
      history.push(`/admin/expenses/${data.id}`)
      if (action === 'new') {
        history.push(`/admin/expenses/new/`)
        toast('Se ha actualizado el gasto exitosamente', {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    })
    .catch(err => {
      dispatch({
        type: EXPENSE_EDIT_ERROR,
        payload: err
      })
    })
}

export const loadExpenses = (search='', status=null, paginationOpts) => dispatch => {

  const pagination = getPagination(paginationOpts)
  dispatch({
    type: LOAD_EXPENSES_START,
    search
  })

  let where = {}
  if (search !== '' || status !== null) {
    const orWhere = [
      { ...search !== '' && {description: { contains: search }}},
    ]

    if (!isNaN(search) && search !== '') {
      orWhere.push({id: parseInt(search)})
    }

    where = status !== null ? {status} : {};
    where.or = orWhere
  }

  let sortStr = '[{"status":"DESC"},{"date":"DESC"}]'

  return instance.get(`/expenses`, {
    params: {
      sort: sortStr,
      limit: pagination.perPage,
      skip: pagination.skip,
      ...Object.keys(where).length > 0 && { where },
    },
  }).then(({ data }) => {
    dispatch({
      type: LOAD_EXPENSES_SUCCESS,
      payload: data,
      pagination,
    })
  }).catch(err => {
    dispatch({
      type: LOAD_EXPENSES_ERROR,
      payload: err,
    })
  })
}

export const downloadFile = expenseId => dispatch => {
  return instance.get(`file/expense/${expenseId}`, {
    responseType: 'arraybuffer',
  }).then(({ data }) => fileDownload(data, expenseId + '_voucherGasto.pdf'))
    .catch(err => console.error(err))
}

export const createMultipleExpenses = (info, history) => dispatch => {
  console.log("INFO", info)
  const allKeys = Object.keys(info).filter(v => v !== 'include')
  const toSubmit = {}
  info['include'].forEach((val, index) => {
    if (val) {
      allKeys.forEach(key => {
        if (!toSubmit.hasOwnProperty(key)) {
          toSubmit[key] = []
        }
        toSubmit[key].push(info[key][index])
      })
    }
  })
  if (Object.keys(toSubmit).length === 0) {
    return false;
  }
  dispatch({
    type: MULTIPLE_EXPENSE_CREATE_START
  })

  return instance.post('expenses/multiple-create', toSubmit)
    .then(response => {
      dispatch({
        type: MULTIPLE_EXPENSE_SUCCESS,
      })
      history.push(`/admin/expenses/`)
    })
    .catch(error => {
      dispatch({
        type: MULTIPLE_EXPENSE_ERROR,
        error,
      })
    })
}

export const updateSingleField = (expenseId, fieldName, newValue) => dispatch => {
  dispatch({
    type: UPDATE_EXPENSE_START
  })

  const data = {
    [`${fieldName}`]: newValue
  }

  return instance.patch(`/expenses/${expenseId}`, data)
    .then(response => {
      dispatch({
        type: UPDATE_EXPENSE_SUCCESS,
        payload: {
          id: expenseId,
          newValue,
          field: fieldName,
        }
      })
    })
    .catch(error => {

    })
}
