import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import loadSuppliers from 'components/HOC/loadSuppliers'
import { updateLandCategorySuppliers, closeEditLandCategorySuppliersModal, updateSuppliersField } from 'redux/actions/landCategories'
import LandCategorySuppliersForm from 'components/LandCategorySuppliers/form'

const EditModal = ({
  isOpen,
  closeModal,
  updateLandCategorySuppliers,
  loading,
  loadingSuppliers,
  form,
  suppliers,
  idLandCategory,
  updateSuppliersField,
}) => {
  const { register, handleSubmit, errors, reset } = useForm()

  useEffect(() => {
    const supplierIds = get(form, 'suppliers', []).map(supplier => supplier.id)
    const resetValues = {
      landCategorySuppliers: form.suppliersField.concat(supplierIds),
      idLandCategory: form.id,
    }
    reset(resetValues)
  }, [form, reset])

  return (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Añadir Proveedor a Lote</ModalHeader>
      <Form onSubmit={handleSubmit(updateLandCategorySuppliers)} noValidate autoComplete="off">
        <ModalBody>
          <LandCategorySuppliersForm
            register={register}
            errors={errors}
            loading={loadingSuppliers}
            suppliers={suppliers}
            closeModal={closeModal}
            idLandCategory={idLandCategory}
            updateSuppliersField={updateSuppliersField}
            categorySuppliers={form.suppliers}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading || get(suppliers, 'length', 0) === 0} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Añadir Proveedor
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

EditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  loadingSuppliers: PropTypes.bool,
  form: PropTypes.shape({
    land: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    supplier: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        alias: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
  }),
  suppliers: PropTypes.arrayOf(PropTypes.shape({
    alias: PropTypes.string,
    id: PropTypes.number
  })),
  closeModal: PropTypes.func.isRequired,
  updateLandCategorySuppliers: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  suppliers: { suppliers, loading: loadingSuppliers },
  landCategories: { updatingLandCategorySuppliers, openedEditCategoryProvidersModal, form, suppliersField }
}) => {
  return ({
  suppliers: suppliers.filter(supplier => !form.suppliers || !form.suppliers.find(s => s.id === supplier.id)).map(supplier => ({...supplier, label: supplier.name})),
  isOpen: openedEditCategoryProvidersModal,
  form: {...form, suppliersField },
  loading: updatingLandCategorySuppliers,
  loadingSuppliers,
})}

const mapDispatchToProps = dispatch => ({
  updateLandCategorySuppliers: info => dispatch(updateLandCategorySuppliers(info)),
  closeModal: () => dispatch(closeEditLandCategorySuppliersModal()),
  updateSuppliersField: value => dispatch(updateSuppliersField(value))
})

export default withRouter(loadSuppliers(connect(mapStateToProps, mapDispatchToProps)(EditModal), {limit: 1000}))
