import React from 'react'
import get from 'lodash/get'
import {
  Card,
  Button,
  CardHeader,
  Row,
  Col,
  Table,
  Progress,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faHome } from '@fortawesome/free-solid-svg-icons'
import { formatCurrency } from 'utils/numberFormat'
import moment from 'moment'

const DependenciesList = ({ block }) => (
  <Row>
    <Col className="order-xl-1 mt-3" xl="12">
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs={12}>
              <h6 className="heading-small text-muted">
                Lotes
                <Button tag={Link} to="/admin/lands/new" className="float-right text-sm btn btn-sm btn-outline-primary btn-sm">
                  <FontAwesomeIcon icon={faHome} className="mr-2" />
                    Nuevo Lote
                  <FontAwesomeIcon icon={faPlus} className="ml-2" />
                </Button>
              </h6>
            </Col>
          </Row>
        </CardHeader>
        {get(block, 'lands', []).length === 0 && <div className="text-mute text-sm p-3">No hay ningún lote relacionado con esta manzana.</div>}
        {get(block, 'lands', []).length > 0 && (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th/>
                <th scope="col" className="text-center">Número</th>
                <th scope="col" className="text-center">Calle</th>
                <th scope="col" className="text-center">Licencia de construcción</th>
                <th scope="col" className="text-center">Predial pagado</th>
                <th scope="col" className="text-center">Presupuesto</th>
              </tr>
            </thead>
            <tbody>
              {get(block, 'lands', []).map(land => (
                <tr key={land.id}>
                  <th scope="row" className="border-left text-center">
                    <Button
                      tag={Link}
                      to={`/admin/lands/${land.id}`}
                      className="btn btn-sm btn-outline-primary mr-0"
                    >
                      Ver
                    </Button>
                  </th>
                  <td className="border-left mb-0 text-sm">
                    {land.number}
                  </td>
                  <td className="border-left mb-0 text-sm">
                    {land.street}
                  </td>
                  <td className="border-left text-center">
                    {(land.has_license && <label>Con licencia</label>) ||
                      <label>Sin licencia</label>
                    }
                  </td>
                  <td className="border-left text-center">
                    {(land.is_predial_paid !== '' && land.is_predial_paid !== null && <label>{ moment(land.is_predial_paid).format('DD-MM-YYYY') }</label>) ||
                      <label>No pagado</label>
                    }
                  </td>
                  <td className="border-left text-center">
                    { formatCurrency({amount: land.paid_amount}) }/{ formatCurrency({amount: land.limit}) }
                    <Progress value={parseInt((land.limit === 0? 0 : land.paid_amount/land.limit) * 100)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card>
    </Col>
  </Row>
)

DependenciesList.propTypes = {
  block: PropTypes.shape({
    lands: PropTypes.arrayOf(PropTypes.shape({
      street: PropTypes.string,
      number: PropTypes.string,
      id: PropTypes.number,
    })),
  })
}

export default DependenciesList
