import React from 'react'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import get from 'lodash/get'
import numeral from 'numeral'
import {
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Progress,
} from 'reactstrap'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThLarge, faPlus, faPencilAlt, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { openAddBlockModal, openDeleteBlockModal, openEditBlockModal } from 'redux/actions/blocks'
import { formatCurrency } from 'utils/numberFormat'

const calculateUsedBudget = block => get(block, 'lands', []).reduce((sum, land) =>
  numeral(get(land, 'usedBudget', 0)).add(sum).value()
, 0)

const calculateMaxBudget = block => get(block, 'lands', []).reduce((sum, land) =>
  numeral(get(land, 'budget', 0)).add(sum).value()
, 0)

const DependenciesList = ({ project, loading, blocks, loadingBlocks, match, openEditBlockModal, openAddBlockModal, openDeleteBlockModal }) => (
  <Row>
    <Col className="order-xl-1 mt-3" xl="12">
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs={12}>
              <h6 className="heading-small text-muted">
                Manzanas
                <Button
                  color="primary"
                  size="sm"
                  className="float-right mt--1"
                  onClick={() => openAddBlockModal({ project: parseInt(match.params.id) })}
                >
                  <FontAwesomeIcon className="mr-2" icon={faThLarge} />
                  Crear Manzana en Proyecto
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </h6>
            </Col>
          </Row>
        </CardHeader>
        {loadingBlocks && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="m-3" />}
        {blocks.length > 0 && !loadingBlocks && (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center">Nombre</th>
                <th scope="col" className="text-center">Presupuesto (Utilizado / Disponible)</th>
                <th scope="col" className="text-center">Situación</th>
                <th scope="col" className="text-center">Descripción</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {blocks.map(block => {
                const id = `landCategory_${block.id}`
                const availableLands = get(block, 'lands', []).filter(land => land.shopper === null).length
                return (
                  <tr key={block.id}>
                    <th scope="row" className="border-left text-center">
                      <Button
                        tag={Link}
                        to={`/admin/blocks/${block.id}`}
                        className="btn btn-sm btn-outline-primary mr-0"
                      >
                        {block.name}
                      </Button>
                    </th>
                    <td className="border-left mb-0 text-sm text-center">
                      {formatCurrency({amount: calculateUsedBudget(block)})} / {formatCurrency({amount: calculateMaxBudget(block)})}
                      <br />
                      <Progress value={parseInt((calculateMaxBudget(block) === 0? 0 : calculateUsedBudget(block)/calculateMaxBudget(block)) * 100)} />
                    </td>
                    <td className="border-left text-sm">
                      {availableLands > 0 ? `Por Vender (${availableLands} Lote${availableLands > 1 ? 's' : ''})` : 'Vendido / Lotes No Disponibles'}
                    </td>
                    <td className="border-left mb-0 text-sm text-center text-muted">
                      {block.description}
                    </td>
                    <td className="border-left text-sm" id={`${id}_container`}>
                      <Button
                        color="primary"
                        size="sm"
                        className="mr-2"
                        onClick={() => openEditBlockModal(block)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                      {get(block, 'lands', []).length > 0 && (
                        <UncontrolledTooltip target={id} boundariesElement={`${id}_container`} placement="left">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="mr-2"
                          />
                          No se puede eliminar esta Manzana pues tiene Lotes asignados a ella.
                        </UncontrolledTooltip>
                      )}
                      <span
                        id={id}
                      >
                        <Button
                          color="secondary"
                          size="sm"
                          disabled={get(block, 'lands', []).length > 0}
                          onClick={() => openDeleteBlockModal(block)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}
        {get(project, 'blocks', []).length === 0 && !loading && <div className="text-mute text-sm p-3">No hay ninguna manzana relacionada con este proyecto.</div>}
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs={12}>
              <h6 className="heading-small text-muted">
                Modelos
              </h6>
            </Col>
          </Row>
        </CardHeader>
        {get(project, 'models', []).length > 0 && (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center">Nombre</th>
                <th scope="col" className="text-center">Descripción</th>
              </tr>
            </thead>
            <tbody>
              {get(project, 'models', []).map(model => (
                <tr key={model.id}>
                  <th scope="row" className="border-left text-center">
                    <Button
                      tag={Link}
                      to={`/admin/models/${model.id}`}
                      className="btn btn-sm btn-outline-primary mr-0"
                    >
                      {model.name}
                    </Button>
                  </th>
                  <td className="border-left mb-0 text-sm">
                    {model.description}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {get(project, 'models', []).length === 0 && !loading && <div className="text-mute text-sm p-3">No hay ningún modelo relacionado con este proyecto.</div>}
        {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="m-3" />}
      </Card>
    </Col>
  </Row>
)

DependenciesList.propTypes = {
  loading: PropTypes.bool,
  loadingBlocks: PropTypes.bool,
  openEditBlockModal: PropTypes.func.isRequired,
  openAddBlockModal: PropTypes.func.isRequired,
  openDeleteBlockModal: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  project: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    name: PropTypes.string,
    blocks: PropTypes.arrayOf(PropTypes.shape({
      address: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.number,
    })),
    models: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.number,
    })),
  }),
  blocks: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
  })),
}

const mapDispatchToProps = dispatch => ({
  openAddBlockModal: block => dispatch(openAddBlockModal(block)),
  openDeleteBlockModal: block => dispatch(openDeleteBlockModal({ block, source: 'project' })),
  openEditBlockModal: block => dispatch(openEditBlockModal({ block, source: 'project' })),
})

export default withRouter(connect(null, mapDispatchToProps)(DependenciesList))
