import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import useForm from 'react-hook-form'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import LandCategoryForm from 'components/LandCategories/form'
import { updateLandCategory, closeEditLandCategoryModal } from 'redux/actions/landCategories'
import sanitizeValues from 'utils/sanitizeValues'

const EditModal = ({
  isOpen,
  closeModal,
  updateLandCategory,
  loading,
  form,
}) => {
  const {
    register,
    errors,
    handleSubmit,
    reset
  } = useForm()


  useEffect(() => {
    reset(sanitizeValues(form))
  }, [form, reset])

  return !form.land && !form.model ? null : (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Actualizar Categoría en Lote</ModalHeader>
      <Form onSubmit={handleSubmit(updateLandCategory)} noValidate autoComplete="off">
        <ModalBody>
          <LandCategoryForm
            category={form.category}
            register={register}
            errors={errors}
            closeModal={closeModal}
            edit
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Actualizar Categoría
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

EditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.shape({
    category: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    land: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    model: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    limit: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ])
  }),
  closeModal: PropTypes.func.isRequired,
  updateLandCategory: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  categories: { categories, loading: loadingCategories },
  landCategories: { landCategories: currentCategories, updatingLandCategory, openedEditLandCategoryModal, form }
}) => ({
  categories: currentCategories && categories && categories.filter(category => !currentCategories.find(current => current.category.id === category.id)),
  isOpen: openedEditLandCategoryModal,
  form,
  loading: updatingLandCategory,
  loadingCategories: loadingCategories
})

const mapDispatchToProps = dispatch => ({
  updateLandCategory: info => dispatch(updateLandCategory(info)),
  closeModal: () => dispatch(closeEditLandCategoryModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditModal))
