import { combineReducers } from 'redux'
import session from './session'
import users from './users'
import permissions from './permissions'
import projects from './projects'
import categories from './categories'
import suppliers from './suppliers'
import supplierContacts from './supplierContacts'
import modelCategories from './modelCategories'
import models from './models'
import blocks from './blocks'
import landCategories from './landCategories'
import landNotes from './landNotes'
import lands from './lands'
import shoppers from './shoppers'
import shopperBalances from './shopperBalances'
import notes from './notes'
import transactions from './transactions'
import invoices from './invoices'
import expenses from './expenses'
import works from './works'
import reports from './reports'


export default combineReducers({
  session,
  users,
  permissions,
  projects,
  categories,
  suppliers,
  supplierContacts,
  modelCategories,
  models,
  landCategories,
  landNotes,
  lands,
  shoppers,
  shopperBalances,
  blocks,
  notes,
  transactions,
  invoices,
  expenses,
  works,
  reports,
})
