import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { loadBlocks } from 'redux/actions/blocks'
import { fetchProject, openDeleteProjectModal } from 'redux/actions/projects'
import ProjectForm from 'components/Projects/form'
import DependenciesList from 'components/Projects/dependenciesList'
import { hasDependencies } from './utils'
import BackButton from 'components/BackButton'

const ProjectDetail = ({ form, loading, loadBlocks, loadingBlocks, blocks, history, match, openDeleteProjectModal, hasDependencies, fetchProject }) => {
  const { register, reset } = useForm()

  useEffect(() => {
    fetchProject(match.params.id)
    loadBlocks(match.params.id)
  }, [fetchProject, loadBlocks, match])

  useEffect(() => {
    reset(form)
  }, [form, reset])

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faFolder} className="mr-1"/> Detalle de Proyecto<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/projects/${match.params.id}/edit`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="mr-2"/>
                      Editar Proyecto
                    </Button>
                    <Button
                      color="warning"
                      onClick={() => openDeleteProjectModal(form)}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2"/>
                      Borrar Proyecto
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!loading && (
                <ProjectForm
                  readOnly
                  history={history}
                  register={register}
                />
              )}
              {loading && (
                <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DependenciesList project={form} loading={loading} loadingBlocks={loadingBlocks} blocks={blocks} />
    </Container>
  )
}

const mapStateToProps = ({ projects: { form, loadingProject }, blocks: { blocks, loading: loadingBlocks } }) => ({
  form,
  loading: loadingProject,
  hasDependencies: hasDependencies(form),
  blocks,
  loadingBlocks
})

const mapDispatchToProps = dispatch => ({
  fetchProject: id => dispatch(fetchProject(id)),
  loadBlocks: project => dispatch(loadBlocks({ project })),
  openDeleteProjectModal: project => dispatch(openDeleteProjectModal({ project, source: 'detail' })),
})

ProjectDetail.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    name: PropTypes.string,
    blocks: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
      project: PropTypes.number,
    })),
    models: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
      project: PropTypes.number,
    }))
  }),
  blocks: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
  })),
  loading: PropTypes.bool,
  loadingBlocks: PropTypes.bool,
  hasDependencies: PropTypes.bool,
  openDeleteProjectModal: PropTypes.func.isRequired,
  fetchProject: PropTypes.func.isRequired,
  loadBlocks: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
}

ProjectDetail.defaultProps = {
  loading: false,
  form: {
    address: '',
    name: '',
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectDetail))
