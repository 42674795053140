import React, { useEffect, useState } from 'react'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { createLand } from 'redux/actions/lands'
import { loadBlocks } from 'redux/actions/blocks'
import { loadShoppers } from 'redux/actions/shoppers'
import { loadModels } from 'redux/actions/models'
import LandForm from 'components/Lands/form'
import BackButton from 'components/BackButton'

const NewLand = ({
  loadBlocks,
  loadShoppers,
  loadModels,
  createLand,
  loading,
  history,
  blocks,
  shoppers,
  models,
}) => {
  const [hasFetchedInfo, setHasFetchedInfo] = useState(false)
  const { register, handleSubmit, errors } = useForm()

  useEffect(() => {
    if (!hasFetchedInfo) {
      loadBlocks()
      loadShoppers()
      loadModels()
      setHasFetchedInfo(true)
    }
  }, [loadBlocks, loadShoppers, loadModels, hasFetchedInfo, setHasFetchedInfo])

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faHome} className="mr-1"/> Nuevo Lote<br/>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <LandForm
                onSubmit={handleSubmit(form => createLand(form, history))}
                register={register}
                errors={errors}
                loading={loading}
                shoppers={shoppers}
                blocks={blocks}
                models={models}
                predial_date={''}
                predial_document={null}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({
  lands: { form, loading },
  models: { models },
  blocks: { blocks },
  shoppers: { shoppers },
}) => ({
  form,
  loading,
  shoppers,
  blocks,
  models
})

const mapDispatchToProps = dispatch => ({
  loadModels: () => dispatch(loadModels({perPage: 1000})),
  loadBlocks: () => dispatch(loadBlocks()),
  loadShoppers: () => dispatch(loadShoppers({perPage: 1000})),
  createLand: (values, history) => dispatch(createLand(values, history))
})

NewLand.propTypes = {
  form: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
  }),
  models: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  blocks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  shoppers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  loading: PropTypes.bool,
  createLand: PropTypes.func.isRequired,
  loadBlocks: PropTypes.func.isRequired,
  loadShoppers: PropTypes.func.isRequired,
  loadModels: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

NewLand.defaultProps = {
  loading: false,
  form: {
    number: '',
    street: '',
    has_license: true,
    is_predial_paid: '',
    predial_document: null,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewLand))
