import React from 'react'
import classNames from 'classnames'
import { NavLink as NavLinkRRD, Link } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  faUser,
  faHome,
  faLandmark,
  faFileContract,
  faSuitcase,
  faFile,
  faListAlt,
  faMoneyBillWave,
  faChevronDown,
  faMoneyBillAlt,
  faFileInvoiceDollar,
  faMoneyCheck
} from '@fortawesome/free-solid-svg-icons'
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from 'reactstrap'

import { logout } from 'redux/actions/session'

const menus = [
  /* {
  path: '/admin/projects',
  icon: faFolder,
  label: 'Proyectos',
  permissions: [
    'project.create',
    'project.update',
    'project.destroy',
    'block.create',
    'block.update',
    'block.destroy',
    'land.add',
    'land.create',
    'land.destroy',
    'land.remove',
    'land.update',
    'landCategory.create',
    'landCategory.destroy',
    'landCategory.update',
  ]
},  */{
  path: '/admin/models',
  icon: faHome,
  label: 'Modelos',
  permissions: [
    'model.create',
    'model.update',
    'model.destroy',
  ]
},{
  path: '/admin/lands',
  icon: faLandmark,
  label: 'Lotes',
  permissions: [
    'land.create',
    'land.update',
    'land.destroy',
    'land.find',
  ]
}, {
  path: '/admin/notes',
  icon: faFile,
  label: 'Notas',
  permissions: [
    'note.create',
    'note.update',
    'note.destroy',
  ]
}, {
  path: '/admin/invoices',
  icon: faFileInvoiceDollar,
  label: 'Facturas',
  permissions: [
    'invoice.create',
    'invoice.update',
    'invoice.destroy',
  ],
}, {
  path: '/admin/expenses',
  icon: faMoneyCheck,
  label: 'Gastos',
  permissions: [
    'expense.create',
    'expense.update',
    'expense.destroy',
  ],
}, {
  path: '/admin/transactions',
  icon: faMoneyBillAlt,
  label: 'Transacciones',
  permissions: [
    'account.create',
    'account.update',
    'account.destroy',
    'account.create_movement',
    'account.pay_expense_or_invoice',
  ]
}, {
  label: 'Catálogos',
  icon: faListAlt,
  permissions: [
    'supplier.create',
    'supplier.update',
    'supplier.destroy',
    'supplierContact.create',
    'supplierContact.update',
    'supplierContact.destroy',
    'shopper.create',
    'shopper.update',
    'shopper.destroy',
    'shopperBalance.create',
    'shopperBalance.update',
    'shopperBalance.destroy',
  ],
  submenu: [{
    path: '/admin/suppliers',
    icon: faSuitcase,
    label: 'Proveedores',
    permissions: [
      'supplier.create',
      'supplier.update',
      'supplier.destroy',
      'supplierContact.create',
      'supplierContact.update',
      'supplierContact.destroy',
    ],
  }, {
    path: '/admin/shoppers',
    icon: faMoneyBillWave,
    label: 'Compradores',
    permissions: [
      'shopper.create',
      'shopper.update',
      'shopper.destroy',
      'shopperBalance.create',
      'shopperBalance.update',
      'shopperBalance.destroy',
    ]
  }]
}, {
  path: '/admin/users',
  icon: faUser,
  label: 'Usuarios',
  permissions: [
    'user.create',
    'user.update',
    'user.destroy',
    'user.add',
    'user.remove',
  ]
}, {
  path: '/admin/categories',
  icon: faFileContract,
  label: 'Categorías',
  permissions: [
    'category.create',
    'category.update',
    'category.destroy',
  ]
},].filter(item => Object.keys(item).length > 0)

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false
    }

    this.activeRoute.bind(this)
    this.closeCollapse = this.closeCollapse.bind(this)
    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.toggleInnerCollapse = this.toggleInnerCollapse.bind(this)
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  toggleCollapse() {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    })
  }

  closeCollapse() {
    this.setState({
      collapseOpen: false,
      innerCollapseOpen: false,
    })
  }

  toggleInnerCollapse() {
    this.setState({
      innerCollapseOpen: !this.state.innerCollapseOpen
    })
  }

  render() {
    const { logo, user, logout, history } = this.props
    const { collapseOpen, innerCollapseOpen } = this.state
    let navbarBrandProps
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      }
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank'
      }
    }
    // filter menu items by user permissions
    const availableMenuItems = (user.id && user.permissions) ? menus.filter(menuItem => user.isSuper || menuItem.permissions.some(permission => user.permissions.map(({ identifier }) => identifier).indexOf(permission) >= 0)) : [];
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="img-fluid"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <FontAwesomeIcon icon={faUser} />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bienvenido {user.name}!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/menu" tag={Link}>
                  <FontAwesomeIcon icon={faUser} />
                  <span>Mi perfil</span>
                </DropdownItem>
                <DropdownItem onClick={e => logout(e, history)}>
                  <i className="ni ni-user-run" />
                  <span>Cerrar sesión</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                        <a href={logo.outterLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </a>
                      )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Navigation */}
            <Nav navbar>
              {availableMenuItems.map(menu => (
                <NavItem key={menu.label}>
                  {menu.path ? (
                    <NavLink
                      to={menu.path}
                      tag={NavLinkRRD}
                      onClick={this.closeCollapse}
                      activeClassName="active"
                    >
                      <FontAwesomeIcon className="mr-2 text-primary" icon={menu.icon} />
                      {menu.label}
                    </NavLink>
                  ) : (
                      <NavLink tag="span" onClick={this.toggleInnerCollapse} className="navbar-toggler flex-column align-items-start">
                        <span>
                          <FontAwesomeIcon className="mr-2 text-primary" icon={menu.icon} />
                          {menu.label}
                          <FontAwesomeIcon className={classNames('ml-3 text-primary', { 'fa-flip-vertical': innerCollapseOpen })} icon={faChevronDown} />
                        </span>
                        <Collapse isOpen={innerCollapseOpen} className="mt-2">
                          {menu.submenu.filter(submenuItem => user.isSuper || submenuItem.permissions.some(permission => user.permissions.map(({ identifier }) => identifier).indexOf(permission) >= 0))
                            .map(item => (
                              <NavLink
                                key={item.label}
                                to={item.path}
                                tag={NavLinkRRD}
                                onClick={e => { e.stopPropagation() }}
                                activeClassName="active"
                              >
                                <FontAwesomeIcon className="mr-2 text-primary" icon={item.icon} />
                                {item.label}
                              </NavLink>
                            ))}
                        </Collapse>
                      </NavLink>
                    )}
                </NavItem>
              ))}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    )
  }
}

const mapStateToProps = ({ session: { user } }) => ({ user })
const mapDispatchToProps = dispatch => ({
  logout: (e, history) => {
    e.preventDefault()
    dispatch(logout(history))
  }
})

Sidebar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired
  }),
  logout: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

Sidebar.defaultProps = {
  user: {
    name: ''
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
