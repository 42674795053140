import instance from '../../axios'
import fileDownload from 'js-file-download'
import { has } from 'lodash'
import moment from 'moment'

export const REPORT_CREATION_BEGIN = 'REPORT_CREATION_BEGIN'
export const REPORT_CREATION_ERROR = 'REPORT_CREATION_ERROR'
export const REPORT_CREATION_FINISHED = 'REPORT_CREATION_FINISHED'

export const generateNotesAndExpensesReport = (options, selectedPanel) => dispatch => {
  dispatch({
    type: REPORT_CREATION_BEGIN
  })

  return instance.get(`/reports/notes-and-expenses`, {
    params: {
      ...has(options, 'billed') && {billed: options.billed},
      startDate: options.startDate ? moment(options.startDate, 'DD/MMMM/YYYY').format('YYYY-MM-DD') : undefined,
      endDate: options.endDate ? moment(options.endDate, 'DD/MMMM/YYYY').format('YYYY-MM-DD') : undefined,
    },
    responseType: 'arraybuffer'
  }).then(response => {
    const { data } = response
    fileDownload(data, `reporte-notas-y-gastos-${new Date().valueOf()}.xlsx`);
    dispatch({
      type: REPORT_CREATION_FINISHED,
    })
  }).catch(err => {
    console.error(err);
    dispatch({
      type: REPORT_CREATION_ERROR,
      error: err,
      selectedPanel,
    })
  })
}
