import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import PaginationComponent from 'react-reactstrap-pagination'

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Button,
  Row,
} from 'reactstrap'
import { loadInvoices, openDeleteInvoiceModal, downloadFile, openPayInvoiceModal } from 'redux/actions/invoices'
import instance from '../../axios'
import TableList from 'components/Invoices/TableList'


class Invoices extends Component {
  componentDidMount() {
    this.props.loadInfo()
  }

  handleFile = (e) => {
    this.setState({
      file: e.target.files[0],
    })
  }

  uploadFile = (id) => {
    const { file } = this.state
    const data = new FormData()
    data.append('file', file)

    instance.post(`/file/note/${id}`, data)
      .then(res => console.log(res))
      .catch(err => console.error(err))
  }

  /* downloadFile = (id) => {
    instance.get(`file/invoice/voucher/${id}`, {
      responseType: 'arraybuffer',
    })
      .then(({ data }) => fileDownload(data, id + '_Voucher.pdf'))
      .catch(err => console.error(err))
  } */

  render() {
    const { invoices, loading, openDeleteInvoiceModal, downloadFile, pagination, loadInfo, accounts, openPayInvoiceModal, history } = this.props


    if (loading) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Facturas
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Facturas
                  <Button tag={Link} to="/admin/invoices/new" className="float-right text-sm btn btn-sm btn-outline-primary btn-sm">
                    <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
                    Nueva Factura
                    <FontAwesomeIcon icon={faPlus} className="ml-2" />
                  </Button>
                </h3>
              </CardHeader>
              {((!invoices || invoices.length === 0)) && (
                <CardBody>No hay Facturas por mostrar, <Link to="/admin/invoices/new" className="text-primary">crea una</Link>.</CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {invoices && invoices.length > 0 && <>
          <Row>
            <Col>
              <Card>
                <TableList
                  invoices={invoices}
                  openDeleteInvoiceModal={openDeleteInvoiceModal}
                  openPayInvoiceModal={openPayInvoiceModal}
                  downloadFile={downloadFile}
                  accounts={accounts}
                  history={history}
                />
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <PaginationComponent
                totalItems={pagination.total}
                pageSize={pagination.perPage}
                onSelect={page => loadInfo({...pagination, current: page})}
                firstPageText="<<"
                previousPageText="<"
                nextPageText=">"
                lastPageText=">>"
                defaultActivePage={pagination.current}
              />
            </Col>
          </Row>
        </>}
      </Container >
    )
  }
}

Invoices.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openDeleteInvoiceModal: PropTypes.func.isRequired,
  openPayInvoiceModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

Invoices.defaultProps = {
  invoices: [],
  permissions: {},
  accounts: []
}

const mapStateToProps = ({ invoices }) => ({
  invoices: invoices.invoices,
  loading: invoices.loading,
  pagination: invoices.pagination,
  accounts: invoices.accounts,
})

const mapDispatchToProps = dispatch => ({
  loadInfo: pagination => dispatch(loadInvoices(pagination)),
  openDeleteInvoiceModal: invoice => dispatch(openDeleteInvoiceModal({ invoice, source: 'list' })),
  openPayInvoiceModal: invoice => dispatch(openPayInvoiceModal({ invoice, source: 'list' })),
  downloadFile: (id, fileType) => dispatch(downloadFile(id, fileType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
