import {
  LOAD_SUPPLIERS_START,
  LOAD_SUPPLIERS_ERROR,
  LOAD_SUPPLIERS_SUCCESS,
  SUPPLIER_FETCH_START,
  SUPPLIER_FETCH_SUCCESS,
  SUPPLIER_FETCH_ERROR,
  OPEN_DELETE_SUPPLIER_MODAL,
  CLOSE_DELETE_SUPPLIER_MODAL,
  DELETE_SUPPLIER_START,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_ERROR,
  ON_CHANGE_SUPPLIER,
  CLEAN_SUPPLIER_SELECT,
} from '../actions/suppliers'
import { getPagination } from 'utils/pagination'

const defaultForm = {
  fiscalName: '',
  alias: '',
  rfc: '',
  turn: '',
  description: '',
  phone: '',
  email: '',
  clabe: '',
}
const defaultPagination = getPagination()
export default (state = {
  suppliers: [],
  loading: false,
  openedDeleteSupplierModal: false,
  loadingSupplier: false,
  form: {...defaultForm},
  pagination: {...defaultPagination},
}, action) => {
  switch (action.type) {
  case OPEN_DELETE_SUPPLIER_MODAL:
    return {
      ...state,
      openedDeleteSupplierModal: true,
      source: action.payload.source,
      selectedSupplier: action.payload.supplier,
    }
  case CLOSE_DELETE_SUPPLIER_MODAL:
    return {
      ...state,
      openedDeleteSupplierModal: false,
      source: null,
      selectedSupplier: null
    }
  case DELETE_SUPPLIER_START:
    return {
      ...state,
      deletingSupplier: true,
    }
  case DELETE_SUPPLIER_SUCCESS:
    return {
      ...state,
      deletingSupplier: false,
      form: {...defaultForm},
      suppliers: state.suppliers.filter(u => u.id !== state.selectedSupplier.id),
      source: null,
      openedDeleteSupplierModal: false,
    }
  case DELETE_SUPPLIER_ERROR:
    return {
      ...state,
    }
  case LOAD_SUPPLIERS_ERROR:
    return {
      ...state,
      suppliers: [],
      error: action.payload
    }
  case LOAD_SUPPLIERS_START:
    return {
      ...state,
      suppliers: [],
      loading: true
    }
  case LOAD_SUPPLIERS_SUCCESS:
    return {
      ...state,
      suppliers: action.payload.results,
      loading: false,
      pagination: {
        ...action.pagination,
        total: action.payload.totalCount,
      }
    }
  case SUPPLIER_FETCH_START:
    return {
      ...state,
      loadingSupplier: true,
      form: defaultForm
    }
  case SUPPLIER_FETCH_SUCCESS:
    return {
      ...state,
      loadingSupplier: false,
      form: action.payload
    }
  case SUPPLIER_FETCH_ERROR:
    return {
      ...state,
      loadingSupplier: false,
      form: action.payload
    }
  case ON_CHANGE_SUPPLIER:
    return {
      ...state,
      supplierId: action.payload
    }
  case CLEAN_SUPPLIER_SELECT:
      return {
        ...state,
        supplierId: null
      }
  default:
    return state
  }
}
