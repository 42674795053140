import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import useForm from 'react-hook-form'
import { faUser, faPencilAlt, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
  UncontrolledTooltip,
} from 'reactstrap'
import PropTypes from 'prop-types'
import { fetchUser, openDeleteUserModal } from 'redux/actions/users'
import BackButton from 'components/BackButton'
import UserForm from 'components/Users/form'

const UserDetail = ({ fetchUser, form, loading, history, match, openDeleteUserModal, currentUser }) => {
  const { register, reset } = useForm()

  useEffect(() => {
    fetchUser(match.params.id)
  }, [fetchUser, match])

  useEffect(() => {
    reset(form)
  }, [form, reset])

  const id = `user_${form.id}`
  const isCurrentUser = !currentUser || currentUser.id === form.id
  const cannotEditSuper = form.isSuper && !currentUser.isSuper
  const disabledDelete = isCurrentUser || cannotEditSuper

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faUser} className="mr-1"/> Detalle de Usuario<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/users/${match.params.id}/edit`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="mr-2"/>
                      Editar Usuario
                    </Button>
                    {isCurrentUser && (
                      <UncontrolledTooltip target={id}>
                        No se puede eliminar a sí mismo.
                      </UncontrolledTooltip>
                    )}
                    {cannotEditSuper && (
                      <UncontrolledTooltip target={id}>
                        No se puede eliminar este usuario por que es un super admin.
                      </UncontrolledTooltip>
                    )}
                    <Button
                      color="warning"
                      disabled={disabledDelete}
                      onClick={() => openDeleteUserModal(form)}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2"/>
                      Borrar Usuario
                      {disabledDelete && (
                        <FontAwesomeIcon
                          id={id}
                          icon={faInfoCircle}
                          className="ml-2"
                        />
                      )}
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
              {!loading && (
                <UserForm
                  readOnly
                  history={history}
                  register={register}
                  currentUserIsSuper={currentUser && currentUser.isSuper}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({ session: { user }, users: { form, loadingUser } }) => ({
  form,
  loading: loadingUser,
  currentUser: user,
})

const mapDispatchToProps = dispatch => ({
  fetchUser: id => dispatch(fetchUser(id)),
  openDeleteUserModal: user => dispatch(openDeleteUserModal({ user, source: 'detail' })),
})

UserDetail.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    user: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    isSuper: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isSuper: PropTypes.bool.isRequired,
  }),
  loading: PropTypes.bool,
  openDeleteUserModal: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
}

UserDetail.defaultProps = {
  loading: false,
  form: {
    user: '',
    name: '',
    email: '',
    isSuper: false,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetail))
