import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'

import AuthFooter from 'components/Footers/AuthFooter.jsx'
import Login from 'views/Login.jsx'

import imgSrc from 'assets/img/brand/terrarrium-white.png'


class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-default')
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default')
  }
  render() {
    return (
      <>
        <div className="main-content">
          <div className="header bg-gradient-dark py-7 py-lg-6">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <img src={imgSrc} alt="Logo Terrarrium" />
                    <h1 className="text-white">Bienvenido!</h1>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                <Route
                  path="/auth/login"
                  component={Login}
                />
              </Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    )
  }
}

export default Auth
