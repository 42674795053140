export const getPaymentMethod = (idPayment, appendId=false) => {
  let paymentText = '';
  let paymentId = '';
  switch (idPayment) {
    case 1:
      paymentId = '01'
      paymentText = 'Efectivo'
      break
    case 2:
      paymentId = '02'
      paymentText = 'Cheque'
      break
    case 3:
      paymentId = '03'
      paymentText = 'Transferencia'
      break
    case 4:
      paymentId = '04'
      paymentText = 'Tarjeta'
      break
    default:
      return '---'
  }

  paymentText = `${appendId? paymentId + ' - ' : ''}${paymentText}`
  return paymentText
}
