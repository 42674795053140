import instance from '../../axios'
import getAsFormData from 'utils/getAsFormData'
import { loadExpenses } from './expenses'
import { getPagination } from 'utils/pagination'
import { closePayExpenseModal } from 'redux/actions/expenses'
import { closePayInvoiceModal } from 'redux/actions/invoices'

export const LOAD_TRANSACTIONS_START = 'LOAD_TRANSACTIONS_START'
export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS'
export const LOAD_TRANSACTIONS_ERROR = 'LOAD_TRANSACTIONS_ERROR'
export const LOAD_PENDING_INVOICES_START = 'LOAD_PENDING_INVOICES_START'
export const LOAD_PENDING_INVOICES_SUCCESS = 'LOAD_PENDING_INVOICES_SUCCESS'
export const LOAD_PENDING_INVOICES_ERROR = 'LOAD_PENDING_INVOICES_ERROR'
export const OPEN_ACCOUNT_MODAL = 'OPEN_ACCOUNT_MODAL'
export const CLOSE_ACCOUNT_MODAL = 'CLOSE_ACCOUNT_MODAL'
export const SELECT_INVOICE = 'SELECT_INVOICE'
export const INVOICE_UPDATE_START = 'INVOICE_UPDATE_START'
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS'
export const INVOICE_UPDATE_ERROR = 'INVOICE_UPDATE_ERROR'
export const ACCOUNT_UPDATE_START = 'ACCOUNT_UPDATE_START'
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS'
export const ACCOUNT_UPDATE_ERROR = 'ACCOUNT_UPDATE_ERROR'

export const TOGGLE_SECTION = 'TOGGLE_SECTION'
export const SELECT_EXPENSE = 'SELECT_EXPENSE'
export const SUBMIT_PAYMENT_START = 'SUBMIT_PAYMENT_START'
export const SUBMIT_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS'
export const SUBMIT_PAYMENT_ERROR = 'SUBMIT_PAYMENT_ERROR'

export const TOGGLE_TRANSACTIONS_MODAL = 'TOGGLE_TRANSACTIONS_MODAL'
export const ACCOUNT_LOG_FETCH_START = 'ACCOUNT_LOG_FETCH_START'
export const ACCOUNT_LOG_SUCCESS = 'ACCOUNT_LOG_SUCCESS'
export const ACCOUNT_LOG_ERROR = 'ACCOUNT_LOG_ERROR'

export const LOAD_WORKS_START = 'LOAD_WORKS_START'
export const LOAD_WORKS_SUCCESS = 'LOAD_WORKS_SUCCESS'
export const LOAD_WORKS_ERROR = 'LOAD_WORKS_ERROR'
export const SET_NEODATA = 'SET_NEODATA'

export const updateAccount = (values, history) => dispatch => {

  dispatch({
    type: ACCOUNT_UPDATE_START
  })

  return instance.post(`/account/setGet/${values.id}`, values)
    .then(({ data }) => {
      dispatch({
        type: ACCOUNT_UPDATE_SUCCESS,
        payload: data
      })
      dispatch({
        type: CLOSE_ACCOUNT_MODAL,
      })
      dispatch(loadTransactions())
    })
    .catch(err => {
      dispatch({
        type: ACCOUNT_UPDATE_ERROR,
        payload: err
      })
    })
}

export const updateInvoice = (values) => dispatch => {
  dispatch({
    type: INVOICE_UPDATE_START
  })

  let formData = getAsFormData(values);

  return instance.post(`/account/setGet/0`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(({ data }) => {
      dispatch({
        type: INVOICE_UPDATE_SUCCESS,
        payload: data
      })
      dispatch(loadTransactions())
      dispatch(loadInvoices(""))
  }).catch(err => {
      dispatch({
        type: INVOICE_UPDATE_ERROR,
        payload: err
      })
  })
}

export const selectInvoice = payload => ({
  type: SELECT_INVOICE,
  payload
})

export const openAccountModal = payload => ({
  type: OPEN_ACCOUNT_MODAL,
  payload
})

export const closeAccountModal = () => ({
  type: CLOSE_ACCOUNT_MODAL,
})

export const loadTransactions = (select, populate) => dispatch => {
  dispatch({
    type: LOAD_TRANSACTIONS_START
  })

  const sort = JSON.stringify([
    { order: 'ASC' },
    { name: 'ASC' },
  ])

  return instance.get(`/accounts`, {
    params: {
      sort,
      select,
      populate,
    }
  })
    .then(({ data }) => {
      dispatch({
        type: LOAD_TRANSACTIONS_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_TRANSACTIONS_ERROR,
        payload: err
      })
    })
}

export const loadInvoices = (search) => dispatch => {
  const where = { paid: false }
  if (search !== "") {
    where.id = search
  }

  dispatch({
    type: LOAD_PENDING_INVOICES_START,
    payload: search
  })

  return instance.get(`/invoices`, {
    params: {
      limit: 500,
      ...Object.keys(where).length > 0 && { where },
    },
  }).then(({ data }) => {
      dispatch({
        type: LOAD_PENDING_INVOICES_SUCCESS,
        payload: data.results,
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_PENDING_INVOICES_ERROR,
        payload: err,
      })
    })
}

export const toggleSection = section => dispatch => {
  return dispatch({
    type: TOGGLE_SECTION,
    section,
  })
}

export const selectExpense = expense => {
  return {
    type: SELECT_EXPENSE,
    expense
  }
}

export const submitExpensePayment = params => dispatch => {
  const formData = getAsFormData(params)
  dispatch({
    type: SUBMIT_PAYMENT_START,
  })

  return instance.post(`/transactions/pay-expense`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(({ data }) => {
    dispatch({
      type: SUBMIT_PAYMENT_SUCCESS,
    })
    dispatch(loadExpenses('', params.hasOwnProperty('status') ? params.status : 'pending'))
    dispatch(loadTransactions())
    dispatch(closePayExpenseModal())
  }).catch(err => {
    dispatch({
      type: SUBMIT_PAYMENT_ERROR,
      payload: err
    })
  })
}

export const submitInvoicePayment = params => dispatch => {
  const formData = getAsFormData(params)
  dispatch({
    type: SUBMIT_PAYMENT_START,
  })

  return instance.post(`/transactions/pay-expense`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(({ data }) => {
    console.log(data);
    dispatch({
      type: SUBMIT_PAYMENT_SUCCESS,
    })
    dispatch(loadInvoices('', params.hasOwnProperty('status') ? params.status : 'pending'))
    dispatch(loadTransactions())
    dispatch(closePayInvoiceModal())
  }).catch(err => {
    dispatch({
      type: SUBMIT_PAYMENT_ERROR,
      payload: err
    })
  })
}

export const setIsModalListOpen = (newState, data) => dispatch => {
  return dispatch({
    type: TOGGLE_TRANSACTIONS_MODAL,
    newState,
    data
  })
}

export const loadAccountLog = (accountId, movType, paginationOpts={perPage: 15}) => dispatch => {
  const pagination = getPagination(paginationOpts)
  dispatch({
    type: ACCOUNT_LOG_FETCH_START,
  })
  let where;
  let logType, paginationType;
  switch (movType) {
    case 'deposits':
      where = {
        accountSet: accountId,
      }
      logType = movType
      paginationType = `${movType}Pagination`
      break

    case 'withdrawals':
      where = {
        accountGet: accountId,
      }
      logType = movType
      paginationType = `${movType}Pagination`
      break

    default:
      where = {
        or: [
          { accountGet: accountId },
          { accountSet: accountId },
        ],
      }
      logType = 'accountLog'
      paginationType = 'pagination'
      break;
  }

  const sortStr = JSON.stringify([
    {date: 'DESC'},
  ])

  return instance.get(`/accountlogs`, {
    params: {
      where,
      sort: sortStr,
      limit: pagination.perPage,
      skip: pagination.skip,
    }
  }).then(({ data }) => {
    dispatch({
      type: ACCOUNT_LOG_SUCCESS,
      accountLog: data,
      pagination: {
        ...pagination,
        total: data.totalCount,
      },
      logType,
      paginationType,
    })
  }).catch(err => {
    dispatch({
      type: ACCOUNT_LOG_ERROR,
      payload: err,
    })
  })
}

export const loadWorks = () => dispatch => {
  dispatch({
    type: LOAD_WORKS_START
  })

  const params = {
    populatesparams: JSON.stringify({ neodatas: { limit: 500 } })
  }
  return instance.get('/works', { params })
    .then(({ data }) => {
      dispatch({
        type: LOAD_WORKS_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_WORKS_ERROR,
        payload: err
      })
    })
}
