import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import { deleteModel, closeDeleteModelModal } from 'redux/actions/models'

const DeleteModal = ({ isOpen, closeModal, model, deleteModel, isLoading, source, history }) => !model ? null : (
  <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
    <ModalHeader toggle={closeModal}>Eliminar Modelo</ModalHeader>
    <ModalBody>
      Estás seguro de que quieres borrar el modelo {model.name} ?
    </ModalBody>
    <ModalFooter>
      <Button disabled={isLoading} color="warning" className="mr-1" onClick={() => deleteModel({model, source, history})}>
        {isLoading && <ReactLoading type="spinningBubbles" color="#fff" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
        Eliminar
      </Button>
      <Button disabled={isLoading} color="primary" onClick={closeModal}>Cancelar</Button>
    </ModalFooter>
  </Modal>
)

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  model: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  source: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  deleteModel: PropTypes.func.isRequired,
}

const mapStateToProps = ({ models: { source, openedDeleteModelModal, selectedModel, deletingModel }}) => {
  return ({
    source,
    isOpen: openedDeleteModelModal,
    model: selectedModel,
    isLoading: deletingModel
  })
}

const mapDispatchToProps = dispatch => ({
  deleteModel: info => dispatch(deleteModel(info)),
  closeModal: () => dispatch(closeDeleteModelModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteModal))
