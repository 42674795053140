import React from 'react';

const EditableField = (props) => {
    return (
      <td className = {props.classes || ""} style={{cursor: 'pointer'}}>
        {
        props.active ?
          <input
            value = {props.value}
            onChange = {props.inputChange}
            onBlur = {props.blur}
            style={props.fieldStyles || {}}
            className={props.fieldClasses || ''}
            autoFocus/>
            :
          <div onDoubleClick = {props.doubleClick}>
            {props.value ? props.value : <>&nbsp;</>}
          </div>
        }
      </td>
    )
  }


export default EditableField;
