import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import SupplierContactForm from 'components/SupplierContacts/form'
import { createSupplierContact, closeAddSupplierContactModal } from 'redux/actions/supplierContacts'

const CreateModal = ({
  isOpen,
  closeModal,
  createSupplierContact,
  form,
  loading,
}) => {
  const { register, handleSubmit, errors, reset } = useForm()
  useEffect(() => {
    reset({ supplier: get(form, 'supplier.id', get(form, 'supplier')) })
  }, [form, reset])

  return (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Añadir Contacto a Proveedor</ModalHeader>
      <Form onSubmit={handleSubmit(createSupplierContact)} noValidate autoComplete="off">
        <ModalBody>
          <SupplierContactForm
            register={register}
            errors={errors}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Añadir Contacto
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

CreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  loadingCategories: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  createSupplierContact: PropTypes.func.isRequired,
  form: PropTypes.shape({
    supplier: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.number])
  })
}

const mapStateToProps = ({
  supplierContacts: { loading, openedAddContactToSupplierModal, form }
}) => ({
  isOpen: openedAddContactToSupplierModal,
  loading,
  form,
})

const mapDispatchToProps = dispatch => ({
  createSupplierContact: info => dispatch(createSupplierContact(info)),
  closeModal: () => dispatch(closeAddSupplierContactModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateModal))
