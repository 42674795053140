import axios from 'axios'
import { toast } from 'react-toastify'

if (!process.env.REACT_APP_API_ENDPOINT) {
  throw new Error('An API Endpoint should be provided as env variable')
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
})

instance.interceptors.response.use((response) => response, (error) => {
  // Do something with request error
  if (error.response && error.response.status) {
    if (error.response.status === 403) {
      toast.error('No tiene permisos para realizar esa acción!', {
        autoClose: 15000,
      })
    }
    if (error.response.status === 401) {
      toast.error('No ha iniciado sesion en el sistema', {
        autoClose: 8000,
        onClose: () => {
          window.location = '/'
        }
      })
    }
  }
  return Promise.reject(error);
});

export const setToken = token => {
  instance.defaults.headers.common['Authorization'] = token
}

export default instance
