import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import useForm from 'react-hook-form'
import { faUserEdit, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { fetchUser, updateUser } from 'redux/actions/users'
import UserForm from 'components/Users/form'
import BackButton from 'components/BackButton'

const EditUser = ({ fetchUser, updateUser, form, loading, history, match }) => {
  const { register, reset, setError, handleSubmit, errors } = useForm()
  useEffect(() => {
    fetchUser(match.params.id)
  }, [fetchUser, match])

  useEffect(() => {
    reset(form)
  }, [form, reset])

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faUserEdit} className="mr-1"/> Editar Usuario<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/users/${match.params.id}`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                      Cancelar
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!loading && (
                <UserForm
                  editUser
                  onSubmit={handleSubmit(params => updateUser({ ...params }, history, setError))}
                  history={history}
                  register={register}
                  errors={errors}
                />
              )}
              {loading && (
                <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({ users: { form, loadingUser } }) => ({
  form,
  loading: loadingUser
})

const mapDispatchToProps = dispatch => ({
  fetchUser: id => dispatch(fetchUser(id)),
  updateUser: (values, history) => dispatch(updateUser(values, history)),
})

EditUser.propTypes = {
  form: PropTypes.shape({
    user: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isSuper: PropTypes.bool.isRequired,
  }),
  loading: PropTypes.bool,
  fetchUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
}

EditUser.defaultProps = {
  loading: false,
  form: {
    user: '',
    name: '',
    email: '',
    isSuper: false,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditUser))
