import {
  LOAD_TRANSACTIONS_START,
  LOAD_TRANSACTIONS_ERROR,
  LOAD_TRANSACTIONS_SUCCESS,
  LOAD_PENDING_INVOICES_START,
  LOAD_PENDING_INVOICES_ERROR,
  LOAD_PENDING_INVOICES_SUCCESS,
  OPEN_ACCOUNT_MODAL,
  CLOSE_ACCOUNT_MODAL,
  SELECT_INVOICE,
  INVOICE_UPDATE_START,
  INVOICE_UPDATE_SUCCESS,
  INVOICE_UPDATE_ERROR,
  ACCOUNT_UPDATE_START,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_ERROR,
  TOGGLE_SECTION,
  SELECT_EXPENSE,
  SUBMIT_PAYMENT_START,
  SUBMIT_PAYMENT_SUCCESS,
  SUBMIT_PAYMENT_ERROR,
  TOGGLE_TRANSACTIONS_MODAL,
  ACCOUNT_LOG_FETCH_START,
  ACCOUNT_LOG_SUCCESS,
  ACCOUNT_LOG_ERROR,
  LOAD_WORKS_START,
  LOAD_WORKS_SUCCESS,
} from '../actions/transactions'
import { getPagination } from 'utils/pagination'

const defaultForm = {
  id: 0,
  amount: 0,
  description: '',
  date: null,
  account: null,
  file: null,
}
const defaultExpenseForm = {
  id: 0,
  amount: 0,
  description: '',
  date: null,
  account: null,
  file: null,
}
const defaultAccountForm = {
  id: 0,
  amount: 0,
  description: '',
  date: null,
  account: null,
  type: 0,
}
const defaultPagination = getPagination({perPage: 15})
export default (state = {
  transactions: [],
  works: [],
  loading: false,
  loadingInvoices: false,
  loadingTransactions: false,
  openedAccountModal: false,
  invoices: [],
  form: {...defaultForm},
  accountForm: {...defaultAccountForm},
  expense_form: { ...defaultExpenseForm },
  typeAccountModal: 0,
  search: '',
  collapse: 0,
  expenses: [],
  error: null,
  isModalListOpen: false,
  transactionData: {},
  loadingAccountLog: false,
  accountLog: {
    deposits: [],
    withdrawals: [],
    accountLog: [],
  },
  pagination: { ...defaultPagination },
  depositsPagination: {...defaultPagination},
  withdrawalsPagination: {...defaultPagination},
}, action) => {
  switch (action.type) {
  case LOAD_WORKS_START:
    return {
      ...state,
      works: [],
      loading: true
    }
  case LOAD_WORKS_SUCCESS:
    return {
      ...state,
      works: action.payload,
      loading: false
    }
  case ACCOUNT_UPDATE_START:
    return {
      ...state,
      loading: true
    }
  case ACCOUNT_UPDATE_SUCCESS:
    return {
      ...state,
      loading: false
    }
  case ACCOUNT_UPDATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case INVOICE_UPDATE_START:
    return {
      ...state,
      loading: true
    }
  case INVOICE_UPDATE_SUCCESS:
    return {
      ...state,
      search: '',
      form: {...defaultForm},
      loading: false
    }
  case INVOICE_UPDATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case SELECT_INVOICE:
    const invoice = action.payload.invoice;
    return {
      ...state,
      form: {
        id: invoice.id,
        amount: invoice.total,
        description: '',
        date: invoice.date,
        file: null,
        notes: invoice.notes,
      }
    }
  case LOAD_TRANSACTIONS_ERROR:
    return {
      ...state,
      transactions: [],
      error: action.payload
    }
  case LOAD_TRANSACTIONS_START:
    return {
      ...state,
      transactions: [],
      loading: true
    }
  case LOAD_TRANSACTIONS_SUCCESS:
    return {
      ...state,
      transactions: action.payload,
      loading: false
    }
  case LOAD_PENDING_INVOICES_ERROR:
    return {
      ...state,
      invoices: [],
      error: action.payload
    }
  case LOAD_PENDING_INVOICES_START:
    return {
      ...state,
      invoices: [],
      loadingInvoices: true,
      search: action.payload,
    }
  case LOAD_PENDING_INVOICES_SUCCESS:
    return {
      ...state,
      invoices: action.payload,
      loadingInvoices: false,
    }
  case OPEN_ACCOUNT_MODAL:
    return {
      ...state,
      openedAccountModal: true,
      typeAccountModal: action.payload.type,
      source: action.payload.source,
      selectedTransaction: action.payload.transaction,
    }
  case CLOSE_ACCOUNT_MODAL:
    return {
      ...state,
      openedAccountModal: false,
      source: null,
      typeAccountModal: 0,
      selectedTransaction: null
    }
  case TOGGLE_SECTION:
    return {
      ...state,
      collapse: action.section,
    }
  case SELECT_EXPENSE:
    const expense = action.expense;
    return {
      ...state,
      expense_form: {
        ...defaultExpenseForm,
        id: expense.id,
        amount: expense.total,
        date: expense.date,
        description: expense.description,
        account: expense.account,
      }
    }
  case SUBMIT_PAYMENT_START:
    return {
      ...state,
      loading: true
    }
  case SUBMIT_PAYMENT_SUCCESS:
    return {
      ...state,
      loading: false,
      expense_form: {
        ...defaultExpenseForm
      }
    }
  case SUBMIT_PAYMENT_ERROR:
    return {
      ...state,
      loading: false,
      error: action.payload
    }
  case TOGGLE_TRANSACTIONS_MODAL:
    return {
      ...state,
      isModalListOpen: action.newState,
      transactionData: action.data,
    }
  case ACCOUNT_LOG_FETCH_START:
    return {
      ...state,
      loadingAccountLog: true,
    }
  case ACCOUNT_LOG_SUCCESS:
    return {
      ...state,
      loadingAccountLog: false,
      accountLog: {
        ...state.accountLog,
        [action.logType]: action.accountLog.results,
      },
      [action.paginationType]: {
        ...action.pagination,
        total: action.accountLog.totalCount,
      }
    }
  case ACCOUNT_LOG_ERROR:
    return {
      ...state,
      loadingAccountLog: false,
      error: action.payload,
      isModalListOpen: false,
    }
  default:
    return state
  }
}
