import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormText,
  Label,
} from 'reactstrap'

const SupplierForm = ({
  onSubmit,
  register,
  errors,
  loading,
  readOnly,
}) => (
  <Form onSubmit={onSubmit} noValidate autoComplete="off">
    <Input name="id" type="hidden" innerRef={register} />
    <div className="pl-lg-4">
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-supplierName"
            >
              Nombre Fiscal
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly}
              id="input-supplierName"
              autoComplete="input-supplierName"
              placeholder="Nombre Fiscal"
              type="text"
              name="fiscalName"
              innerRef={register({ required: true })}
            />
            {errors.fiscalName && errors.fiscalName.type === 'required' && (
              <FormText color="primary">
                Ingrese el nombre fiscal del proveedor
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-alias"
            >
              Alias
            </Label>
            <Input
              className="form-control-alternative"
              id="input-alias"
              disabled={readOnly}
              autoComplete="input-alias"
              placeholder="Alias"
              name="alias"
              innerRef={register}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-rfc"
            >
              RFC
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly}
              id="input-rfc"
              autoComplete="input-rfc"
              placeholder="RFC"
              type="text"
              name="rfc"
              innerRef={register({
                required: false,
                pattern: /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/
              })}
              onChange={e => {
                if (e.target.value) {
                  e.target.value = e.target.value.toUpperCase()
                }
              }}
            />
            {errors.rfc && errors.rfc.type === 'required' && (
              <FormText color="primary">
                Ingrese un RFC para el proveedor
              </FormText>
            )}
            {errors.rfc && errors.rfc.type === 'pattern' && (
              <FormText color="primary">
                Ingrese un RFC válido
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-turn"
            >
              Giro
            </Label>
            <Input
              className="form-control-alternative"
              id="input-turn"
              disabled={readOnly}
              autoComplete="input-turn"
              placeholder="Giro"
              name="turn"
              innerRef={register}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-description"
            >
              Descripción
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly}
              id="input-description"
              autoComplete="input-description"
              placeholder="Descripción"
              type="text"
              name="description"
              innerRef={register}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-phone"
            >
              Teléfono
            </Label>
            <Input
              className="form-control-alternative"
              id="input-phone"
              disabled={readOnly}
              autoComplete="input-phone"
              placeholder="Teléfono"
              name="phone"
              innerRef={register({
                validate: value => value.match(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/) || value === ''
              })}
            />
            {errors.phone && errors.phone.type === 'validate' && (
              <FormText color="primary">
                Ingrese un teléfono válido
              </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-email"
            >
              Correo electrónico
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly}
              id="input-email"
              autoComplete="input-email"
              placeholder="Correo electrónico"
              type="email"
              name="email"
              innerRef={register({
                validate: value => value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || value === ''
              })}
            />
            {errors.email && errors.email.type === 'validate' && (
              <FormText color="primary">
                Ingrese un correo electrónico válido
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-clabe"
            >
              CLABE
            </Label>
            <Input
              className="form-control-alternative"
              id="input-clabe"
              disabled={readOnly}
              autoComplete="input-clabe"
              placeholder="CLABE"
              type="text"
              name="clabe"
              innerRef={register({
                validate: value => {
                  return /^\d+$/.test(value) || value === '' // se quito clabe validator por conflictos con produccion totalmente
                }
              })}
            />
            {errors.clabe && errors.clabe.type === 'validate' && (
              <FormText color="primary">
                Debes ingresar una cuenta CLABE válida
              </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
    </div>
    {!readOnly && (
      <>
        <hr className="my-4" />
        <Button disabled={loading} color="primary">Guardar</Button>
      </>
    )}
  </Form>
)

SupplierForm.propTypes = {
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.shape({
    fiscalName: PropTypes.shape({
      type: PropTypes.string
    }),
    rfc: PropTypes.shape({
      type: PropTypes.string
    }),
    email: PropTypes.shape({
      type: PropTypes.string
    }),
    phone: PropTypes.shape({
      type: PropTypes.string
    }),
    clabe: PropTypes.shape({
      type: PropTypes.string
    }),
  }),
  onSubmit: PropTypes.func,
  register: PropTypes.func.isRequired,
}

SupplierForm.defaultProps = {
  loading: false,
  readOnly: false,
  errors: {},
}

export default SupplierForm
