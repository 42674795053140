import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faSquare, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import ReactLoading from 'react-loading'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import MultipleExpensesForm from 'components/Expenses/multipleExpenses'
import { loadTransactions } from 'redux/actions/transactions'
import { loadSuppliers } from 'redux/actions/suppliers'
import { loadWorks } from 'redux/actions/works'
import { createMultipleExpenses } from 'redux/actions/expenses'


const CreateMultipleExpenses = ({
  loading,
  history,
  suppliers,
  works,
  neodatas,
  accounts,
  createMultipleExpenses,
  fetchAccounts,
  fetchSuppliers,
  fetchWorks,
}) => {

  useEffect(() => {
    fetchAccounts();
    fetchSuppliers();
    fetchWorks();
  }, [fetchAccounts, fetchSuppliers, fetchWorks])

  console.log(new Date(), "LOADING", loading)

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <Button
        color="primary"
        onClick={() => history.goBack()}
        size="sm"
        className="mb-4"
      >
        <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
        Regresar
      </Button>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faSquare} className="mr-1"/> Registra hasta 10 gastos<br/>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!loading && (
                <MultipleExpensesForm
                  onSubmit={info => createMultipleExpenses(info, history)}
                  suppliers={suppliers}
                  works={works}
                  neodatas={neodatas}
                  accounts={accounts}
                  loading={loading}
                />
              )}
              {loading && (
                <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
              )}
              {loading && "Cargando..."}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({
  expenses: { multipleForm, loading },
  session: { user },
  transactions: { transactions: accounts, loading: loadingAccounts },
  suppliers: { loading: loadingSuppliers, suppliers },
  works: { works, loading: loadingWorks },
}) => ({
  accounts,
  loading: loadingAccounts || loadingSuppliers || loadingWorks,
  suppliers,
  works,
  /* form,
  hasDependencies: get(form, 'categories', []).length > 0,
  loading: loadingLand,
  user, */
})

const mapDispatchToProps = dispatch => ({
  createMultipleExpenses: (info, history)=> dispatch(createMultipleExpenses(info, history)),
  fetchAccounts: () => dispatch(loadTransactions('id,name', false)),
  fetchSuppliers: () => dispatch(loadSuppliers({perPage: 10000}, 'id,alias,fiscalName')),
  fetchWorks: () => dispatch(loadWorks()),
})

CreateMultipleExpenses.propTypes = {
  form: PropTypes.array,
  /* form: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
    has_license: PropTypes.bool,
    is_predial_paid: PropTypes.string,
    predial_document: PropTypes.string,
    shopper: PropTypes.shape({}),
    block: PropTypes.shape({}),
    model: PropTypes.shape({}),
  }),
  loading: PropTypes.bool,
  openDeleteLandModal: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  hasDependencies: PropTypes.bool.isRequired */
}

CreateMultipleExpenses.defaultProps = {
  loading: false,
  form: [],
  suppliers: [],
  neodatas: [],
  works: [],
  accounts: [],
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateMultipleExpenses))
