import {
  LOGIN_STARTED,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_PROFILE_UPDATE_START,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_ERROR,
} from '../actions/session'

export default (state = {
  loggedIn: false,
  loading: false,
}, action) => {
  switch (action.type) {
  case LOGOUT:
    return {
      loggedIn: false,
      user: null
    }
  case LOGIN_SUCCESS:
    return {
      loggedIn: true,
      user: action.payload
    }
  case LOGIN_ERROR:
    return {
      loggedIn: false,
      error: action.payload
    }
  case LOGIN_STARTED:
    return {
      loggedIn: false,
      loading: true
    }
  case USER_PROFILE_UPDATE_START:
    return {
      ...state,
      loading: true
    }
  case USER_PROFILE_UPDATE_SUCCESS:
    return {
      ...state,
      loading: false,
      user: action.payload
    }
  case USER_PROFILE_UPDATE_ERROR:
    return {
      ...state,
      loading: false,
      error: action.payload
    }
  default:
    return state
  }
}
