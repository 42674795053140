import React from 'react';
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText,
} from 'reactstrap';
import useForm from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import ReactDatetime from 'react-datetime'
import moment from 'moment'

const tomorrow = moment().add(1, 'day').startOf('day')

const ReportWithDatesForm = props => {
  const {
    onSubmit,
    loadingReport,
  } = props

  const { handleSubmit, register, getValues, errors: formErrors } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>

      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label className="form-control-label" htmlFor="startDate">Fecha de inicio</Label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                className="form-control-alternative"
                dateFormat="DD/MMMM/YYYY"
                timeFormat={false}
                closeOnSelect={true}
                isValidDate={current => current.isBefore(tomorrow)}
                inputProps={{
                  autoComplete: 'off',
                  placeholder: 'Fecha de inicio del reporte',
                  name: 'startDate',
                  readOnly: true,
                  ref: register({
                    validate: value => {
                      const {endDate} = getValues()
                      const selected = value !== ''? moment(value, 'DD/MMMM/YYYY') : moment('1970-01-01')
                      const endSelected = endDate !== ''? moment(endDate, 'DD/MMMM/YYYY') : moment().endOf('day')
                      return selected.isSameOrBefore(endSelected)
                    }
                  }),
                }}
              />
            </InputGroup>
            {formErrors.startDate && formErrors.startDate.type === 'required' && (
              <FormText color="primary">
                Seleccione una fecha.
              </FormText>
            )}
            {formErrors.startDate && formErrors.startDate.type === 'validate' && (
              <FormText color="primary">
                Ingrese una fecha válida o seleccione una en el calendario.
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label className="form-control-label" htmlFor="endDate">Fecha final</Label>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  className="form-control-alternative"
                  dateFormat="DD/MMMM/YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  isValidDate={current => current.isBefore(tomorrow)}
                  inputProps={{
                    autoComplete: 'off',
                    readOnly: true,
                    placeholder: 'Fecha final del reporte',
                    name: 'endDate',
                    ref: register({
                      validate: value => {
                        const {startDate} = getValues()
                        const selected = value !== ''? moment(value, 'DD/MMMM/YYYY') : moment().endOf('day')
                        const startSelected = startDate !== ''? moment(startDate, 'DD/MMMM/YYYY') : moment('1970-01-01')
                        return selected.isSameOrAfter(startSelected)
                      }
                    }),
                  }}
                />
              </InputGroup>
              {formErrors.endDate && formErrors.endDate.type === 'required' && (
                <FormText color="primary">
                  Seleccione una fecha.
                </FormText>
              )}
              {formErrors.endDate && formErrors.endDate.type === 'validate' && (
                <FormText color="primary">
                  Ingrese una fecha válida o seleccione una en el calendario.
                </FormText>
              )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            color="primary"
            size="sm"
            disabled={loadingReport}
          >
            <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
            Generar reporte
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default ReportWithDatesForm;
