import {
  LOAD_LAND_CATEGORIES_START,
  LOAD_LAND_CATEGORIES_ERROR,
  LOAD_LAND_CATEGORIES_SUCCESS,
  LAND_CATEGORY_FETCH_START,
  LAND_CATEGORY_FETCH_SUCCESS,
  LAND_CATEGORY_FETCH_ERROR,
  LAND_CATEGORY_CREATE_START,
  LAND_CATEGORY_CREATE_SUCCESS,
  LAND_CATEGORY_CREATE_ERROR,
  OPEN_DELETE_LAND_CATEGORY_MODAL,
  CLOSE_DELETE_LAND_CATEGORY_MODAL,
  DELETE_LAND_CATEGORY_START,
  DELETE_LAND_CATEGORY_SUCCESS,
  DELETE_LAND_CATEGORY_ERROR,
  LAND_CATEGORY_UPDATE_START,
  LAND_CATEGORY_UPDATE_SUCCESS,
  LAND_CATEGORY_UPDATE_ERROR,
  OPEN_ADD_CATEGORY_TO_LAND_MODAL,
  CLOSE_ADD_CATEGORY_TO_LAND_MODAL,
  OPEN_EDIT_LAND_CATEGORY_MODAL,
  CLOSE_EDIT_LAND_CATEGORY_MODAL,
  OPEN_EDIT_CATEGORY_PROVIDERS_MODAL,
  CLOSE_EDIT_LAND_CATEGORY_SUPPLIERS_MODAL,
  UPDATE_SUPPLIERS_FIELD,
  LAND_CATEGORY_SUPPLIERS_UPDATE_SUCCESS,
  LAND_CATEGORY_SUPPLIERS_UPDATE_ERROR,
} from '../actions/landCategories'
import { getPagination } from 'utils/pagination'

const defaultForm = {
  category: {},
  land: {},
  suppliersField: [],
  limit: 0,
}
const defaultPagination = getPagination()
export default (state = {
  landCategories: [],
  loading: false,
  openedRemoveCategoryFromLandModal: false,
  openedAddCategoryToLandModal: false,
  openedEditLandCategoryModal: false,
  openedEditCategoryProvidersModal: false,
  form: {...defaultForm},
  suppliersField: [],
  pagination: {...defaultPagination},
}, action) => {
  switch (action.type) {
  case LAND_CATEGORY_CREATE_START:
    return {
      ...state,
      loading: true
    }
  case LAND_CATEGORY_CREATE_SUCCESS:
    return {
      ...state,
      loading: false,
      updatingLandCategory: false
    }
  case LAND_CATEGORY_CREATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case OPEN_ADD_CATEGORY_TO_LAND_MODAL:
    return {
      ...state,
      form: {...defaultForm, land: action.payload },
      openedAddCategoryToLandModal: true
    }
  case CLOSE_ADD_CATEGORY_TO_LAND_MODAL:
    return {
      ...state,
      form: {...defaultForm},
      openedAddCategoryToLandModal: false
    }
  case OPEN_EDIT_LAND_CATEGORY_MODAL:
    return {
      ...state,
      openedEditLandCategoryModal: true,
      form: action.payload,
    }
  case CLOSE_EDIT_LAND_CATEGORY_MODAL:
    return {
      ...state,
      openedEditLandCategoryModal: false,
      form: {...defaultForm}
    }
  case OPEN_DELETE_LAND_CATEGORY_MODAL:
    return {
      ...state,
      openedRemoveCategoryFromLandModal: true,
      selectedLandCategory: action.payload,
    }
  case CLOSE_DELETE_LAND_CATEGORY_MODAL:
    return {
      ...state,
      openedRemoveCategoryFromLandModal: false,
      selectedLandCategory: null
    }
  case OPEN_EDIT_CATEGORY_PROVIDERS_MODAL:
    return {
      ...state,
      openedEditCategoryProvidersModal: true,
      form: action.payload,
    }
  case CLOSE_EDIT_LAND_CATEGORY_SUPPLIERS_MODAL:
    return {
      ...state,
      openedEditCategoryProvidersModal: false,
      form: {...defaultForm},
      suppliersField: [],
    }
  case UPDATE_SUPPLIERS_FIELD:
    return {
      ...state,
      form: { ...(state.form), suppliers: [] },
      suppliersField: action.suppliersField
    }
  case LAND_CATEGORY_SUPPLIERS_UPDATE_SUCCESS:
    return {
      ...state,
      form: {...defaultForm},
      suppliersField: [],
    }
  case LAND_CATEGORY_SUPPLIERS_UPDATE_ERROR:
    return {
      ...state,
    }
  case DELETE_LAND_CATEGORY_START:
    return {
      ...state,
      deletingLandCategory: true,
    }
  case DELETE_LAND_CATEGORY_SUCCESS:
    return {
      ...state,
      deletingLandCategory: false,
      form: {...defaultForm},
      landCategories: state.landCategories.filter(u => u.id !== state.selectedLandCategory.id),
      openedRemoveCategoryFromLandModal: false,
    }
  case DELETE_LAND_CATEGORY_ERROR:
    return {
      ...state,
    }
  case LAND_CATEGORY_UPDATE_START:
    return {
      ...state,
      updatingLandCategory: true,
    }
  case LAND_CATEGORY_UPDATE_SUCCESS:
    return {
      ...state,
      updatingLandCategory: false,
      form: {...defaultForm},
      openedEditLandCategoryModal: false,
    }
  case LAND_CATEGORY_UPDATE_ERROR:
    return {
      ...state,
    }
  case LOAD_LAND_CATEGORIES_ERROR:
    return {
      ...state,
      landCategories: [],
      error: action.payload
    }
  case LOAD_LAND_CATEGORIES_START:
    return {
      ...state,
      landCategories: [],
      loading: true
    }
  case LOAD_LAND_CATEGORIES_SUCCESS:
    return {
      ...state,
      landCategories: action.payload.results,
      loading: false,
      pagination: {
        ...action.pagination,
        total: action.payload.totalCount,
      }
    }
  case LAND_CATEGORY_FETCH_START:
    return {
      ...state,
      loadingLandCategory: true,
      form: defaultForm
    }
  case LAND_CATEGORY_FETCH_SUCCESS:
    return {
      ...state,
      loadingLandCategory: false,
      form: action.payload
    }
  case LAND_CATEGORY_FETCH_ERROR:
    return {
      ...state,
      loadingLandCategory: false,
      form: action.payload
    }
  default:
    return state
  }
}
