import React, { useEffect } from 'react'
import get from 'lodash/get'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import LandNoteForm from 'components/LandNotes/form'
import { createLandNote, closeAddLandNoteModal, pushLandNoteForm } from 'redux/actions/landNotes'
import { changeModalCategory } from 'redux/actions/categories'
import loadLandsAndCategories from 'components/HOC/loadLandsAndCategories'

const CreateModal = ({
  isOpen,
  closeModal,
  createLandNote,
  loadCategories,
  loadingLandNotes,
  loadingLands,
  loadingCategories,
  categories,
  lands,
  form,
  changeModalCategory,
  category,
  pushLandNoteForm,
  isNew,
  supplierId,
  error,
}) => {
  const {
    register,
    errors,
    setError,
    clearError,
    handleSubmit,
    getValues,
    reset
  } = useForm()

  useEffect(() => {
    reset({ note: form.note })
  }, [form, reset])

  useEffect(() => {
    if (error)
      setError('amount', 'serverError', error)
  }, [setError, error])

  const { land } = getValues()
  const submitForm = isNew ? pushLandNoteForm : createLandNote

  return (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Añadir Concepto/Categoría a Nota</ModalHeader>
      <Form onSubmit={handleSubmit(submitForm)} noValidate autoComplete="off">
        <ModalBody>
          <LandNoteForm
            register={register}
            errors={errors}
            setError={setError}
            clearError={clearError}
            loadingLands={loadingLands}
            loadingCategories={loadingCategories}
            loadCategories={loadCategories}
            categories={categories}
            lands={lands}
            closeModal={closeModal}
            category={category}
            changeModalCategory={changeModalCategory}
            land={land}
            supplierId={supplierId}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={
              loadingLands ||
              loadingCategories ||
              get(categories, 'length', 0) === 0 ||
              get(lands, 'length', 0) === 0 ||
              !land ||
              !category ||
              Object.keys(errors).length !== 0
            }
            color="primary"
            className="mr-1"
            type="submit"
          >
            {(loadingLands || loadingCategories || loadingLandNotes) && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Añadir Concepto
          </Button>
          <Button disabled={loadingLands || loadingCategories || !!errors.amount} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

CreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loadingLandNotes: PropTypes.bool,
  loadingLands: PropTypes.bool,
  loadingCategories: PropTypes.bool,
  form: PropTypes.shape({
    note: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({})
    ]),
  }),
  loadCategories: PropTypes.func.isRequired,
  lands: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      street: PropTypes.string,
      number: PropTypes.string,
      id: PropTypes.number,
    })
  ])),
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number
  })),
  closeModal: PropTypes.func.isRequired,
  createLandNote: PropTypes.func.isRequired,
  category: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ])
}

const mapStateToProps = props => {
  const {
    landNotes: { loading: loadingLandNotes, form, isNew, error },
    categories: { categories }
  } = props

  return {
    form,
    categories,
    loadingLandNotes,
    isNew,
    error,
  }
}

const mapDispatchToProps = dispatch => ({
  pushLandNoteForm: data => dispatch(pushLandNoteForm(data)),
  createLandNote: info => dispatch(createLandNote(info)),
  closeModal: () => dispatch(closeAddLandNoteModal()),
  changeModalCategory: category => dispatch(changeModalCategory(category)),
})

export default withRouter(loadLandsAndCategories(connect(mapStateToProps, mapDispatchToProps)(CreateModal), 'openedAddNoteToLandModal'))
