import { toast } from 'react-toastify'
import instance from '../../axios'
import sanitizeValues from 'utils/sanitizeValues'
import { fetchSupplier } from './suppliers'

export const SUPPLIER_CONTACT_CREATE_START = 'SUPPLIER_CONTACT_CREATE_START'
export const SUPPLIER_CONTACT_CREATE_SUCCESS = 'SUPPLIER_CONTACT_CREATE_SUCCESS'
export const SUPPLIER_CONTACT_CREATE_ERROR = 'SUPPLIER_CONTACT_CREATE_ERROR'

export const SUPPLIER_CONTACT_UPDATE_START = 'SUPPLIER_CONTACT_UPDATE_START'
export const SUPPLIER_CONTACT_UPDATE_SUCCESS = 'SUPPLIER_CONTACT_UPDATE_SUCCESS'
export const SUPPLIER_CONTACT_UPDATE_ERROR = 'SUPPLIER_CONTACT_UPDATE_ERROR'

export const OPEN_ADD_CONTACT_TO_SUPPLIER_MODAL = 'OPEN_ADD_CONTACT_TO_SUPPLIER_MODAL'
export const CLOSE_ADD_CONTACT_TO_SUPPLIER_MODAL = 'CLOSE_ADD_CONTACT_TO_SUPPLIER_MODAL'

export const OPEN_DELETE_SUPPLIER_CONTACT_MODAL = 'OPEN_DELETE_SUPPLIER_CONTACT_MODAL'
export const CLOSE_DELETE_SUPPLIER_CONTACT_MODAL = 'CLOSE_DELETE_SUPPLIER_CONTACT_MODAL'

export const OPEN_EDIT_SUPPLIER_CONTACT_MODAL = 'OPEN_EDIT_SUPPLIER_CONTACT_MODAL'
export const CLOSE_EDIT_SUPPLIER_CONTACT_MODAL = 'CLOSE_EDIT_SUPPLIER_CONTACT_MODAL'

export const DELETE_SUPPLIER_CONTACT_START = 'DELETE_SUPPLIER_CONTACT_START'
export const DELETE_SUPPLIER_CONTACT_SUCCESS = 'DELETE_SUPPLIER_CONTACT_SUCCESS'
export const DELETE_SUPPLIER_CONTACT_ERROR = 'DELETE_SUPPLIER_CONTACT_ERROR'

export const openAddSupplierContactModal = payload => ({
  type: OPEN_ADD_CONTACT_TO_SUPPLIER_MODAL,
  payload
})

export const closeAddSupplierContactModal = () => ({
  type: CLOSE_ADD_CONTACT_TO_SUPPLIER_MODAL,
})

export const openEditSupplierContactModal = payload => ({
  type: OPEN_EDIT_SUPPLIER_CONTACT_MODAL,
  payload
})

export const closeEditSupplierContactModal = () => ({
  type: CLOSE_EDIT_SUPPLIER_CONTACT_MODAL,
})

export const openDeleteSupplierContactModal = payload => ({
  type: OPEN_DELETE_SUPPLIER_CONTACT_MODAL,
  payload
})

export const closeDeleteSupplierContactModal = () => ({
  type: CLOSE_DELETE_SUPPLIER_CONTACT_MODAL,
})

export const deleteSupplierContact = supplierContact => dispatch => {
  dispatch({
    type: DELETE_SUPPLIER_CONTACT_START
  })

  return instance.delete(`/supplierContacts/${supplierContact.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_SUPPLIER_CONTACT_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado al contacto ${data.name} del proveedor ${data.supplier.alias}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      fetchSupplier(data.supplier.id)(dispatch)
    })
    .catch(err => {
      dispatch({
        type: DELETE_SUPPLIER_CONTACT_ERROR,
        payload: err
      })
    })
}

export const updateSupplierContact = values => dispatch => {
  dispatch({
    type: SUPPLIER_CONTACT_UPDATE_START
  })

  const sanitized = sanitizeValues(values)

  return instance.patch(`/supplierContacts/${sanitized.id}`, sanitized)
    .then(({ data }) => {
      dispatch({
        type: SUPPLIER_CONTACT_UPDATE_SUCCESS,
        payload: data
      })

      fetchSupplier(data.supplier.id)(dispatch)
    })
    .catch(err => {
      dispatch({
        type: SUPPLIER_CONTACT_UPDATE_ERROR,
        payload: err
      })
    })
}

// eslint-disable-next-line no-unused-vars
export const createSupplierContact = ({ id, ...values }) => dispatch => {
  dispatch({
    type: SUPPLIER_CONTACT_CREATE_START
  })

  const sanitized = sanitizeValues(values)

  return instance.post('/supplierContacts', sanitized)
    .then(({ data }) => {
      dispatch({
        type: SUPPLIER_CONTACT_CREATE_SUCCESS,
        payload: data
      })
      fetchSupplier(data.supplier.id)(dispatch)
    })
    .catch(err => {
      dispatch({
        type: SUPPLIER_CONTACT_CREATE_ERROR,
        payload: err
      })
    })
}
