import React, { useEffect } from 'react'
import get from 'lodash/get'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import BlockForm from 'components/Blocks/form'
import { updateBlock, closeEditBlockModal } from 'redux/actions/blocks'

const EditModal = ({
  isOpen,
  closeModal,
  updateBlock,
  loading,
  form,
}) => {
  const { register, handleSubmit, errors, reset } = useForm()

  useEffect(() => {
    reset({ ...form, project: get(form, 'project.id', get(form, 'project')) })
  }, [form, reset])

  return (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Editar Manzana</ModalHeader>
      <Form onSubmit={handleSubmit(updateBlock)} noValidate autoComplete="off">
        <ModalBody>
          <BlockForm
            register={register}
            errors={errors}
            inProject
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Actualizar Manzana
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

EditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.shape({
    amount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    date: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
    ]),
    info: PropTypes.string,
    isCharge: PropTypes.bool,
    id: PropTypes.number,
    shopper: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
  }),
  closeModal: PropTypes.func.isRequired,
  updateBlock: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  blocks: { updatingBlock, openedEditBlockModal, form }
}) => ({
  form,
  isOpen: openedEditBlockModal,
  loading: updatingBlock,
})

const mapDispatchToProps = dispatch => ({
  updateBlock: info => dispatch(updateBlock(info, null, true)),
  closeModal: () => dispatch(closeEditBlockModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditModal))
