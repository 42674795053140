import {
  LOAD_SHOPPERS_START,
  LOAD_SHOPPERS_ERROR,
  LOAD_SHOPPERS_SUCCESS,
  SHOPPER_FETCH_START,
  SHOPPER_FETCH_SUCCESS,
  SHOPPER_FETCH_ERROR,
  OPEN_DELETE_SHOPPER_MODAL,
  CLOSE_DELETE_SHOPPER_MODAL,
  DELETE_SHOPPER_START,
  DELETE_SHOPPER_SUCCESS,
  DELETE_SHOPPER_ERROR,
} from '../actions/shoppers'
import { getPagination } from 'utils/pagination'

const defaultForm = {
  name: '',
  lastName: '',
  mLastName: '',
  phone: '',
  email: '',
}
const defaultPagination = getPagination()
export default (state = {
  shoppers: [],
  loading: false,
  openedDeleteShopperModal: false,
  form: {...defaultForm},
  pagination: {...defaultPagination},
}, action) => {
  switch (action.type) {
  case OPEN_DELETE_SHOPPER_MODAL:
    return {
      ...state,
      openedDeleteShopperModal: true,
      source: action.payload.source,
      selectedShopper: action.payload.shopper,
    }
  case CLOSE_DELETE_SHOPPER_MODAL:
    return {
      ...state,
      openedDeleteShopperModal: false,
      source: null,
      selectedShopper: null
    }
  case DELETE_SHOPPER_START:
    return {
      ...state,
      deletingShopper: true,
    }
  case DELETE_SHOPPER_SUCCESS:
    return {
      ...state,
      deletingShopper: false,
      form: {...defaultForm},
      shoppers: state.shoppers.filter(u => u.id !== state.selectedShopper.id),
      source: null,
      openedDeleteShopperModal: false,
    }
  case DELETE_SHOPPER_ERROR:
    return {
      ...state,
    }
  case LOAD_SHOPPERS_ERROR:
    return {
      ...state,
      shoppers: [],
      error: action.payload
    }
  case LOAD_SHOPPERS_START:
    return {
      ...state,
      shoppers: [],
      loading: true
    }
  case LOAD_SHOPPERS_SUCCESS:
    return {
      ...state,
      shoppers: action.payload.results,
      loading: false,
      pagination: {
        ...action.pagination,
        total: action.payload.totalCount,
      },
    }
  case SHOPPER_FETCH_START:
    return {
      ...state,
      loadingShopper: true,
      form: defaultForm
    }
  case SHOPPER_FETCH_SUCCESS:
    return {
      ...state,
      loadingShopper: false,
      form: action.payload
    }
  case SHOPPER_FETCH_ERROR:
    return {
      ...state,
      loadingShopper: false,
      form: action.payload
    }
  default:
    return state
  }
}
