import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { loadCategories as loadInfo } from 'redux/actions/categories'

const loadCategories = (component, pagination={}) => {
  const pag = pagination ? pagination : {};
  const LoadCategories = ({ isOpen, loadCategories: loadInfo }) => {
    useEffect(() => {
      if (isOpen) {
        loadInfo(pag)
      }
    }, [isOpen, loadInfo, pag])

    const Component = component
    return <Component />
  }

  const mapDispatchToProps = dispatch => ({
    loadCategories: () => dispatch(loadInfo(pag)),
  })

  const mapStateToProps = ({
    modelCategories: { openedAddCategoryToModelModal: isOpenModel },
    landCategories: { openedAddCategoryToLandModal: isOpenLand }
  }) => ({
    isOpen: isOpenModel || isOpenLand
  })

  LoadCategories.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    loadCategories: PropTypes.func.isRequired
  }

  return connect(mapStateToProps, mapDispatchToProps)(LoadCategories)
}

export default loadCategories
