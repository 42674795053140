import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import { deleteModelCategory, closeDeleteModelCategoryModal } from 'redux/actions/modelCategories'

const DeleteModal = ({ isOpen, closeModal, modelCategory, deleteModelCategory, isLoading }) => !modelCategory ? null : (
  <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
    <ModalHeader toggle={closeModal}>Eliminar Categoría de Modelo</ModalHeader>
    <ModalBody>
      Estás seguro de que quieres borrar la categoría {modelCategory.category.name} del modelo {modelCategory.model.name} ?
    </ModalBody>
    <ModalFooter>
      <Button disabled={isLoading} color="warning" className="mr-1" onClick={() => deleteModelCategory(modelCategory)}>
        {isLoading && <ReactLoading type="spinningBubbles" color="#fff" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
        Eliminar
      </Button>
      <Button disabled={isLoading} color="primary" onClick={closeModal}>Cancelar</Button>
    </ModalFooter>
  </Modal>
)

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  modelCategory: PropTypes.shape({
    category: PropTypes.shape({
      name: PropTypes.string
    }),
    model: PropTypes.shape({
      name: PropTypes.string
    }),
  }),
  closeModal: PropTypes.func.isRequired,
  deleteModelCategory: PropTypes.func.isRequired,
}

const mapStateToProps = ({ modelCategories: { openedRemoveCategoryFromModelModal, selectedModelCategory, deletingModelCategory }}) => {
  return ({
    isOpen: openedRemoveCategoryFromModelModal,
    modelCategory: selectedModelCategory,
    isLoading: deletingModelCategory
  })
}

const mapDispatchToProps = dispatch => ({
  deleteModelCategory: info => dispatch(deleteModelCategory(info)),
  closeModal: () => dispatch(closeDeleteModelCategoryModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteModal))
