import React from 'react'
import {
  Table,
  Row,
  Col,
} from 'reactstrap'
import { get } from 'lodash'
import moment from 'moment'
import { formatCurrency } from 'utils/numberFormat'
import PaginationComponent from 'react-reactstrap-pagination'

const getAccountMovementType = (selectedAccount, movement) => {
  switch (movement.type) {
    case 0:
      return get(movement, 'accountGet.id') === selectedAccount?
      'Transferencia hacia cuenta' :
      (movement.accountGet ? 'Transferencia desde cuenta' : 'Depósito')
    case 1:
      return 'Transferencia a cuenta externa'
    case 2:
      return 'Pago factura'
    case 3:
      return 'Pago de gasto'

    default:
      return 'Otro tipo'
  }
}

const AccountLogTable = ({ accountId, accountLog, depositsPagination, withdrawalsPagination, loadAccountLog }) => {

  const payments = accountLog.deposits
  const transfers = accountLog.withdrawals

  return (
  <>
    <Row>
      <Table striped responsive>
        <thead>
          <tr>
            <th colSpan="6" className="text-center">Depósitos</th>
          </tr>
          <tr>
            <th>FECHA</th>
            <th>#</th>
            <th>TIPO DE MOVIMIENTO</th>
            <th>DESCRIPCIÓN</th>
            <th>CUENTA DESTINO</th>
            <th>MONTO</th>
          </tr>
        </thead>
        <tbody>
          {payments.length > 0 && payments.map(activity => (
            <tr key={activity.id}>
              <td>{ moment(activity.date).utc().format('DD-MMMM-YYYY') }</td>
              <td>{ activity.id }</td>
              <td>{ getAccountMovementType(accountId, activity) }</td>
              <td>{ activity.description }</td>
              <td>{ get(activity, 'accountSet.name', 'N/A') }</td>
              <td>{ formatCurrency({amount: activity.amount}) }</td>
            </tr>))
          }
          {payments.length === 0 && <tr>
            <td colSpan="6">SIN MOVIMIENTOS</td>
          </tr>}
        </tbody>
      </Table>
    </Row>

    <Row className="mt-5">
      <Col>
        {depositsPagination.total > 0 && <PaginationComponent
          totalItems={depositsPagination.total}
          pageSize={depositsPagination.perPage}
          onSelect={page => {
            loadAccountLog(accountId, 'deposits', {...depositsPagination, current: page})
          }}
          firstPageText="<<"
          previousPageText="<"
          nextPageText=">"
          lastPageText=">>"
          defaultActivePage={depositsPagination.current}
        />}
      </Col>
    </Row>

    <Row className="mt-3">
      <Table striped responsive>
        <thead>
          <tr>
            <th colSpan="6" className="text-center">Retiros</th>
          </tr>
          <tr>
            <th>FECHA</th>
            <th>#</th>
            <th>TIPO DE MOVIMIENTO</th>
            <th>DESCRIPCIÓN</th>
            <th>CUENTA DESTINO</th>
            <th>MONTO</th>
          </tr>
        </thead>
        <tbody>
          {transfers.length > 0 && transfers.map(activity => (
            <tr key={activity.id}>
              <td>{ moment(activity.date).utc().format('DD-MMMM-YYYY') }</td>
              <td>{ activity.id }</td>
              <td>{ getAccountMovementType(accountId, activity) }</td>
              <td>{ activity.description }</td>
              <td>{ get(activity, 'accountSet.name', 'N/A') }</td>
              <td>{ formatCurrency({amount: activity.amount}) }</td>
            </tr>))
          }
          {transfers.length === 0 && <tr>
            <td colSpan="6">SIN MOVIMIENTOS</td>
          </tr>}
        </tbody>
      </Table>
    </Row>

    <Row className="mt-5">
      <Col>
        {withdrawalsPagination.total > 0 && <PaginationComponent
          totalItems={withdrawalsPagination.total}
          pageSize={withdrawalsPagination.perPage}
          onSelect={page => {
            loadAccountLog(accountId, 'withdrawals', {...withdrawalsPagination, current: page})
          }}
          firstPageText="<<"
          previousPageText="<"
          nextPageText=">"
          lastPageText=">>"
          defaultActivePage={withdrawalsPagination.current}
        />}
      </Col>
    </Row>
  </>);
}

export default AccountLogTable
