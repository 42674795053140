import React, { useEffect } from 'react'
import get from 'lodash/get'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import ShopperBalanceForm from 'components/ShopperBalances/form'
import { createShopperBalance, closeAddShopperBalanceModal } from 'redux/actions/shopperBalances'

const CreateModal = ({
  isOpen,
  closeModal,
  createShopperBalance,
  loading,
  form,
}) => {
  const { register, handleSubmit, errors, reset } = useForm()

  useEffect(() => {
    reset({ shopper: get(form, 'shopper.id') })
  }, [reset, form])

  return (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Añadir Balance a Comprador</ModalHeader>
      <Form
        onSubmit={handleSubmit(info => {
          closeModal()
          createShopperBalance(info)
        })}
        noValidate
        autoComplete="off"
      >
        <ModalBody>
          <ShopperBalanceForm
            register={register}
            errors={errors}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Añadir Balance
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

CreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  loadingCategories: PropTypes.bool,
  form: PropTypes.shape({
    shopper: PropTypes.number,
  }),
  closeModal: PropTypes.func.isRequired,
  createShopperBalance: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  shopperBalances: { loading, openedAddBalanceToShopperModal, form }
}) => ({
  isOpen: openedAddBalanceToShopperModal,
  loading,
  form,
})

const mapDispatchToProps = dispatch => ({
  createShopperBalance: info => dispatch(createShopperBalance(info)),
  closeModal: () => dispatch(closeAddShopperBalanceModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateModal))
