import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { loadSuppliers as loadInfo } from 'redux/actions/suppliers'

const loadSuppliers = (component, pagination) => {
  const LoadSuppliers = ({ isOpen, loadSuppliers: loadInfo }) => {
    useEffect(() => {
      if (isOpen) {
        loadInfo(pagination)
      }
    }, [isOpen, loadInfo])

    const Component = component
    return <Component />
  }

  const mapDispatchToProps = dispatch => ({
    loadSuppliers: pagination => dispatch(loadInfo(pagination)),
  })

  const mapStateToProps = ({
    landCategories: { openedEditCategoryProvidersModal }
  }) => ({
    isOpen: openedEditCategoryProvidersModal
  })

  LoadSuppliers.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    loadSuppliers: PropTypes.func.isRequired
  }

  return connect(mapStateToProps, mapDispatchToProps)(LoadSuppliers)
}

export default loadSuppliers
