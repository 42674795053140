import {
  SUPPLIER_CONTACT_CREATE_START,
  SUPPLIER_CONTACT_CREATE_SUCCESS,
  SUPPLIER_CONTACT_CREATE_ERROR,
  OPEN_DELETE_SUPPLIER_CONTACT_MODAL,
  CLOSE_DELETE_SUPPLIER_CONTACT_MODAL,
  DELETE_SUPPLIER_CONTACT_START,
  DELETE_SUPPLIER_CONTACT_SUCCESS,
  DELETE_SUPPLIER_CONTACT_ERROR,
  SUPPLIER_CONTACT_UPDATE_START,
  SUPPLIER_CONTACT_UPDATE_SUCCESS,
  SUPPLIER_CONTACT_UPDATE_ERROR,
  OPEN_ADD_CONTACT_TO_SUPPLIER_MODAL,
  CLOSE_ADD_CONTACT_TO_SUPPLIER_MODAL,
  OPEN_EDIT_SUPPLIER_CONTACT_MODAL,
  CLOSE_EDIT_SUPPLIER_CONTACT_MODAL,
} from '../actions/supplierContacts'

const defaultForm = {
  name: '',
  job: '',
  phone: '',
  email: '',
}
export default (state = {
  loading: false,
  openedRemoveContactFromSupplierModal: false,
  openedAddContactToSupplierModal: false,
  openedEditSupplierContactModal: false,
  form: {...defaultForm},
}, action) => {
  switch (action.type) {
  case SUPPLIER_CONTACT_CREATE_START:
    return {
      ...state,
      loading: true
    }
  case SUPPLIER_CONTACT_CREATE_SUCCESS:
    return {
      ...state,
      loading: false,
      openedAddContactToSupplierModal: false,
      updatingSupplierContact: false
    }
  case SUPPLIER_CONTACT_CREATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case OPEN_ADD_CONTACT_TO_SUPPLIER_MODAL:
    return {
      ...state,
      form: {...defaultForm, supplier: action.payload },
      openedAddContactToSupplierModal: true
    }
  case CLOSE_ADD_CONTACT_TO_SUPPLIER_MODAL:
    return {
      ...state,
      form: {...defaultForm},
      openedAddContactToSupplierModal: false
    }
  case OPEN_EDIT_SUPPLIER_CONTACT_MODAL:
    return {
      ...state,
      openedEditSupplierContactModal: true,
      form: action.payload,
    }
  case CLOSE_EDIT_SUPPLIER_CONTACT_MODAL:
    return {
      ...state,
      openedEditSupplierContactModal: false,
      form: {...defaultForm}
    }
  case OPEN_DELETE_SUPPLIER_CONTACT_MODAL:
    return {
      ...state,
      openedRemoveContactFromSupplierModal: true,
      selectedSupplierContact: action.payload,
    }
  case CLOSE_DELETE_SUPPLIER_CONTACT_MODAL:
    return {
      ...state,
      openedRemoveContactFromSupplierModal: false,
      selectedSupplierContact: null
    }
  case DELETE_SUPPLIER_CONTACT_START:
    return {
      ...state,
      deletingSupplierContact: true,
    }
  case DELETE_SUPPLIER_CONTACT_SUCCESS:
    return {
      ...state,
      deletingSupplierContact: false,
      form: {...defaultForm},
      openedRemoveContactFromSupplierModal: false,
    }
  case DELETE_SUPPLIER_CONTACT_ERROR:
    return {
      ...state,
    }
  case SUPPLIER_CONTACT_UPDATE_START:
    return {
      ...state,
      updatingSupplierContact: true,
    }
  case SUPPLIER_CONTACT_UPDATE_SUCCESS:
    return {
      ...state,
      updatingSupplierContact: false,
      form: {...defaultForm},
      openedEditSupplierContactModal: false,
    }
  case SUPPLIER_CONTACT_UPDATE_ERROR:
    return {
      ...state,
    }
  default:
    return state
  }
}
