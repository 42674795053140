import { toast } from 'react-toastify'
import instance from '../../axios'
import sanitizeValues from 'utils/sanitizeValues'
import { getPagination } from 'utils/pagination'

export const LOAD_MODELS_START = 'LOAD_MODELS_START'
export const LOAD_MODELS_SUCCESS = 'LOAD_MODELS_SUCCESS'
export const LOAD_MODELS_ERROR = 'LOAD_MODELS_ERROR'

export const MODEL_CREATE_START = 'MODEL_CREATE_START'
export const MODEL_CREATE_SUCCESS = 'MODEL_CREATE_SUCCESS'
export const MODEL_CREATE_ERROR = 'MODEL_CREATE_ERROR'

export const MODEL_FETCH_START = 'MODEL_FETCH_START'
export const MODEL_FETCH_SUCCESS = 'MODEL_FETCH_SUCCESS'
export const MODEL_FETCH_ERROR = 'MODEL_FETCH_ERROR'

export const MODEL_UPDATE_START = 'MODEL_UPDATE_START'
export const MODEL_UPDATE_SUCCESS = 'MODEL_UPDATE_SUCCESS'
export const MODEL_UPDATE_ERROR = 'MODEL_UPDATE_ERROR'

export const OPEN_DELETE_MODEL_MODAL = 'OPEN_DELETE_MODEL_MODAL'
export const CLOSE_DELETE_MODEL_MODAL = 'CLOSE_DELETE_MODEL_MODAL'
export const DELETE_MODEL_START = 'DELETE_MODEL_START'
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS'
export const DELETE_MODEL_ERROR = 'DELETE_MODEL_ERROR'

export const openDeleteModelModal = payload => ({
  type: OPEN_DELETE_MODEL_MODAL,
  payload
})

export const closeDeleteModelModal = () => ({
  type: CLOSE_DELETE_MODEL_MODAL,
})

export const deleteModel = ({ model, source, history }) => dispatch => {
  dispatch({
    type: DELETE_MODEL_START
  })

  return instance.delete(`/models/${model.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_MODEL_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado el modelo ${model.name}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin/models')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_MODEL_ERROR,
        payload: err
      })
    })
}

export const fetchModel = id => dispatch => {
  dispatch({
    type: MODEL_FETCH_START
  })

  return instance.get(`/models/${id}`)
    .then(({ data }) => {
      dispatch({
        type: MODEL_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: MODEL_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateModel = ({ project, ...values }, history) => dispatch => {
  dispatch({
    type: MODEL_UPDATE_START
  })

  return instance.patch(`/models/${values.id}`, { project: (project && project.id) || project, ...values })
    .then(({ data }) => {
      dispatch({
        type: MODEL_UPDATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/models/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: MODEL_UPDATE_ERROR,
        payload: err
      })
    })
}

// eslint-disable-next-line no-unused-vars
export const createModel = ({ id, ...values }, history) => dispatch => {
  dispatch({
    type: MODEL_CREATE_START
  })

  const sanitized = sanitizeValues(values)

  return instance.post('/models', sanitized)
    .then(({ data }) => {
      dispatch({
        type: MODEL_CREATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/models/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: MODEL_CREATE_ERROR,
        payload: err
      })
    })
}

export const loadModels = paginationOpts => dispatch => {
  const pagination = getPagination(paginationOpts)
  dispatch({
    type: LOAD_MODELS_START
  })

  const orderBy = [
    {name: 'ASC'},
  ]

  return instance.get('/models', {
    params: {
      sort: JSON.stringify(orderBy),
      skip: pagination.skip,
      limit: pagination.perPage,
    },
  })
    .then(({ data }) => {
      dispatch({
        type: LOAD_MODELS_SUCCESS,
        payload: data,
        pagination,
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_MODELS_ERROR,
        payload: err
      })
    })
}
