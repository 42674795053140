import { toast } from 'react-toastify'
import instance from '../../axios'
import { getPagination } from 'utils/pagination'

export const ON_CHANGE_SUPPLIER = 'ON_CHANGE_SUPPLIER'
export const CLEAN_SUPPLIER_SELECT = 'CLEAN_SUPPLIER_SELECT'

export const LOAD_SUPPLIERS_START = 'LOAD_SUPPLIERS_START'
export const LOAD_SUPPLIERS_SUCCESS = 'LOAD_SUPPLIERS_SUCCESS'
export const LOAD_SUPPLIERS_ERROR = 'LOAD_SUPPLIERS_ERROR'

export const SUPPLIER_CREATE_START = 'SUPPLIER_CREATE_START'
export const SUPPLIER_CREATE_SUCCESS = 'SUPPLIER_CREATE_SUCCESS'
export const SUPPLIER_CREATE_ERROR = 'SUPPLIER_CREATE_ERROR'

export const SUPPLIER_FETCH_START = 'SUPPLIER_FETCH_START'
export const SUPPLIER_FETCH_SUCCESS = 'SUPPLIER_FETCH_SUCCESS'
export const SUPPLIER_FETCH_ERROR = 'SUPPLIER_FETCH_ERROR'

export const SUPPLIER_UPDATE_START = 'SUPPLIER_UPDATE_START'
export const SUPPLIER_UPDATE_SUCCESS = 'SUPPLIER_UPDATE_SUCCESS'
export const SUPPLIER_UPDATE_ERROR = 'SUPPLIER_UPDATE_ERROR'

export const OPEN_DELETE_SUPPLIER_MODAL = 'OPEN_DELETE_SUPPLIER_MODAL'
export const CLOSE_DELETE_SUPPLIER_MODAL = 'CLOSE_DELETE_SUPPLIER_MODAL'
export const DELETE_SUPPLIER_START = 'DELETE_SUPPLIER_START'
export const DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS'
export const DELETE_SUPPLIER_ERROR = 'DELETE_SUPPLIER_ERROR'


export const onChangeSupplier = supplierId => ({
  type: ON_CHANGE_SUPPLIER,
  payload: supplierId
})

export const cleanSupplierSelect = () => ({
  type: CLEAN_SUPPLIER_SELECT
})

export const openDeleteSupplierModal = payload => ({
  type: OPEN_DELETE_SUPPLIER_MODAL,
  payload
})

export const closeDeleteSupplierModal = () => ({
  type: CLOSE_DELETE_SUPPLIER_MODAL,
})

export const deleteSupplier = ({ supplier, source, history }) => dispatch => {
  dispatch({
    type: DELETE_SUPPLIER_START
  })

  return instance.delete(`/suppliers/${supplier.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_SUPPLIER_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado el proveedor ${supplier.fiscalName}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin/suppliers')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_SUPPLIER_ERROR,
        payload: err
      })
    })
}

export const fetchSupplier = id => dispatch => {
  dispatch({
    type: SUPPLIER_FETCH_START
  })

  return instance.get(`/suppliers/${id}`)
    .then(({ data }) => {
      dispatch({
        type: SUPPLIER_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: SUPPLIER_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateSupplier = (values, history) => dispatch => {
  dispatch({
    type: SUPPLIER_UPDATE_START
  })

  return instance.patch(`/suppliers/${values.id}`, values)
    .then(({ data }) => {
      dispatch({
        type: SUPPLIER_UPDATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/suppliers/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: SUPPLIER_UPDATE_ERROR,
        payload: err
      })
    })
}

// eslint-disable-next-line no-unused-vars
export const createSupplier = ({ id, ...values }, history) => dispatch => {
  dispatch({
    type: SUPPLIER_CREATE_START
  })

  return instance.post('/suppliers', values)
    .then(({ data }) => {
      dispatch({
        type: SUPPLIER_CREATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/suppliers/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: SUPPLIER_CREATE_ERROR,
        payload: err
      })
    })
}

export const loadSuppliers = (paginationOpts, select, populate) => dispatch => {
  const defaultPag = {perPage: 1000};
  const pagination = select ? defaultPag : getPagination(paginationOpts)
  dispatch({
    type: LOAD_SUPPLIERS_START
  })

  const order_by = [
    { alias: 'ASC' },
    { email: 'ASC' },
    { description: 'ASC' },
    { rfc: 'ASC' }
  ];

  return instance.get(`/suppliers`, {
    params: {
      sort: JSON.stringify(order_by),
      limit: pagination.perPage,
      skip: pagination.skip,
      select,
      populate,
    }
  })
    .then(({ data }) => {
      dispatch({
        type: LOAD_SUPPLIERS_SUCCESS,
        payload: data,
        pagination,
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_SUPPLIERS_ERROR,
        payload: err
      })
    })
}
