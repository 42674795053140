import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import get from 'lodash/get'
import ReactLoading from 'react-loading'
import {
  Card,
  CardHeader,
  Row,
  Button,
  Col,
  Table,
  UncontrolledTooltip,
  Progress,
} from 'reactstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faPlus, faPencilAlt, faTrash, faInfoCircle, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { loadLandCategories, openEditLandCategoryModal, openAddLandCategoryModal, openDeleteLandCategoryModal, openEditCategoryProvidersModal } from 'redux/actions/landCategories'
import { loadLandNotes } from 'redux/actions/landNotes'
import { formatCurrency } from 'utils/numberFormat'
import PaginationComponent from 'react-reactstrap-pagination'
import { hasPermission } from 'utils/permissions'

const DependenciesList = ({
  landCategories,
  modelCategories,
  landNotes,
  openAddLandCategoryModal,
  openDeleteLandCategoryModal,
  openEditLandCategoryModal,
  openEditCategoryProvidersModal,
  loadingLandCategories,
  loadingModelCategories,
  modelId,
  landId,
  loadingLandNotes,
  match,
  loadLandCategories,
  loadLandNotes,
  loadingLand,
  landCategoriesPagination,
  landNotesPagination,
  user,
}) => {

  useEffect(() => {
    if (!loadingLandCategories && !loadingLandNotes) {
      loadLandCategories(match.params.id, { perPage: 10000 })
      loadLandNotes(match.params.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, loadingLand, loadLandCategories, loadLandNotes, modelId])

  const loading = loadingLandCategories || loadingModelCategories
  const hasCategories = landCategories.length > 0 || modelCategories.length > 0
  const hasEditPermission = hasPermission(user, 'landCategory.update')
  const hasDeletePermission = hasPermission(user, 'landCategory.destroy')

  return (
    <Row>
      <Col className="order-xl-1 mt-3" xl="12">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs={12}>
                <h6 className="heading-small text-muted">
                  Categorías
                  <Button
                    color="primary"
                    size="sm"
                    className="float-right mt--1"
                    onClick={() => openAddLandCategoryModal({ id: parseInt(match.params.id) })}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faFileContract} />
                    Añadir categoría a lote
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </h6>
              </Col>
            </Row>
          </CardHeader>
          {loading && <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block m-4" />}
          {!loading && !hasCategories && <div className="text-mute text-sm p-3">No hay categorías relacionadas a este lote.</div>}
          {!loading && hasCategories > 0 && (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" className="text-center">Nombre</th>
                  <th scope="col" className="text-center">Código</th>
                  <th scope="col" className="text-center">Límite</th>
                  <th scope="col" className="text-center">Proveedor</th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              <tbody>
                {landCategories.concat(modelCategories.filter(
                  modelCategory => !landCategories.find(landCategory => landCategory.category.id === modelCategory.category.id)
                )).map(landCategory => {
                  const id = `landCategory_${landCategory.category.id}`
                  return (
                    <tr key={id}>
                      <th scope="row" className="text-center">
                        <Button tag={Link} to={`/admin/categories/${landCategory.category.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                          {landCategory.category.name}
                        </Button>
                      </th>
                      <th className="border-left text-center mb-0 text-sm">
                        {landCategory.category.description}
                      </th>
                      <td className="border-left text-center mb-0 text-sm">
                        {formatCurrency({amount: landCategory.paid_amount})} / {formatCurrency({amount: landCategory.limit})}
                        <Progress value={parseInt((landCategory.limit === 0? 0 : landCategory.paid_amount/landCategory.limit) * 100)} />
                      </td>
                      <td className="border-left text-center mb-0 text-sm">
                        {landCategory.suppliers && landCategory.suppliers.length > 0 && <label>{landCategory.suppliers.map(supplier => supplier.alias).join(', ')}</label>}
                        <span>
                          <Button
                            color="primary"
                            size="sm"
                            className="ml-2"
                            title="Editar proveedores de ésta categoría"
                            onClick={() => openEditCategoryProvidersModal(Object.assign({ land: landId }, landCategory))}
                          >
                            <FontAwesomeIcon icon={faUserEdit} />
                          </Button>
                        </span>
                      </td>
                      <td className="border-left text-center" id={`${id}_container`}>
                        {hasEditPermission && <Button
                          color="primary"
                          size="sm"
                          className="mr-2"
                          onClick={() => openEditLandCategoryModal(Object.assign({ land: landId }, landCategory))}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>}
                        {landCategory.model && (
                          <UncontrolledTooltip target={id} boundariesElement={`${id}_container`} >
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="mr-2"
                            />
                            Esta categoría pertenece al modelo del lote, no se puede eliminar pero sí editar.
                          </UncontrolledTooltip>
                        )}
                        {hasDeletePermission && <span
                          id={id}
                        >
                          <Button
                            color="secondary"
                            size="sm"
                            disabled={(!!landCategory.model) || (landCategory.paid_amount != null && landCategory.paid_amount > 0)}
                            onClick={() => openDeleteLandCategoryModal(landCategory)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </span>}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {landCategoriesPagination.total > 0 && <tfoot>
                <tr>
                  <td colSpan="5">
                  <PaginationComponent
                    totalItems={landCategoriesPagination.total}
                    pageSize={landCategoriesPagination.perPage}
                    onSelect={page => loadLandCategories(match.params.id, {...landCategoriesPagination, current: page})}
                    firstPageText="<<"
                    previousPageText="<"
                    nextPageText=">"
                    lastPageText=">>"
                    defaultActivePage={landCategoriesPagination.current}
                  />
                  </td>
                </tr>
              </tfoot>}
            </Table>
          )}

          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs={12}>
                <h6 className="heading-small text-muted">
                  Notas
                </h6>
              </Col>
            </Row>
          </CardHeader>
          {loadingLandNotes && <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block m-4" />}
          {!loadingLandNotes && landNotes.length === 0 && <div className="text-mute text-sm p-3">No hay categorías relacionadas a este lote.</div>}
          {!loadingLandNotes && landNotes.length > 0 && (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" className="text-center">Nota</th>
                  <th scope="col" className="text-center">Categoría</th>
                  <th scope="col" className="text-center">Importe</th>
                </tr>
              </thead>
              <tbody>
                {landNotes.map(landNote => (
                  <tr key={landNote.id}>
                    <th scope="row" className="text-center">
                      <Button tag={Link} to={`/admin/notes/${landNote.note.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                        Ver
                      </Button>
                    </th>
                    <th className="border-left text-center mb-0 text-sm">
                      {landNote.category.description}
                    </th>
                    <td className="border-left text-center mb-0 text-sm">
                      {formatCurrency({amount: landNote.amount})}
                    </td>
                  </tr>
                ))}
              </tbody>
              {landNotesPagination.total > 0 && <tfoot>
                <tr>
                  <td colSpan="5">
                  <PaginationComponent
                    totalItems={landNotesPagination.total}
                    pageSize={landNotesPagination.perPage}
                    onSelect={page => loadLandNotes(match.params.id, {...landNotesPagination, current: page})}
                    firstPageText="<<"
                    previousPageText="<"
                    nextPageText=">"
                    lastPageText=">>"
                    defaultActivePage={landNotesPagination.current}
                  />
                  </td>
                </tr>
              </tfoot>}
            </Table>
          )}
        </Card>
      </Col>
    </Row>
  )
}

DependenciesList.propTypes = {
  landCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    limit: PropTypes.string,
    land: PropTypes.shape({
      id: PropTypes.number,
      street: PropTypes.string,
      number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      area: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    category: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      name: PropTypes.string,
    }),
  })),
  modelCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    limit: PropTypes.number,
    model: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      name: PropTypes.string,
      project: PropTypes.number,
    }),
    category: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      name: PropTypes.string,
    }),
  })),
  landNotes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    land: PropTypes.shape({
      id: PropTypes.number,
      street: PropTypes.string,
      number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      area: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    note: PropTypes.shape({
      id: PropTypes.number,
    }),
  })),
  suppliers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    alias: PropTypes.string,
    description: PropTypes.string,
  })),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  modelId: PropTypes.number,
  landId: PropTypes.number,
  loadingLandCategories: PropTypes.bool.isRequired,
  loadingModelCategories: PropTypes.bool.isRequired,
  loadingLandNotes: PropTypes.bool.isRequired,
  loadingLand: PropTypes.bool.isRequired,
  loadLandCategories: PropTypes.func.isRequired,
  loadLandNotes: PropTypes.func.isRequired,
  openAddLandCategoryModal: PropTypes.func.isRequired,
  openDeleteLandCategoryModal: PropTypes.func.isRequired,
  openEditLandCategoryModal: PropTypes.func.isRequired,
  openEditCategoryProvidersModal: PropTypes.func.isRequired
}

DependenciesList.defaultProps = {
  suppliers: []
}

const mapStateToProps = ({
  landCategories: { landCategories, loading: loadingLandCategories, pagination: landCategoriesPagination },
  modelCategories: { modelCategories, loading: loadingModelCategories },
  landNotes: { landNotes, loading: loadingLandNotes, pagination: landNotesPagination },
  lands: { loading: loadingLand, form: { model, id, suppliers } },
}) => ({
  landCategories,
  modelCategories,
  landNotes,
  loadingLandCategories,
  loadingModelCategories,
  loadingLandNotes,
  loadingLand,
  modelId: get(model, 'id', model),
  landId: id,
  suppliers,
  landCategoriesPagination,
  landNotesPagination,
})

const mapDispatchToProps = dispatch => ({
  loadLandCategories: (land, pagination) => dispatch(loadLandCategories({ land }, pagination)),
  loadLandNotes: (land, pagination) => dispatch(loadLandNotes({ land }, pagination)),
  openAddLandCategoryModal: land => dispatch(openAddLandCategoryModal(land)),
  openDeleteLandCategoryModal: land => dispatch(openDeleteLandCategoryModal(land)),
  openEditLandCategoryModal: land => dispatch(openEditLandCategoryModal(land)),
  openEditCategoryProvidersModal: land_category => dispatch(openEditCategoryProvidersModal(land_category)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DependenciesList))
