import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import { deleteSupplierContact, closeDeleteSupplierContactModal } from 'redux/actions/supplierContacts'

const DeleteModal = ({ isOpen, closeModal, supplierContact, deleteSupplierContact, isLoading }) => !supplierContact ? null : (
  <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
    <ModalHeader toggle={closeModal}>Eliminar Contacto de Proveedor</ModalHeader>
    <ModalBody>
      Estás seguro de que quieres borrar el contacto {supplierContact.name} del proveedor {supplierContact.supplier.alias} ?
    </ModalBody>
    <ModalFooter>
      <Button disabled={isLoading} color="warning" className="mr-1" onClick={() => deleteSupplierContact(supplierContact)}>
        {isLoading && <ReactLoading type="spinningBubbles" color="#fff" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
        Eliminar
      </Button>
      <Button disabled={isLoading} color="primary" onClick={closeModal}>Cancelar</Button>
    </ModalFooter>
  </Modal>
)

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  supplierContact: PropTypes.shape({
    supplier: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string
      }),
      PropTypes.number
    ]),
    name: PropTypes.string
  }),
  closeModal: PropTypes.func.isRequired,
  deleteSupplierContact: PropTypes.func.isRequired,
}

const mapStateToProps = ({ supplierContacts: { openedRemoveContactFromSupplierModal, selectedSupplierContact, deletingSupplierContact }}) => ({
  isOpen: openedRemoveContactFromSupplierModal,
  supplierContact: selectedSupplierContact,
  isLoading: deletingSupplierContact
})

const mapDispatchToProps = dispatch => ({
  deleteSupplierContact: info => dispatch(deleteSupplierContact(info)),
  closeModal: () => dispatch(closeDeleteSupplierContactModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteModal))
