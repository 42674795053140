import React, { useEffect } from 'react'
import get from 'lodash/get'
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faThLarge, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
  UncontrolledTooltip
} from 'reactstrap'
import PropTypes from 'prop-types'
import BlockForm from 'components/Blocks/form'
import { fetchBlock, openDeleteBlockModal } from 'redux/actions/blocks'
import DependenciesList from 'components/Blocks/dependenciesList'
import BackButton from 'components/BackButton'

const BlockDetail = ({ fetchBlock, form, loading, history, match, openDeleteBlockModal, hasDependencies }) => {
  const { register, reset } = useForm()

  useEffect(() => {
    fetchBlock(match.params.id)
  }, [fetchBlock, match])

  useEffect(() => {
    reset(form)
  }, [form, reset])

  const id = `block_${form.id}`
  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faThLarge} className="mr-1"/> Detalle de Manzana<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/blocks/${match.params.id}/edit`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="mr-2"/>
                      Editar Manzana
                    </Button>
                    {hasDependencies && (
                      <UncontrolledTooltip target={id}>
                        Esta manzana no se puede eliminar por que tiene lotes asociados a ella.
                      </UncontrolledTooltip>
                    )}
                    <Button
                      color="warning"
                      disabled={hasDependencies}
                      onClick={() => openDeleteBlockModal(form)}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2"/>
                      Borrar Manzana
                      {hasDependencies && (
                        <FontAwesomeIcon
                          id={id}
                          icon={faInfoCircle}
                          className="ml-2"
                        />
                      )}
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loading && (
                <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
              )}
              <BlockForm
                readOnly
                loadingBlock={loading}
                history={history}
                project={form.project}
                register={register}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DependenciesList block={form} />
    </Container>
  )
}

const mapStateToProps = ({ blocks: { form, loadingBlock } }) => ({
  form,
  hasDependencies: get(form, 'lands', []).length > 0,
  loading: loadingBlock,
})

const mapDispatchToProps = dispatch => ({
  fetchBlock: id => dispatch(fetchBlock(id)),
  openDeleteBlockModal: block => dispatch(openDeleteBlockModal({ block, source: 'detail' })),
})

BlockDetail.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
    project: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  }),
  loading: PropTypes.bool,
  openDeleteBlockModal: PropTypes.func.isRequired,
  fetchBlock: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  hasDependencies: PropTypes.bool.isRequired
}

BlockDetail.defaultProps = {
  loading: false,
  form: {
    description: '',
    name: '',
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlockDetail))
