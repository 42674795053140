import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import ReactLoading from 'react-loading'
import { Link } from 'react-router-dom'
import {
  FormGroup,
  Input,
  Row,
  Col,
  FormText,
  Button,
  Label,
} from 'reactstrap'

const LandCategoryForm = ({
  register,
  errors,
  category,
  categories,
  readOnly,
  loading,
  closeModal,
  edit,
}) => (
  <div className="pl-lg-4">
    <Input name="id" type="hidden" innerRef={register} />
    <Input name="model" type="hidden" innerRef={register} />
    <Input name="land" type="hidden" innerRef={register} />
    <Row>
      <Col lg="6">
        <FormGroup>
          <Label className="form-control-label" htmlFor="input-category">
            Categoría
          </Label>
          {get(categories, 'length', 0) === 0 && !loading && !edit && (
            <div>
              <span className="text-mute">No hay categorías disponibles para agregar a este lote, puedes editar las existentes o <Link to="/admin/categories/new" className="text-underline" onClick={closeModal}>añadir una nueva</Link>.</span>
            </div>
          )}
          {loading && (
            <div className="text-sm text-muted">
              <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block mr-2 mb-2" />
              Cargando Categorías
            </div>
          )}
          {!readOnly && !edit && get(categories, 'length', 0) > 0 && <Input
            className="form-control-alternative"
            id="input-category"
            autoComplete="input-category"
            placeholder="Categoría"
            type="select"
            name="category"
            innerRef={register({
              validate: value => value !== 'Seleccione una opción'
            })}
          >
            {[<option key={'emptyValue'}>Seleccione una opción</option>]
              .concat(categories
                .map(category => <option value={category.id} key={category.id}>{category.description} - {category.name}</option>)
              )
            }
          </Input>}
          {(readOnly || edit) && category && category.id && (
            <div>
              <Button tag={Link} to={`/admin/categories/${category.id}`} className="text-sm btn btn-sm btn-outline-primary">{get(category, 'name', '')}</Button>
              <Input name="category" type="hidden" innerRef={register} />
            </div>
          )}
          {errors.category && (
            <FormText color="primary" className="mt-2">
              Seleccione una categoría para continuar
            </FormText>
          )}
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <Label className="form-control-label" htmlFor="input-limit">
            Límite de presupuesto
          </Label>
          <Input
            className="form-control-alternative"
            disabled={readOnly}
            id="input-limit"
            autoComplete="input-limit"
            placeholder="Límite de presupuesto"
            type="text"
            name="limit"
            innerRef={register({
              required: true,
              pattern: /^\d+(\.\d)?/,
            })}
          />
          {errors.limit && errors.limit.type === 'pattern' && (
            <FormText color="primary" className="mt-2">
              Ingrese un límite de presupuesto válido.
            </FormText>
          )}
          {errors.limit && errors.limit.type === 'required' && (
            <FormText color="primary" className="mt-2">
              Ingrese un límite de presupuesto.
            </FormText>
          )}
        </FormGroup>
      </Col>
    </Row>
  </div>
)

LandCategoryForm.propTypes = {
  category: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ]),
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    category: PropTypes.shape({}),
    limit: PropTypes.shape({
      type: PropTypes.string
    })
  }),
}

LandCategoryForm.defaultProps = {
  readOnly: false,
  edit: false,
  categories: [],
  errors: {},
}

export default LandCategoryForm
