import {
  LOAD_INVOICES_START,
  LOAD_INVOICES_ERROR,
  LOAD_INVOICES_SUCCESS,
  INVOICE_FETCH_START,
  INVOICE_FETCH_SUCCESS,
  INVOICE_FETCH_ERROR,
  OPEN_DELETE_INVOICE_MODAL,
  CLOSE_DELETE_INVOICE_MODAL,
  OPEN_PAY_INVOICE_MODAL,
  CLOSE_PAY_INVOICE_MODAL,
  DELETE_INVOICE_START,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_ERROR,
  LOAD_ACCOUNTS_SUCCESS,
  UPDATE_INVOICE_START,
  UPDATE_INVOICE_SUCCESS,
} from '../actions/invoices'
import { getPagination } from 'utils/pagination'

const defaultForm = {
  landInvoices: []
}

const defaultPagination = getPagination()

export default (state = {
  invoices: [],
  accounts: [],
  loading: false,
  openPayInvoiceModal: false,
  openedDeleteInvoiceModal: false,
  form: {...defaultForm},
  pagination: { ...defaultPagination },
}, action) => {
  switch (action.type) {
  case OPEN_DELETE_INVOICE_MODAL:
    return {
      ...state,
      openedDeleteInvoiceModal: true,
      source: action.payload.source,
      selectedInvoice: action.payload.invoice,
    }
  case CLOSE_DELETE_INVOICE_MODAL:
    return {
      ...state,
      openedDeleteInvoiceModal: false,
      source: null,
      selectedInvoice: null
    }
  case OPEN_PAY_INVOICE_MODAL:
    return {
      ...state,
      openPayInvoiceModal: true,
      source: action.payload.source,
      selectedInvoice: action.payload.invoice,
    }
  case CLOSE_PAY_INVOICE_MODAL:
    return {
      ...state,
      openPayInvoiceModal: false,
      source: null,
      selectedInvoice: null,
    }
  case DELETE_INVOICE_START:
    return {
      ...state,
      deletingInvoice: true,
    }
  case DELETE_INVOICE_SUCCESS:
    return {
      ...state,
      deletingInvoice: false,
      form: {...defaultForm},
      invoices: state.invoices.filter(u => u.id !== state.selectedInvoice.id),
      source: null,
      openedDeleteInvoiceModal: false,
    }
  case DELETE_INVOICE_ERROR:
    return {
      ...state,
    }
  case LOAD_INVOICES_ERROR:
    return {
      ...state,
      invoices: [],
      error: action.payload
    }
  case LOAD_INVOICES_START:
    return {
      ...state,
      invoices: [],
      loading: true
    }
  case LOAD_INVOICES_SUCCESS:
    return {
      ...state,
      invoices: action.payload.results,
      pagination: {
        ...action.pagination,
        total: action.payload.totalCount,
      }
    }
  case INVOICE_FETCH_START:
    return {
      ...state,
      loadingInvoice: true,
      form: defaultForm
    }
  case INVOICE_FETCH_SUCCESS:
    return {
      ...state,
      loadingInvoice: false,
      form: action.payload
    }
  case INVOICE_FETCH_ERROR:
    return {
      ...state,
      loadingInvoice: false,
      form: action.payload
    }
  case LOAD_ACCOUNTS_SUCCESS:
    return {
      ...state,
      loading: false,
      accounts: action.payload
    };
  case UPDATE_INVOICE_START:
    return {
      ...state,
      loading: true,
    }
  case UPDATE_INVOICE_SUCCESS:
    return {
      ...state,
      loading: false,
      invoices: state.invoices.map(invoice => {
        if (invoice.id === action.payload.id) {
          return {
            ...invoice,
            [`${action.payload.field}`]: action.payload.newValue
          }
        }
        return invoice;
      })
    }
  default:
    return state
  }
}
