import React from 'react'
import PropTypes from 'prop-types'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'

const BackButton = ({ history }) => (
  <Button
    color="primary"
    onClick={() => history.goBack()}
    size="sm"
    className="mb-4"
  >
    <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
    Regresar
  </Button>
)

BackButton.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired
}

export default withRouter(BackButton)
