import { toast } from 'react-toastify'
import instance from '../../axios'

export const LOAD_PROJECTS_START = 'LOAD_PROJECTS_START'
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS'
export const LOAD_PROJECTS_ERROR = 'LOAD_PROJECTS_ERROR'

export const PROJECT_CREATE_START = 'PROJECT_CREATE_START'
export const PROJECT_CREATE_SUCCESS = 'PROJECT_CREATE_SUCCESS'
export const PROJECT_CREATE_ERROR = 'PROJECT_CREATE_ERROR'

export const PROJECT_FETCH_START = 'PROJECT_FETCH_START'
export const PROJECT_FETCH_SUCCESS = 'PROJECT_FETCH_SUCCESS'
export const PROJECT_FETCH_ERROR = 'PROJECT_FETCH_ERROR'

export const PROJECT_UPDATE_START = 'PROJECT_UPDATE_START'
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS'
export const PROJECT_UPDATE_ERROR = 'PROJECT_UPDATE_ERROR'

export const OPEN_DELETE_PROJECT_MODAL = 'OPEN_DELETE_PROJECT_MODAL'
export const CLOSE_DELETE_PROJECT_MODAL = 'CLOSE_DELETE_PROJECT_MODAL'
export const DELETE_PROJECT_START = 'DELETE_PROJECT_START'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR'

export const openDeleteProjectModal = payload => ({
  type: OPEN_DELETE_PROJECT_MODAL,
  payload
})

export const closeDeleteProjectModal = () => ({
  type: CLOSE_DELETE_PROJECT_MODAL,
})

export const deleteProject = ({ project, source, history }) => dispatch => {
  dispatch({
    type: DELETE_PROJECT_START
  })

  return instance.delete(`/projects/${project.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_PROJECT_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado el proyecto ${project.name}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin/projects')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_PROJECT_ERROR,
        payload: err
      })
    })
}

export const fetchProject = id => dispatch => {
  dispatch({
    type: PROJECT_FETCH_START
  })

  return instance.get(`/projects/${id}`)
    .then(({ data }) => {
      dispatch({
        type: PROJECT_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: PROJECT_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateProject = (values, history) => dispatch => {
  dispatch({
    type: PROJECT_UPDATE_START
  })

  return instance.patch(`/projects/${values.id}`, values)
    .then(({ data }) => {
      dispatch({
        type: PROJECT_UPDATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/projects/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: PROJECT_UPDATE_ERROR,
        payload: err
      })
    })
}

// eslint-disable-next-line no-unused-vars
export const createProject = ({ id, ...values }, history) => dispatch => {
  dispatch({
    type: PROJECT_CREATE_START
  })

  return instance.post('/projects', values)
    .then(({ data }) => {
      dispatch({
        type: PROJECT_CREATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/projects/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: PROJECT_CREATE_ERROR,
        payload: err
      })
    })
}

export const loadProjects = () => dispatch => {
  dispatch({
    type: LOAD_PROJECTS_START
  })

  return instance.get('/projects/with-amounts')
    .then(({ data }) => {
      dispatch({
        type: LOAD_PROJECTS_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_PROJECTS_ERROR,
        payload: err
      })
    })
}
