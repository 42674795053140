import instance from '../../axios'

export const LOAD_WORKS_START = 'LOAD_WORKS_START'
export const LOAD_WORKS_SUCCESS = 'LOAD_WORKS_SUCCESS'
export const LOAD_WORKS_ERROR = 'LOAD_WORKS_ERROR'
export const SET_NEODATA = 'SET_NEODATA'


export const loadWorks = () => dispatch => {

  dispatch({
    type: LOAD_WORKS_START
  })

  const params = {
    populatesparams: JSON.stringify({ neodatas: { limit: 500 } })
  }
  return instance.get('/works', { params })
    .then(({ data }) => {
      dispatch({
        type: LOAD_WORKS_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_WORKS_ERROR,
        payload: err
      })
    })
}

export const setCurrentNeodatas = neodatas => dispatch => {
  return dispatch({
    type: SET_NEODATA,
    neodatas,
  })
}
