import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import { deleteExpense, closeDeleteExpenseModal } from 'redux/actions/expenses'


const DeleteModal = ({ isOpen, closeModal, expense, deleteExpense, isLoading, source, history }) => !expense ? null : (
  <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
    <ModalHeader toggle={closeModal}>Eliminar Gasto</ModalHeader>
    <ModalBody>
      ¿Estás seguro de que quieres borrar este gasto?
    </ModalBody>
    <ModalFooter>
      <Button disabled={isLoading} color="warning" className="mr-1" onClick={() => deleteExpense({ expense, source, history })}>
        {isLoading && <ReactLoading type="spinningBubbles" color="#fff" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
        Eliminar
      </Button>
      <Button disabled={isLoading} color="primary" onClick={closeModal}>Cancelar</Button>
    </ModalFooter>
  </Modal>
)

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  expense: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  source: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  deleteExpense: PropTypes.func.isRequired,
}

const mapStateToProps = props => {
  const { expenses: { openedDeleteExpenseModal, selectedExpense, deletingExpense, source } } = props

  return {
    isOpen: openedDeleteExpenseModal,
    expense: selectedExpense,
    isLoading: deletingExpense,
    source
  }
}

const mapDispatchToProps = dispatch => ({
  deleteExpense: info => dispatch(deleteExpense(info)),
  closeModal: () => dispatch(closeDeleteExpenseModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteModal))
