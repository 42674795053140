import React from 'react'

import { Container, Row, Col } from 'reactstrap'

const Login = () => (
  <footer className="py-5">
    <Container>
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © 2020 Terrarrium
          </div>
        </Col>
        <Col xl="6" />
      </Row>
    </Container>
  </footer>
)

export default Login
