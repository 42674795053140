import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faPencilAlt, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  UncontrolledTooltip,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { fetchCategory, openDeleteCategoryModal } from 'redux/actions/categories'
import CategoryForm from 'components/Categories/form'
import DependenciesList from 'components/Categories/dependenciesList'
import { hasDependencies } from './utils'
import BackButton from 'components/BackButton'

const CategoryDetail = ({ form, loading, history, match, openDeleteCategoryModal, hasDependencies, fetchCategory }) => {
  const { register, reset } = useForm()

  useEffect(() => {
    fetchCategory(match.params.id)
  }, [fetchCategory, match])

  useEffect(() => {
    reset(form)
  }, [form, reset])

  const id = `category_${form.id}`

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faFolder} className="mr-1"/> Detalle de Categoría<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/categories/${match.params.id}/edit`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="mr-2"/>
                      Editar Categoría
                    </Button>
                    {hasDependencies && (
                      <UncontrolledTooltip target={id}>
                        Esta categoría no se puede eliminar por que tiene modelos, lotes o notas asociadas a ella.
                      </UncontrolledTooltip>
                    )}
                    <Button
                      color="warning"
                      onClick={() => openDeleteCategoryModal(form)}
                      size="sm"
                      className="mt-2"
                      disabled={hasDependencies}
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2"/>
                      Borrar Proyecto
                      {hasDependencies && (
                        <FontAwesomeIcon
                          id={id}
                          icon={faInfoCircle}
                          className="ml-2"
                        />
                      )}
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!loading && (
                <CategoryForm
                  readOnly
                  history={history}
                  register={register}
                />
              )}
              {loading && (
                <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DependenciesList category={form} />
    </Container>
  )
}

const mapStateToProps = ({ categories: { form, loadingCategory } }) => ({
  form,
  loading: loadingCategory,
  hasDependencies: hasDependencies(form)
})

const mapDispatchToProps = dispatch => ({
  fetchCategory: id => dispatch(fetchCategory(id)),
  openDeleteCategoryModal: category => dispatch(openDeleteCategoryModal({ category, source: 'detail' })),
})

CategoryDetail.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
  }),
  loading: PropTypes.bool,
  hasDependencies: PropTypes.bool,
  openDeleteCategoryModal: PropTypes.func.isRequired,
  fetchCategory: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
}

CategoryDetail.defaultProps = {
  loading: false,
  form: {
    description: '',
    name: '',
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryDetail))
