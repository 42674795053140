import { toast } from 'react-toastify'
import instance from '../../axios'
import { LAND_NOTE_FORM_CLEAR_CATEGORY } from './landNotes'
import { getPagination } from 'utils/pagination'

export const LOAD_CATEGORIES_START = 'LOAD_CATEGORIES_START'
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS'
export const CHANGE_MODAL_CATEGORY_STATE = 'CHANGE_MODAL_CATEGORY_STATE'
export const LOAD_CATEGORIES_ERROR = 'LOAD_CATEGORIES_ERROR'

export const CATEGORY_CREATE_START = 'CATEGORY_CREATE_START'
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS'
export const CATEGORY_CREATE_ERROR = 'CATEGORY_CREATE_ERROR'

export const CATEGORY_FETCH_START = 'CATEGORY_FETCH_START'
export const CATEGORY_FETCH_SUCCESS = 'CATEGORY_FETCH_SUCCESS'
export const CATEGORY_FETCH_ERROR = 'CATEGORY_FETCH_ERROR'

export const CATEGORY_UPDATE_START = 'CATEGORY_UPDATE_START'
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS'
export const CATEGORY_UPDATE_ERROR = 'CATEGORY_UPDATE_ERROR'

export const OPEN_DELETE_CATEGORY_MODAL = 'OPEN_DELETE_CATEGORY_MODAL'
export const CLOSE_DELETE_CATEGORY_MODAL = 'CLOSE_DELETE_CATEGORY_MODAL'
export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR'

export const openDeleteCategoryModal = payload => ({
  type: OPEN_DELETE_CATEGORY_MODAL,
  payload
})

export const closeDeleteCategoryModal = () => ({
  type: CLOSE_DELETE_CATEGORY_MODAL,
})

export const deleteCategory = ({ category, source, history }) => dispatch => {
  dispatch({
    type: DELETE_CATEGORY_START
  })

  return instance.delete(`/categories/${category.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_CATEGORY_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado la categoría ${category.name}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin/categories')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_CATEGORY_ERROR,
        payload: err
      })
    })
}

export const fetchCategory = id => dispatch => {
  dispatch({
    type: CATEGORY_FETCH_START
  })

  return instance.get(`/categories/${id}`)
    .then(({ data }) => {
      dispatch({
        type: CATEGORY_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: CATEGORY_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateCategory = (values, history) => dispatch => {
  dispatch({
    type: CATEGORY_UPDATE_START
  })

  return instance.patch(`/categories/${values.id}`, values)
    .then(({ data }) => {
      dispatch({
        type: CATEGORY_UPDATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/categories/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: CATEGORY_UPDATE_ERROR,
        payload: err
      })
    })
}

// eslint-disable-next-line no-unused-vars
export const createCategory = ({ id, ...values }, history) => dispatch => {
  dispatch({
    type: CATEGORY_CREATE_START
  })

  return instance.post('/categories', values)
    .then(({ data }) => {
      dispatch({
        type: CATEGORY_CREATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/categories/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: CATEGORY_CREATE_ERROR,
        payload: err
      })
    })
}

export const loadCategories = paginationOpts => dispatch => {
  const pagination = getPagination(paginationOpts)
  dispatch({
    type: LOAD_CATEGORIES_START
  })

  let order_by = [{description: 'asc'}];

  return instance.get(`/categories`, {
    params: {
      sort: JSON.stringify(order_by),
      limit: pagination.perPage,
      skip: pagination.skip,
    }
  })
    .then(({ data }) => {
      dispatch({
        type: LOAD_CATEGORIES_SUCCESS,
        payload: data,
        pagination,
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_CATEGORIES_ERROR,
        payload: err
      })
    })
}

export const loadCategoriesFromLand = (land, supplierId) => dispatch => {
  if (!land || land === 'Seleccione una opción') {
    return dispatch({
      type: LAND_NOTE_FORM_CLEAR_CATEGORY
    })
  }

  dispatch({
    type: LOAD_CATEGORIES_START
  })

  let params = land.id || land

  return instance.get(`/categoriesFromLand/${params}/${supplierId}`)
    .then(({ data }) => {
      dispatch({
        type: LOAD_CATEGORIES_SUCCESS,
        payload: {
          results: data,
          totalCount: data.length,
        }
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_CATEGORIES_ERROR,
        payload: err
      })
    })
}

export const changeModalCategory = category => ({
  type: CHANGE_MODAL_CATEGORY_STATE,
  category
})
