import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  Card,
  CardHeader,
  Row,
  Button,
  Col,
  Table,
  Input,
  Alert,
} from 'reactstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { openAddLandNoteModal, deleteLandNoteForm } from 'redux/actions/landNotes'
import get from 'lodash/get'
import { formatCurrency } from 'utils/numberFormat'

const AddNoteLand = props => {

  const {
    projectId,
    openAddLandNoteModal,
    deleteLandNoteForm,
    landNotes,
    register,
    categories,
    lands,
    supplierId,
    error,
  } = props

  const getLand = id => lands.find(land => land.id === parseInt(id))
  const getCategory = id => categories.find(category => category.id === parseInt(id))

  return (
    <Row>
      {error && <Col className="mt-3" xl="12">
        <Alert color="danger">
          {error}
        </Alert>
      </Col>}
      <Col className="order-xl-1 mt-3" xl="12">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs={12}>
                <h6 className="heading-small text-muted">
                  Conceptos
                  <Button
                    color="primary"
                    size="sm"
                    className="float-right mt--1"
                    onClick={() => openAddLandNoteModal({}, projectId, supplierId)}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faFileContract} />
                    Añadir concepto a nota
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </h6>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center">Importe</th>
                <th scope="col" className="text-center">Lote</th>
                <th scope="col" className="text-center">Categoría</th>
                <th className="text-center">Descripción</th>
                <th scope="col" className="text-center"></th>
              </tr>
            </thead>
            <tbody>
              {landNotes.landNotes.map((landNote, index) => {
                const land = getLand(landNote.land)
                return (
                  <tr key={index}>
                    <th className="text-center mb-0 text-sm">
                      {formatCurrency({ amount: landNote.amount })}
                      <Input
                        name={`landNote[${index}].amount`}
                        value={landNote.amount}
                        innerRef={register({
                          required: false
                        })}
                        type="hidden"
                      />
                    </th>
                    <th scope="row" className="text-center mb-0 text-sm">
                      <Button tag={Link} to={`/admin/lands/${get(land, 'id')}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                        {get(land, 'street')} {get(land, 'number')}
                      </Button>
                      <Input
                        name={`landNote[${index}].land`}
                        value={landNote.land}
                        innerRef={register({
                          required: false
                        })}
                        type="hidden"
                      />
                    </th>
                    <th scope="row" className="text-center mb-0 text-sm">
                      <Button tag={Link} to={`/admin/categories/${get(getCategory(landNote.category), 'id')}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                        {get(getCategory(landNote.category), 'name')}
                      </Button>
                      <Input
                        name={`landNote[${index}].category`}
                        value={landNote.category}
                        innerRef={register({
                          required: false
                        })}
                        type="hidden"
                      />
                    </th>
                    <td className="mb-0 text-sm">
                      {get(landNote, 'description', '---')}
                      <Input
                        type="hidden"
                        name={`landNote[${index}].description`}
                        value={get(landNote, 'description', '')}
                        innerRef={register}
                      />
                    </td>
                    <td className="border-left text-center mb-0 text-sm">
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={() => deleteLandNoteForm(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  )
}

AddNoteLand.propTypes = {
  landNotes: PropTypes.object,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  openAddLandNoteModal: PropTypes.func.isRequired,
}

const mapStateToProps = props => {
  const { landNotes, suppliers: { suppliers }, categories: { categories }, lands: { lands } } = props
  return {
    landNotes,
    suppliers,
    categories,
    lands
  }
}

const mapDispatchToProps = dispatch => ({
  openAddLandNoteModal: (form, projectId, supplierId) => dispatch(openAddLandNoteModal(form, projectId, supplierId)),
  deleteLandNoteForm: noteLand => dispatch(deleteLandNoteForm(noteLand))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNoteLand))
