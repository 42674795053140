import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import useForm from 'react-hook-form'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import ReactDatetime from 'react-datetime'
import ReactLoading from 'react-loading'
import {submitExpensePayment} from 'redux/actions/transactions'
import { formatCurrency } from 'utils/numberFormat'
import { closePayExpenseModal } from 'redux/actions/expenses'
import moment from 'moment'


const PayExpenseModal = ({ isOpen, closeModal, expense, payExpense, isLoading }) => {
  const { register, reset, handleSubmit, errors } = useForm()

  useEffect(() => {
    reset({
      date: moment().format('DD/MM/YYYY')
    })
  }, [moment, reset])

  if (!expense) {
    return null;
  }

  const paymentDisabled = (isLoading || (
    !expense.authNumber ||
    expense.authNumber.trim() === '' ||
    expense.account.amount - expense.total < 0
  ));
  return (
  <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
    <ModalHeader toggle={closeModal}>Pagar este gasto</ModalHeader>
    <ModalBody>
      <Form noValidate autoComplete="off">
        <p>
          ¿Estás seguro de que quieres pagar este gasto?
        </p>
        <ul>
          <li>
            <strong>Descripción: </strong>{expense.description}
          </li>
          <li>
            <strong>Cantidad: </strong>{formatCurrency({ amount: expense.total })}
          </li>
          <li>
            <strong>Neodata: </strong>{
              expense.neodata && expense.neodata.name
            }
          </li>
          <li>
            <strong>Obra: </strong>{
              expense.neodata && expense.neodata.work.name
            }
          </li>
          <li>
            <strong>Pagar con: </strong>Cuenta {
              expense.account && (`${expense.account.name} (${formatCurrency({ amount: expense.account.amount })})`)
            }
          </li>
          <li>
            <strong>Folio: </strong> {
              (expense.folio && (expense.folio.startsWith('F-') ? expense.folio : `F-${expense.folio}`)) || '-'
            }
          </li>
          <li>
            <strong>Número de autorización: </strong> {
              (expense.authNumber && (expense.authNumber.startsWith('A-') ? expense.authNumber : `A-${expense.authNumber}`)) || '-'
            }
          </li>
          <li>
            <strong>Fecha: </strong> {
              moment(expense.date).format('DD/MM/YYYY')
            }
          </li>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                className="form-control-alternative"
                id="modal-expense-pay-date"
                timeFormat={false}
                closeOnSelect={true}
                defaultValue={Date.now()}
                inputProps={{
                  autoComplete: 'off',
                  placeholder: 'Fecha',
                  name: 'date',
                  ref: register({
                    required: true,
                    validate: value => console.log("value", value, moment(value, 'DD/MM/YYYY').isValid()) || moment(value, 'DD/MM/YYYY').isValid()
                  }),
                }}
              />
              {errors.date && errors.date.type === 'required' && (
                <FormText color="primary">
                  Seleccione una fecha.
                </FormText>
              )}
              {errors.date && errors.date.type === 'validate' && (
                <FormText color="primary">
                  Ingrese una fecha válida o seleccione una en el calendario.
                </FormText>
              )}
            </InputGroup>
          </FormGroup>
        </ul>
        <strong style={{ color:'red' }}>{ expense.account.amount - expense.total < 0 ? 'FONDOS INSUFICIENTES' : '' }</strong>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button
        disabled={paymentDisabled}
        color="warning"
        className="mr-1"
        onClick={handleSubmit(
          (info) => {
            const selDate = info.date;
            payExpense({
              date: selDate,
              id: expense.id,
              amount: expense.total,
              account: expense.account,
              description: expense.description,
              status: null
            })
          }
        )}>
        {isLoading && <ReactLoading type="spinningBubbles" color="#fff" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
        Realizar pago
      </Button>
      <Button disabled={isLoading} color="primary" onClick={closeModal}>Cancelar</Button>
    </ModalFooter>
  </Modal>
)}

PayExpenseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  expense: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  source: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  payExpense: PropTypes.func.isRequired,
}

const mapStateToProps = props => {
  const {
    expenses: {
      openedPayExpenseModal,
      selectedExpense,
      source,
    },
    transactions: {
      loading: payingExpense
    }
  } = props

  return {
    isOpen: openedPayExpenseModal,
    expense: selectedExpense,
    isLoading: payingExpense,
    source,
  }
}

const mapDispatchToProps = dispatch => ({
  payExpense: (info) => {
    let formatInfo = ({...info});
    formatInfo.account = formatInfo.account.id;
    return dispatch(submitExpensePayment(formatInfo));
  },
  closeModal: () => dispatch(closePayExpenseModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayExpenseModal))
