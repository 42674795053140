import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderPlus, faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Button,
  Row,
  Col,
  Progress,
} from 'reactstrap'
import { loadProjects, openDeleteProjectModal } from 'redux/actions/projects'
import { formatCurrency } from 'utils/numberFormat'

class Projects extends Component {
  componentDidMount() {
    this.props.loadInfo()
  }

  render() {
    const { projects, loading, openDeleteProjectModal } = this.props

    if (loading) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Proyectos
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <>
        <Container className="pt-2 pt-md-5 pt-lg-6 mb-3" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0 rounded">
                  <h3 className="mb-0">
                    Proyectos
                    <Button tag={Link} to="/admin/projects/new" className="float-right text-sm btn btn-sm btn-outline-primary btn-sm">
                      <FontAwesomeIcon icon={faFolderPlus} className="mr-2" />
                      Nuevo Proyecto
                    </Button>
                  </h3>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            {projects.map(project => {
              const id = `project_${project.id}`
              const containerId = `container_${project.id}`
              return (
                <Col sm={6} lg={4} xl={3} key={project.id} className="mb-2" >
                  <Card className="card-stats h-100">
                    <CardBody>
                      <Row>
                        <Col>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            {project.address}
                          </CardTitle>
                          <Link to={`/admin/projects/${project.id}`}>
                            <h2 className="font-weight-bold mb-0 text-primary">
                              {project.name}
                            </h2>
                          </Link>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Row className="text-muted text-sm">
                        <Col>
                          <h6 className="text-center text-uppercase text-muted mb-0">
                            Manzanas:
                            <br />
                            {project.blocks.length}
                          </h6>
                        </Col>
                      </Row>
                    </CardFooter>
                    <CardFooter id={containerId}>
                      <Row>
                        <Col xs={12}>
                          <div className="text-center">
                            Usado: {formatCurrency({amount: project.paid_amount})} / Limite: {formatCurrency({amount: project.limit})} ({parseInt((project.limit === 0? 0 : project.paid_amount/project.limit) * 100)}%)
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Progress value={parseInt((project.limit === 0? 0 : project.paid_amount/project.limit) * 100)} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Button tag={Link} color="primary" to={`/admin/projects/${project.id}`} className="btn-sm">Ver</Button>
                        </Col>
                        <Col xs={6} className="text-right">
                          <Button
                            tag={Link}
                            color="primary"
                            to={`/admin/projects/${project.id}/edit`}
                            size="sm"
                            className="mr-2"
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                          <span id={id}>
                            <Button
                              color="warning"
                              onClick={() => openDeleteProjectModal(project)}
                              size="sm"
                            >
                              <FontAwesomeIcon className="mr-0" icon={faTrash} />
                            </Button>
                          </span>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Container>
      </>
    )
  }
}

Projects.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openDeleteProjectModal: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
}

Projects.defaultProps = {
  projects: [],
  permissions: {},
}

const mapStateToProps = ({ projects }) => ({
  projects: projects.projects,
  loading: projects.loading,
})

const mapDispatchToProps = dispatch => ({
  loadInfo: () => dispatch(loadProjects()),
  openDeleteProjectModal: project => dispatch(openDeleteProjectModal({ project, source: 'list' })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
