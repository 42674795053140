import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { loadLandsByProject } from 'redux/actions/lands'
import { loadCategoriesFromLand } from 'redux/actions/categories'

const loadLandsAndCategories = (component, openState) => {
  const LoadLandsAndCategories = ({
    projectId,
    isOpen,
    loadLandsByProject,
    loadCategories,
    loadingLands,
    loadingCategories,
    lands,
    categories,
    category,
    supplierId,
  }) => {
    useEffect(() => {
      if (isOpen) {
        loadLandsByProject(projectId)
      }
    }, [isOpen, loadLandsByProject, projectId])

    const Component = component
    return <Component
      isOpen={isOpen}
      loadingLands={loadingLands}
      lands={lands}
      categories={categories}
      loadCategories={loadCategories}
      loadingCategories={loadingCategories}
      category={category}
      supplierId={supplierId}
    />
  }

  const mapDispatchToProps = dispatch => ({
    loadLandsByProject: id => dispatch(loadLandsByProject(id)),
    loadCategories: (land, supplierId) => dispatch(loadCategoriesFromLand(land, supplierId)),
  })

  const mapStateToProps = props => {
    const {
      landNotes: { [openState]: isOpen, projectId, supplierId },
      // suppliers: { loadingSupplier: loadingLands, form },
      categories: { categories, loading: loadingCategories, category },
      lands: { lands, loading: loadingLands },
    } = props
    return {
      loadingLands,
      loadingCategories,
      isOpen,
      lands: lands,
      categories,
      projectId,
      category,
      supplierId,
    }
  }

  LoadLandsAndCategories.propTypes = {
    loadingLands: PropTypes.bool.isRequired,
    loadingCategories: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    projectId: PropTypes.number,
    loadLandsByProject: PropTypes.func.isRequired,
    loadCategories: PropTypes.func.isRequired,
    lands: PropTypes.arrayOf(PropTypes.shape({
      street: PropTypes.string,
      number: PropTypes.string,
      id: PropTypes.number,
    })),
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })),
  }

  return connect(mapStateToProps, mapDispatchToProps)(LoadLandsAndCategories)
}

export default loadLandsAndCategories
