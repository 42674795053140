import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSuitcase, faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'

import {
  Card,
  CardHeader,
  UncontrolledTooltip,
  Table,
  Container,
  Col,
  Button,
  Row,
} from 'reactstrap'
import { loadSuppliers, openDeleteSupplierModal } from 'redux/actions/suppliers'
import { hasDependencies } from './utils'
import PaginationComponent from 'react-reactstrap-pagination'

class Suppliers extends Component {
  componentDidMount() {
    this.props.loadInfo({
      perPage: 10000
    })
  }

  render() {
    const { suppliers, loading, openDeleteSupplierModal, loadInfo, pagination } = this.props

    if (loading) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Proveedores
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Proveedores
                  <Button tag={Link} to="/admin/suppliers/new" className="float-right text-sm btn btn-sm btn-outline-primary btn-sm">
                    <FontAwesomeIcon icon={faSuitcase} className="mr-2" />
                    Nuevo Proveedor
                    <FontAwesomeIcon icon={faPlus} className="ml-2" />
                  </Button>
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" size="sm" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">Alias</th>
                    <th scope="col" className="text-center">Email</th>
                    <th scope="col" className="text-center">Descripción</th>
                    <th scope="col" className="text-center">RFC</th>
                  </tr>
                </thead>
                <tbody>
                  {suppliers.map(supplier => {
                    const id = `supplier_${supplier.id}`
                    const containerId = `container_${supplier.id}`
                    return (
                      <tr key={supplier.id}>
                        <th scope="row" className="text-center mb-0 text-sm">
                          <Button
                            tag={Link}
                            to={`/admin/suppliers/${supplier.id}`}
                            className="btn btn-sm btn-outline-primary mr-0"
                          >
                            {supplier.alias}
                          </Button>
                          <Button
                            tag={Link}
                            color="primary"
                            to={`/admin/suppliers/${supplier.id}/edit`}
                            size="sm"
                            className="mr-2 mt-2"
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                          {hasDependencies(supplier) && <UncontrolledTooltip boundariesElement={containerId} placement="top" target={id} >Este proveedor no se puede eliminar por que tiene contactos, lotes o notas asociadas a el.</UncontrolledTooltip>}
                          <span id={id}>
                            <Button
                              color="warning"
                              onClick={() => openDeleteSupplierModal(supplier)}
                              size="sm"
                              className="mt-2"
                              disabled={hasDependencies(supplier)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </span>
                        </th>
                        <td className="border-left mb-0 text-sm">
                          {supplier.email}
                        </td>
                        <td className="border-left mb-0 text-sm">
                          {supplier.description}
                        </td>
                        <td className="border-left mb-0 text-sm">
                          {supplier.rfc}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <PaginationComponent
              totalItems={pagination.total}
              pageSize={pagination.perPage}
              onSelect={page => loadInfo({...pagination, current: page})}
              firstPageText="<<"
              previousPageText="<"
              nextPageText=">"
              lastPageText=">>"
              defaultActivePage={pagination.current}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

Suppliers.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openDeleteSupplierModal: PropTypes.func.isRequired,
  suppliers: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
}

Suppliers.defaultProps = {
  suppliers: [],
  permissions: {},
}

const mapStateToProps = ({ suppliers }) => ({
  suppliers: suppliers.suppliers,
  loading: suppliers.loading,
  pagination: suppliers.pagination,
})

const mapDispatchToProps = dispatch => ({
  loadInfo: pagination => dispatch(loadSuppliers(pagination)),
  openDeleteSupplierModal: supplier => dispatch(openDeleteSupplierModal({ supplier, source: 'list' })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers)
