import {
  LOAD_PROJECTS_START,
  LOAD_PROJECTS_ERROR,
  LOAD_PROJECTS_SUCCESS,
  PROJECT_FETCH_START,
  PROJECT_FETCH_SUCCESS,
  PROJECT_FETCH_ERROR,
  OPEN_DELETE_PROJECT_MODAL,
  CLOSE_DELETE_PROJECT_MODAL,
  PROJECT_UPDATE_START,
  PROJECT_UPDATE_ERROR,
  PROJECT_UPDATE_SUCCESS,
  DELETE_PROJECT_START,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
  PROJECT_CREATE_START,
  PROJECT_CREATE_ERROR,
  PROJECT_CREATE_SUCCESS,
} from '../actions/projects'

const defaultForm = {
  name: '',
  address: '',
}
export default (state = {
  projects: [],
  loading: false,
  openedDeleteProjectModal: false,
  form: {...defaultForm}
}, action) => {
  switch (action.type) {
  case PROJECT_UPDATE_START:
    return {
      ...state,
      loading: true
    }
  case PROJECT_UPDATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case PROJECT_UPDATE_SUCCESS:
    return {
      ...state,
      loading: false
    }
  case PROJECT_CREATE_START:
    return {
      ...state,
      loading: true
    }
  case PROJECT_CREATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case PROJECT_CREATE_SUCCESS:
    return {
      ...state,
      loading: false
    }
  case OPEN_DELETE_PROJECT_MODAL:
    return {
      ...state,
      openedDeleteProjectModal: true,
      source: action.payload.source,
      selectedProject: action.payload.project,
    }
  case CLOSE_DELETE_PROJECT_MODAL:
    return {
      ...state,
      openedDeleteProjectModal: false,
      source: null,
      selectedProject: null
    }
  case DELETE_PROJECT_START:
    return {
      ...state,
      deletingProject: true,
    }
  case DELETE_PROJECT_SUCCESS:
    return {
      ...state,
      deletingProject: false,
      form: {...defaultForm},
      projects: state.projects.filter(u => u.id !== state.selectedProject.id),
      source: null,
      openedDeleteProjectModal: false,
    }
  case DELETE_PROJECT_ERROR:
    return {
      ...state,
    }
  case LOAD_PROJECTS_ERROR:
    return {
      ...state,
      projects: [],
      error: action.payload
    }
  case LOAD_PROJECTS_START:
    return {
      ...state,
      projects: [],
      loading: true
    }
  case LOAD_PROJECTS_SUCCESS:
    return {
      ...state,
      projects: action.payload,
      loading: false
    }
  case PROJECT_FETCH_START:
    return {
      ...state,
      loadingProject: true,
      form: defaultForm
    }
  case PROJECT_FETCH_SUCCESS:
    return {
      ...state,
      loadingProject: false,
      form: action.payload
    }
  case PROJECT_FETCH_ERROR:
    return {
      ...state,
      loadingProject: false,
      form: action.payload
    }
  default:
    return state
  }
}
