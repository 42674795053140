import React, { useEffect } from 'react'
import get from 'lodash/get'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import BlockForm from 'components/Blocks/form'
import { createBlock, closeAddBlockModal } from 'redux/actions/blocks'

const CreateModal = ({
  isOpen,
  closeModal,
  createBlock,
  loading,
  form,
}) => {
  const { register, handleSubmit, errors, reset } = useForm()

  useEffect(() => {
    reset({ project: get(form, 'project.id', get(form, 'project')) })
  }, [reset, form])

  return (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Añadir Manzana a Proyecto</ModalHeader>
      <Form
        onSubmit={handleSubmit(info => {
          closeModal()
          createBlock(info)
        })}
        noValidate
        autoComplete="off"
      >
        <ModalBody>
          <BlockForm
            register={register}
            errors={errors}
            inProject
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Añadir Balance
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

CreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.shape({
    project: PropTypes.shape({
      id: PropTypes.number
    }),
  }),
  closeModal: PropTypes.func.isRequired,
  createBlock: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  blocks: { loading, openedAddBlockToProjectModal, form }
}) => ({
  isOpen: openedAddBlockToProjectModal,
  loading,
  form,
})

const mapDispatchToProps = dispatch => ({
  createBlock: info => dispatch(createBlock(info, null, true)),
  closeModal: () => dispatch(closeAddBlockModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateModal))
