import { toast } from 'react-toastify'
import instance from '../../axios'
import sanitizeValues from 'utils/sanitizeValues'
import { fetchShopper } from './shoppers'

export const SHOPPER_BALANCE_CREATE_START = 'SHOPPER_BALANCE_CREATE_START'
export const SHOPPER_BALANCE_CREATE_SUCCESS = 'SHOPPER_BALANCE_CREATE_SUCCESS'
export const SHOPPER_BALANCE_CREATE_ERROR = 'SHOPPER_BALANCE_CREATE_ERROR'

export const SHOPPER_BALANCE_UPDATE_START = 'SHOPPER_BALANCE_UPDATE_START'
export const SHOPPER_BALANCE_UPDATE_SUCCESS = 'SHOPPER_BALANCE_UPDATE_SUCCESS'
export const SHOPPER_BALANCE_UPDATE_ERROR = 'SHOPPER_BALANCE_UPDATE_ERROR'

export const OPEN_ADD_BALANCE_TO_SHOPPER_MODAL = 'OPEN_ADD_BALANCE_TO_SHOPPER_MODAL'
export const CLOSE_ADD_BALANCE_TO_SHOPPER_MODAL = 'CLOSE_ADD_BALANCE_TO_SHOPPER_MODAL'

export const OPEN_DELETE_SHOPPER_BALANCE_MODAL = 'OPEN_DELETE_SHOPPER_BALANCE_MODAL'
export const CLOSE_DELETE_SHOPPER_BALANCE_MODAL = 'CLOSE_DELETE_SHOPPER_BALANCE_MODAL'

export const OPEN_EDIT_SHOPPER_BALANCE_MODAL = 'OPEN_EDIT_SHOPPER_BALANCE_MODAL'
export const CLOSE_EDIT_SHOPPER_BALANCE_MODAL = 'CLOSE_EDIT_SHOPPER_BALANCE_MODAL'

export const DELETE_SHOPPER_BALANCE_START = 'DELETE_SHOPPER_BALANCE_START'
export const DELETE_SHOPPER_BALANCE_SUCCESS = 'DELETE_SHOPPER_BALANCE_SUCCESS'
export const DELETE_SHOPPER_BALANCE_ERROR = 'DELETE_SHOPPER_BALANCE_ERROR'

export const openAddShopperBalanceModal = payload => ({
  type: OPEN_ADD_BALANCE_TO_SHOPPER_MODAL,
  payload
})

export const closeAddShopperBalanceModal = () => ({
  type: CLOSE_ADD_BALANCE_TO_SHOPPER_MODAL,
})

export const openEditShopperBalanceModal = payload => ({
  type: OPEN_EDIT_SHOPPER_BALANCE_MODAL,
  payload
})

export const closeEditShopperBalanceModal = () => ({
  type: CLOSE_EDIT_SHOPPER_BALANCE_MODAL,
})

export const openDeleteShopperBalanceModal = payload => ({
  type: OPEN_DELETE_SHOPPER_BALANCE_MODAL,
  payload
})

export const closeDeleteShopperBalanceModal = () => ({
  type: CLOSE_DELETE_SHOPPER_BALANCE_MODAL,
})

export const deleteShopperBalance = shopperBalance => dispatch => {
  dispatch({
    type: DELETE_SHOPPER_BALANCE_START
  })

  return instance.delete(`/shopperBalances/${shopperBalance.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_SHOPPER_BALANCE_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado ese movimiento del comprador ${data.shopper.name} ${data.shopper.lastName}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      fetchShopper(data.shopper.id)(dispatch)
    })
    .catch(err => {
      dispatch({
        type: DELETE_SHOPPER_BALANCE_ERROR,
        payload: err
      })
    })
}

export const updateShopperBalance = ({ isCharge, amount, date, ...values}) => dispatch => {
  dispatch({
    type: SHOPPER_BALANCE_UPDATE_START
  })

  const sanitized = sanitizeValues(values)
  sanitized.amount = parseFloat(amount) * (isCharge ? -1 : 1)
  sanitized.date = (new Date(date)).valueOf()

  return instance.patch(`/shopperBalances/${sanitized.id}`, sanitized)
    .then(({ data }) => {
      dispatch({
        type: SHOPPER_BALANCE_UPDATE_SUCCESS,
        payload: data
      })

      fetchShopper(data.shopper.id)(dispatch)
    })
    .catch(err => {
      dispatch({
        type: SHOPPER_BALANCE_UPDATE_ERROR,
        payload: err
      })
    })
}

export const createShopperBalance = ({
  isCharge,
  amount,
  date,
  // eslint-disable-next-line no-unused-vars
  id,
  ...values
}) => dispatch => {
  dispatch({
    type: SHOPPER_BALANCE_CREATE_START
  })
  const sanitized = sanitizeValues(values)
  sanitized.amount = parseFloat(amount) * (isCharge === 'true' ? -1 : 1)
  sanitized.date = (new Date(date)).valueOf()

  return instance.post('/shopperBalances', sanitized)
    .then(({ data }) => {
      dispatch({
        type: SHOPPER_BALANCE_CREATE_SUCCESS,
        payload: data
      })
      fetchShopper(data.shopper.id)(dispatch)
    })
    .catch(err => {
      dispatch({
        type: SHOPPER_BALANCE_CREATE_ERROR,
        payload: err
      })
    })
}
