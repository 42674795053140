import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useForm from 'react-hook-form'
import get from 'lodash/get'
import ReactDatetime from 'react-datetime'
import { isNumeric } from 'utils/numberFormat'

import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import moment from 'moment'
import 'moment/locale/es'

const tomorrow = moment().add(1, 'day')

const onChangeWork = (index, id, selected, setSelected) => {
  console.log('ID', id, 'index', index, 'selected', selected);
  const newSelected = [...selected];
  newSelected[index] = id === "" ? null : parseInt(id);
  setSelected([...newSelected]);
};

const MultipleExpensesForm = props => {
  const {
    readOnly,
    loading,
    suppliers,
    works,
    form,
    onSubmit,
    accounts,
  } = props

  const [selected, setSelected] = useState([]);

  const { register, errors, handleSubmit, getValues } = useForm()

  const date = get(form, 'date') ? moment(get(form, 'date'), 'DD/MM/YYYY', true).utc() : Date.now()
  /* useEffect(() => {
    const neodata = get(form, 'neodata')
    const work = get(neodata, 'work')
    reset({
      payment_method: '0',
      ...form,
      neodata: get(neodata, 'id'),
      work,
      supplier: supplier || "-1"
    })
  }, [form, reset, supplier, supplierList, transactions]) */


  return (
    <Form noValidate autoComplete="off">
      <Card className="mt-4">
        <CardBody>
          {Array.from({ length: 10 }, (_, k) => k)
            .map(number => {
              const selectedWork = get(selected, `[${number}]`)
              const neodatas = selectedWork ? works.find(w => w.id === selectedWork).neodatas : []
              return (
                <div key={number}>
                  <Row>
                    <Col>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            name={`include[${number}]`}
                            innerRef={register}
                          />{' '}
                          Incluir este gasto
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="date">Fecha</Label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            className="form-control-alternative"
                            timeFormat={false}
                            closeOnSelect={true}
                            locale='es'
                            utc
                            dateFormat="DD/MM/YYYY"
                            defaultValue={date}
                            isValidDate={current => current.isBefore(tomorrow)}
                            inputProps={{
                              autoComplete: 'off',
                              placeholder: 'Fecha',
                              name: `date[${number}]`,
                              readOnly: true,
                              ref: register({
                                validate: value => {
                                  const allValues = getValues()
                                  if (allValues[`include[${number}]`] && !moment(value, 'DD/MM/YYYY').isValid()) {
                                    return "Ingresar una fecha válida";
                                  }
                                }
                              }),
                            }}
                          />
                        </InputGroup>
                        {errors[`date[${number}]`] && (
                          <FormText color="primary">
                            ERROR: {errors[`date[${number}]`].message}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="supplier">Proveedor <span className="text-danger">*</span></Label>
                        {!loading &&
                          <Input
                            className="form-control-alternative"
                            id="supplier"
                            autoComplete="off"
                            type="select"
                            name={`supplier[${number}]`}
                            innerRef={register({
                              validate: value => {
                                const allValues = getValues()
                                if (allValues[`include[${number}]`] && value === '-1') {
                                  return "Seleccione un proveedor";
                                }
                              }
                            })}
                          >
                            {[<option key={'emptyValue'} value="-1">Seleccione una opción</option>]
                              .concat(suppliers
                                .map(supplier => <option value={supplier.id} key={supplier.id}>
                                    {!supplier.alias || supplier.alias.trim() === '' ? supplier.fiscalName : supplier.alias}
                                  </option>
                                )
                              )
                            }
                          </Input>
                        }
                        {errors[`supplier[${number}]`] && (
                          <FormText color="primary">
                            Seleccione un proveedor
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="payment-method">Forma de pago</Label>
                        {!loading && <Input
                          className="form-control-alternative"
                          id="payment-method"
                          autoComplete="off"
                          type="select"
                          name={`payment_method[${number}]`}
                          innerRef={register({
                            validate: value => {
                              const allValues = getValues()
                              if (allValues[`include[${number}]`] &&  value === '') {
                                return "Seleccione un método de pago";
                              }
                            }
                          })}
                        >
                          <option value="">Seleccione una opción</option>
                          <option value="1">Efectivo</option>
                          <option value="2">Cheque</option>
                          <option value="3">Transferencia</option>
                          <option value="4">Tarjeta</option>
                        </Input>}
                        {errors[`payment_method[${number}]`] && (
                          <FormText color="primary">
                            Seleccione un metodo de pago valido.
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="account">Cuenta <span className="text-danger">*</span></Label>
                        {!loading &&
                          <Input
                            className="form-control-alternative"
                            autoComplete="off"
                            type="select"
                            name={`account[${number}]`}
                            innerRef={register({
                              validate: value => {
                                const allValues = getValues()
                                if (allValues[`include[${number}]`] && value === '') {
                                  return "seleccione una cuenta";
                                }
                              }
                            })}
                          >
                            {[<option key={'emptyValue'} value="">Seleccione una opción</option>]
                              .concat(accounts
                                .map(account => <option value={account.id} key={account.id}>{account.name}</option>)
                              )
                            }
                          </Input>
                        }
                        {errors[`account[${number}]`] && (
                          <FormText color="primary">
                            Seleccione una cuenta
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="folio">Folio</Label>
                        <Input
                          type="text"
                          name={`folio[${number}]`}
                          className="form-control-alternative"
                          innerRef={register}
                          placeholder="Puede omitir 'F-', escriba solo el número."
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="work">Obra <span className="text-danger">*</span></Label>
                        {!loading && <Input
                          className="form-control-alternative"
                          id={`work_${number}`}
                          autoComplete="off"
                          type="select"
                          name={`work[${number}]`}
                          onChange={e => onChangeWork(number, e.target.value, selected, setSelected)}
                          innerRef={register({
                            validate: val => {
                              const allValues = getValues()
                              const isRequired = allValues[`include[${number}]`]
                              if (isRequired && val === '') {
                                return "Seleccione Obra"
                              }
                            }
                          })}
                        >
                          {[<option key={'emptyValue'} value="">Seleccione una opción</option>]
                            .concat(works
                              .map(work => <option value={work.id} key={work.id}>{work.name}</option>)
                            )
                          }
                        </Input>}
                        {errors[`work[${number}]`] && (
                          <FormText color="primary">
                            Seleccione una obra
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="neodata">Neodata <span className="text-danger">*</span></Label>
                        {neodatas.length === 0 && <Input disabled value="Selecciona una obra" />}
                        {neodatas.length > 0 &&
                          <Input
                            className="form-control-alternative"
                            id={`neodata_${number}`}
                            autoComplete="off"
                            type="select"
                            name={`neodata[${number}]`}
                            innerRef={register({
                              validate: value => {
                                const allValues = getValues()
                                if (allValues[`include[${number}]`] && value === '') {
                                  return "Ingresar una fecha válida";
                                }
                              }
                            })}
                          >
                            {[<option key={'emptyValue'} value="">Seleccione una opción</option>]
                              .concat(neodatas
                                .map(neodata => <option value={neodata.id} key={neodata.id}>{neodata.name}</option>)
                              )
                            }
                          </Input>
                        }
                        {errors[`neodata[${number}]`] && (
                          <FormText color="primary">
                            Seleccione una Neodata
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="total">Total <span className="text-danger">*</span></Label>
                        <Input
                          type="text"
                          disabled={readOnly || form.status === 'paid'}
                          name={`total[${number}]`}
                          className="form-control-alternative text-right"
                          innerRef={register({
                            validate: value => {
                              const allValues = getValues()
                              if (allValues[`include[${number}]`] && (
                                value.trim() === '' || !isNumeric(value)
                              )) {
                                return "Ingresar un total válido, sin comas ni signos de $";
                              }
                            }
                          })}
                        // value={formatCurrency({ amount: get(totales, 'total', 0) })}
                        />
                        {errors[`total[${number}]`] && (
                          <FormText color="primary">
                            {errors[`total[${number}]`].message}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="description">Descripción</Label>
                        <Input
                          type="text"
                          name={`description[${number}]`}
                          className="form-control-alternative"
                          placeholder="Descripción"
                          innerRef={register({
                            validate: value => {
                              const allValues = getValues()
                              if (allValues[`include[${number}]`] && value.trim() === '') {
                                return "Ingresar una descripción";
                              }
                            }
                          })}
                        />
                        {errors[`description[${number}]`] && (
                          <FormText color="primary">
                            Ingrese una descripción
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                      <Label className="form-control-label" htmlFor="authNumber">Número de autorización / Cheque</Label>
                        <Input
                          type="text"
                          name={`authNumber[${number}]`}
                          placeholder="Puede omitir 'A-', escriba solo el número."
                          className="form-control-alternative"
                          innerRef={register}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                <hr />
                </div>
              )
            })
          }
        </CardBody>
      </Card>
      <hr className="my-4" />
      <Button disabled={loading} color="primary" onClick={handleSubmit(info => onSubmit(info))}>Guardar</Button>
    </Form>
  )
}


MultipleExpensesForm.propTypes = {
  errors: PropTypes.shape({
    supplier: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  suppliers: PropTypes.array,
  loading: PropTypes.bool,
  register: PropTypes.func,
  onSubmit: PropTypes.func,
}

MultipleExpensesForm.defaultProps = {
  loading: false,
  suppliers: [],
  works: [],
  neodatas: [],
  errors: {},
  form: [],
}


export default MultipleExpensesForm
