import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormText,
} from 'reactstrap'

const UserForm = ({
  onSubmit,
  loading,
  register,
  errors,
  readOnly,
  editUser,
}) => (
  <Form onSubmit={onSubmit} noValidate autoComplete="off">
    <Input name="id" type="hidden" innerRef={register} />
    <div className="pl-lg-4">
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-username"
            >
              Nombre de Usuario
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly}
              id="input-username"
              autoComplete="off"
              placeholder="Nombre de Usuario"
              type="text"
              name="user"
              innerRef={register({ required: true })}
            />
            {errors.user && errors.user.type === 'required' && (
              <FormText color="primary">
                Ingrese un nombre de usuario
              </FormText>
            )}
            {errors.user && errors.user.type === 'unique' && (
              <FormText color="primary">
                Ese nombre de usuario ya existe
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-email"
            >
              Correo electrónico
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly || editUser}
              id="input-email"
              autoComplete="off"
              placeholder="correo@ejemplo.com"
              type="email"
              name="email"
              innerRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              })}
            />
            {errors.email && errors.email.type === 'required' && (
              <FormText color="primary">
                Ingrese un correo electrónico para el usuario
              </FormText>
            )}
            {errors.email && errors.email.type === 'pattern' && (
              <FormText color="primary">
                Ingrese un correo electrónico válido
              </FormText>
            )}
            {errors.email && errors.email.type === 'unique' && (
              <FormText color="primary">
                Ese correo electrónico ya existe
              </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-name"
            >
              Nombre completo
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly}
              id="input-name"
              autoComplete="off"
              placeholder="Nombre"
              type="text"
              name="name"
              innerRef={register({ required: true })}
            />
            {errors.name && (
              <FormText color="primary">
                Ingrese un nombre para el usuario
              </FormText>
            )}
          </FormGroup>
        </Col>
        {!readOnly && !editUser && (
          <Col lg="6">
            <FormGroup>
              <Label
                className="form-control-label"
                htmlFor="input-password"
              >
                Contraseña
              </Label>
              <Input
                className="form-control-alternative"
                id="input-password"
                autoComplete="off"
                placeholder="Contraseña"
                type="password"
                name="password"
                innerRef={register({ required: true })}
              />
              {errors.password && (
                <FormText color="primary">
                  Ingrese una contraseña para el usuario
                </FormText>
              )}
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg="6" className="d-flex align-items-center">
          <div className="custom-control custom-control-alternative custom-checkbox">
            <input
              className="custom-control-input"
              disabled={readOnly}
              id="checkbox-superadmin"
              type="checkbox"
              name="isSuper"
              ref={register}
            />
            <label
              className="custom-control-label"
              htmlFor="checkbox-superadmin"
            >
              <span className="text-muted">Super Admin</span>
            </label>
          </div>
        </Col>
      </Row>
    </div>
    {!readOnly && (
      <>
        <hr className="my-4" />
        <Button disabled={loading} color="primary">Guardar</Button>
      </>
    )}
  </Form>
)

UserForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.shape({
    user: PropTypes.shape({
      type: PropTypes.string
    }),
    name: PropTypes.shape({}),
    password: PropTypes.shape({}),
    email: PropTypes.shape({
      type: PropTypes.string
    }),
    isSuper: PropTypes.shape({}),
  }),
  form: PropTypes.shape({
    user: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isSuper: PropTypes.bool.isRequired,
  }),
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  editUser: PropTypes.bool,
  onSubmit: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

UserForm.defaultProps = {
  editUser: false,
  loading: false,
  readOnly: false,
  errors: {},
  onSubmit: noop,
  register: noop,
}

export default UserForm
