import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faFileAlt, faPen, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import moment from 'moment'

import {
  Table,
  Button,
} from 'reactstrap'

import EditableField from 'components/Helpers/EditableField'

import { formatCurrency } from 'utils/numberFormat'
import { getPaymentMethod } from 'utils/paymentMethods'
import { updateSingleField } from 'redux/actions/expenses'

const TableList = props => {
  const [editingColumn, setEditingColumn] = useState(null);
  const { updateExpense } = props;
  return (
    <Table className="align-items-center table-compact" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col" className="text-center">Fecha</th>
          <th scope="col" className="text-center">Proveedor</th>
          <th scope="col" className="text-center">Obra</th>
          <th scope="col" className="text-center">Neodata</th>
          <th scope="col" className="text-center">Total</th>
          <th scope="col" className="text-center">Forma de pago</th>
          <th scope="col" className="text-center">No. de Folio</th>
          <th scope="col" className="text-center">Autorización</th>
          <th scope="col" className="text-center">Cuenta</th>
          <th scope="col" className="text-center">Voucher</th>
          <th scope="col" className="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {props.expenses.map(expense => {
          const id = `expense_${expense.id}`
          let expenseFolio = (expense.folio && (expense.folio.startsWith('F-') ? expense.folio : `F-${expense.folio}`)) || '';
          expenseFolio = (
            get(editingColumn, '[0]') === expense.id &&
            get(editingColumn, '[1]') === 'folio'
          ) ? get(editingColumn, '[2]', expenseFolio) : expenseFolio;
          let expenseAuth = (expense.authNumber && (expense.authNumber.startsWith('A-') ? expense.authNumber : `A-${expense.authNumber}`)) || '-';
          expenseAuth = (
            get(editingColumn, '[0]') === expense.id &&
            get(editingColumn, '[1]') === 'authNumber'
          ) ? get(editingColumn, '[2]', expenseAuth) : expenseAuth;
          return (
            <tr key={expense.id} color={expense.status}>
              <td className="border-left text-sm text-center">
                {expense.date ? moment(expense.date).utc().format('DD/MMMM/YYYY') : '---'}
              </td>
              <td className="border-left text-sm text-center">
                {expense.supplier && <Button
                  tag={Link}
                  color="primary"
                  to={`/admin/suppliers/${expense.supplier.id}`}
                  className="btn btn-sm mr-0"
                >
                  {expense.supplier && expense.supplier.alias && <>{expense.supplier.alias}</>}
                </Button>}
                {!expense.supplier && <>Sin Proveedor asignado</>}
              </td>
              <td className="border-left text-sm text-center">
                <strong>{get(expense.neodata.work, 'name')}</strong>
              </td>
              <td className="border-left text-sm text-center">
                <strong>{get(expense.neodata, 'name')}</strong>
              </td>
              <td className="border-left text-sm text-right">
                {formatCurrency({ amount: expense.total })}
              </td>
              <td className="border-left text-sm text-center">
                {getPaymentMethod(expense.payment_method, true)}
              </td>
              <EditableField
                classes='border-left text-sm text-center'
                fieldClasses="form-control"
                fieldStyles={{width: '100%'}}
                value = {expenseFolio}
                inputChange = {(e) => setEditingColumn([expense.id, 'folio', e.target.value])}
                doubleClick = {() => setEditingColumn([expense.id, 'folio', expenseFolio])}
                blur = {(e) => updateExpense(expense.id, 'folio', e.target.value)}
                active = {expense.id === get(editingColumn, '[0]') && get(editingColumn, '[1]') === 'folio'} />
              <EditableField
                classes='border-left text-sm text-center'
                fieldClasses="form-control"
                fieldStyles={{width: '100%'}}
                value = {expenseAuth}
                inputChange = {(e) => setEditingColumn([expense.id, 'authNumber', e.target.value])}
                doubleClick = {() => setEditingColumn([expense.id, 'authNumber', expenseAuth])}
                blur = {(e) => updateExpense(expense.id, 'authNumber', e.target.value)}
                active = {expense.id === get(editingColumn, '[0]') && get(editingColumn, '[1]') === 'authNumber'} />
              <td className="border-left text-sm text-center">
                {expense.account && (expense.account.name)}
              </td>
              <td className="border-left text-sm text-center">
                {expense.voucher_url &&
                <Button
                  size="sm"
                  onClick={() => props.downloadFile(expense.id)}
                >
                  <FontAwesomeIcon icon={faFileAlt} />
                </Button>}
                {expense.voucher_url? '': '---'}
              </td>
              <th scope="row" className="border-left text-sm text-center" id={`${id}_container`}>
                <Button
                  color="warning"
                  disabled={expense.status === 'paid'}
                  onClick={() => props.openPayExpenseModal(expense)}
                  size="sm"
                >
                  <FontAwesomeIcon icon={faCreditCard} />
                </Button>
                <Button
                  tag={Link}
                  size="sm"
                  to={`/admin/expenses/${expense.id}`}
                >
                  <FontAwesomeIcon icon={faFileAlt} />
                </Button>
                <Button
                  tag={Link}
                  color="primary"
                  size="sm"
                  to={`/admin/expenses/${expense.id}/edit`}
                >
                  <FontAwesomeIcon icon={faPen} />
                </Button>
                <Button
                  color="warning"
                  disabled={expense.status === 'paid'}
                  onClick={() => props.openDeleteExpenseModal(expense)}
                  size="sm"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </th>
            </tr>
          )
        })}
      </tbody>
    </Table >
  )
}


TableList.propTypes = {
  openDeleteExpenseModal: PropTypes.func.isRequired,
  openPayExpenseModal: PropTypes.func.isRequired,
  expenses: PropTypes.arrayOf(PropTypes.shape({}))
}

TableList.defaultProps = {
  expenses: [],
  permissions: {},
}

const mapDispatchToProps = dispatch => {
  return {
    updateExpense: (expenseId, field, newValue) => dispatch(updateSingleField(expenseId, field, newValue))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(TableList))
