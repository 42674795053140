import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import { deleteLand, closeDeleteLandModal } from 'redux/actions/lands'

const DeleteModal = ({ isOpen, closeModal, land, deleteLand, isLoading, source, history }) => !land ? null : (
  <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
    <ModalHeader toggle={closeModal}>Eliminar Lote</ModalHeader>
    <ModalBody>
      Estás seguro de que quieres borrar el lote en {land.street} {land.number} ?
    </ModalBody>
    <ModalFooter>
      <Button disabled={isLoading} color="warning" className="mr-1" onClick={() => deleteLand({land, source, history})}>
        {isLoading && <ReactLoading type="spinningBubbles" color="#fff" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
        Eliminar
      </Button>
      <Button disabled={isLoading} color="primary" onClick={closeModal}>Cancelar</Button>
    </ModalFooter>
  </Modal>
)

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  land: PropTypes.shape({
    street: PropTypes.string,
    number: PropTypes.number,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  source: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  deleteLand: PropTypes.func.isRequired,
}

const mapStateToProps = ({ lands: { source, openedDeleteLandModal, selectedLand, deletingLand }}) => {
  return ({
    source,
    isOpen: openedDeleteLandModal,
    land: selectedLand,
    isLoading: deletingLand
  })
}

const mapDispatchToProps = dispatch => ({
  deleteLand: info => dispatch(deleteLand(info)),
  closeModal: () => dispatch(closeDeleteLandModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteModal))
