
export const getPagination = ({current=1, perPage=30, total=null}={}) => {
  current = current ? current : 1
  perPage = perPage ? perPage : 30
  const skip = (current-1) * perPage
  total = (total === null || total < 0) ? 0 : total
  return {
    current,
    perPage,
    total,
    skip,
  }
}
