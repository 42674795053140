import React, { useEffect } from 'react'
import get from 'lodash/get'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { fetchLand, updateLand } from 'redux/actions/lands'
import { loadBlocks } from 'redux/actions/blocks'
import { loadShoppers } from 'redux/actions/shoppers'
import { loadModels } from 'redux/actions/models'
import LandForm from 'components/Lands/form'
import BackButton from 'components/BackButton'

const EditLand = ({
  fetchLand,
  loadBlocks,
  loadShoppers,
  loadModels,
  updateLand,
  form,
  loadingLand,
  loadingDependencies,
  blocks,
  shoppers,
  models,
  history,
  match
}) => {
  const { register, reset, handleSubmit, errors, setValue } = useForm()

  useEffect(() => {
    fetchLand(match.params.id)
    loadBlocks()
    loadShoppers()
    loadModels()
  }, [fetchLand, match, loadBlocks, loadShoppers, loadModels])

  useEffect(() => {
    reset({ ...form, predial_document: '' })
  }, [form, reset])

  useEffect(() => {
    if (blocks.length > 0 && get(form, 'block.id') !== undefined) {
      setValue('block', get(form, 'block.id'))
    }
  }, [blocks, form, setValue])

  useEffect(() => {
    if (models.length > 0 && get(form, 'model.id') !== undefined) {
      setValue('model', get(form, 'model.id'))
    }
  }, [models, form, setValue])

  useEffect(() => {
    if (shoppers.length > 0 && get(form, 'shopper.id') !== undefined) {
      setValue('shopper', get(form, 'shopper.id'))
    }
  }, [shoppers, form, setValue])

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faSquare} className="mr-1"/> Editar Lote<br/>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loadingLand && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
              <LandForm
                onSubmit={handleSubmit(params => updateLand(params, history))}
                register={register}
                errors={errors}
                models={models}
                blocks={blocks}
                shoppers={shoppers}
                loadingLand={loadingLand}
                loading={loadingDependencies}
                predial_date={form.is_predial_paid}
                predial_document={form.predial_document}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({
  lands: { form, loadingLand },
  blocks: { blocks, loading: loadingBlocks },
  shoppers: { shoppers, loading: loadingShoppers },
  models: { models, loading: loadingModels }
}) => ({
  form,
  loadingLand,
  models,
  blocks,
  shoppers,
  loadingDependencies: loadingModels || loadingBlocks || loadingShoppers
})

const mapDispatchToProps = dispatch => ({
  fetchLand: id => dispatch(fetchLand(id)),
  loadBlocks: () => dispatch(loadBlocks()),
  loadModels: () => dispatch(loadModels({perPage: 1000})),
  loadShoppers: () => dispatch(loadShoppers({perPage: 1000})),
  updateLand: (values, history) => dispatch(updateLand(values, history)),
})

EditLand.propTypes = {
  form: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    has_license: PropTypes.bool,
    is_predial_paid: PropTypes.string,
  }),
  models: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  blocks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  shoppers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  loadingLand: PropTypes.bool,
  loadingDependencies: PropTypes.bool,
  fetchLand: PropTypes.func.isRequired,
  loadBlocks: PropTypes.func.isRequired,
  loadShoppers: PropTypes.func.isRequired,
  loadModels: PropTypes.func.isRequired,
  updateLand: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
}

EditLand.defaultProps = {
  loading: false,
  form: {
    description: '',
    name: '',
    has_license: true,
    is_predial_paid: '',
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditLand))
