import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormText,
  Label,
} from 'reactstrap'

const ProjectForm = ({
  onSubmit,
  loading,
  register,
  errors,
  readOnly,
}) => (
  <Form onSubmit={onSubmit} noValidate autoComplete="off">
    <Input name="id" type="hidden" innerRef={register} />
    <div className="pl-lg-4">
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-projectname"
            >
              Nombre del Proyecto
            </Label>
            <Input
              className="form-control-alternative"
              // disabled={readOnly}
              id="input-projectname"
              autoComplete="off"
              placeholder="Nombre del Proyecto"
              type="text"
              name="name"
              innerRef={register({ required: true })}
            />
            {errors.name && errors.name.type === 'required' && (
              <FormText color="primary">
                Ingrese un nombre de proyecto
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-address"
            >
              Dirección
            </Label>
            <Input
              className="form-control-alternative"
              id="input-address"
              disabled={readOnly}
              autoComplete="off"
              placeholder="Dirección"
              type="textarea"
              name="address"
              innerRef={register}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
    {!readOnly && (
      <>
        <hr className="my-4" />
        <Button disabled={loading} color="primary">Guardar</Button>
      </>
    )}
  </Form>
)

ProjectForm.propTypes = {
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  errors: PropTypes.shape({
    name: PropTypes.shape({
      type: PropTypes.string
    })
  }),
}

ProjectForm.defaultProps = {
  loading: false,
  readOnly: false,
  onSubmit: noop,
  errors: {}
}

export default ProjectForm
