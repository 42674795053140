import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import classNames from 'classnames'
import {
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  Table
} from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { faMoneyBillWave, faPlus, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import date from 'utils/date'
import { openEditShopperBalanceModal, openAddShopperBalanceModal, openDeleteShopperBalanceModal } from 'redux/actions/shopperBalances'
import { formatCurrency } from 'utils/numberFormat'

const DependenciesList = ({ shopper, match, openEditShopperBalanceModal, openAddShopperBalanceModal, openDeleteShopperBalanceModal }) => {
  const balancesSum = get(shopper, 'balances', []).reduce((sum, balance) => sum + balance.amount, 0)
  return (
    <Row>
      <Col className="order-xl-1 mt-3" xl="12">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs={12}>
                <h6 className="heading-small text-muted">
                  Lotes
                </h6>
              </Col>
            </Row>
          </CardHeader>
          {get(shopper, 'lands', []).length === 0 && <div className="text-mute text-sm p-3">Este cliente no ha comprado ningún lote.</div>}
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th/>
                <th scope="col" className="text-center">Número</th>
                <th scope="col" className="text-center">Calle</th>
                <th scope="col" className="text-center">Costo</th>
              </tr>
            </thead>
            <tbody>
              {get(shopper, 'lands', []).map(land => (
                <tr key={land.id}>
                  <th scope="row" className="border-left text-center">
                    <Button
                      tag={Link}
                      to={`/admin/lands/${land.id}`}
                      className="btn btn-sm btn-outline-primary mr-0"
                    >
                      Ver
                    </Button>
                  </th>
                  <td className="border-left mb-0 text-sm">
                    {land.number}
                  </td>
                  <td className="border-left mb-0 text-sm">
                    {land.street}
                  </td>
                  <td className="border-left mb-0 text-sm">
                    {formatCurrency({amount: land.cost})}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs={12}>
                <h6 className="heading-small text-muted">
                  Movimientos
                  <Button
                    color="primary"
                    size="sm"
                    className="float-right mt--1"
                    onClick={() => openAddShopperBalanceModal({ id: parseInt(match.params.id) })}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faMoneyBillWave} />
                    Añadir Movimiento a Comprador
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </h6>
              </Col>
            </Row>
          </CardHeader>
          {get(shopper, 'lands', []).length === 0 && <div className="text-mute text-sm p-3">No hay ningún movimiento de este comprador.</div>}
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center">Número</th>
                <th scope="col" className="text-center">Fecha</th>
                <th scope="col" className="text-center">Información</th>
                <th scope="col" className="text-center">Cargos</th>
                <th scope="col" className="text-center">Abonos</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {get(shopper, 'balances', []).map((balance, index) => (
                <tr key={balance.id}>
                  <th scope="row" className="border-left text-center">
                    {index + 1}
                  </th>
                  <td className="border-left mb-0 text-sm text-center">
                    {date(balance.date, 'fullDate')}
                  </td>
                  <td className="border-left mb-0 text-sm">
                    {balance.info}
                  </td>
                  <td className="border-left mb-0 text-sm text-right">
                    {balance.amount < 0 ? formatCurrency({amount: Math.abs(balance.amount)}) : '-'}
                  </td>
                  <td className="border-left mb-0 text-sm text-right">
                    {balance.amount > 0 ? formatCurrency({amount: balance.amount}) : '-'}
                  </td>
                  <td className="border-left text-center">
                    <Button
                      color="primary"
                      size="sm"
                      className="mr-2"
                      onClick={() => openEditShopperBalanceModal(Object.assign({ shopper }, balance))}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={() => openDeleteShopperBalanceModal(balance)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <th colSpan="3" className="text-right text-sm">Subtotal</th>
                <td className="text-sm border-left text-right text-primary">{formatCurrency({
                  amount: Math.abs(get(shopper, 'balances', []).filter(b => b.amount < 0).reduce((sum, balance) => sum + balance.amount, 0))
                })}</td>
                <td className="text-sm border-left text-right text-success">{formatCurrency({
                  amount: Math.abs(get(shopper, 'balances', []).filter(b => b.amount > 0).reduce((sum, balance) => sum + balance.amount, 0))
                })}</td>
                <td className="border-left" />
              </tr>
              <tr>
                <th colSpan="3" className="text-right text-sm">Total</th>
                <th colSpan="2" className={classNames('text-center text-sm border-left', {'text-primary': balancesSum < 0, 'text-success': balancesSum > 0 })}>
                  {formatCurrency({amount: Math.abs(balancesSum)})}
                </th>
                <td className="border-left" />
              </tr>
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  )
}

DependenciesList.propTypes = {
  shopper: PropTypes.shape({
    lands: PropTypes.arrayOf(PropTypes.shape({
      street: PropTypes.string,
      number: PropTypes.string,
      id: PropTypes.number,
    })),
    balances: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      date: PropTypes.date,
      id: PropTypes.number,
      info: PropTypes.string,
    })),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  openEditShopperBalanceModal: PropTypes.func.isRequired,
  openAddShopperBalanceModal: PropTypes.func.isRequired,
  openDeleteShopperBalanceModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  openAddShopperBalanceModal: contact => dispatch(openAddShopperBalanceModal(contact)),
  openDeleteShopperBalanceModal: contact => dispatch(openDeleteShopperBalanceModal(contact)),
  openEditShopperBalanceModal: contact => dispatch(openEditShopperBalanceModal(contact)),
})

export default withRouter(connect(null, mapDispatchToProps)(DependenciesList))
