import { toast } from 'react-toastify'
import instance from '../../axios'
import sanitizeValues from 'utils/sanitizeValues'
import { getPagination } from 'utils/pagination'

export const LOAD_LANDS_START = 'LOAD_LANDS_START'
export const LOAD_LANDS_SUCCESS = 'LOAD_LANDS_SUCCESS'
export const LOAD_LANDS_ERROR = 'LOAD_LANDS_ERROR'

export const LAND_CREATE_START = 'LAND_CREATE_START'
export const LAND_CREATE_SUCCESS = 'LAND_CREATE_SUCCESS'
export const LAND_CREATE_ERROR = 'LAND_CREATE_ERROR'

export const LAND_FETCH_START = 'LAND_FETCH_START'
export const LAND_FETCH_SUCCESS = 'LAND_FETCH_SUCCESS'
export const LAND_FETCH_ERROR = 'LAND_FETCH_ERROR'

export const LAND_UPDATE_START = 'LAND_UPDATE_START'
export const LAND_UPDATE_SUCCESS = 'LAND_UPDATE_SUCCESS'
export const LAND_UPDATE_ERROR = 'LAND_UPDATE_ERROR'

export const OPEN_DELETE_LAND_MODAL = 'OPEN_DELETE_LAND_MODAL'
export const CLOSE_DELETE_LAND_MODAL = 'CLOSE_DELETE_LAND_MODAL'

export const OPEN_ADD_SUPPLIER_TO_LAND_MODAL = 'OPEN_ADD_SUPPLIER_TO_LAND_MODAL'
export const CLOSE_ADD_SUPPLIER_TO_LAND_MODAL = 'CLOSE_ADD_SUPPLIER_TO_LAND_MODAL'

export const OPEN_REMOVE_SUPPLIER_FROM_MODAL = 'OPEN_REMOVE_SUPPLIER_FROM_MODAL'
export const CLOSE_REMOVE_SUPPLIER_FROM_MODAL = 'CLOSE_REMOVE_SUPPLIER_FROM_MODAL'

export const ADD_SUPPLIER_TO_LAND_START = 'ADD_SUPPLIER_TO_LAND_START'
export const ADD_SUPPLIER_TO_LAND_SUCCESS = 'ADD_SUPPLIER_TO_LAND_SUCCESS'
export const ADD_SUPPLIER_TO_LAND_ERROR = 'ADD_SUPPLIER_TO_LAND_ERROR'

export const REMOVE_SUPPLIER_TO_LAND_START = 'REMOVE_SUPPLIER_TO_LAND_START'
export const REMOVE_SUPPLIER_TO_LAND_SUCCESS = 'REMOVE_SUPPLIER_TO_LAND_SUCCESS'
export const REMOVE_SUPPLIER_TO_LAND_ERROR = 'REMOVE_SUPPLIER_TO_LAND_ERROR'

export const DELETE_LAND_START = 'DELETE_LAND_START'
export const DELETE_LAND_SUCCESS = 'DELETE_LAND_SUCCESS'
export const DELETE_LAND_ERROR = 'DELETE_LAND_ERROR'

export const openAddSupplierToLandModal = payload => ({
  type: OPEN_ADD_SUPPLIER_TO_LAND_MODAL,
  payload
})

export const closeAddSupplierToLandModal = () => ({
  type: CLOSE_ADD_SUPPLIER_TO_LAND_MODAL
})

export const openRemoveSupplierToLandModal = payload => ({
  type: OPEN_REMOVE_SUPPLIER_FROM_MODAL,
  payload
})

export const closeRemoveSupplierToLandModal = () => ({
  type: CLOSE_REMOVE_SUPPLIER_FROM_MODAL
})

const editSupplier = ({ method, type, typeSuccess, typeError }) => values => dispatch => {
  const { land, supplier } = sanitizeValues(values)
  dispatch({
    type,
    payload: values.supplier
  })


  return instance[method](`/lands/${land}/suppliers/${supplier}`)
    .then(({ data }) => {
      dispatch({
        type: typeSuccess,
        payload: data
      })
      dispatch({
        type: CLOSE_DELETE_LAND_MODAL,
      })
      dispatch({
        type: CLOSE_ADD_SUPPLIER_TO_LAND_MODAL,
      })

      toast(`Se ha ${method === 'delete' ? 'eliminado al proveedor del' : 'agregado al proveedor al'} lote`, {
        position: toast.POSITION.TOP_CENTER,
      })

      fetchLand(land)(dispatch)
    })
    .catch(err => {
      dispatch({
        type: typeError,
        payload: err
      })
      dispatch({
        type: CLOSE_DELETE_LAND_MODAL,
      })
      dispatch({
        type: CLOSE_ADD_SUPPLIER_TO_LAND_MODAL,
      })
    })
}

export const addSupplier = editSupplier({
  method: 'put',
  type: ADD_SUPPLIER_TO_LAND_START,
  typeSuccess: ADD_SUPPLIER_TO_LAND_SUCCESS,
  typeError: ADD_SUPPLIER_TO_LAND_ERROR,
})

export const removeSupplier = editSupplier({
  method: 'delete',
  type: REMOVE_SUPPLIER_TO_LAND_START,
  typeSuccess: REMOVE_SUPPLIER_TO_LAND_SUCCESS,
  typeError: REMOVE_SUPPLIER_TO_LAND_ERROR,
})

export const openDeleteLandModal = payload => ({
  type: OPEN_DELETE_LAND_MODAL,
  payload
})

export const closeDeleteLandModal = () => ({
  type: CLOSE_DELETE_LAND_MODAL,
})

export const deleteLand = ({ land, source, history }) => dispatch => {
  dispatch({
    type: DELETE_LAND_START
  })

  return instance.delete(`/lands/${land.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_LAND_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado el lote ${land.street}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin/lands')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_LAND_ERROR,
        payload: err
      })
    })
}

export const fetchLand = id => dispatch => {
  dispatch({
    type: LAND_FETCH_START
  })

  const populates_params = JSON.stringify({
    categories: { limit: 1000 }
  });

  return instance.get(`/lands/${id}?populatesparams=${populates_params}`)
    .then(({ data }) => {
      dispatch({
        type: LAND_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LAND_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateLand = (values, history) => dispatch => {
  dispatch({
    type: LAND_UPDATE_START
  })
  const sanitized = sanitizeValues(values)
  const fileFormData = new FormData();
  const formKeys = Object.keys(sanitized);
  let fileToAppend = null;

  formKeys.map(key => {
    let formVal = sanitized[key];
    if (formVal instanceof FileList) {
      if (formVal.length > 0) {
        fileToAppend = key;
      }
    } else {
      fileFormData.append(key, formVal);
    }
    return key;
  });

  if (fileToAppend !== null) {
    fileFormData.append(fileToAppend, sanitized[fileToAppend][0]);
  }

  return instance.patch(`/lands/${values.id}`, fileFormData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(({ data }) => {
      dispatch({
        type: LAND_UPDATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/lands/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: LAND_UPDATE_ERROR,
        payload: err
      })
    })
}

export const createLand = (values, history) => dispatch => {
  dispatch({
    type: LAND_CREATE_START
  })

  const sanitized = sanitizeValues(values)
  const fileFormData = new FormData();
  const formKeys = Object.keys(sanitized);
  let fileToAppend = null;

  formKeys.map(key => {
    let formVal = sanitized[key];
    if (formVal instanceof FileList) {
      if (formVal.length > 0) {
        fileToAppend = key;
      }
    } else {
      fileFormData.append(key, formVal);
    }
    return key;
  });

  if (fileToAppend !== null) {
    fileFormData.append(fileToAppend, sanitized[fileToAppend][0]);
  }

  return instance.post('/lands', fileFormData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(({ data }) => {
      dispatch({
        type: LAND_CREATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/lands/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: LAND_CREATE_ERROR,
        payload: err
      })
    })
}

export const loadLands = pagination => dispatch => {

  pagination = getPagination(pagination)
  const order_by = [
    { number: 'ASC' },
    { street: 'ASC' }
  ];

  dispatch({
    type: LOAD_LANDS_START
  })

  var totalCounts = 0;
  const getCounts = instance.get(`/lands`).then(({ data }) => {
    totalCounts = data.length
    pagination.total = totalCounts
    return instance.get(`/lands`, {
      params: {
        limit: totalCounts,
        skip: pagination.skip,
        sort: JSON.stringify(order_by),
      }
    })
      .then(({ data }) => {
        dispatch({
          type: LOAD_LANDS_SUCCESS,
          payload: data,
          pagination,
        })
      })
      .catch(err => {
        dispatch({
          type: LOAD_LANDS_ERROR,
          payload: err
        })
      })
  })
}

export const loadLandsByProject = projectId => dispatch => {
  dispatch({
    type: LOAD_LANDS_START
  })

  return instance.get(`/lands/project/${projectId}`)
    .then(({ data }) => {
      dispatch({
        type: LOAD_LANDS_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_LANDS_ERROR,
        payload: err
      })
    })
}

export const changePage = (page, pagination) => {
  const newPagination = getPagination({...pagination, current: page})
  return loadLands(newPagination)
}
