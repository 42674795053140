import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormText,
  Label,
} from 'reactstrap'

const ShopperForm = ({
  onSubmit,
  loading,
  register,
  errors,
  readOnly,
}) => (
  <Form onSubmit={onSubmit} noValidate autoComplete="off">
    <Input name="id" type="hidden" innerRef={register} />
    <div className="pl-lg-4">
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-name"
            >
              Nombre
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly}
              id="input-name"
              autoComplete="input-name"
              placeholder="Nombre"
              type="text"
              name="name"
              innerRef={register({ required: true })}
            />
          </FormGroup>
          {errors.name && (
            <FormText color="primary">
              Ingrese un nombre para el comprador
            </FormText>
          )}
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-lastName"
            >
              Apellido Paterno
            </Label>
            <Input
              className="form-control-alternative"
              id="input-lastName"
              disabled={readOnly}
              autoComplete="input-lastName"
              placeholder="Apellido Paterno"
              type="text"
              name="lastName"
              innerRef={register({ required: true })}
            />
            {errors.lastName && (
              <FormText color="primary">
                Ingrese un apellido paterno para el comprador
              </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-mLastName"
            >
              Apellido Materno
            </Label>
            <Input
              className="form-control-alternative"
              id="input-mLastName"
              disabled={readOnly}
              autoComplete="input-mLastName"
              placeholder="Apellido Materno"
              type="text"
              name="mLastName"
              innerRef={register({ required: true })}
            />
            {errors.mLastName && (
              <FormText color="primary">
                Ingrese un apellido materno para el comprador
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-phone"
            >
              Teléfono
            </Label>
            <Input
              className="form-control-alternative"
              id="input-phone"
              disabled={readOnly}
              autoComplete="input-phone"
              placeholder="Teléfono"
              type="text"
              name="phone"
              innerRef={register({
                validate: value => value.match(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/) || value === ''
              })}
            />
            {errors.phone && errors.phone.type === 'validate' && (
              <FormText color="primary">
                Ingrese un teléfono válido
              </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-email"
            >
              Correo Electrónico
            </Label>
            <Input
              className="form-control-alternative"
              id="input-email"
              disabled={readOnly}
              autoComplete="input-email"
              placeholder="Correo Electrónico"
              type="text"
              name="email"
              innerRef={register({
                validate: value => value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || value === ''
              })}
            />
            {errors.email && errors.email.type === 'validate' && (
              <FormText color="primary">
                Ingrese un correo electrónico válido
              </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
    </div>
    {!readOnly && (
      <>
        <hr className="my-4" />
        <Button disabled={loading} color="primary">Guardar</Button>
      </>
    )}
  </Form>
)

ShopperForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.shape({}),
    lastName: PropTypes.shape({}),
    mLastName: PropTypes.shape({}),
    email: PropTypes.shape({
      type: PropTypes.string
    }),
    phone: PropTypes.shape({
      type: PropTypes.string
    }),
  }),
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  editUser: PropTypes.bool,
  onSubmit: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

ShopperForm.defaultProps = {
  editUser: false,
  loading: false,
  readOnly: false,
  errors: {},
  onSubmit: noop,
}

export default ShopperForm
