import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import LandNoteForm from 'components/LandNotes/form'
import { updateLandNote, closeEditLandNoteModal } from 'redux/actions/landNotes'
import loadLandsAndCategories from 'components/HOC/loadLandsAndCategories'
import sanitizeValues from 'utils/sanitizeValues'

const EditLandNoteModal = ({
  isOpen,
  closeModal,
  updateLandNote,
  form,
  loadCategories,
  loadingCategories,
  loadingLandNotes,
  categories,
  supplierId
}) => {
  const {
    register,
    errors,
    setError,
    clearError,
    handleSubmit,
    reset
  } = useForm()

  useEffect(() => {
    reset(sanitizeValues(form))
  }, [form, reset])

  const landId = get(form, 'land.id', get(form, 'land'))

  useEffect(() => {
    if (landId && form.id && isOpen) {
      loadCategories(landId, supplierId)
    }
  }, [landId, form.id, loadCategories, isOpen, supplierId])

  return !form.note ? null : (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Actualizar Concepto en Nota</ModalHeader>
      <Form onSubmit={handleSubmit(updateLandNote)} noValidate autoComplete="off">
        <ModalBody>
          <LandNoteForm
            edit
            loadingCategories={loadingCategories}
            categories={categories}
            closeModal={closeModal}
            land={form.land}
            category={form.category}
            register={register}
            errors={errors}
            setError={setError}
            clearError={clearError}
            supplierId={supplierId}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loadingCategories || get(categories, 'length', 0) === 0 || !!errors.amount} color="primary" className="mr-1" type="submit">
            {(loadingCategories || loadingLandNotes) && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Actualizar Concepto
          </Button>
          <Button disabled={loadingCategories} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

EditLandNoteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loadingLandNotes: PropTypes.bool,
  loadingCategories: PropTypes.bool,
  form: PropTypes.shape({
    id: PropTypes.number,
    note: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({})
    ]),
    category: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    land: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        street: PropTypes.string,
        number: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    limit: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ])
  }),
  loadCategories: PropTypes.func.isRequired,
  lands: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      street: PropTypes.string,
      number: PropTypes.string,
      id: PropTypes.number,
    })
  ])),
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number
  })),
  closeModal: PropTypes.func.isRequired,
  updateLandNote: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  landNotes: { form, loading: loadingLandNotes },
  categories: { categories }
}) => ({
  categories,
  loadingLandNotes,
  form,
})

const mapDispatchToProps = dispatch => ({
  updateLandNote: info => dispatch(updateLandNote(info)),
  closeModal: () => dispatch(closeEditLandNoteModal()),
})

export default withRouter(loadLandsAndCategories(connect(mapStateToProps, mapDispatchToProps)(EditLandNoteModal), 'openedEditLandNoteModal'))
