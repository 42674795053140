import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'

import {
  Card,
  CardHeader,
  Table,
  Container,
  Col,
  Button,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import Permission from 'components/Users/permission'
import { loadUsers, openDeleteUserModal } from 'redux/actions/users'
import { loadPermissions } from 'redux/actions/permissions'

class Users extends Component {
  componentDidMount() {
    this.props.loadInfo()
  }

  render() {
    const { users, loading, permissions, openDeleteUserModal, currentUser } = this.props

    if (loading) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Usuarios
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Usuarios
                  <Button
                    tag={Link}
                    to="/admin/users/new"
                    className="float-right text-sm btn btn-sm btn-outline-primary btn-sm"
                  >
                    <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                    Nuevo Usuario
                  </Button>
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">Usuario</th>
                    <th scope="col" className="text-center">Nombre</th>
                    <th scope="col" className="text-center">Correo</th>
                    {Object.keys(permissions).map(permission => (
                      <th scope="col" className="text-center" key={permissions[permission][0].module}>{permission}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {users.map(user => {
                    const id = `user_${user.id}`
                    return (
                      <tr key={user.id}>
                        <th scope="row" className="text-center mb-0 text-sm" id={`${id}_container`}>
                          <Button
                            tag={Link}
                            to={`/admin/users/${user.id}`}
                            className="btn btn-sm btn-outline-primary mr-0"
                          >
                            {user.user}
                          </Button>
                          <br/>
                          <Button
                            tag={Link}
                            color="primary"
                            to={`/admin/users/${user.id}/edit`}
                            size="sm"
                            className="mr-2 mt-2"
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                          {(user.isSuper && user.id !== currentUser.id && !currentUser.isSuper) && (
                            <UncontrolledTooltip target={id} boundariesElement={`${id}_container`} >
                              No se puede eliminar este usuario porque es un super admin.
                            </UncontrolledTooltip>
                          )}
                          {user.id === currentUser.id && (
                            <UncontrolledTooltip target={id} boundariesElement={`${id}_container`} >
                              No se puede eliminar a sí mismo.
                            </UncontrolledTooltip>
                          )}
                          <span id={id}>
                            <Button
                              color="warning"
                              disabled={(user.isSuper && !currentUser.isSuper) || user.id === currentUser.id}
                              onClick={() => openDeleteUserModal(user)}
                              size="sm"
                              className="mt-2"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </span>
                        </th>
                        <th scope="row" className="border-left mb-0 text-sm">
                          {user.name}
                        </th>
                        <td className="border-left mb-0 text-sm">
                          {user.email}
                        </td>
                        {Object.keys(permissions).map(permission => (
                          <td key={permissions[permission][0].module} className="border-left">
                            {permissions[permission].map(p => (
                              <Permission
                                key={p.id}
                                hasPermission={!!user.permissions.find(userP => userP.id === p.id) || user.isSuper}
                                permission={p}
                                user={user}
                              />
                            ))}
                          </td>
                        ))}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

Users.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openDeleteUserModal: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  permissions: PropTypes.shape({}),
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isSuper: PropTypes.bool.isRequired
  }),
  loading: PropTypes.bool.isRequired,
}

Users.defaultProps = {
  users: [],
  permissions: {},
}

const mapStateToProps = ({ session: { user }, users, permissions }) => ({
  users: users.users,
  currentUser: user,
  permissions: permissions.permissionsObj,
  loading: users.loading || permissions.loading,
})

const mapDispatchToProps = dispatch => ({
  loadInfo: () => {
    dispatch(loadPermissions())
      .then(() => dispatch(loadUsers()))
  },
  openDeleteUserModal: user => dispatch(openDeleteUserModal({ user, source: 'list' })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
