import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faFileInvoice, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { createNote } from 'redux/actions/notes'
import { loadSuppliers } from 'redux/actions/suppliers'
import NoteForm from 'components/Notes/form'
import { loadLands } from 'redux/actions/lands'
import { cleanLandNoteForm } from 'redux/actions/landNotes'
import { loadProjects } from 'redux/actions/projects'
import { loadTransactions } from 'redux/actions/transactions'


class NewNote extends Component {

  state = {
    projectId: 4,
    supplierId: null
  }

  componentDidMount() {
    this.props.loadSuppliers()
    this.props.loadLands()
    this.props.loadProjects()
    this.props.loadTransactions()
  }

  handleOnChange = (name, value) => {
    this.setState({ [name]: value })
  }

  render() {
    const { createNote, loading, loadingNote, suppliers, history, transactions } = this.props
    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="12">
                    <h3 className="mb-0">
                      <FontAwesomeIcon icon={faFileInvoice} className="mr-1" /> Nueva Nota<br />
                      <Button
                        tag={Link}
                        color="primary"
                        to="/admin/notes"
                        size="sm"
                        className="mt-2"
                      >
                        <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                        Regresar a Lista
                      </Button>
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <NoteForm
              onSubmit={(info, action) => createNote(info, action, history)}
              suppliers={suppliers}
              loading={loading}
              loadingNote={loadingNote}
              onChange={this.handleOnChange}
              projectId={this.state.projectId}
              supplierId={this.state.supplierId}
              transactions={transactions}
              editing={true}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = ({ notes: { loading: loadingNote }, suppliers: { suppliers, loading }, transactions, landNotes }) => ({
  loading,
  loadingNote,
  suppliers,
  transactions,
  landNotes,
})

const mapDispatchToProps = dispatch => ({
  loadSuppliers: () => dispatch(loadSuppliers({perPage: 1000}, 'id,alias,fiscalName', false), dispatch(cleanLandNoteForm())),
  createNote: (values, action, history) => dispatch(createNote(values, action, history)),
  loadLands: () => dispatch(loadLands()),
  loadProjects: () => dispatch(loadProjects()),
  loadTransactions: () => dispatch(loadTransactions())
})

NewNote.propTypes = {
  suppliers: PropTypes.arrayOf(PropTypes.shape({
    alias: PropTypes.string,
    id: PropTypes.number,
  })),
  loading: PropTypes.bool,
  loadingNote: PropTypes.bool,
  loadSuppliers: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

NewNote.defaultProps = {
  loading: false,
  loadingNote: false,
  suppliers: [],
  transactions: []
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewNote))
