import React from 'react'
import ReactLoading from 'react-loading'
import get from 'lodash/get'
import {
  Button,
  Card,
  CardHeader,
  Row,
  Col,
  Table
} from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { faUserFriends, faPlus, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openEditSupplierContactModal, openAddSupplierContactModal, openDeleteSupplierContactModal } from 'redux/actions/supplierContacts'
import { formatCurrency } from 'utils/numberFormat'

const DependenciesList = ({
  supplier,
  loading,
  notes,
  match,
  openEditSupplierContactModal,
  openAddSupplierContactModal,
  openDeleteSupplierContactModal
}) => (
  <Row>
    <Col className="order-xl-1 mt-3" xl="12">
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs={12}>
              <h6 className="heading-small text-muted">
                Contactos
                <Button
                  color="primary"
                  size="sm"
                  className="float-right mt--1"
                  onClick={() => openAddSupplierContactModal({ id: parseInt(match.params.id) })}
                >
                  <FontAwesomeIcon className="mr-2" icon={faUserFriends} />
                  Añadir Contacto a Proveedor
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </h6>
            </Col>
          </Row>
        </CardHeader>
        {loading && (
          <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="m-3" />
        )}
        {get(supplier, 'contacts', []).length === 0 && !loading && <div className="text-mute text-sm p-3">No hay contactos relacionados a este Proveedor.</div>}
        {get(supplier, 'contacts', []).length > 0 && !loading && (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center">Nombre</th>
                <th scope="col" className="text-center">Puesto</th>
                <th scope="col" className="text-center">Teléfono</th>
                <th scope="col" className="text-center">Correo</th>
                <th scope="col" className="text-center" />
              </tr>
            </thead>
            <tbody>
              {get(supplier, 'contacts', []).map(contact => (
                <tr key={contact.id}>
                  <th scope="row" className="border-left text-center mb-0 text-sm">
                    {contact.name}
                  </th>
                  <td className="border-left mb-0 text-sm">
                    {contact.job}
                  </td>
                  <td className="border-left mb-0 text-sm">
                    {contact.phone}
                  </td>
                  <td className="border-left mb-0 text-sm">
                    {contact.email}
                  </td>
                  <td className="border-left text-center">
                    <Button
                      color="primary"
                      size="sm"
                      className="mr-2"
                      onClick={() => openEditSupplierContactModal(Object.assign({ supplier }, contact))}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={() => openDeleteSupplierContactModal(contact)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs={12}>
              <h6 className="heading-small text-muted">
                Notas
              </h6>
            </Col>
          </Row>
        </CardHeader>
        {loading && (
          <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="m-3" />
        )}
        {notes.length === 0 && !loading && <div className="text-mute text-sm p-3">No hay notas relacionadas a este Proveedor.</div>}
        {notes.length > 0 && !loading && (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col"></th>
                <th scope="col" className="text-center">Conceptos</th>
                <th scope="col" className="text-center">Total</th>
                <th scope="col" className="text-center">Archivo</th>
                <th scope="col" className="text-center">Factura</th>
              </tr>
            </thead>
            <tbody>
              {notes.map(note => (
                <tr key={note.id}>
                  <th scope="row" className="text-center">
                    <Button tag={Link} to={`/admin/notes/${note.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                      Ver
                    </Button>
                  </th>
                  <td className="border-left mb-0 text-sm">
                    {note.landNotes.length}
                  </td>
                  <td className="border-left mb-0 text-sm">
                    {formatCurrency({amount: note.landNotes.reduce((a, b) => a + b.amount, 0)})}
                  </td>
                  <td className="border-left mb-0 text-sm">
                    {note.fileUrl}
                  </td>
                  <td className="border-left text-center">
                    {note.invoice && note.invoice.id && (
                      <Button tag={Link} to={`/admin/invoices/${note.invoice.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                        Ver
                      </Button>
                    )}
                    {(!note.invoice || !note.invoice.id) && <span>Sin factura ligada</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs={12}>
              <h6 className="heading-small text-muted">
                Lotes en los que trabaja
              </h6>
            </Col>
          </Row>
        </CardHeader>
        {loading && (
          <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="m-3" />
        )}
        {get(supplier, 'lands', []).length === 0 && !loading && <div className="text-mute text-sm p-3">No hay lotes relacionados a este Proveedor.</div>}
        {get(supplier, 'lands', []).length > 0 && !loading && (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col"></th>
                <th scope="col" className="text-center">Calle</th>
                <th scope="col" className="text-center">Número</th>
                <th scope="col" className="text-center">Costo</th>
                <th scope="col" className="text-center">Área</th>
                <th scope="col" className="text-center">Comprador</th>
                <th scope="col" className="text-center">Manzana</th>
                <th scope="col" className="text-center">Modelo</th>
              </tr>
            </thead>
            <tbody>
              {get(supplier, 'lands', []).map(land => (
                <tr key={land.id}>
                  <th scope="row" className="text-center">
                    <Button tag={Link} to={`/admin/lands/${land.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                      Ver
                    </Button>
                  </th>
                  <th scope="row" className="border-left text-center mb-0 text-sm">
                    {land.street}
                  </th>
                  <td className="text-center mb-0 text-sm">
                    {land.number}
                  </td>
                  <td className="text-center mb-0 text-sm">
                    {formatCurrency({amount: land.cost})}
                  </td>
                  <td className="text-center mb-0 text-sm">
                    {land.area}
                  </td>
                  <td className="border-left text-center">
                    <Button tag={Link} to={`/admin/shoppers/${land.shopper}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                      Ver
                    </Button>
                  </td>
                  <td className="border-left text-center">
                    <Button tag={Link} to={`/admin/blocks/${land.block}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                      Ver
                    </Button>
                  </td>
                  <td className="border-left text-center">
                    <Button tag={Link} to={`/admin/models/${land.model}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                      Ver
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card>
    </Col>
  </Row>
)

DependenciesList.propTypes = {
  openEditSupplierContactModal: PropTypes.func.isRequired,
  openAddSupplierContactModal: PropTypes.func.isRequired,
  openDeleteSupplierContactModal: PropTypes.func.isRequired,
  supplier: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    name: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      email: PropTypes.string,
      job: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
    })),
    lands: PropTypes.arrayOf(PropTypes.shape({
      area: PropTypes.number,
      block: PropTypes.number,
      cost: PropTypes.number,
      id: PropTypes.number,
      model: PropTypes.number,
      shopper: PropTypes.number,
      street: PropTypes.string,
    }))
  }),
  notes: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number,
    id: PropTypes.number,
    invoice: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ]),
    supplier: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({}),
    ]),
    fileUrl: PropTypes.string,
    landNotes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      amount: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      land: PropTypes.oneOfType([
        PropTypes.shape({
          id: PropTypes.number,
          street: PropTypes.string,
          number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          area: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
        PropTypes.number,
      ]),
      note: PropTypes.oneOfType([
        PropTypes.shape({
          id: PropTypes.number,
        }),
        PropTypes.number,
      ])
    })),
  })),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  loading: PropTypes.bool
}

DependenciesList.defaultProps = {
  notes: [],
  loading: false
}

const mapDispatchToProps = dispatch => ({
  openAddSupplierContactModal: contact => dispatch(openAddSupplierContactModal(contact)),
  openDeleteSupplierContactModal: contact => dispatch(openDeleteSupplierContactModal(contact)),
  openEditSupplierContactModal: contact => dispatch(openEditSupplierContactModal(contact)),
})

export default withRouter(connect(null, mapDispatchToProps)(DependenciesList))
