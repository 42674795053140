import { toast } from 'react-toastify'
import instance from '../../axios'
import sanitizeValues from 'utils/sanitizeValues'
import { getPagination } from 'utils/pagination'

export const LOAD_LAND_CATEGORIES_START = 'LOAD_LAND_CATEGORIES_START'
export const LOAD_LAND_CATEGORIES_SUCCESS = 'LOAD_LAND_CATEGORIES_SUCCESS'
export const LOAD_LAND_CATEGORIES_ERROR = 'LOAD_LAND_CATEGORIES_ERROR'

export const LAND_CATEGORY_CREATE_START = 'LAND_CATEGORY_CREATE_START'
export const LAND_CATEGORY_CREATE_SUCCESS = 'LAND_CATEGORY_CREATE_SUCCESS'
export const LAND_CATEGORY_CREATE_ERROR = 'LAND_CATEGORY_CREATE_ERROR'

export const LAND_CATEGORY_FETCH_START = 'LAND_CATEGORY_FETCH_START'
export const LAND_CATEGORY_FETCH_SUCCESS = 'LAND_CATEGORY_FETCH_SUCCESS'
export const LAND_CATEGORY_FETCH_ERROR = 'LAND_CATEGORY_FETCH_ERROR'

export const LAND_CATEGORY_UPDATE_START = 'LAND_CATEGORY_UPDATE_START'
export const LAND_CATEGORY_UPDATE_SUCCESS = 'LAND_CATEGORY_UPDATE_SUCCESS'
export const LAND_CATEGORY_UPDATE_ERROR = 'LAND_CATEGORY_UPDATE_ERROR'

export const OPEN_ADD_CATEGORY_TO_LAND_MODAL = 'OPEN_ADD_CATEGORY_TO_LAND_MODAL'
export const CLOSE_ADD_CATEGORY_TO_LAND_MODAL = 'CLOSE_ADD_CATEGORY_TO_LAND_MODAL'

export const OPEN_DELETE_LAND_CATEGORY_MODAL = 'OPEN_DELETE_LAND_CATEGORY_MODAL'
export const CLOSE_DELETE_LAND_CATEGORY_MODAL = 'CLOSE_DELETE_LAND_CATEGORY_MODAL'

export const OPEN_EDIT_LAND_CATEGORY_MODAL = 'OPEN_EDIT_LAND_CATEGORY_MODAL'
export const CLOSE_EDIT_LAND_CATEGORY_MODAL = 'CLOSE_EDIT_LAND_CATEGORY_MODAL'

export const OPEN_EDIT_CATEGORY_PROVIDERS_MODAL = 'OPEN_EDIT_CATEGORY_PROVIDERS_MODAL'
export const CLOSE_EDIT_LAND_CATEGORY_SUPPLIERS_MODAL = 'CLOSE_EDIT_LAND_CATEGORY_SUPPLIERS_MODAL'
export const UPDATE_SUPPLIERS_FIELD = 'UPDATE_SUPPLIERS_FIELD'
export const LAND_CATEGORY_SUPPLIERS_UPDATE_START = 'LAND_CATEGORY_SUPPLIERS_UPDATE_START'
export const LAND_CATEGORY_SUPPLIERS_UPDATE_SUCCESS = 'LAND_CATEGORY_SUPPLIERS_UPDATE_SUCCESS'
export const LAND_CATEGORY_SUPPLIERS_UPDATE_ERROR = 'LAND_CATEGORY_SUPPLIERS_UPDATE_ERROR'

export const DELETE_LAND_CATEGORY_START = 'DELETE_LAND_CATEGORY_START'
export const DELETE_LAND_CATEGORY_SUCCESS = 'DELETE_LAND_CATEGORY_SUCCESS'
export const DELETE_LAND_CATEGORY_ERROR = 'DELETE_LAND_CATEGORY_ERROR'

export const openAddLandCategoryModal = payload => ({
  type: OPEN_ADD_CATEGORY_TO_LAND_MODAL,
  payload
})

export const closeAddLandCategoryModal = () => ({
  type: CLOSE_ADD_CATEGORY_TO_LAND_MODAL,
})

export const openEditLandCategoryModal = payload => ({
  type: OPEN_EDIT_LAND_CATEGORY_MODAL,
  payload
})

export const closeEditLandCategoryModal = () => ({
  type: CLOSE_EDIT_LAND_CATEGORY_MODAL,
})

export const openDeleteLandCategoryModal = payload => ({
  type: OPEN_DELETE_LAND_CATEGORY_MODAL,
  payload
})

export const closeDeleteLandCategoryModal = () => ({
  type: CLOSE_DELETE_LAND_CATEGORY_MODAL,
})

export const openEditCategoryProvidersModal = payload => ({
  type: OPEN_EDIT_CATEGORY_PROVIDERS_MODAL,
  payload
})

export const closeEditLandCategorySuppliersModal = () => ({
  type: CLOSE_EDIT_LAND_CATEGORY_SUPPLIERS_MODAL
})

export const updateLandCategorySuppliers = ({ ...values }) => dispatch => {
  dispatch({
    type: LAND_CATEGORY_SUPPLIERS_UPDATE_START
  })

  return instance.post(`/landCategorySuppliers`, values)
    .then(({ data }) => {
      dispatch({
        type: LAND_CATEGORY_SUPPLIERS_UPDATE_SUCCESS,
        payload: data
      })
      dispatch({
        type: CLOSE_EDIT_LAND_CATEGORY_SUPPLIERS_MODAL
      })

      loadLandCategories({ land: data.land })(dispatch)
    })
    .catch(err => {
      dispatch({
        type: LAND_CATEGORY_SUPPLIERS_UPDATE_ERROR,
        payload: err
      })
      dispatch({
        type: CLOSE_EDIT_LAND_CATEGORY_SUPPLIERS_MODAL
      })
    })
}

export const updateSuppliersField = ({ value }) => ({
  type: UPDATE_SUPPLIERS_FIELD,
  suppliersField: value,
})

export const deleteLandCategory = landCategory => dispatch => {
  dispatch({
    type: DELETE_LAND_CATEGORY_START
  })

  return instance.delete(`/landCategories/${landCategory.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_LAND_CATEGORY_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado a la categoría ${data.category.name} del lote ${data.land.number} ${data.land.street || ''}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      loadLandCategories({ land: data.land.id })(dispatch)
    })
    .catch(err => {
      dispatch({
        type: DELETE_LAND_CATEGORY_ERROR,
        payload: err
      })
    })
}

export const fetchLandCategory = id => dispatch => {
  dispatch({
    type: LAND_CATEGORY_FETCH_START
  })

  return instance.get(`/landCategories/${id}`)
    .then(({ data }) => {
      dispatch({
        type: LAND_CATEGORY_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LAND_CATEGORY_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateLandCategory = ({ model, ...values }) => dispatch => {
  dispatch({
    type: LAND_CATEGORY_UPDATE_START
  })

  if (model) {
    return createLandCategory(values)(dispatch)
      .then(() => {
        dispatch({
          type: CLOSE_EDIT_LAND_CATEGORY_MODAL
        })
      })
  }

  const sanitized = sanitizeValues(values)

  return instance.patch(`/landCategories/${sanitized.id}`, sanitized)
    .then(({ data }) => {
      dispatch({
        type: LAND_CATEGORY_UPDATE_SUCCESS,
        payload: data
      })
      dispatch({
        type: CLOSE_EDIT_LAND_CATEGORY_MODAL
      })

      loadLandCategories({ land: data.land.id })(dispatch)
    })
    .catch(err => {
      dispatch({
        type: LAND_CATEGORY_UPDATE_ERROR,
        payload: err
      })
      dispatch({
        type: CLOSE_EDIT_LAND_CATEGORY_MODAL
      })
    })
}

// eslint-disable-next-line no-unused-vars
export const createLandCategory = ({ id, model, ...values }) => dispatch => {
  dispatch({
    type: LAND_CATEGORY_CREATE_START
  })

  const sanitized = sanitizeValues(values)

  return instance.post('/landCategories', sanitized)
    .then(({ data }) => {
      dispatch({
        type: LAND_CATEGORY_CREATE_SUCCESS,
        payload: data
      })
      dispatch({
        type: CLOSE_ADD_CATEGORY_TO_LAND_MODAL,
      })
      loadLandCategories({ land: data.land })(dispatch)
    })
    .catch(err => {
      dispatch({
        type: LAND_CATEGORY_CREATE_ERROR,
        payload: err
      })
      dispatch({
        type: CLOSE_ADD_CATEGORY_TO_LAND_MODAL,
      })
    })
}

export const loadLandCategories = (landOrCat, paginationOpts) => dispatch => {
  const { land, category } = landOrCat
  // console.log(landOrCat)
  const pagination = getPagination(paginationOpts)
  let params = {}

  if (category) {
    params.where = { category }
  } else {
    params.where = { land }
  }

  params.skip = pagination.skip
  params.limit = pagination.perPage

  dispatch({
    type: LOAD_LAND_CATEGORIES_START
  })

  return instance.get(`/landCategories`, {
    params
  }).then(({ data }) => {
    dispatch({
      type: LOAD_LAND_CATEGORIES_SUCCESS,
      payload: data,
      pagination,
    })
  }).catch(err => {
    dispatch({
      type: LOAD_LAND_CATEGORIES_ERROR,
      payload: err
    })
  })
}
