import instance from '../../axios'

export const LOAD_PERMISSIONS_START = 'LOAD_PERMISSIONS_START'
export const LOAD_PERMISSIONS_SUCCESS = 'LOAD_PERMISSIONS_SUCCESS'
export const LOAD_PERMISSIONS_ERROR = 'LOAD_PERMISSIONS_ERROR'

export const loadPermissions = () => dispatch => {
  dispatch({
    type: LOAD_PERMISSIONS_START
  })

  return instance.get(`/permissions?limit=1000&sort=${JSON.stringify([{module: 'ASC'}])}`)
    .then(({ data }) => {
      dispatch({
        type: LOAD_PERMISSIONS_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_PERMISSIONS_ERROR,
        payload: err
      })
    })
}
