import {
  LOAD_MODELS_START,
  LOAD_MODELS_ERROR,
  LOAD_MODELS_SUCCESS,
  MODEL_FETCH_START,
  MODEL_FETCH_SUCCESS,
  MODEL_FETCH_ERROR,
  OPEN_DELETE_MODEL_MODAL,
  CLOSE_DELETE_MODEL_MODAL,
  MODEL_CREATE_START,
  MODEL_CREATE_SUCCESS,
  MODEL_CREATE_ERROR,
  MODEL_UPDATE_START,
  MODEL_UPDATE_SUCCESS,
  MODEL_UPDATE_ERROR,
  DELETE_MODEL_START,
  DELETE_MODEL_SUCCESS,
  DELETE_MODEL_ERROR,
} from '../actions/models'
import { getPagination } from 'utils/pagination'

const defaultForm = {
  name: '',
  description: '',
  project: null,
}
const defaultPagination = getPagination()
export default (state = {
  models: [],
  loading: false,
  loadingModel: false,
  openedDeleteModelModal: false,
  form: {...defaultForm},
  pagination: {...defaultPagination},
}, action) => {
  switch (action.type) {
  case MODEL_CREATE_START:
    return {
      ...state,
      loading: true
    }
  case MODEL_CREATE_SUCCESS:
    return {
      ...state,
      loading: false
    }
  case MODEL_CREATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case MODEL_UPDATE_START:
    return {
      ...state,
      loading: true
    }
  case MODEL_UPDATE_SUCCESS:
    return {
      ...state,
      loading: false
    }
  case MODEL_UPDATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case OPEN_DELETE_MODEL_MODAL:
    return {
      ...state,
      openedDeleteModelModal: true,
      source: action.payload.source,
      selectedModel: action.payload.model,
    }
  case CLOSE_DELETE_MODEL_MODAL:
    return {
      ...state,
      openedDeleteModelModal: false,
      source: null,
      selectedModel: null
    }
  case DELETE_MODEL_START:
    return {
      ...state,
      deletingModel: true,
    }
  case DELETE_MODEL_SUCCESS:
    return {
      ...state,
      deletingModel: false,
      form: {...defaultForm},
      models: state.models.filter(u => u.id !== state.selectedModel.id),
      source: null,
      openedDeleteModelModal: false,
    }
  case DELETE_MODEL_ERROR:
    return {
      ...state,
    }
  case LOAD_MODELS_ERROR:
    return {
      ...state,
      models: [],
      error: action.payload
    }
  case LOAD_MODELS_START:
    return {
      ...state,
      models: [],
      loading: true
    }
  case LOAD_MODELS_SUCCESS:
    return {
      ...state,
      models: action.payload.results,
      loading: false,
      pagination: {
        ...action.pagination,
        total: action.payload.totalCount,
      }
    }
  case MODEL_FETCH_START:
    return {
      ...state,
      loadingModel: true,
      form: defaultForm
    }
  case MODEL_FETCH_SUCCESS:
    return {
      ...state,
      loadingModel: false,
      form: action.payload
    }
  case MODEL_FETCH_ERROR:
    return {
      ...state,
      loading: false,
      form: action.payload
    }
  default:
    return state
  }
}
