import React from 'react'
import ReactDatetime from 'react-datetime'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText
} from 'reactstrap'

const tomorrow = moment().add(1, 'day')

const ShopperBalanceForm = ({ register, errors }) => (
  <div className="pl-lg-4">
    <Input name="id" type="hidden" innerRef={register} />
    <Input name="shopper" type="hidden" innerRef={register} />
    <Row>
      <Col lg="6">
        <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="input-date"
          >
            Fecha
          </Label>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
              className="form-control-alternative"
              id="input-date"
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{
                autoComplete: 'off',
                placeholder: 'Fecha',
                name: 'date',
                ref: register({
                  required: true,
                  validate: value => moment(value).isValid()
                }),
              }}
              isValidDate={current => current.isBefore(tomorrow)}
            />
          </InputGroup>
          {errors.date && errors.date.type === 'required' && (
            <FormText color="primary">
              Seleccione una fecha.
            </FormText>
          )}
          {errors.date && errors.date.type === 'validate' && (
            <FormText color="primary">
              Ingrese una fecha válida o seleccione una en el calendario.
            </FormText>
          )}
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="input-amount"
          >
            Monto
          </Label>
          <Input
            className="form-control-alternative"
            id="input-amount"
            autoComplete="off"
            placeholder="Monto"
            type="text"
            name="amount"
            innerRef={register({
              required: true,
              pattern: /^\d+(\.\d)?/,
            })}
          />
          {errors.amount && errors.amount.type === 'required' && (
            <FormText color="primary">
              Ingrese un monto.
            </FormText>
          )}
          {errors.amount && errors.amount.type === 'pattern' && (
            <FormText color="primary">
              Ingrese sólo dígitos.
            </FormText>
          )}
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col lg="6">
        <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="input-phone"
          >
            Información
          </Label>
          <Input
            className="form-control-alternative"
            id="input-info"
            autoComplete="off"
            placeholder="Información"
            type="textarea"
            name="info"
            innerRef={register}
          />
        </FormGroup>
      </Col>
      <Col lg="6">
        <div className="custom-control custom-radio mb-3">
          <input
            className="custom-control-input"
            id="customRadio1"
            name="isCharge"
            type="radio"
            value={true}
            ref={register({ required: true })}
          />
          <label className="custom-control-label" htmlFor="customRadio1">
            Cargo
          </label>
        </div>
        <div className="custom-control custom-radio mb-3">
          <input
            className="custom-control-input"
            id="customRadio2"
            name="isCharge"
            type="radio"
            value={false}
            ref={register({ required: true })}
          />
          <label className="custom-control-label" htmlFor="customRadio2">
            Abono
          </label>
        </div>
        {errors.isCharge && (
          <FormText color="primary">
            Seleccione Cargo o Abono para el monto a registrar.
          </FormText>
        )}
      </Col>
    </Row>
  </div>
)

ShopperBalanceForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    isCharge: PropTypes.shape({
      type: PropTypes.string
    }),
    date: PropTypes.shape({
      type: PropTypes.string
    }),
    amount: PropTypes.shape({
      type: PropTypes.string
    }),
  }),
}

ShopperBalanceForm.defaultProps = {
  errors: {}
}

export default ShopperBalanceForm
