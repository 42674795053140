import {
  SHOPPER_BALANCE_CREATE_START,
  SHOPPER_BALANCE_CREATE_SUCCESS,
  SHOPPER_BALANCE_CREATE_ERROR,
  OPEN_DELETE_SHOPPER_BALANCE_MODAL,
  CLOSE_DELETE_SHOPPER_BALANCE_MODAL,
  DELETE_SHOPPER_BALANCE_START,
  DELETE_SHOPPER_BALANCE_SUCCESS,
  DELETE_SHOPPER_BALANCE_ERROR,
  SHOPPER_BALANCE_UPDATE_START,
  SHOPPER_BALANCE_UPDATE_SUCCESS,
  SHOPPER_BALANCE_UPDATE_ERROR,
  OPEN_ADD_BALANCE_TO_SHOPPER_MODAL,
  CLOSE_ADD_BALANCE_TO_SHOPPER_MODAL,
  OPEN_EDIT_SHOPPER_BALANCE_MODAL,
  CLOSE_EDIT_SHOPPER_BALANCE_MODAL,
} from '../actions/shopperBalances'

const defaultForm = {
  amount: '',
  date: '',
  info: '',
  isCharge: false,
}
export default (state = {
  loading: false,
  updatingShopperBalance: false,
  openedRemoveBalanceFromShopperModal: false,
  openedAddBalanceToShopperModal: false,
  openedEditShopperBalanceModal: false,
  form: {...defaultForm},
}, action) => {
  switch (action.type) {
  case SHOPPER_BALANCE_CREATE_START:
    return {
      ...state,
      loading: true
    }
  case SHOPPER_BALANCE_CREATE_SUCCESS:
    return {
      ...state,
      loading: false,
      updatingShopperBalance: false
    }
  case SHOPPER_BALANCE_CREATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case OPEN_ADD_BALANCE_TO_SHOPPER_MODAL:
    return {
      ...state,
      form: {...defaultForm, shopper: action.payload },
      openedAddBalanceToShopperModal: true
    }
  case CLOSE_ADD_BALANCE_TO_SHOPPER_MODAL:
    return {
      ...state,
      form: {...defaultForm},
      openedAddBalanceToShopperModal: false
    }
  case OPEN_EDIT_SHOPPER_BALANCE_MODAL:
    return {
      ...state,
      openedEditShopperBalanceModal: true,
      form: {
        ...action.payload,
        isCharge: action.payload.amount < 0,
        amount: Math.abs(action.payload.amount)
      }
    }
  case CLOSE_EDIT_SHOPPER_BALANCE_MODAL:
    return {
      ...state,
      openedEditShopperBalanceModal: false,
      form: {...defaultForm}
    }
  case OPEN_DELETE_SHOPPER_BALANCE_MODAL:
    return {
      ...state,
      openedRemoveBalanceFromShopperModal: true,
      selectedShopperBalance: action.payload,
    }
  case CLOSE_DELETE_SHOPPER_BALANCE_MODAL:
    return {
      ...state,
      openedRemoveBalanceFromShopperModal: false,
      selectedShopperBalance: null
    }
  case DELETE_SHOPPER_BALANCE_START:
    return {
      ...state,
      deletingShopperBalance: true,
    }
  case DELETE_SHOPPER_BALANCE_SUCCESS:
    return {
      ...state,
      deletingShopperBalance: false,
      form: {...defaultForm},
      openedRemoveBalanceFromShopperModal: false,
    }
  case DELETE_SHOPPER_BALANCE_ERROR:
    return {
      ...state,
    }
  case SHOPPER_BALANCE_UPDATE_START:
    return {
      ...state,
      updatingShopperBalance: true,
    }
  case SHOPPER_BALANCE_UPDATE_SUCCESS:
    return {
      ...state,
      updatingShopperBalance: false,
      form: {...defaultForm},
      openedEditShopperBalanceModal: false,
    }
  case SHOPPER_BALANCE_UPDATE_ERROR:
    return {
      ...state,
    }
  default:
    return state
  }
}
