import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faAngleLeft, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { fetchLand, openDeleteLandModal } from 'redux/actions/lands'
import DependenciesList from 'components/Lands/dependenciesList'
import LandForm from 'components/Lands/form'
import numeral from 'numeral'

const LandDetail = ({ fetchLand, form, loading, history, match, openDeleteLandModal, hasDependencies, user, landCategories }) => {
  const { register, reset } = useForm()

  useEffect(() => {
    fetchLand(match.params.id)
  }, [fetchLand, match])

  useEffect(() => {
    reset({...form, predial_document: ''})
  }, [form, reset])

  const getTotalPaidAmount = (landCategories = []) => {
    var total = 0;
    if (landCategories.length > 0) {
      total = landCategories.reduce((prev, item) => {
        return numeral(prev).add(numeral(item.paid_amount).value()).value()
      }, 0);
    }
    return total;
  }

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <Button
        color="primary"
        onClick={() => history.goBack()}
        size="sm"
        className="mb-4"
      >
        <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
        Regresar
      </Button>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faSquare} className="mr-1"/> Detalle de Lote<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/lands/${match.params.id}/edit`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="mr-2"/>
                      Editar Lote
                    </Button>
                    <Button
                      color="warning"
                      disabled={hasDependencies}
                      onClick={() => openDeleteLandModal(form)}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2"/>
                      Borrar Lote
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!loading && (
                <LandForm
                  readOnly
                  register={register}
                  shopper={form.shopper}
                  block={form.block}
                  model={form.model}
                  id={form.id}
                  limit={form.limit}
                  predial_date={form.is_predial_paid}
                  predial_document={form.predial_document}
                  paid_amount={form.notes ? getTotalPaidAmount(landCategories) : 0}
                />
              )}
              {loading && (
                <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!loading && <DependenciesList land={form} user={user} />}
    </Container>
  )
}

const mapStateToProps = ({ lands: { form, loadingLand }, session: { user }, landCategories: { landCategories } }) => ({
  form,
  hasDependencies: get(form, 'categories', []).length > 0,
  loading: loadingLand,
  user,
  landCategories,
})

const mapDispatchToProps = dispatch => ({
  fetchLand: id => dispatch(fetchLand(id)),
  openDeleteLandModal: land => dispatch(openDeleteLandModal({ land, source: 'detail' })),
})

LandDetail.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
    has_license: PropTypes.bool,
    is_predial_paid: PropTypes.string,
    predial_document: PropTypes.string,
    shopper: PropTypes.shape({}),
    block: PropTypes.shape({}),
    model: PropTypes.shape({}),
  }),
  loading: PropTypes.bool,
  openDeleteLandModal: PropTypes.func.isRequired,
  fetchLand: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  hasDependencies: PropTypes.bool.isRequired
}

LandDetail.defaultProps = {
  loading: false,
  form: {
    description: '',
    name: '',
    has_license: false,
    is_predial_paid: null,
    predial_document: null,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandDetail))
