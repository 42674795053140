import { toast } from 'react-toastify'
import instance from '../../axios'
import { getPagination } from 'utils/pagination'

export const LOAD_SHOPPERS_START = 'LOAD_SHOPPERS_START'
export const LOAD_SHOPPERS_SUCCESS = 'LOAD_SHOPPERS_SUCCESS'
export const LOAD_SHOPPERS_ERROR = 'LOAD_SHOPPERS_ERROR'

export const SHOPPER_CREATE_START = 'SHOPPER_CREATE_START'
export const SHOPPER_CREATE_SUCCESS = 'SHOPPER_CREATE_SUCCESS'
export const SHOPPER_CREATE_ERROR = 'SHOPPER_CREATE_ERROR'

export const SHOPPER_FETCH_START = 'SHOPPER_FETCH_START'
export const SHOPPER_FETCH_SUCCESS = 'SHOPPER_FETCH_SUCCESS'
export const SHOPPER_FETCH_ERROR = 'SHOPPER_FETCH_ERROR'

export const SHOPPER_UPDATE_START = 'SHOPPER_UPDATE_START'
export const SHOPPER_UPDATE_SUCCESS = 'SHOPPER_UPDATE_SUCCESS'
export const SHOPPER_UPDATE_ERROR = 'SHOPPER_UPDATE_ERROR'

export const OPEN_DELETE_SHOPPER_MODAL = 'OPEN_DELETE_SHOPPER_MODAL'
export const CLOSE_DELETE_SHOPPER_MODAL = 'CLOSE_DELETE_SHOPPER_MODAL'
export const DELETE_SHOPPER_START = 'DELETE_SHOPPER_START'
export const DELETE_SHOPPER_SUCCESS = 'DELETE_SHOPPER_SUCCESS'
export const DELETE_SHOPPER_ERROR = 'DELETE_SHOPPER_ERROR'

export const openDeleteShopperModal = payload => ({
  type: OPEN_DELETE_SHOPPER_MODAL,
  payload
})

export const closeDeleteShopperModal = () => ({
  type: CLOSE_DELETE_SHOPPER_MODAL,
})

export const deleteShopper = ({ shopper, source, history }) => dispatch => {
  dispatch({
    type: DELETE_SHOPPER_START
  })

  return instance.delete(`/shoppers/${shopper.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_SHOPPER_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado el proveedor ${shopper.fiscalName}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin/shoppers')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_SHOPPER_ERROR,
        payload: err
      })
    })
}

export const fetchShopper = id => dispatch => {
  dispatch({
    type: SHOPPER_FETCH_START
  })

  return instance.get(`/shoppers/${id}`)
    .then(({ data: { balances, ...data } }) => {
      dispatch({
        type: SHOPPER_FETCH_SUCCESS,
        payload: { ...data, balances: balances.sort((a, b) => a.date < b.date ? 1 : -1) }
      })
    })
    .catch(err => {
      dispatch({
        type: SHOPPER_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateShopper = (values, history) => dispatch => {
  dispatch({
    type: SHOPPER_UPDATE_START
  })

  return instance.patch(`/shoppers/${values.id}`, values)
    .then(({ data }) => {
      dispatch({
        type: SHOPPER_UPDATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/shoppers/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: SHOPPER_UPDATE_ERROR,
        payload: err
      })
    })
}

// eslint-disable-next-line no-unused-vars
export const createShopper = ({ id, ...values }, history) => dispatch => {
  dispatch({
    type: SHOPPER_CREATE_START
  })

  return instance.post('/shoppers', values)
    .then(({ data }) => {
      dispatch({
        type: SHOPPER_CREATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/shoppers/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: SHOPPER_CREATE_ERROR,
        payload: err
      })
    })
}

export const loadShoppers = paginationOpts => dispatch => {
  const pagination = getPagination(paginationOpts)
  dispatch({
    type: LOAD_SHOPPERS_START
  })

  const order_by = [
    {name: 'ASC'},
    {lastName: 'ASC'},
    {mLastName: 'ASC'}
  ];

  return instance.get(`/shoppers`, {
    params: {
      sort: JSON.stringify(order_by),
      limit: pagination.perPage,
      skip: pagination.skip,
    },
  })
    .then(({ data }) => {
      dispatch({
        type: LOAD_SHOPPERS_SUCCESS,
        payload: data,
        pagination,
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_SHOPPERS_ERROR,
        payload: err
      })
    })
}
