import {
  LOAD_BLOCKS_START,
  LOAD_BLOCKS_ERROR,
  LOAD_BLOCKS_SUCCESS,
  BLOCK_FETCH_START,
  BLOCK_FETCH_SUCCESS,
  BLOCK_FETCH_ERROR,
  OPEN_DELETE_BLOCK_MODAL,
  CLOSE_DELETE_BLOCK_MODAL,
  BLOCK_UPDATE_START,
  BLOCK_UPDATE_SUCCESS,
  BLOCK_UPDATE_ERROR,
  DELETE_BLOCK_START,
  DELETE_BLOCK_SUCCESS,
  DELETE_BLOCK_ERROR,
  OPEN_ADD_BLOCK_TO_PROJECT_MODAL,
  CLOSE_ADD_BLOCK_TO_PROJECT_MODAL,
  OPEN_EDIT_BLOCK_ON_PROJECT_MODAL,
  CLOSE_EDIT_BLOCK_ON_PROJECT_MODAL
} from '../actions/blocks'

const defaultForm = {
  name: '',
  description: '',
  project: null,
}
export default (state = {
  blocks: [],
  loading: false,
  openedDeleteBlockModal: false,
  openedAddBlockToProjectModal: false,
  openedEditBlockModal: false,
  form: {...defaultForm}
}, action) => {
  switch (action.type) {
  case OPEN_ADD_BLOCK_TO_PROJECT_MODAL:
    return {
      ...state,
      openedAddBlockToProjectModal: true,
      form: {...defaultForm, ...action.payload },
    }
  case CLOSE_ADD_BLOCK_TO_PROJECT_MODAL:
    return {
      ...state,
      openedAddBlockToProjectModal: false,
      form: {...defaultForm},
    }
  case OPEN_EDIT_BLOCK_ON_PROJECT_MODAL:
    // eslint-disable-next-line no-case-declarations
    const { id, name, description, project } = action.payload.block
    return {
      ...state,
      openedEditBlockModal: true,
      source: action.payload.source,
      form: { id, name, description, project },
    }
  case CLOSE_EDIT_BLOCK_ON_PROJECT_MODAL:
    return {
      ...state,
      openedEditBlockModal: false,
    }
  case BLOCK_UPDATE_START:
    return {
      ...state,
      loading: true
    }
  case BLOCK_UPDATE_SUCCESS:
    return {
      ...state,
      loading: false
    }
  case BLOCK_UPDATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case OPEN_DELETE_BLOCK_MODAL:
    return {
      ...state,
      openedDeleteBlockModal: true,
      source: action.payload.source,
      selectedBlock: action.payload.block,
    }
  case CLOSE_DELETE_BLOCK_MODAL:
    return {
      ...state,
      openedDeleteBlockModal: false,
      source: null,
      selectedBlock: null
    }
  case DELETE_BLOCK_START:
    return {
      ...state,
      deletingBlock: true,
    }
  case DELETE_BLOCK_SUCCESS:
    return {
      ...state,
      deletingBlock: false,
      form: {...defaultForm},
      blocks: state.blocks.filter(u => u.id !== state.selectedBlock.id),
      source: null,
      openedDeleteBlockModal: false,
    }
  case DELETE_BLOCK_ERROR:
    return {
      ...state,
    }
  case LOAD_BLOCKS_ERROR:
    return {
      ...state,
      blocks: [],
      error: action.payload
    }
  case LOAD_BLOCKS_START:
    return {
      ...state,
      blocks: [],
      loading: true
    }
  case LOAD_BLOCKS_SUCCESS:
    return {
      ...state,
      blocks: action.payload,
      loading: false
    }
  case BLOCK_FETCH_START:
    return {
      ...state,
      loadingBlock: true,
      form: defaultForm
    }
  case BLOCK_FETCH_SUCCESS:
    return {
      ...state,
      loadingBlock: false,
      form: action.payload
    }
  case BLOCK_FETCH_ERROR:
    return {
      ...state,
      loadingBlock: false,
      form: action.payload
    }
  default:
    return state
  }
}
