import React from 'react'
import PropTypes from 'prop-types'
import {
  FormGroup,
  Input,
  Row,
  Col,
  FormText,
  Label,
} from 'reactstrap'

const SupplierContactForm = ({
  register,
  errors,
}) => (
  <>
    <Input name="id" type="hidden" innerRef={register} />
    <Input name="supplier" type="hidden" innerRef={register} />
    <Row>
      <Col lg="6">
        <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="input-name"
          >
            Nombre
          </Label>
          <Input
            className="form-control-alternative"
            id="input-name"
            autoComplete="off"
            placeholder="Nombre"
            type="text"
            name="name"
            innerRef={register({ required: true })}
          />
          {errors.name && errors.name.type === 'required' && (
            <FormText color="primary">
              Ingrese el nombre del contacto del proveedor
            </FormText>
          )}
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="input-job"
          >
            Puesto
          </Label>
          <Input
            className="form-control-alternative"
            id="input-job"
            autoComplete="off"
            placeholder="Puesto"
            type="text"
            name="job"
            innerRef={register}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col lg="6">
        <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="input-phone"
          >
            Teléfono
          </Label>
          <Input
            className="form-control-alternative"
            id="input-phone"
            autoComplete="off"
            placeholder="Teléfono"
            type="text"
            name="phone"
            innerRef={register({
              validate: value => value.match(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/) || value === ''
            })}
          />
          {errors.phone && errors.phone.type === 'validate' && (
            <FormText color="primary">
              Ingrese un teléfono válido
            </FormText>
          )}
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <Label
            className="form-control-label"
            htmlFor="input-email"
          >
            Correo electrónico
          </Label>
          <Input
            className="form-control-alternative"
            id="input-email"
            autoComplete="off"
            placeholder="Correo electrónico"
            type="email"
            name="email"
            innerRef={register({
              validate: value => value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || value === ''
            })}
          />
          {errors.email && errors.email.type === 'validate' && (
            <FormText color="primary">
              Ingrese un correo electrónico válido
            </FormText>
          )}
        </FormGroup>
      </Col>
    </Row>
  </>
)

SupplierContactForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.shape({
      type: PropTypes.string
    }),
    phone: PropTypes.shape({
      type: PropTypes.string
    }),
    email: PropTypes.shape({
      type: PropTypes.string
    }),
  }),
  register: PropTypes.func.isRequired,
}

SupplierContactForm.defaultProps = {
  errors: {}
}

export default SupplierContactForm
