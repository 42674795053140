import {
  LOAD_LANDS_START,
  LOAD_LANDS_ERROR,
  LOAD_LANDS_SUCCESS,
  LAND_FETCH_START,
  LAND_FETCH_SUCCESS,
  LAND_FETCH_ERROR,
  OPEN_DELETE_LAND_MODAL,
  CLOSE_DELETE_LAND_MODAL,
  LAND_UPDATE_START,
  LAND_UPDATE_SUCCESS,
  LAND_UPDATE_ERROR,
  DELETE_LAND_START,
  DELETE_LAND_SUCCESS,
  DELETE_LAND_ERROR,
  OPEN_ADD_SUPPLIER_TO_LAND_MODAL,
  CLOSE_ADD_SUPPLIER_TO_LAND_MODAL,
  ADD_SUPPLIER_TO_LAND_START,
  ADD_SUPPLIER_TO_LAND_SUCCESS,
  ADD_SUPPLIER_TO_LAND_ERROR,
  REMOVE_SUPPLIER_TO_LAND_START,
  REMOVE_SUPPLIER_TO_LAND_SUCCESS,
  REMOVE_SUPPLIER_TO_LAND_ERROR,
  OPEN_REMOVE_SUPPLIER_FROM_MODAL,
  CLOSE_REMOVE_SUPPLIER_FROM_MODAL,
} from '../actions/lands'

const defaultForm = {
  number: '',
  street: '',
  has_license: false,
  is_predial_paid: null,
  block: null,
  model: null,
  shopper: null,
}
const defaultSupplierForm = {
  land: null,
  supplier: null,
}
export default (state = {
  lands: [],
  loading: false,
  openedDeleteLandModal: false,
  openedAddSupplierModal: false,
  openedRemoveSupplierModal: false,
  form: {...defaultForm},
  supplierForm: {...defaultSupplierForm}
}, action) => {
  switch (action.type) {
  case OPEN_ADD_SUPPLIER_TO_LAND_MODAL:
    return {
      ...state,
      openedAddSupplierModal: true,
      supplierForm: { ...action.payload }
    }
  case CLOSE_ADD_SUPPLIER_TO_LAND_MODAL:
    return {
      ...state,
      openedAddSupplierModal: false,
      supplierForm: {...defaultSupplierForm}
    }
  case OPEN_REMOVE_SUPPLIER_FROM_MODAL:
    return {
      ...state,
      openedRemoveSupplierModal: true,
      supplierForm: { ...action.payload },
    }
  case CLOSE_REMOVE_SUPPLIER_FROM_MODAL:
    return {
      ...state,
      openedRemoveSupplierModal: false,
      supplierForm: {...defaultSupplierForm}
    }
  case ADD_SUPPLIER_TO_LAND_START:
    return {
      ...state,
      addingSupplier: true
    }
  case ADD_SUPPLIER_TO_LAND_SUCCESS:
    return {
      ...state,
      addingSupplier: false
    }
  case ADD_SUPPLIER_TO_LAND_ERROR:
    return {
      ...state,
      addingSupplier: false,
      error: action.payload
    }
  case REMOVE_SUPPLIER_TO_LAND_START:
    return {
      ...state,
      removingSupplier: true
    }
  case REMOVE_SUPPLIER_TO_LAND_SUCCESS:
    return {
      ...state,
      openedRemoveSupplierModal: false,
      removingSupplier: false
    }
  case REMOVE_SUPPLIER_TO_LAND_ERROR:
    return {
      ...state,
      openedRemoveSupplierModal: false,
      removingSupplier: false,
      error: action.payload
    }
  case LAND_UPDATE_START:
    return {
      ...state,
      loading: true
    }
  case LAND_UPDATE_SUCCESS:
    return {
      ...state,
      loading: false
    }
  case LAND_UPDATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case OPEN_DELETE_LAND_MODAL:
    return {
      ...state,
      openedDeleteLandModal: true,
      source: action.payload.source,
      selectedLand: action.payload.land,
    }
  case CLOSE_DELETE_LAND_MODAL:
    return {
      ...state,
      openedDeleteLandModal: false,
      source: null,
      selectedLand: null
    }
  case DELETE_LAND_START:
    return {
      ...state,
      deletingLand: true,
    }
  case DELETE_LAND_SUCCESS:
    return {
      ...state,
      deletingLand: false,
      form: {...defaultForm},
      lands: state.lands.filter(u => u.id !== state.selectedLand.id),
      source: null,
      openedDeleteLandModal: false,
    }
  case DELETE_LAND_ERROR:
    return {
      ...state,
    }
  case LOAD_LANDS_ERROR:
    return {
      ...state,
      lands: [],
      error: action.payload
    }
  case LOAD_LANDS_START:
    return {
      ...state,
      lands: [],
      loading: true
    }
  case LOAD_LANDS_SUCCESS:
    return {
      ...state,
      lands: action.payload,
      loading: false,
      pagination: {...action.pagination},
    }
  case LAND_FETCH_START:
    return {
      ...state,
      loadingLand: true,
      form: defaultForm
    }
  case LAND_FETCH_SUCCESS:
    return {
      ...state,
      loadingLand: false,
      form: action.payload
    }
  case LAND_FETCH_ERROR:
    return {
      ...state,
      loading: false,
      form: action.payload
    }
  default:
    return state
  }
}
