import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faMoneyBillWave, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { fetchShopper, updateShopper } from 'redux/actions/shoppers'
import ShopperForm from 'components/Shoppers/form'
import BackButton from 'components/BackButton'

const EditShopper = ({ fetchShopper, updateShopper, form, loading, history, match }) => {
  const { register, reset, setError, handleSubmit, errors } = useForm()
  useEffect(() => {
    fetchShopper(match.params.id)
  }, [fetchShopper, match])

  useEffect(() => {
    reset(form)
  }, [form, reset])

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faMoneyBillWave} className="mr-1"/> Editar Comprador<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to="/admin/shoppers"
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                      Regresar a Lista
                    </Button>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/shoppers/${match.params.id}`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                      Cancelar
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!loading && (
                <ShopperForm
                  onSubmit={handleSubmit(params => updateShopper({ ...params }, history, setError))}
                  history={history}
                  register={register}
                  errors={errors}
                />
              )}
              {loading && (
                <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({ shoppers: { form, loadingShopper } }) => ({
  form,
  loading: loadingShopper
})

const mapDispatchToProps = dispatch => ({
  fetchShopper: id => dispatch(fetchShopper(id)),
  updateShopper: (values, history) => dispatch(updateShopper(values, history)),
})

EditShopper.propTypes = {
  form: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    mLastName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool,
  fetchShopper: PropTypes.func.isRequired,
  updateShopper: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
}

EditShopper.defaultProps = {
  loading: false,
  form: {
    mLastName: '',
    lastName: '',
    name: '',
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditShopper))
