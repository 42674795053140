import { toast } from 'react-toastify'
import instance from '../../axios'
import get from 'lodash/get'

export const LOAD_BLOCKS_START = 'LOAD_BLOCKS_START'
export const LOAD_BLOCKS_SUCCESS = 'LOAD_BLOCKS_SUCCESS'
export const LOAD_BLOCKS_ERROR = 'LOAD_BLOCKS_ERROR'

export const BLOCK_CREATE_START = 'BLOCK_CREATE_START'
export const BLOCK_CREATE_SUCCESS = 'BLOCK_CREATE_SUCCESS'
export const BLOCK_CREATE_ERROR = 'BLOCK_CREATE_ERROR'

export const BLOCK_FETCH_START = 'BLOCK_FETCH_START'
export const BLOCK_FETCH_SUCCESS = 'BLOCK_FETCH_SUCCESS'
export const BLOCK_FETCH_ERROR = 'BLOCK_FETCH_ERROR'

export const BLOCK_UPDATE_START = 'BLOCK_UPDATE_START'
export const BLOCK_UPDATE_SUCCESS = 'BLOCK_UPDATE_SUCCESS'
export const BLOCK_UPDATE_ERROR = 'BLOCK_UPDATE_ERROR'

export const OPEN_DELETE_BLOCK_MODAL = 'OPEN_DELETE_BLOCK_MODAL'
export const CLOSE_DELETE_BLOCK_MODAL = 'CLOSE_DELETE_BLOCK_MODAL'
export const DELETE_BLOCK_START = 'DELETE_BLOCK_START'
export const DELETE_BLOCK_SUCCESS = 'DELETE_BLOCK_SUCCESS'
export const DELETE_BLOCK_ERROR = 'DELETE_BLOCK_ERROR'

export const OPEN_ADD_BLOCK_TO_PROJECT_MODAL = 'OPEN_ADD_BLOCK_TO_PROJECT_MODAL'
export const CLOSE_ADD_BLOCK_TO_PROJECT_MODAL = 'CLOSE_ADD_BLOCK_TO_PROJECT_MODAL'

export const OPEN_EDIT_BLOCK_ON_PROJECT_MODAL = 'OPEN_EDIT_BLOCK_ON_PROJECT_MODAL'
export const CLOSE_EDIT_BLOCK_ON_PROJECT_MODAL = 'CLOSE_EDIT_BLOCK_ON_PROJECT_MODAL'

export const openAddBlockModal = payload => ({
  type: OPEN_ADD_BLOCK_TO_PROJECT_MODAL,
  payload
})

export const closeAddBlockModal = () => ({
  type: CLOSE_ADD_BLOCK_TO_PROJECT_MODAL
})

export const openEditBlockModal = payload => ({
  type: OPEN_EDIT_BLOCK_ON_PROJECT_MODAL,
  payload
})

export const closeEditBlockModal = () => ({
  type: CLOSE_EDIT_BLOCK_ON_PROJECT_MODAL
})

export const openDeleteBlockModal = payload => ({
  type: OPEN_DELETE_BLOCK_MODAL,
  payload
})

export const closeDeleteBlockModal = () => ({
  type: CLOSE_DELETE_BLOCK_MODAL,
})

export const deleteBlock = ({ block, source, history }) => dispatch => {
  dispatch({
    type: DELETE_BLOCK_START
  })

  return instance.delete(`/blocks/${block.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_BLOCK_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado la manzana ${block.name}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_BLOCK_ERROR,
        payload: err
      })
    })
}

export const fetchBlock = id => dispatch => {
  dispatch({
    type: BLOCK_FETCH_START
  })

  return instance.get(`/blocks/${id}`)
    .then(({ data }) => {
      dispatch({
        type: BLOCK_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: BLOCK_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateBlock = ({ project, ...values }, history, fromProject) => dispatch => {
  dispatch({
    type: BLOCK_UPDATE_START
  })

  return instance.patch(`/blocks/${values.id}`, { project: (project && project.id) || project, ...values })
    .then(({ data }) => {
      dispatch({
        type: BLOCK_UPDATE_SUCCESS,
        payload: data
      })

      if (!fromProject) {
        history.push(`/admin/blocks/${data.id}`)
      } else {
        loadBlocks({ project: data.project.id })(dispatch)
        dispatch({
          type: CLOSE_EDIT_BLOCK_ON_PROJECT_MODAL
        })
      }
    })
    .catch(err => {
      dispatch({
        type: BLOCK_UPDATE_ERROR,
        payload: err
      })
    })
}

// eslint-disable-next-line no-unused-vars
export const createBlock = ({ project, id, ...values }, history, fromProject) => dispatch => {
  dispatch({
    type: BLOCK_CREATE_START
  })

  return instance.post('/blocks', { project: get(project, 'id', project), ...values })
    .then(({ data }) => {
      dispatch({
        type: BLOCK_CREATE_SUCCESS,
        payload: data
      })

      if (!fromProject) {
        history.push(`/admin/blocks/${data.id}`)
      } else {
        loadBlocks({ project: data.project.id })(dispatch)
        dispatch({
          type: CLOSE_ADD_BLOCK_TO_PROJECT_MODAL
        })
      }
    })
    .catch(err => {
      dispatch({
        type: BLOCK_CREATE_ERROR,
        payload: err
      })
    })
}

export const loadBlocks = ({ project } = {}) => dispatch => {
  dispatch({
    type: LOAD_BLOCKS_START
  })

  const order_by = JSON.stringify([
    {project: 'ASC'},
    {name: 'ASC'}
  ]);
  let query = `/blocks?limit=1000&sort=${order_by}`;

  if (project) {
    query = `/blocks/expandedFind/?where={"project":${project}}&sort=${order_by}`;
  }

  return instance.get(query)
    .then(({ data }) => {
      dispatch({
        type: LOAD_BLOCKS_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_BLOCKS_ERROR,
        payload: err
      })
    })
}
