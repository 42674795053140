import {
  LOAD_CATEGORIES_START,
  LOAD_CATEGORIES_ERROR,
  LOAD_CATEGORIES_SUCCESS,
  CHANGE_MODAL_CATEGORY_STATE,
  CATEGORY_FETCH_START,
  CATEGORY_FETCH_SUCCESS,
  CATEGORY_FETCH_ERROR,
  OPEN_DELETE_CATEGORY_MODAL,
  CLOSE_DELETE_CATEGORY_MODAL,
  CATEGORY_UPDATE_START,
  CATEGORY_UPDATE_ERROR,
  CATEGORY_UPDATE_SUCCESS,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
} from '../actions/categories'
import { getPagination } from 'utils/pagination'

const defaultForm = {
  name: '',
  description: '',
}
const defaultPagination = getPagination()
export default (state = {
  categories: [],
  category: null,
  loading: false,
  openedDeleteCategoryModal: false,
  form: {...defaultForm},
  pagination: {...defaultPagination}
}, action) => {
  switch (action.type) {
  case CATEGORY_UPDATE_START:
    return {
      ...state,
      loading: true
    }
  case CATEGORY_UPDATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case CATEGORY_UPDATE_SUCCESS:
    return {
      ...state,
      loading: false
    }
  case OPEN_DELETE_CATEGORY_MODAL:
    return {
      ...state,
      openedDeleteCategoryModal: true,
      source: action.payload.source,
      selectedCategory: action.payload.category,
    }
  case CLOSE_DELETE_CATEGORY_MODAL:
    return {
      ...state,
      openedDeleteCategoryModal: false,
      source: null,
      selectedCategory: null
    }
  case DELETE_CATEGORY_START:
    return {
      ...state,
      deletingCategory: true,
    }
  case DELETE_CATEGORY_SUCCESS:
    return {
      ...state,
      deletingCategory: false,
      form: {...defaultForm},
      categories: state.categories.filter(u => u.id !== state.selectedCategory.id),
      source: null,
      openedDeleteCategoryModal: false,
    }
  case DELETE_CATEGORY_ERROR:
    return {
      ...state,
    }
  case LOAD_CATEGORIES_ERROR:
    return {
      ...state,
      categories: [],
      error: action.payload
    }
  case LOAD_CATEGORIES_START:
    return {
      ...state,
      categories: [],
      loading: true
    }
  case LOAD_CATEGORIES_SUCCESS:
    return {
      ...state,
      categories: action.payload.results,
      loading: false,
      pagination: {
        ...action.pagination,
        total: action.payload.totalCount,
      }
    }
  case CHANGE_MODAL_CATEGORY_STATE:
    return {
      ...state,
      category: action.category
    }
  case CATEGORY_FETCH_START:
    return {
      ...state,
      loadingCategory: true,
      form: defaultForm
    }
  case CATEGORY_FETCH_SUCCESS:
    return {
      ...state,
      loadingCategory: false,
      form: action.payload
    }
  case CATEGORY_FETCH_ERROR:
    return {
      ...state,
      loadingCategory: false,
      form: action.payload
    }
  default:
    return state
  }
}
