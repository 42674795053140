import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBillWave, faAngleLeft, faPencilAlt, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { fetchShopper, openDeleteShopperModal } from 'redux/actions/shoppers'
import ShopperForm from 'components/Shoppers/form'
import DependenciesList from 'components/Shoppers/dependenciesList'
import { hasDependencies } from './utils'
import BackButton from 'components/BackButton'

const ShopperDetail = ({ form, loading, history, match, openDeleteShopperModal, hasDependencies, fetchShopper }) => {
  const { register, reset } = useForm()

  useEffect(() => {
    fetchShopper(match.params.id)
  }, [fetchShopper, match])

  useEffect(() => {
    reset(form)
  }, [form, reset])

  const id = `shopper_${form.id}`

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Button
        color="primary"
        onClick={() => history.goBack()}
        size="sm"
        className="mb-4"
      >
        <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
        Regresar
      </Button>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faMoneyBillWave} className="mr-1"/> Detalle de Comprador<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/shoppers/${match.params.id}/edit`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="mr-2"/>
                      Editar Comprador
                    </Button>
                    {hasDependencies && (
                      <UncontrolledTooltip target={id}>
                        No se puede eliminar este comprador porque tiene lotes asociados a el.
                      </UncontrolledTooltip>
                    )}
                    <Button
                      color="warning"
                      onClick={() => openDeleteShopperModal(form)}
                      size="sm"
                      className="mt-2"
                      disabled={hasDependencies}
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2"/>
                      Borrar Comprador
                      {hasDependencies && (
                        <FontAwesomeIcon
                          id={id}
                          icon={faInfoCircle}
                          className="ml-2"
                        />
                      )}
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {!loading && (
                <ShopperForm
                  readOnly
                  history={history}
                  register={register}
                />
              )}
              {loading && (
                <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DependenciesList shopper={form} />
    </Container>
  )
}

const mapStateToProps = ({ shoppers: { form, loadingShopper } }) => ({
  form,
  loading: loadingShopper,
  hasDependencies: hasDependencies(form)
})

const mapDispatchToProps = dispatch => ({
  fetchShopper: id => dispatch(fetchShopper(id)),
  openDeleteShopperModal: shopper => dispatch(openDeleteShopperModal({ shopper, source: 'detail' })),
})

ShopperDetail.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    name: PropTypes.string,
  }),
  loading: PropTypes.bool,
  hasDependencies: PropTypes.bool,
  openDeleteShopperModal: PropTypes.func.isRequired,
  fetchShopper: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
}

ShopperDetail.defaultProps = {
  loading: false,
  form: {
    address: '',
    name: '',
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopperDetail))
