const sanitizeValues = (values) => {
  const newValues = {}
  Object.keys(values).forEach(key => {
    if (!values[key] || values[key] === 'Seleccione una opción') {
      return
    }

    if (values[key].id) {
      newValues[key] = values[key].id
    } else {
      newValues[key] = values[key]
    }
  })

  return newValues
}

export default sanitizeValues
