import {
  LOAD_EXPENSES_START,
  LOAD_EXPENSES_ERROR,
  LOAD_EXPENSES_SUCCESS,
  EXPENSE_FETCH_START,
  EXPENSE_FETCH_SUCCESS,
  EXPENSE_FETCH_ERROR,
  OPEN_DELETE_EXPENSE_MODAL,
  CLOSE_DELETE_EXPENSE_MODAL,
  OPEN_PAY_EXPENSE_MODAL,
  CLOSE_PAY_EXPENSE_MODAL,
  DELETE_EXPENSE_START,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_ERROR,
  MULTIPLE_EXPENSE_CREATE_START,
  MULTIPLE_EXPENSE_SUCCESS,
  MULTIPLE_EXPENSE_ERROR,
  UPDATE_EXPENSE_START,
  UPDATE_EXPENSE_SUCCESS,
} from '../actions/expenses'
import { getPagination } from 'utils/pagination'

const defaultForm = {}

const defaultPagination = getPagination()

export default (state = {
  expenses: [],
  loading: false,
  loadingExpense: false,
  openedDeleteExpenseModal: false,
  openedPayExpenseModal: false,
  form: { ...defaultForm },
  search: '',
  pagination: { ...defaultPagination },
}, action) => {
  switch (action.type) {
    case OPEN_DELETE_EXPENSE_MODAL:
      return {
        ...state,
        openedDeleteExpenseModal: true,
        source: action.payload.source,
        selectedExpense: action.payload.expense,
      }
    case CLOSE_DELETE_EXPENSE_MODAL:
      return {
        ...state,
        openedDeleteExpenseModal: false,
        source: null,
        selectedExpense: null
      }
    case OPEN_PAY_EXPENSE_MODAL:
      return {
        ...state,
        openedPayExpenseModal: true,
        source: action.payload.source,
        selectedExpense: action.payload.expense,
      }
    case CLOSE_PAY_EXPENSE_MODAL:
      return {
        ...state,
        openedPayExpenseModal: false,
        source: null,
        selectedExpense: null
      }
    case DELETE_EXPENSE_START:
      return {
        ...state,
        deletingExpense: true,
      }
    case DELETE_EXPENSE_SUCCESS:
      return {
        ...state,
        deletingExpense: false,
        form: { ...defaultForm },
        expenses: state.expenses.filter(u => u.id !== state.selectedExpense.id),
        source: null,
        openedDeleteExpenseModal: false,
      }
    case DELETE_EXPENSE_ERROR:
      return {
        ...state,
      }
    case LOAD_EXPENSES_ERROR:
      return {
        ...state,
        expenses: [],
        error: action.payload
      }
    case LOAD_EXPENSES_START:
      return {
        ...state,
        expenses: [],
        loading: true,
        search: action.search,
      }
    case LOAD_EXPENSES_SUCCESS:
      return {
        ...state,
        expenses: action.payload.results,
        loading: false,
        pagination: {
          ...action.pagination,
          total: action.payload.totalCount,
        },
      }
    case EXPENSE_FETCH_START:
      return {
        ...state,
        loadingExpense: true,
      }
    case EXPENSE_FETCH_SUCCESS:
      return {
        ...state,
        loadingExpense: false,
        form: action.payload
      }
    case EXPENSE_FETCH_ERROR:
      return {
        ...state,
        loadingExpense: false,
        error: action.payload
      }
    case MULTIPLE_EXPENSE_CREATE_START:
      return {
        ...state,
        loading: true,
      }
    case MULTIPLE_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case MULTIPLE_EXPENSE_ERROR:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_EXPENSE_START:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        expenses: state.expenses.map(expense => {
          if (expense.id === action.payload.id) {
            return {
              ...expense,
              [`${action.payload.field}`]: action.payload.newValue
            }
          }
          return expense;
        })
      }
    default:
      return state
  }
}
