import React from 'react'
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faFolderPlus, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { createProject } from 'redux/actions/projects'
import ProjectForm from 'components/Projects/form'

const NewProject = ({ createProject, loading, history }) => {
  const { register, handleSubmit, errors, setError } = useForm()
  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faFolderPlus} className="mr-1"/> Nuevo Proyecto<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to="/admin/projects"
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                      Regresar a Lista
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <ProjectForm
                onSubmit={handleSubmit(params => createProject(params, history, setError))}
                loading={loading}
                register={register}
                errors={errors}
                setError={setError}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({ projects: { loading } }) => ({
  loading
})

const mapDispatchToProps = dispatch => ({
  createProject: (values, history, setError) => dispatch(createProject(values, history, setError))
})

NewProject.propTypes = {
  loading: PropTypes.bool,
  createProject: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

NewProject.defaultProps = {
  loading: false,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewProject))
