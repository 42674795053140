import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import ReactLoading from 'react-loading'
import {
  Card,
  CardHeader,
  Row,
  Button,
  Col,
  Table,
} from 'reactstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faPlus, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { loadLandNotes, openEditLandNoteModal, openAddLandNoteModal, openDeleteLandNoteModal } from 'redux/actions/landNotes'
import get from 'lodash/get'
import { formatCurrency } from 'utils/numberFormat'

const DependenciesList = props => {

  const {
    loadingLandNotes,
    loadingNote,
    loadLandNotes,
    landNotes,
    projectId,
    match,
    openAddLandNoteModal,
    openDeleteLandNoteModal,
    openEditLandNoteModal,
    readOnly,
    supplierId
  } = props

  useEffect(() => {
    if (!loadingLandNotes) {
      loadLandNotes(match.params.id)
    }
  }, [match.params.id, loadingNote, loadLandNotes])

  const loading = loadingLandNotes
  const hasLandNotes = landNotes.length > 0

  return (
    <Row>
      <Col className="order-xl-1 mt-3" xl="12">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs={12}>
                <h6 className="heading-small text-muted">
                  Conceptos
                  {!readOnly &&
                    <Button
                      color="primary"
                      size="sm"
                      className="float-right mt--1"
                      onClick={() => openAddLandNoteModal({ note: parseInt(match.params.id) }, projectId, supplierId)}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faFileContract} />
                      Añadir concepto a nota
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  }
                </h6>
              </Col>
            </Row>
          </CardHeader>
          {loading && <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block m-4" />}
          {!loading && !hasLandNotes && <div className="text-mute text-sm p-3">No hay conceptos relacionados a este lote.</div>}
          {!loading && hasLandNotes > 0 && (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" className="text-center">Importe</th>
                  <th scope="col" className="text-center">Lote</th>
                  <th scope="col" className="text-center">Categoría</th>
                  <th scope="col" className="text-center">Descripción</th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              <tbody>
                {landNotes.map(landNote => (
                  <tr key={landNote.id}>
                    <th className="text-center mb-0 text-sm">
                      {formatCurrency({ amount: landNote.amount })}
                    </th>
                    <th scope="row" className="text-center mb-0 text-sm">
                      <Button tag={Link} to={`/admin/lands/${landNote.land.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                      {landNote.land.number} {landNote.land.street}
                      </Button>
                    </th>
                    <th scope="row" className="text-center mb-0 text-sm">
                      <Button tag={Link} to={`/admin/categories/${landNote.category.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                        {landNote.category.name}
                      </Button>
                    </th>
                    <th scope="row" className="text-center mb-0 text-sm">
                      {get(landNote, 'description', '---')}
                    </th>
                    {!readOnly &&
                      <td className="border-left text-center mb-0 text-sm">
                        <Button
                          color="primary"
                          size="sm"
                          className="mr-2"
                          onClick={() => openEditLandNoteModal(landNote, projectId, supplierId)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>
                        <Button
                          color="secondary"
                          size="sm"
                          onClick={() => openDeleteLandNoteModal(landNote)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </Col>
    </Row>
  )
}

DependenciesList.propTypes = {
  landNotes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    land: PropTypes.shape({
      id: PropTypes.number,
      street: PropTypes.string,
      number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    category: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      name: PropTypes.string,
    }),
  })),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  id: PropTypes.number,
  projectId: PropTypes.number,
  loadingLandNotes: PropTypes.bool.isRequired,
  loadingNote: PropTypes.bool.isRequired,
  loadLandNotes: PropTypes.func.isRequired,
  openAddLandNoteModal: PropTypes.func.isRequired,
  openDeleteLandNoteModal: PropTypes.func.isRequired,
  openEditLandNoteModal: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  landNotes: { landNotes, loading: loadingLandNotes },
  notes: { loading: loadingNote, form }
}) => ({
  landNotes,
  loadingLandNotes,
  loadingNote,
  projectId: get(form, 'project.id', null),
})

const mapDispatchToProps = dispatch => ({
  loadLandNotes: note => dispatch(loadLandNotes({ note }, {perPage: 1000})),
  openAddLandNoteModal: (info, projectId, supplierId) => dispatch(openAddLandNoteModal(info, projectId, supplierId)),
  openDeleteLandNoteModal: info => dispatch(openDeleteLandNoteModal(info)),
  openEditLandNoteModal: (info, projectId, supplierId) => dispatch(openEditLandNoteModal(info, projectId, supplierId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DependenciesList))
