import * as numeral from 'numeral'

const formatNumber = ({value=0, prefix='', decimals=2}) => {
    let decimalsFormat = decimals >= 1 ? '.' + '0'.repeat(decimals) : '';
    const formatStr = `${prefix}0,0${decimalsFormat}`
    return numeral(value).format(formatStr);
}

const formatCurrency = ({amount=0, prefix='$', decimals=2}) => {
    return formatNumber({value: amount, prefix, decimals});
}

const formatNoDecimals = ({value=0, prefix=''}) => {
    return formatNumber({value, prefix, decimals: 0})
}

const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

export {formatNumber, formatCurrency, formatNoDecimals, isNumeric}
