import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ReactLoading from 'react-loading'
import get from 'lodash/get'
import {
  Card,
  CardHeader,
  Row,
  Button,
  Col,
  Table
} from 'reactstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadModelCategories } from 'redux/actions/modelCategories'
import { loadLandNotes } from 'redux/actions/landNotes'
import {formatCurrency} from 'utils/numberFormat'
import PaginationComponent from 'react-reactstrap-pagination'
import moment from 'moment'

class DependenciesList extends Component {
  componentDidMount() {
    this.props.loadModelCategories(this.props.match.params.id)
    this.props.loadLandNotes(this.props.match.params.id)
  }

  render () {
    const {
      modelCategories,
      landNotes,
      form,
      loading,
      loadLandNotes,
      loadModelCategories,
      landNotesPagination,
      modelCategoriesPagination,
      match,
    } = this.props

    return (
      <Row>
        <Col className="order-xl-1 mt-3" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs={12}>
                  <h6 className="heading-small text-muted">
                    Lotes
                  </h6>
                </Col>
              </Row>
            </CardHeader>
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block m-4" />}
            {!loading && get(form, 'lands', []).length === 0 && <div className="text-mute text-sm p-3">No hay ningún lote relacionado con esta categoría.</div>}
            {!loading && get(form, 'lands', []).length !== 0 && (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">Lote</th>
                    <th scope="col" className="text-center">Licencia de construcción</th>
                    <th scope="col" className="text-center">Predial Pagado</th>
                  </tr>
                </thead>
                <tbody>
                  {get(form, 'lands', []).map(land => (
                    <tr key={land.id}>
                      <th scope="row" className="border-left text-center">
                        <Button tag={Link} to={`/admin/lands/${land.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                          {land.street} {land.number}
                        </Button>
                      </th>
                      <td className="border-left text-center mb-0 text-sm">
                        {land.has_license? 'Con licencia': 'Sin licencia'}
                      </td>
                      <td className="border-left text-center mb-0 text-sm">
                        {!land.is_predial_paid? 'No pagado': moment(land.is_predial_paid).format('DD-MMMM-YYYY')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs={12}>
                  <h6 className="heading-small text-muted">
                    Modelos
                  </h6>
                </Col>
              </Row>
            </CardHeader>
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block m-4" />}
            {!loading && modelCategories.length === 0 && <div className="text-mute text-sm p-3">No hay ningún modelo relacionado con esta categoría.</div>}
            {!loading && modelCategories.length !== 0 && (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">Modelo</th>
                    <th scope="col" className="text-center">Límite</th>
                  </tr>
                </thead>
                <tbody>
                  {modelCategories.map(modelCategory => (
                    <tr key={modelCategory.id}>
                      <th scope="row" className="border-left text-center">
                        <Button tag={Link} to={`/admin/models/${modelCategory.model.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                          {modelCategory.model.name}
                        </Button>
                      </th>
                      <td className="border-left text-center mb-0 text-sm">
                        {formatCurrency({amount: modelCategory.limit})}
                      </td>
                    </tr>
                  ))}
                </tbody>
                {modelCategoriesPagination.total > 0 && <tfoot>
                  <tr>
                    <td colSpan="5">
                    <PaginationComponent
                      totalItems={modelCategoriesPagination.total}
                      pageSize={modelCategoriesPagination.perPage}
                      onSelect={page => loadModelCategories(match.params.id, {...modelCategoriesPagination, current: page})}
                      firstPageText="<<"
                      previousPageText="<"
                      nextPageText=">"
                      lastPageText=">>"
                      defaultActivePage={modelCategoriesPagination.current}
                    />
                    </td>
                  </tr>
                </tfoot>}
              </Table>
            )}

            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs={12}>
                  <h6 className="heading-small text-muted">
                    Notas
                  </h6>
                </Col>
              </Row>
            </CardHeader>
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block m-4" />}
            {!loading && landNotes.length === 0 && <div className="text-mute text-sm p-3">No hay ninguna nota relacionada con esta categoría.</div>}
            {!loading && landNotes.length !== 0 && (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">Nota</th>
                    <th scope="col" className="text-center">Lote</th>
                    <th scope="col" className="text-center">Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  {landNotes.map(landNote => (
                    <tr key={landNote.id}>
                      <th scope="row" className="border-left text-center">
                        <Button tag={Link} to={`/admin/notes/${landNote.note.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                          Ver Nota
                        </Button>
                      </th>
                      <th scope="row" className="border-left text-center">
                        <Button tag={Link} to={`/admin/lands/${landNote.land.id}`} className="mb-0 text-sm btn btn-sm btn-outline-primary">
                          {landNote.land.street} {landNote.land.number}
                        </Button>
                      </th>
                      <td className="border-left text-center mb-0 text-sm">
                        {formatCurrency({ amount: landNote.amount })}
                      </td>
                    </tr>
                  ))}
                </tbody>
                {landNotesPagination.total > 0 && <tfoot>
                  <tr>
                    <td colSpan="5">
                    <PaginationComponent
                      totalItems={landNotesPagination.total}
                      pageSize={landNotesPagination.perPage}
                      onSelect={page => loadLandNotes(match.params.id, {...landNotesPagination, current: page})}
                      firstPageText="<<"
                      previousPageText="<"
                      nextPageText=">"
                      lastPageText=">>"
                      defaultActivePage={landNotesPagination.current}
                    />
                    </td>
                  </tr>
                </tfoot>}
              </Table>
            )}
          </Card>
        </Col>
      </Row>
    )
  }
}

DependenciesList.propTypes = {
  form: PropTypes.shape({
    lands: PropTypes.arrayOf(PropTypes.shape({
      street: PropTypes.string,
      number: PropTypes.string,
      id: PropTypes.number,
    })),
  }),
  landNotes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    land: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number,
        street: PropTypes.string,
        number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        area: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
      PropTypes.number,
    ]),
    note: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number,
      }),
      PropTypes.number,
    ])
  })),
  modelCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    limit: PropTypes.number,
    model: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      name: PropTypes.string,
      project: PropTypes.number,
    }),
    category: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      name: PropTypes.string,
    }),
  })),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  loadModelCategories: PropTypes.func.isRequired,
  loadLandNotes: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

const mapStateToProps = ({
  modelCategories: { modelCategories, loading: loadingModelCategories, pagination: modelCategoriesPagination },
  categories: { form, loading: loadingCategory },
  landNotes: { landNotes, loading: loadingLandNotes, pagination: landNotesPagination } }) => ({
  modelCategories,
  landNotes,
  loading: loadingModelCategories || loadingLandNotes || loadingCategory,
  form,
  landNotesPagination,
  modelCategoriesPagination,
})

const mapDispatchToProps = dispatch => ({
  loadModelCategories: (category, pagination) => dispatch(loadModelCategories({ category }, pagination)),
  loadLandNotes: (category, pagination) => dispatch(loadLandNotes({ category }, pagination)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DependenciesList))
