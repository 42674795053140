import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature, faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import get from 'lodash/get'

import {
  Card,
  CardHeader,
  Table,
  Container,
  Button,
  Row,
  UncontrolledTooltip,
  Col,
} from 'reactstrap'
import { loadCategories, openDeleteCategoryModal } from 'redux/actions/categories'
import PaginationComponent from 'react-reactstrap-pagination'

class Categories extends Component {
  componentDidMount() {
    this.props.loadInfo({
      perPage: 10000
    })
  }

  render() {
    const { categories, loading, openDeleteCategoryModal, loadInfo, pagination, } = this.props

    if (loading) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Categorías
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Categorías
                  <Button
                    tag={Link}
                    to="/admin/categories/new"
                    className="float-right text-sm btn btn-sm btn-outline-primary btn-sm"
                  >
                    <FontAwesomeIcon icon={faFileSignature} className="mr-2" />
                    Nueva Categoría
                  </Button>
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" size="sm" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">Nombre</th>
                    <th scope="col" className="text-center">Código</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map(category => {
                    const id = `category_${category.id}`
                    const containerId = `container_${category.id}`
                    return (
                      <tr key={category.id}>
                        <th scope="row" className="text-center">
                          <Button
                            className="mb-0 btn btn-sm btn-outline-primary"
                            to={`/admin/categories/${category.id}`}
                            tag={Link}
                          >
                            {category.name}
                          </Button>
                          <Button
                            tag={Link}
                            color="primary"
                            to={`/admin/categories/${category.id}/edit`}
                            size="sm"
                            className="mr-2 mt-2"
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                          {get(category, 'models.length', 0) > 0 && <UncontrolledTooltip boundariesElement={containerId} placement="top" target={id} >Esta categoría no se puede eliminar por que tiene modelos asociados a ella.</UncontrolledTooltip>}
                          <span id={id}>
                            <Button
                              color="warning"
                              onClick={() => openDeleteCategoryModal(category)}
                              size="sm"
                              className="mt-2"
                              disabled={get(category, 'models.length', 0) > 0}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </span>
                        </th>
                        <th scope="row" className="border-left">
                          {category.description}
                        </th>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            {pagination.total > 0 && <PaginationComponent
              totalItems={pagination.total}
              pageSize={pagination.perPage}
              onSelect={page => loadInfo({...pagination, current: page})}
              firstPageText="<<"
              previousPageText="<"
              nextPageText=">"
              lastPageText=">>"
              defaultActivePage={pagination.current}
            />}
          </Col>
        </Row>
      </Container>
    )
  }
}

Categories.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openDeleteCategoryModal: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}),
}

Categories.defaultProps = {
  categories: [],
  permissions: {},
}

const mapStateToProps = ({ categories }) => ({
  categories: categories.categories,
  loading: categories.loading,
  pagination: categories.pagination,
})

const mapDispatchToProps = dispatch => ({
  loadInfo: pagination => dispatch(loadCategories(pagination)),
  openDeleteCategoryModal: category => dispatch(openDeleteCategoryModal({ category, source: 'list' })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
