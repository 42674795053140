import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormText
} from 'reactstrap'

import UserHeader from 'components/Headers/UserHeader.jsx'
import { updateProfile } from 'redux/actions/session'

const Profile = ({ user, loading, updateProfile }) => {
  const { register, reset, handleSubmit, errors, watch } = useForm({reValidateMode: 'onSubmit'})

  useEffect(() => {
    reset(user)
  },[user, reset])

  return (
  <>
    <UserHeader />
    <Container className="mt--7" fluid>
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Mi perfil</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(params => updateProfile(params))} noValidate autoComplete="off">
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Nombre de usuario
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={user.user}
                          id="input-username"
                          placeholder="Username"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          Correo electrónico
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-email"
                          name="email"
                          placeholder="usuario@example.com"
                          type="email"
                          disabled
                          defaultValue={user.email}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Nombre
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={user.name}
                          id="input-first-name"
                          name="name"
                          placeholder="First name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-current-password"
                        >
                          Contraseña actual
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-current-password"
                          name="current_password"
                          placeholder="Ingrese su contraseña actual"
                          type="password"
                          innerRef={register({ required: true })}
                        />
                        {errors.current_password &&
                          <FormText color="primary">
                            Ingrese la constraseña actual para poder cambiarla a una diferente
                          </FormText>
                        }
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-password"
                        >
                          Nueva contraseña
                        </label>
                        <Input
                          className="form-control-alternative"
                          // defaultValue={user.name}
                          innerRef={register({
                            required: true
                          })}
                          id="input-password"
                          name="password"
                          placeholder="Nueva contraseña"
                          type="password"
                        />
                        {errors.password &&
                          <FormText color="primary">
                            Favor de llenar este campo para actualizar su contraseña
                          </FormText>
                        }
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-confirm-password"
                        >
                          Escribe nuevamente la nueva contraseña
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-confirm-password"
                          name="confirm_password"
                          placeholder="Verifica tu contraseña nueva"
                          type="password"
                          innerRef={register({
                            required: true,
                            validate: value => value === watch('password')
                          })}
                        />
                        {errors.confirm_password && errors.confirm_password.type === 'validate' &&
                          <FormText color="primary">
                            Este campo debe coincidir exactamente con el campo de Nueva Contraseña
                          </FormText>
                        }
                        {errors.confirm_password && errors.confirm_password.type === 'required' &&
                          <FormText color="primary">
                            Favor de escribir una nueva contraseña
                          </FormText>
                        }
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <Button disabled={loading} color="primary">Guardar</Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
)}

Profile.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }),
  loading: PropTypes.bool
}

Profile.defaultProps = {
  user: {
    name: '',
    user: '',
    email: '',
  },
  loading: false,
}

const mapStateToProps = ({ session: { user, loading }}) => ({ user, loading })

const mapDispatchToProps = dispatch => ({
  updateProfile: params => dispatch(updateProfile(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
