import { toast } from 'react-toastify'
import get from 'lodash/get'
import instance from '../../axios'

export const LOAD_USERS_START = 'LOAD_USERS_START'
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const LOAD_USERS_ERROR = 'LOAD_USERS_ERROR'

export const EDIT_PERMISSION_START = 'EDIT_PERMISSION_START'
export const EDIT_PERMISSION_SUCCESS = 'EDIT_PERMISSION_SUCCESS'
export const EDIT_PERMISSION_ERROR = 'EDIT_PERMISSION_ERROR'

export const USER_CREATE_START = 'USER_CREATE_START'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR'

export const USER_FETCH_START = 'USER_FETCH_START'
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS'
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR'

export const USER_UPDATE_START = 'USER_UPDATE_START'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR'

export const OPEN_DELETE_USER_MODAL = 'OPEN_DELETE_USER_MODAL'
export const CLOSE_DELETE_USER_MODAL = 'CLOSE_DELETE_USER_MODAL'
export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

export const openDeleteUserModal = payload => ({
  type: OPEN_DELETE_USER_MODAL,
  payload
})

export const closeDeleteUserModal = () => ({
  type: CLOSE_DELETE_USER_MODAL,
})

export const deleteUser = ({ user, source, history }) => dispatch => {
  dispatch({
    type: DELETE_USER_START
  })

  return instance.delete(`/users/${user.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado el usuario ${user.name}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin/users')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_USER_ERROR,
        payload: err
      })
    })
}

export const fetchUser = id => dispatch => {
  dispatch({
    type: USER_FETCH_START
  })

  return instance.get(`/users/${id}`)
    .then(({ data }) => {
      dispatch({
        type: USER_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: USER_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateUser = (values, history) => dispatch => {
  dispatch({
    type: USER_UPDATE_START
  })

  return instance.patch(`/users/${values.id}`, values)
    .then(({ data }) => {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/users/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: USER_UPDATE_ERROR,
        payload: err
      })
    })
}

// eslint-disable-next-line no-unused-vars
export const createUser = ({ id, ...values}, history, setError) => dispatch => {
  dispatch({
    type: USER_CREATE_START
  })

  return instance.post('/users', values)
    .then(({ data }) => {
      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/users/${data.id}`)
    })
    .catch(err => {
      if (!err || !err.response) {
        return
      }

      dispatch({
        type: USER_CREATE_ERROR,
        payload: err
      })

      if (err.response.data.code === 'E_UNIQUE') {
        setError(get(err, 'response.data.attrNames[0]', 'user'), 'unique')
      }
    })
}

const editPermission = method => ({ user, permission, permissionId }) => dispatch => {
  dispatch({
    type: EDIT_PERMISSION_START,
    payload: permissionId
  })

  return instance[method](`/users/${user.id}/permissions/${permission.id}`)
    .then(({ data }) => dispatch({
      type: EDIT_PERMISSION_SUCCESS,
      payload: data
    }))
    .catch(err => {
      dispatch({
        type: EDIT_PERMISSION_ERROR,
        payload: err
      })
    })
}

export const addPermission = editPermission('put')
export const removePermission = editPermission('delete')

export const loadUsers = () => dispatch => {
  dispatch({
    type: LOAD_USERS_START
  })

  return instance.get('/users', {
    params: {
      limit: 500
    }
  })
    .then(({ data }) => {
      dispatch({
        type: LOAD_USERS_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_USERS_ERROR,
        payload: err
      })
    })
}
