import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactLoading from 'react-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice, faTrash, faAngleLeft, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { fetchExpense, openDeleteExpenseModal } from 'redux/actions/expenses'
import { loadSuppliers } from 'redux/actions/suppliers'
import { loadWorks } from 'redux/actions/works'
import { loadTransactions } from 'redux/actions/transactions'
import ExpenseForm from 'components/Expenses/form'

class ExpenseDetail extends Component {

  componentDidMount() {
    this.props.fetchExpense(this.props.match.params.id)
    this.props.loadSuppliers()
    this.props.loadWorks()
    this.props.loadTransactions()
  }

  render() {
    const { form, loading, openDeleteExpenseModal, suppliers, works, match, transactions } = this.props
    const work = get(works, 'works') && get(form, 'neodata') ? works.works.find(work => work.neodatas.some(neodata => neodata.id === form.neodata.id)) : {}

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row className="pb-3">
          <Col>
            <Button
              tag={Link}
              color="primary"
              to="/admin/expenses"
              size="sm"
              className="mt-2"
            >
              <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
              Regresar a Lista
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="12">
                    <h3 className="mb-0">
                      <FontAwesomeIcon icon={faFileInvoice} className="mr-1" /> Detalle del Gasto<br />
                        <Button
                          tag={Link}
                          color="primary"
                          // disabled={hasDependencies}
                          to={`/admin/expenses/${match.params.id}/edit`}
                          size="sm"
                          className="mt-2"
                        >
                          <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                          Editar Gasto
                        </Button>
                        <Button
                          color="warning"
                          disabled={form.status === 'paid'}
                          onClick={() => openDeleteExpenseModal(form)}
                          size="sm"
                          className="mt-2"
                        >
                          <FontAwesomeIcon icon={faTrash} className="mr-2" />
                          Borrar Gasto
                        </Button>
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
                {!loading && form &&
                  <ExpenseForm
                    readOnly
                    form={form}
                    supplier={get(form.supplier, 'id')}
                    work={get(work, 'id')}
                    neodata={get(form, 'neodata')}
                    transaction={get(form, 'transaction')}
                    suppliers={suppliers}
                    works={works}
                    neodatas={get(work, 'neodatas', [])}
                    transactions={transactions}
                    loading={loading}
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = props => {
  const { expenses: { form, loading }, suppliers, works, transactions } = props
  return {
    form,
    loading,
    suppliers,
    works,
    transactions
  }
}

const mapDispatchToProps = dispatch => ({
  fetchExpense: id => dispatch(fetchExpense(id)),
  openDeleteExpenseModal: expense => dispatch(openDeleteExpenseModal({ expense, source: 'detail' })),
  loadSuppliers: () => dispatch(loadSuppliers({perPage: 1000})),
  loadWorks: () => dispatch(loadWorks()),
  loadTransactions: () => dispatch(loadTransactions())
})

ExpenseDetail.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    landExpenses: PropTypes.arrayOf(PropTypes.shape({
      expense: PropTypes.number,
      land: PropTypes.number,
      category: PropTypes.number,
      amount: PropTypes.number,
    })),
    supplier: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number,
        alias: PropTypes.string,
      }),
      PropTypes.string,
      PropTypes.number
    ]),
    expense: PropTypes.shape({
      id: PropTypes.number,
    }),
    fileUrl: PropTypes.string
  }),
  loading: PropTypes.bool,
  openDeleteExpenseModal: PropTypes.func.isRequired,
  fetchExpense: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired
}

ExpenseDetail.defaultProps = {
  loading: false,
  form: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDetail)
