import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import { deleteLandCategory, closeDeleteLandCategoryModal } from 'redux/actions/landCategories'

const DeleteModal = ({ isOpen, closeModal, landCategory, deleteLandCategory, isLoading }) => !landCategory ? null : (
  <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
    <ModalHeader toggle={closeModal}>Eliminar Categoría de Lote</ModalHeader>
    <ModalBody>
      Estás seguro de que quieres borrar la categoría {landCategory.category.name} del lote {landCategory.land.name} ?
    </ModalBody>
    <ModalFooter>
      <Button disabled={isLoading} color="warning" className="mr-1" onClick={() => deleteLandCategory(landCategory)}>
        {isLoading && <ReactLoading type="spinningBubbles" color="#fff" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
        Eliminar
      </Button>
      <Button disabled={isLoading} color="primary" onClick={closeModal}>Cancelar</Button>
    </ModalFooter>
  </Modal>
)

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  landCategory: PropTypes.shape({
    category: PropTypes.shape({
      name: PropTypes.string
    }),
    land: PropTypes.shape({
      name: PropTypes.string
    }),
  }),
  closeModal: PropTypes.func.isRequired,
  deleteLandCategory: PropTypes.func.isRequired,
}

const mapStateToProps = ({ landCategories: { openedRemoveCategoryFromLandModal, selectedLandCategory, deletingLandCategory }}) => ({
  isOpen: openedRemoveCategoryFromLandModal,
  landCategory: selectedLandCategory,
  isLoading: deletingLandCategory
})

const mapDispatchToProps = dispatch => ({
  deleteLandCategory: info => dispatch(deleteLandCategory(info)),
  closeModal: () => dispatch(closeDeleteLandCategoryModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteModal))
