import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMoneyCheck } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import PaginationComponent from 'react-reactstrap-pagination'

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Button,
  Row,
} from 'reactstrap'
import { loadExpenses, openDeleteExpenseModal, downloadFile, openPayExpenseModal } from 'redux/actions/expenses'
import TableList from 'components/Expenses/TableList'


class Expenses extends Component {
  componentDidMount() {
    this.props.loadInfo()
  }

  render() {
    const { expenses, loading, openDeleteExpenseModal, downloadFile, loadInfo, pagination, openPayExpenseModal, history } = this.props

    if (loading) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Gastos
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Gastos
                  <Button tag={Link} to="/admin/expenses/new" className="float-right text-sm btn btn-sm btn-outline-primary btn-sm">
                    <FontAwesomeIcon icon={faMoneyCheck} className="mr-2" />
                    Nuevo Gasto
                    <FontAwesomeIcon icon={faPlus} className="ml-2" />
                  </Button>
                  <Button tag={Link} to="/admin/expenses/multiple" className="float-right text-sm btn btn-sm btn-outline-primary btn-sm mr-3">
                    Registro de multiples gastos
                    <FontAwesomeIcon icon={faPlus} className="ml-2" />
                  </Button>
                </h3>
              </CardHeader>
              {((!expenses || expenses.length === 0)) && (
                <CardBody>No hay gastos por mostrar, <Link to="/admin/expenses/new" className="text-primary">crea uno</Link>.</CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {expenses && expenses.length > 0 && <>
          <Row>
            <Col>
              <Card>
                <TableList
                  expenses={expenses}
                  openDeleteExpenseModal={openDeleteExpenseModal}
                  openPayExpenseModal={openPayExpenseModal}
                  downloadFile={downloadFile}
                  history={history}
                />
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <PaginationComponent
                totalItems={pagination.total}
                pageSize={pagination.perPage}
                onSelect={page => loadInfo({...pagination, current: page})}
                firstPageText="<<"
                previousPageText="<"
                nextPageText=">"
                lastPageText=">>"
                defaultActivePage={pagination.current}
              />
            </Col>
          </Row>
        </>}
      </Container >
    )
  }
}

Expenses.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openDeleteExpenseModal: PropTypes.func.isRequired,
  openPayExpenseModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  downloadFile: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

Expenses.defaultProps = {
  expenses: [],
  permissions: {},
}

const mapStateToProps = props => {
  const { expenses: {expenses, loading, pagination} } = props
  return {
    expenses,
    loading,
    pagination,
  }
}

const mapDispatchToProps = dispatch => ({
  loadInfo: pagination => dispatch(loadExpenses('', null, pagination)),
  openDeleteExpenseModal: expense => dispatch(openDeleteExpenseModal({ expense, source: 'list' })),
  openPayExpenseModal: expense => dispatch(openPayExpenseModal({ expense, source: 'list' })),
  downloadFile: id => dispatch(downloadFile(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Expenses)
