import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormText,
} from 'reactstrap'

const ModelForm = ({
  onSubmit,
  loading,
  loadingModel,
  register,
  errors,
  readOnly,
}) => (
  <Form onSubmit={onSubmit} noValidate autoComplete="off" className={loadingModel ? 'invisible' : ''}>
    <Input name="id" type="hidden" innerRef={register} />
    <div className="pl-lg-4">
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-modelname"
            >
              Nombre del Modelo
            </Label>
            <Input
              className="form-control-alternative"
              disabled={readOnly}
              id="input-modelname"
              autoComplete="off"
              placeholder="Nombre del Modelo"
              type="text"
              name="name"
              innerRef={register({ required: true })}
            />
            {errors.name && (
              <FormText color="primary">
                Ingrese un nombre para el modelo
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label
              className="form-control-label"
              htmlFor="input-description"
            >
              Descripción
            </Label>
            <Input
              className="form-control-alternative"
              id="input-description"
              disabled={readOnly}
              autoComplete="off"
              placeholder="Descripción"
              type="textarea"
              name="description"
              innerRef={register({ required: true })}
            />
            {errors.description && (
              <FormText color="primary">
                Ingrese una descripción para el modelo
              </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
    </div>
    {!readOnly && (
      <>
        <hr className="my-4" />
        <Button disabled={loading} color="primary">Guardar</Button>
      </>
    )}
  </Form>
)

ModelForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.shape({
      type: PropTypes.string
    }),
    description: PropTypes.shape({
      type: PropTypes.string
    }),
  }),
  loading: PropTypes.bool,
  loadingModel: PropTypes.bool,
  readOnly: PropTypes.bool,
  register: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

ModelForm.defaultProps = {
  loading: false,
  loadingModel: false,
  readOnly: false,
  onSubmit: noop,
  errors: {}
}

export default ModelForm
