import React, { useEffect } from 'react'
import get from 'lodash/get'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import ModelCategoryForm from 'components/ModelCategories/form'
import { updateModelCategory, closeEditModelCategoryModal } from 'redux/actions/modelCategories'

const EditModal = ({
  isOpen,
  closeModal,
  updateModelCategory,
  loading,
  form,
}) => {
  const { register, handleSubmit, errors, reset } = useForm()
  useEffect(() => {
    reset({ ...form, category: get(form, 'category.id', get(form, 'category')), model: get(form, 'model.id', get(form, 'model')) })
  }, [form, reset])

  return !form.model ? null : (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Actualizar Categoría en Modelo</ModalHeader>
      <Form onSubmit={handleSubmit(updateModelCategory)} noValidate autoComplete="off">
        <ModalBody>
          <ModelCategoryForm
            register={register}
            errors={errors}
            closeModal={closeModal}
            categories={[form.category]}
            edit
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Actualizar Categoría
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

EditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.shape({
    category: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    model: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    limit: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ])
  }),
  closeModal: PropTypes.func.isRequired,
  updateModelCategory: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  categories: { categories, loading: loadingCategories },
  modelCategories: { modelCategories: currentCategories, updatingModelCategory, openedEditCategoryModelModal, form }
}) => ({
  categories: currentCategories && categories && categories.filter(category => !currentCategories.find(current => current.category.id === category.id)),
  isOpen: openedEditCategoryModelModal,
  form,
  loading: updatingModelCategory,
  loadingCategories: loadingCategories
})

const mapDispatchToProps = dispatch => ({
  updateModelCategory: info => dispatch(updateModelCategory(info)),
  closeModal: () => dispatch(closeEditModelCategoryModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditModal))
