import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import { ToastContainer } from 'react-toastify'

import AdminNavbar from 'components/Navbars/AdminNavbar'
import AdminFooter from 'components/Footers/AdminFooter'
import Sidebar from 'components/Sidebar/Sidebar'
import Profile from 'views/Profile'

// Modals
import DeleteUserModal from 'views/Users/DeleteModal'
import DeleteNoteModal from 'views/Notes/DeleteModal'
import DeleteBlockModal from 'views/Blocks/DeleteModal'
import DeleteProjectModal from 'views/Projects/DeleteModal'
import DeleteShopperModal from 'views/Shoppers/DeleteModal'
import DeleteCategoryModal from 'views/Categories/DeleteModal'
import DeleteSupplierModal from 'views/Suppliers/DeleteModal'
import EditModelCategoriesModal from 'views/ModelCategories/EditModal'
import DeleteModelCategoriesModal from 'views/ModelCategories/DeleteModal'
import CreateModelCategoriesModal from 'views/ModelCategories/CreateModal'
import DeleteModelModal from 'views/Models/DeleteModal'
import EditLandCategoriesModal from 'views/LandCategories/EditModal'
import DeleteLandCategoriesModal from 'views/LandCategories/DeleteModal'
import CreateLandCategoriesModal from 'views/LandCategories/CreateModal'
import EditLandCategorySuppliersModal from 'views/LandCategorySuppliers/EditModal'
import EditLandNotesModal from 'views/LandNotes/EditModal'
import DeleteLandNotesModal from 'views/LandNotes/DeleteModal'
import CreateLandNotesModal from 'views/LandNotes/CreateModal'
import RemoveSupplierModal from 'views/Lands/RemoveSupplierModal'
import EditSupplierContactsModal from 'views/SupplierContacts/EditModal'
import DeleteSupplierContactsModal from 'views/SupplierContacts/DeleteModal'
import CreateSupplierContactsModal from 'views/SupplierContacts/CreateModal'
import EditShopperBalancesModal from 'views/ShopperBalances/EditModal'
import DeleteShopperBalancesModal from 'views/ShopperBalances/DeleteModal'
import CreateShopperBalancesModal from 'views/ShopperBalances/CreateModal'
import EditBlockModal from 'views/Blocks/EditModal'
import CreateBlockModal from 'views/Blocks/CreateModal'
import DeleteLandModal from 'views/Lands/DeleteModal'
import AccountModal from 'views/Transactions/AccountModal'
import DeleteInvoiceModal from 'views/Invoices/DeleteModal'
import DeleteExpenseModal from 'views/Expenses/DeleteModal'
import PayExpenseModal from 'views/Expenses/PayExpenseModal'
import PayInvoiceModal from 'views/Invoices/PayInvoiceModal'

// Users
import UsersList from 'views/Users/List'
import NewUser from 'views/Users/New'
import UserDetail from 'views/Users/Detail'
import EditUser from 'views/Users/Edit'

// Projects
import ProjectsList from 'views/Projects/List'
import NewProject from 'views/Projects/New'
import ProjectDetail from 'views/Projects/Detail'
import EditProject from 'views/Projects/Edit'

// Shoppers
import ShoppersList from 'views/Shoppers/List'
import NewShopper from 'views/Shoppers/New'
import ShopperDetail from 'views/Shoppers/Detail'
import EditShopper from 'views/Shoppers/Edit'

// Categories
import CategoriesList from 'views/Categories/List'
import NewCategory from 'views/Categories/New'
import CategoryDetail from 'views/Categories/Detail'
import EditCategory from 'views/Categories/Edit'

// Suppliers
import SuppliersList from 'views/Suppliers/List'
import NewSupplier from 'views/Suppliers/New'
import SupplierDetail from 'views/Suppliers/Detail'
import EditSupplier from 'views/Suppliers/Edit'

// Models
import ModelsList from 'views/Models/List'
import NewModel from 'views/Models/New'
import ModelDetail from 'views/Models/Detail'
import EditModel from 'views/Models/Edit'

// Lands
import LandsList from 'views/Lands/List'
import NewLand from 'views/Lands/New'
import LandDetail from 'views/Lands/Detail'
import EditLand from 'views/Lands/Edit'

// Blocks
import NewBlock from 'views/Blocks/New'
import BlockDetail from 'views/Blocks/Detail'
import EditBlock from 'views/Blocks/Edit'

// Notes
import NotesList from 'views/Notes/List'
import NewNote from 'views/Notes/New'
import NoteDetail from 'views/Notes/Detail'
import NoteEdit from 'views/Notes/Edit'

// Transactions
import TransactionsList from 'views/Transactions/List'

// Invoices
import InvoicesList from 'views/Invoices/List'
import NewInvoice from 'views/Invoices/New'
import InvoiceDetail from 'views/Invoices/Detail'
import InvoiceEdit from 'views/Invoices/Edit'

// Invoices
import ExpensesList from 'views/Expenses/List'
import NewExpense from 'views/Expenses/New'
import ExpenseDetail from 'views/Expenses/Detail'
import ExpenseEdit from 'views/Expenses/Edit'
import MultipleCreate from 'views/Expenses/MultipleCreate'

// Reports
import Reports from 'views/Reports/index'

import imgSrc from 'assets/img/brand/terra.png'

class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.mainContent = React.createRef()
  }

  componentDidUpdate() {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    this.mainContent.current.scrollTop = 0
  }

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          logo={{
            innerLink: '/admin',
            imgSrc,
            imgAlt: '...'
          }}
        />
        <div className="main-content" ref={this.mainContent}>
          <AdminNavbar {...this.props} />
          <DeleteUserModal />
          <DeleteProjectModal />
          <DeleteShopperModal />
          <DeleteCategoryModal />
          <DeleteSupplierModal />
          <DeleteModelModal />
          <DeleteNoteModal />
          <EditModelCategoriesModal />
          <DeleteModelCategoriesModal />
          <CreateModelCategoriesModal />
          <DeleteLandModal />
          <EditLandCategoriesModal />
          <DeleteLandCategoriesModal />
          <CreateLandCategoriesModal />
          <EditLandCategorySuppliersModal />
          <EditLandNotesModal />
          <DeleteLandNotesModal />
          <CreateLandNotesModal />
          <EditSupplierContactsModal />
          <DeleteSupplierContactsModal />
          <CreateSupplierContactsModal />
          <EditShopperBalancesModal />
          <DeleteShopperBalancesModal />
          <CreateShopperBalancesModal />
          <RemoveSupplierModal />
          <DeleteBlockModal />
          <EditBlockModal />
          <CreateBlockModal />
          <AccountModal />
          <DeleteInvoiceModal />
          <DeleteExpenseModal />
          <PayExpenseModal />
          <PayInvoiceModal />
          <ToastContainer />
          <Switch>
            <Route exact path="/admin" component={Reports} />
            <Route path="/admin/me" component={Profile} />
            <Route exact path="/admin/users" component={UsersList} />
            <Route exact path="/admin/users/new" component={NewUser} />
            <Route exact path="/admin/users/:id" component={UserDetail} />
            <Route exact path="/admin/users/:id/edit" component={EditUser} />

            <Route exact path="/admin/projects" component={ProjectsList} />
            <Route exact path="/admin/projects/new" component={NewProject} />
            <Route exact path="/admin/projects/:id" component={ProjectDetail} />
            <Route exact path="/admin/projects/:id/edit" component={EditProject} />

            <Route exact path="/admin/shoppers" component={ShoppersList} />
            <Route exact path="/admin/shoppers/new" component={NewShopper} />
            <Route exact path="/admin/shoppers/:id" component={ShopperDetail} />
            <Route exact path="/admin/shoppers/:id/edit" component={EditShopper} />

            <Route exact path="/admin/categories" component={CategoriesList} />
            <Route exact path="/admin/categories/new" component={NewCategory} />
            <Route exact path="/admin/categories/:id" component={CategoryDetail} />
            <Route exact path="/admin/categories/:id/edit" component={EditCategory} />

            <Route exact path="/admin/suppliers" component={SuppliersList} />
            <Route exact path="/admin/suppliers/new" component={NewSupplier} />
            <Route exact path="/admin/suppliers/:id" component={SupplierDetail} />
            <Route exact path="/admin/suppliers/:id/edit" component={EditSupplier} />

            <Route exact path="/admin/models" component={ModelsList} />
            <Route exact path="/admin/models/new" component={NewModel} />
            <Route exact path="/admin/models/:id" component={ModelDetail} />
            <Route exact path="/admin/models/:id/edit" component={EditModel} />

            <Route exact path="/admin/lands" component={LandsList} />
            <Route exact path="/admin/lands/new" component={NewLand} />
            <Route exact path="/admin/lands/:id" component={LandDetail} />
            <Route exact path="/admin/lands/:id/edit" component={EditLand} />

            <Route exact path="/admin/notes" component={NotesList} />
            <Route exact path="/admin/notes/new" component={NewNote} />
            <Route exact path="/admin/notes/:id" component={NoteDetail} />
            <Route exact path="/admin/notes/:id/edit" component={NoteEdit} />

            <Route exact path="/admin/transactions" component={TransactionsList} />

            <Route exact path="/admin/invoices" component={InvoicesList} />
            <Route exact path="/admin/invoices/new" component={NewInvoice} />
            <Route exact path="/admin/invoices/:id" component={InvoiceDetail} />
            <Route exact path="/admin/invoices/:id/edit" component={InvoiceEdit} />

            <Route exact path="/admin/expenses" component={ExpensesList} />
            <Route exact path="/admin/expenses/new" component={NewExpense} />
            <Route exact path="/admin/expenses/multiple" component={MultipleCreate} />
            <Route exact path="/admin/expenses/:id" component={ExpenseDetail} />
            <Route exact path="/admin/expenses/:id/edit" component={ExpenseEdit} />

            <Route exact path="/admin/blocks/new" component={NewBlock} />
            <Route exact path="/admin/blocks/:id" component={BlockDetail} />
            <Route exact path="/admin/blocks/:id/edit" component={EditBlock} />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    )
  }
}

export default Admin
