import React, { Component, useEffect } from 'react'
import useForm from 'react-hook-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import {DelayInput} from 'react-delay-input'
import moment from 'moment'
import { get, isNil } from 'lodash'

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
  Collapse,
  Alert,
} from 'reactstrap'
import {
  loadTransactions,
  loadInvoices,
  openAccountModal,
  selectInvoice,
  selectExpense,
  updateInvoice,
  toggleSection,
  submitExpensePayment,
  setIsModalListOpen,
  loadWorks,
} from 'redux/actions/transactions'
import { formatCurrency } from 'utils/numberFormat'
import TransactionForm from 'components/Transactions/form'
import PayExpensesForm from 'components/Transactions/expenses-form'
import { loadExpenses } from 'redux/actions/expenses'
import TransactionsDetailModal from 'views/Transactions/TransactionsDetailModal'


const TransForm = ({history, form, loading, updateInvoice, transactions, works}) => {
  const { register, reset, setError, handleSubmit, errors, getValues } = useForm()

  useEffect(() => {
    reset({
      ...form,
      account: get(form, 'notes[0].account', '')
    })
  }, [form, reset])

  return(
    <>{!loading && (
      <TransactionForm
        onSubmit={handleSubmit(params => updateInvoice({ ...params }, history, setError))}
        history={history}
        register={register}
        errors={errors}
        transactions={transactions}
        works={works}
        getValues={getValues}
      />
    )}
    {loading && (
      <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
    )}</>
  )
}

const ExpensesFormWrapper = ({form, loading, submitPayment, accounts}) => {
  const { register, reset, handleSubmit, errors, getValues } = useForm()

  useEffect(() => {
    reset({
      ...form,
      account: get(form, 'account.id', '')
    })
  }, [form, reset])

  return(
    <>{!loading && (
      <PayExpensesForm
        onSubmit={handleSubmit(params => submitPayment(params))}
        register={register}
        errors={errors}
        accounts={accounts}
        getValues={getValues}
      />
    )}
    {loading && (
      <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
    )}</>
  )
}

class Transactions extends Component {
  componentDidMount() {
    this.props.loadInfo()
  }

  render() {
    const {
      transactions,
      invoices,
      loading,
      loadingInvoices,
      loadingExpenses,
      openAccountModal,
      openAccountModal2,
      selectInvoice,
      form,
      expense_form,
      updateInvoice,
      history,
      loadInvoices,
      loadExpenses,
      search,
      searchExpense,
      toggle,
      collapse,
      expenses,
      selectExpense,
      submitExpensePayment,
      error,
      setIsModalListOpen,
      isModalListOpen,
      transactionsDetailData,
      works
    } = this.props

    const toggleClick = (e) => {
      let event = e.target.dataset.event
      toggle(collapse === Number(event) ? 0 : Number(event))
    }

    if (loading) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                  Transacciones
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <>
        <TransactionsDetailModal
          isOpen={isModalListOpen}
          toggle={() => setIsModalListOpen(isModalListOpen, transactionsDetailData)}
        />
        <Container className="pt-2 pt-md-5 pt-lg-6 mb-3" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Transacciones
                  </h3>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          {error && <Row>
            <Col xs="12">
              <Alert color="danger">
                Ocurrio un error al realizar la operación, favor de recargar la pagina
              </Alert>
            </Col>
          </Row>}
          <Row>
            {transactions.map(transaction => {
              return (
                <Col sm={6} lg={4} xl={3} key={transaction.id} className="mb-2" >
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle className="text-uppercase text-muted mb-0">
                            <Button
                              color="link"
                              onClick={() => setIsModalListOpen(isModalListOpen, {account: transaction})}
                            >
                              {transaction.name}
                            </Button>
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{formatCurrency({amount: transaction.amount})}</span>
                        </div>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-primary mr-2">
                          <Button onClick={(e) => {
                            e.preventDefault()
                            openAccountModal(transaction)}}>
                            Realizar depósito&nbsp;
                            <i className="fa fa-arrow-right" />
                          </Button>
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Container>
        <Container className="pt-2 pt-md-5 pt-lg-2" fluid>
          <Row>
            <Col className="mb-2">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    PAGOS:
                  </h3>
                  <Button onClick={(e) => {
                    e.preventDefault()
                    openAccountModal2(null)
                  }}>
                    Realizar retiro&nbsp;
                    <i className="fa fa-arrow-right" />
                  </Button>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Container fluid>
                      <Card style={{ marginBottom: '1rem' }}>
                        <CardHeader onClick={toggleClick} data-event={1}>FACTURAS DE INSUMOS</CardHeader>
                        <Collapse isOpen={collapse === 1}>
                          <CardBody>
                            <Row>
                              <Col sm={6}>
                                <FormGroup>
                                  <InputGroup className="input-group-alternative mb-4">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-zoom-split-in" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <DelayInput
                                      className="form-control-alternative"
                                      placeholder="Buscar"
                                      value={search}
                                      minLength={0}
                                      delayTimeout={1000}
                                      onChange={e => {
                                        const search = e.target.value;
                                        var numbers = /^[1-9]{1}[0-9]*$/;
                                        if(search.match(numbers) || search === ""){
                                          loadInvoices(search)
                                        }
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <div>
                                  {invoices.map(invoice => {
                                    const id = `invoice_${invoice.id}`
                                    const stylePrimary = form && form.id === invoice.id ? 'card-stats border-primary mb-4 mb-lg-0' : 'card-stats mb-4 mb-lg-0';
                                    return (
                                      <Card className={stylePrimary} key={id} onClick={() => selectInvoice(invoice)}>
                                        <CardBody>
                                          <Row>
                                            <div className="col">
                                              <h3 className="mb-0">#{invoice.id} - Folio: "{get(invoice, 'folio', '-')}"</h3>
                                              <p className="mb-0">No Auth: {
                                                (!isNil(get(invoice, 'authNumber')) && get(invoice, 'authNumber') !== '')? `"${get(invoice, 'authNumber')}"` : 'S/N'
                                              }</p>
                                              <p className="mb-0">Total: {formatCurrency({amount: invoice.total})}</p>
                                            </div>
                                          </Row>
                                          <p className="mt-3 mb-0 text-muted text-sm">
                                            <span className="text-primary mr-2">
                                              <i className="fa fa-calendar" />
                                              &nbsp;
                                              {moment(invoice.date).utc().format('DD-MMMM-YYYY')}
                                            </span>
                                          </p>
                                        </CardBody>
                                      </Card>
                                    )
                                  })}
                                  {loadingInvoices && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
                                </div>
                              </Col>
                              <Col sm={6} lg={6} xl={6}>
                                <h3 className="mb-0">
                                  <i className="fa fa-file" />&nbsp;
                                  #{form.id ? form.id : ""}
                                </h3>
                                <TransForm
                                  history={history}
                                  form={form}
                                  loading={loading}
                                  updateInvoice={updateInvoice}
                                  transactions={transactions}
                                  works={works}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Collapse>
                      </Card>

                      <Card style={{ marginBottom: '1rem' }}>
                        <CardHeader onClick={toggleClick} data-event={2}>PAGO DE GASTOS</CardHeader>
                        <Collapse isOpen={collapse === 2}>
                          <CardBody>

                            <Row>
                              <Col sm={6}>
                                <FormGroup>
                                  <InputGroup className="input-group-alternative mb-4">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-zoom-split-in" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <DelayInput
                                      className="form-control-alternative"
                                      placeholder="Buscar"
                                      value={searchExpense}
                                      minLength={0}
                                      delayTimeout={1000}
                                      onChange={e => {
                                        const search = e.target.value;
                                        loadExpenses(search)
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <div>
                                  {expenses.map(expense => {
                                    const id = `expense_${expense.id}`
                                    const stylePrimary = expense_form && expense_form.id === expense.id ? 'card-stats border-primary mb-4 mb-lg-0' : 'card-stats mb-4 mb-lg-0'
                                    return (
                                      <Card className={stylePrimary} key={id} onClick={() => selectExpense(expense)}>
                                        <CardBody>
                                          <Row>
                                            <div className="col">
                                              <h3 className="mb-0">#{expense.id} - {expense.description}</h3>
                                              {expense.account && <h4>CUENTA: {expense.account.name}</h4>}
                                              <p className="mb-0">Total: {formatCurrency({amount: expense.total})}</p>
                                            </div>
                                          </Row>
                                          <p className="mt-3 mb-0 text-muted text-sm">
                                            <span className="text-primary mr-2">
                                              <i className="fa fa-calendar" />
                                              &nbsp;
                                              {moment(expense.date).utc().format('DD-MMMM-YYYY')}
                                            </span>
                                          </p>
                                        </CardBody>
                                      </Card>
                                    )
                                  })}
                                  {loadingExpenses && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
                                </div>
                              </Col>
                              <Col sm={6} lg={6} xl={6}>
                                <h3 className="mb-0">
                                  <i className="fa fa-file" />&nbsp;
                                  #{expense_form.id ? expense_form.id : ""}
                                </h3>
                                <ExpensesFormWrapper
                                  history={history}
                                  form={expense_form}
                                  loading={loading}
                                  expenses={expenses}
                                  accounts={transactions}
                                  submitPayment={submitExpensePayment}
                                />
                              </Col>
                            </Row>

                          </CardBody>
                        </Collapse>
                      </Card>
                    </Container>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

Transactions.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openAccountModal: PropTypes.func.isRequired,
  selectInvoice: PropTypes.func.isRequired,
  updateInvoice: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.shape({})),
  works: PropTypes.arrayOf(PropTypes.shape({})),
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  loadingInvoices: PropTypes.bool.isRequired,
  loadingExpenses: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  collapse: PropTypes.number,
  expenses: PropTypes.arrayOf(PropTypes.shape({})),
  expense_form: PropTypes.shape({}),
  searchExpense: PropTypes.string,
  error: PropTypes.string,
  isModalListOpen: PropTypes.bool,
}

Transactions.defaultProps = {
  transactions: [],
  works: [],
  invoices: [],
  form: {},
  permissions: {},
  search: '',
  searchExpense: '',
  collapse: 0,
  expenses: [],
  loading: true,
  loadingInvoices: true,
  loadingExpenses: true,
  error: null,
  isModalListOpen: false,
}

const mapStateToProps = ({ transactions, expenses, works }) => ({
  transactions: transactions.transactions,
  works: works.works,
  invoices: transactions.invoices,
  form: transactions.form,
  expense_form: transactions.expense_form,
  loading: transactions.loading,
  loadingInvoices: transactions.loadingInvoices,
  search: transactions.search,
  collapse: transactions.collapse,
  expenses: expenses.expenses,
  loadingExpenses: expenses.loading,
  searchExpense: expenses.search,
  error: transactions.error,
  isModalListOpen: transactions.isModalListOpen,
  transactionsDetailData: transactions.transactionData,
})

const mapDispatchToProps = dispatch => ({
  loadInfo: () => {
    dispatch(loadWorks())
    dispatch(loadTransactions())
    dispatch(loadInvoices(""))
    dispatch(loadExpenses('', 'pending', {perPage: 500}))
  },
  openAccountModal: transaction => dispatch(openAccountModal({ transaction, source: 'list', type: 0 })),
  openAccountModal2: transaction => dispatch(openAccountModal({ transaction, source: 'list', type: 1 })),
  selectInvoice: invoice => dispatch(selectInvoice({invoice})),
  updateInvoice: (values, history) => dispatch(updateInvoice(values, history)),
  loadInvoices: search => dispatch(loadInvoices(search)),
  loadExpenses: search => dispatch(loadExpenses(search, 'pending', {perPage: 500})),
  toggle: section => dispatch(toggleSection(section)),
  selectExpense: expense => dispatch(selectExpense(expense)),
  submitExpensePayment: params => dispatch(submitExpensePayment(params)),
  setIsModalListOpen: (isModalListOpen, data) => dispatch(setIsModalListOpen(!isModalListOpen, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
