import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import ReactLoading from 'react-loading'
import { Link } from 'react-router-dom'
import Multiselect from 'react-widgets/lib/Multiselect';

import {
  FormGroup,
  Input,
  Row,
  Col,
  FormText,
  Label,
} from 'reactstrap'

const LandCategorySuppliersForm = ({
  register,
  errors,
  suppliers,
  readOnly,
  loading,
  closeModal,
  edit,
  updateSuppliersField,
  categorySuppliers
}) => (
  <div className="pl-lg-4">
    <Input name="idLandCategory" type="hidden" innerRef={register} />
    <Input name="landCategorySuppliers" type="hidden" innerRef={register} />
    <Row>
      <Col>
        <FormGroup>
          <Label className="form-control-label" htmlFor="input-category">
            Proveedores
          </Label>
          {get(suppliers, 'length', 0) === 0 && !loading && !edit && (
            <div>
              <span className="text-mute">No hay proveedores en el sistema, ir a <Link to="/admin/suppliers/new" className="text-underline" onClick={closeModal}>añadir una nueva</Link>.</span>
            </div>
          )}
          {loading && (
            <div className="text-sm text-muted">
              <ReactLoading type="spinningBubbles" color="#000" height={20} width={20} className="d-block mr-2 mb-2" />
              Cargando Proveedores
            </div>
          )}
          {!readOnly && !edit && get(suppliers, 'length', 0) > 0 && <Multiselect
            allowCreate={false}
            id="input-suppliers"
            placeholder="Proveedores de la categoría"
            name="suppliers"
            data={suppliers}
            textField="alias"
            valueField="id"
            defaultValue={categorySuppliers}
            onChange={value => {
              const selectedIds = value.map(({ id }) => id)
              updateSuppliersField({
                value: selectedIds
              })
            }}
          />}
          {errors.supplier && (
            <FormText color="primary" className="mt-2">
              Seleccione una categoría para continuar
            </FormText>
          )}
        </FormGroup>
      </Col>
    </Row>
  </div>
)

LandCategorySuppliersForm.propTypes = {
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    supplier: PropTypes.shape({}),
  }),
}

LandCategorySuppliersForm.defaultProps = {
  readOnly: false,
  edit: false,
  categories: [],
  errors: {},
}

export default LandCategorySuppliersForm
