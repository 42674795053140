import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import useForm from 'react-hook-form'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import ReactDatetime from 'react-datetime'
import ReactLoading from 'react-loading'
import { updateInvoice as submitInvoicePayment } from 'redux/actions/transactions'
import { formatCurrency } from 'utils/numberFormat'
import { closePayInvoiceModal, loadInvoices } from 'redux/actions/invoices'
import moment from 'moment'


const PayInvoiceModal = ({ isOpen, closeModal, invoice, payInvoice, isLoading, accounts, pagination }) => {
  const { register, reset, handleSubmit, errors } = useForm()

  useEffect(() => {
    reset({
      date: moment().format('DD/MM/YYYY')
    })
  }, [moment, reset])

  if (!invoice) {
    return null;
  }

  let currentAccount = {};

  accounts.forEach(a => {
    if (a.id === invoice.notes[0].account) {
      currentAccount = a;
    }
  });


  const paymentDisabled = (isLoading || (
    !invoice.authNumber ||
    invoice.authNumber.trim() === '' ||
    currentAccount.amount - invoice.total < 0
  ));

  return (
  <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
    <ModalHeader toggle={closeModal}>Pagar este gasto</ModalHeader>
    <ModalBody>
      <p>
        ¿Estás seguro de que quieres pagar este gasto?
      </p>
      <ul>
        <li>
          <strong>Pagar con: </strong>Cuenta {
             currentAccount && (`${currentAccount.name} (${formatCurrency({ amount: currentAccount.amount })})`)
          }
        </li>
        <li>
          <strong>Total: </strong> {
             invoice.total && (`${formatCurrency({ amount: invoice.total })}`)
          }
        </li>
        {console.log()}
        <li>
          <strong>Folio: </strong> {
            (invoice.folio && (invoice.folio.startsWith('F-') ? invoice.folio : `F-${invoice.folio}`)) || '-'
          }
        </li>
        <li>
          <strong>Número de autorización: </strong> {
            (invoice.authNumber && (invoice.authNumber.startsWith('A-') ? invoice.authNumber : `A-${invoice.authNumber}`)) || '-'
          }
        </li>
        <li>
          <strong>Fecha: </strong> {
            moment(invoice.date).format('DD/MM/YYYY')
          }
        </li>
      </ul>
      <FormGroup>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
          <ReactDatetime
            className="form-control-alternative"
            id="modal-pay-invoice-date"
            timeFormat={false}
            closeOnSelect={true}
            defaultValue={Date.now()}
            inputProps={{
              autoComplete: 'off',
              placeholder: 'Fecha',
              name: 'date',
              ref: register({
                required: true,
                validate: value => moment(value, 'DD/MM/YYYY').isValid()
              }),
            }}
          />
          {errors.date && errors.date.type === 'required' && (
            <FormText color="primary">
              Seleccione una fecha.
            </FormText>
          )}
          {errors.date && errors.date.type === 'validate' && (
            <FormText color="primary">
              Ingrese una fecha válida o seleccione una en el calendario.
            </FormText>
          )}
        </InputGroup>
      </FormGroup>
      <strong style={{ color:'red' }}>{ currentAccount.amount - invoice.total < 0 ? 'FONDOS INSUFICIENTES' : '' }</strong>
    </ModalBody>
    <ModalFooter>
      <Button
        disabled={paymentDisabled}
        color="warning"
        className="mr-1"
        onClick={handleSubmit(
          (info) => {
            const selDate = info.date;
            payInvoice({
              type: 2,
              date: selDate,
              id: invoice.id,
              amount: invoice.total,
              account: Number(invoice.notes[0].account),
              description: `Pago factura #${invoice.id}`,
            }, pagination)
          }
        )}>
        {isLoading && <ReactLoading type="spinningBubbles" color="#fff" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
        Realizar pago
      </Button>
      <Button disabled={isLoading} color="primary" onClick={closeModal}>Cancelar</Button>
    </ModalFooter>
  </Modal>
)}

PayInvoiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  invoice: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  source: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  payInvoice: PropTypes.func.isRequired,
}

const mapStateToProps = props => {
  const {
    invoices: {
      openPayInvoiceModal,
      selectedInvoice,
      source,
      pagination,
    },
    transactions: {
      loading: payingInvoice
    }
  } = props

  return {
    isOpen: openPayInvoiceModal,
    invoice: selectedInvoice,
    isLoading: payingInvoice,
    accounts: props.invoices.accounts,
    source,
    pagination,
  }
}

const mapDispatchToProps = dispatch => ({
  payInvoice: (info, pagination) => {
    let formatInfo = ({...info});
    formatInfo.account = info.account;
    dispatch(submitInvoicePayment(formatInfo)).then(result => dispatch(closePayInvoiceModal())).then(() => dispatch(loadInvoices()))
  },
  closeModal: () => dispatch(closePayInvoiceModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayInvoiceModal))
