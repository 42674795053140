import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faFileInvoice, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { loadPendingNotes } from 'redux/actions/notes'
import { createInvoice } from 'redux/actions/invoices'
import { loadSuppliers } from 'redux/actions/suppliers'
import InvoiceForm from 'components/Invoices/form'
import numeral from 'numeral'


class NewInvoice extends Component {

  state = {
    supplierId: null,
    totales: {
      total: 0,
      iva: 0,
      subtotal: 0
    }
  }

  componentDidMount() {
    this.props.loadSuppliers()
  }

  handleOnSelectNote = (checked, note) => {
    const { total, iva, subtotal } = this.state.totales
    let [_total, _iva, _subtotal ] = [numeral(total), numeral(iva), numeral(subtotal)]
    const noteTotal = numeral(note.total)
    const noteSubtotal = numeral(note.subtotal)
    const noteIva = numeral(note.iva)

    const newTotales = {
      total: _total,
      subtotal: _subtotal,
      iva: _iva
    }

    if (checked) {
      /* newTotales.total += noteTotal.value()
      newTotales.subtotal += noteSubtotal.value()
      newTotales.iva += noteIva.value() */
      newTotales.total.add(noteTotal.value())
      newTotales.subtotal.add(noteSubtotal.value())
      newTotales.iva.add(noteIva.value())
    } else {
      newTotales.total.subtract(noteTotal.value())
      newTotales.subtotal.subtract(noteSubtotal.value())
      newTotales.iva.subtract(noteIva.value())
    }

    Object.assign(newTotales, {
      total: newTotales.total.value(),
      subtotal: newTotales.subtotal.value(),
      iva: newTotales.iva.value(),
    });

    this.setState({ totales: newTotales })
  }

  handleOnChangeSupplier = value => {
    this.props.loadPendingNotes({ supplier: value })
    const totales = { subtotal: 0, iva: 0, total: 0 }
    this.setState({ supplierId: value, totales })
  }

  render() {
    const { createInvoice, loading, loadingInvoice, suppliers, history, notes } = this.props
    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="12">
                    <h3 className="mb-0">
                      <FontAwesomeIcon icon={faFileInvoice} className="mr-1" /> Nueva Factura<br />
                      <Button
                        tag={Link}
                        color="primary"
                        to="/admin/invoices"
                        size="sm"
                        className="mt-2"
                      >
                        <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                        Regresar a Lista
                      </Button>
                    </h3>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <InvoiceForm
              onSubmit={info => createInvoice(info, history)}
              notes={notes}
              suppliers={suppliers}
              loading={loading}
              loadingInvoice={loadingInvoice}
              onChangeSupplier={this.handleOnChangeSupplier}
              supplierId={this.state.supplierId}
              onSelectNote={this.handleOnSelectNote}
              totales={this.state.totales}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = props => {
  const { invoices: { loading: loadingInvoice }, landInvoices, suppliers: { suppliers, loading }, notes: { notes } } = props
  return {
    loading,
    loadingInvoice,
    suppliers,
    landInvoices,
    notes
  }
}

const mapDispatchToProps = dispatch => ({
  loadSuppliers: () => dispatch(loadSuppliers({perPage: 1000})),
  loadPendingNotes: supplierId => dispatch(loadPendingNotes(supplierId)),
  createInvoice: (values, history) => dispatch(createInvoice(values, history)),
})

NewInvoice.propTypes = {
  suppliers: PropTypes.arrayOf(PropTypes.shape({
    alias: PropTypes.string,
    id: PropTypes.number,
  })),
  loading: PropTypes.bool,
  loadingInvoice: PropTypes.bool,
  loadSuppliers: PropTypes.func.isRequired,
  createInvoice: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

NewInvoice.defaultProps = {
  loading: false,
  loadingInvoice: false,
  suppliers: [],
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewInvoice))
