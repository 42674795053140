import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'assets/vendor/nucleo/css/nucleo.css'
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
import 'assets/scss/argon-dashboard-react.scss'
import 'react-widgets/dist/css/react-widgets.css';

import configureStore from 'redux/store'
import AuthLayout from 'layouts/Auth.jsx'
import AdminLayout from 'layouts/Admin.jsx'
import { autoLogin } from 'redux/actions/session'

const store = configureStore()
const token = localStorage.getItem('token')

if (token) {
  autoLogin(token, store.dispatch)
}

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
)
