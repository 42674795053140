import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormText,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import ReactDatetime from 'react-datetime'
import moment from 'moment'

const AccountForm = ({
  onSubmit,
  loading,
  register,
  errors,
  readOnly,
  transaction,
  transactions,
  type,
}) => (<>
  {type === 0 ?
    <Form onSubmit={onSubmit} noValidate autoComplete="off">
      <div className="pl-lg-4">
        <Row>
          <Input
            id="id"
            type="hidden"
            name="id"
            value={transaction.id}
            innerRef={register({ required: true })}
          />
          <Input
            id="type"
            type="hidden"
            name="type"
            value={type}
            innerRef={register({ required: true })}
          />

          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-blockname"
              >
                Monto:
              </Label>
              <Input
                id="amount-modal"
                autoComplete="off"
                placeholder="Monto"
                type="number"
                name="amount"
                className="col-sm-8"
                innerRef={register({
                  required: true,
                  pattern: /^\d+(\.\d)?/,
                })}
              />
              {errors.amount && errors.amount.type === 'required' && (
                <FormText color="primary">
                  Ingrese un monto
                </FormText>
              )}
              {errors.amount && errors.amount.type === 'pattern' && (
                <FormText color="primary">
                  Ingrese sólo dígitos
                </FormText>
              )}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-authnumber"
              >
                No. de autorización:
              </Label>
              <Input
                id="input-authnumber"
                autoComplete="off"
                placeholder="Número de autorización"
                type="text"
                name="authNumber"
                className="col-sm-8"
                innerRef={register({
                  required: true,
                })}
              />
              {errors.authNumber && errors.authNumber.type === 'required' && (
                <FormText color="primary">
                  Ingrese un número de autorización
                </FormText>
              )}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-blockname"
              >
                Descripción:
              </Label>
              <Input
                id="description"
                placeholder="Descripción..."
                rows="2"
                type="textarea"
                name="description"
                className="col-sm-8"
                innerRef={register({ required: true })}
              />
              {errors.description && (
                <FormText color="primary">
                  Ingrese una descripción
                </FormText>
              )}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-blockname"
              >
                Fecha pago:
              </Label>
              <InputGroup className="col-sm-8 input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  className="form-control-alternative"
                  id="date"
                  timeFormat={false}
                  closeOnSelect={true}
                  inputProps={{
                    autoComplete: 'off',
                    placeholder: 'Fecha',
                    name: 'date',
                    ref: register({
                      required: true,
                      validate: value => moment(value, 'DD/MM/YYYY').isValid()
                    }),
                  }}
                />
                {errors.date && errors.date.type === 'required' && (
                  <FormText color="primary">
                    Seleccione una fecha.
                  </FormText>
                )}
                {errors.date && errors.date.type === 'validate' && (
                  <FormText color="primary">
                    Ingrese una fecha válida o seleccione una en el calendario.
                  </FormText>
                )}
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-blockname"
              >
                Cuenta origen:
              </Label>
              <Input
                id="account"
                placeholder="Cuenta..."
                type="select"
                name="account"
                className="col-sm-8"
                innerRef={register({
                  required: true,
                  validate: value => value !== 'Seleccione una opción'
                })}
              >
                {[<option key={'emptyValue'}>Seleccione una opción</option>,<option key={'0'} value="0">Cuenta externa</option>]
                  .concat(transactions
                    .map(trans => transaction.id!==trans.id ? <option value={trans.id} key={trans.id}>{trans.name}</option> : null)
                  )
                }
              </Input>
              {errors.account && (
                <FormText color="primary">
                  Seleccione una cuenta
                </FormText>
              )}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup row>
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-check-worktext"
              >
              </Label>
              <Col md={{ size: 8 }}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="input-check-worktext"
                      name="custom_text"
                      innerRef={register}
                    />{' '}
                    Texto de Obra/Neodata Personalizado
                  </Label>
                </FormGroup>
              </Col>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-work-text"
              >
                Texto Obra
              </Label>
              <Input
                id="input-work-text"
                autoComplete="off"
                placeholder="BANCOS"
                type="text"
                name="workText"
                className="col-sm-8"
                style={{"textTransform": "uppercase" }}
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-neodata-text"
              >
                Texto Neodata
              </Label>
              <Input
                id="input-neodata-text"
                autoComplete="off"
                placeholder="BANCOS"
                type="text"
                name="neodataText"
                className="col-sm-8"
                style={{"textTransform": "uppercase" }}
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            {!readOnly && (<Button disabled={loading} color="primary" size="sm" className="float-right">
              Realizar pago
            </Button>)}
          </Col>
        </Row>
      </div>
    </Form>
    :
    <Form onSubmit={onSubmit} noValidate autoComplete="off">
      <div className="pl-lg-4">
        <Row>
          <Input
            id="id"
            type="hidden"
            name="id"
            innerRef={register({ required: true })}
          />
          <Input
            id="type"
            type="hidden"
            name="type"
            value={type}
            innerRef={register({ required: true })}
          />

          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-blockname"
              >
                Monto:
              </Label>
              <Input
                id="amount-modal"
                autoComplete="off"
                placeholder="Monto"
                type="number"
                name="amount"
                className="col-sm-8"
                innerRef={register({
                  required: true,
                  pattern: /^\d+(\.\d)?/,
                })}
              />
              {errors.amount && errors.amount.type === 'required' && (
                <FormText color="primary">
                  Ingrese un monto
                </FormText>
              )}
              {errors.amount && errors.amount.type === 'pattern' && (
                <FormText color="primary">
                  Ingrese sólo dígitos
                </FormText>
              )}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-withdrawal-authnumber"
              >
                No. de autorización:
              </Label>
              <Input
                id="input-withdrawal-authnumber"
                autoComplete="off"
                placeholder="Número de autorización"
                type="text"
                name="authNumber"
                className="col-sm-8"
                innerRef={register({
                  required: true,
                })}
              />
              {errors.authNumber && errors.authNumber.type === 'required' && (
                <FormText color="primary">
                  Ingrese un número de autorización
                </FormText>
              )}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-blockname"
              >
                Descripción:
              </Label>
              <Input
                id="description"
                placeholder="Descripción..."
                rows="2"
                type="textarea"
                name="description"
                className="col-sm-8"
                innerRef={register({ required: true })}
              />
              {errors.description && (
                <FormText color="primary">
                  Ingrese una descripción
                </FormText>
              )}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-blockname"
              >
                Fecha de retiro:
              </Label>
              <InputGroup className="col-sm-8 input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  className="form-control-alternative"
                  id="date"
                  timeFormat={false}
                  closeOnSelect={true}
                  inputProps={{
                    autoComplete: 'off',
                    placeholder: 'Fecha',
                    name: 'date',
                    ref: register({
                      required: true,
                      validate: value => moment(value, 'DD/MM/YYYY').isValid()
                    }),
                  }}
                />
                {errors.date && errors.date.type === 'required' && (
                  <FormText color="primary">
                    Seleccione una fecha.
                  </FormText>
                )}
                {errors.date && errors.date.type === 'validate' && (
                  <FormText color="primary">
                    Ingrese una fecha válida o seleccione una en el calendario.
                  </FormText>
                )}
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-blockname"
              >
                Cuenta:
              </Label>
              <Input
                id="account"
                placeholder="Cuenta..."
                type="select"
                name="account"
                className="col-sm-8"
                innerRef={register({
                  required: true,
                  validate: value => value !== 'Seleccione una opción'
                })}
              >
                {[<option key={'emptyValue'}>Seleccione una opción</option>]
                  .concat(transactions
                    .map(transaction => <option value={transaction.id} key={transaction.id}>{transaction.name}</option>)
                  )
                }
              </Input>
              {errors.account && (
                <FormText color="primary">
                  Seleccione una cuenta
                </FormText>
              )}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup row>
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-check-worktext"
              >
              </Label>
              <Col md={{ size: 8 }}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="input-check-worktext"
                      name="custom_text"
                      innerRef={register}
                    />{' '}
                    Texto de Obra/Neodata Personalizado
                  </Label>
                </FormGroup>
              </Col>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-work-text"
              >
                Texto Obra
              </Label>
              <Input
                id="input-work-text"
                autoComplete="off"
                placeholder="BANCOS"
                type="text"
                name="workText"
                className="col-sm-8"
                style={{"textTransform": "uppercase" }}
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup className="row">
              <Label
                className="col-sm-4 col-form-label text-right"
                htmlFor="input-neodata-text"
              >
                Texto Neodata
              </Label>
              <Input
                id="input-neodata-text"
                autoComplete="off"
                placeholder="BANCOS"
                type="text"
                name="neodataText"
                className="col-sm-8"
                style={{"textTransform": "uppercase" }}
                innerRef={register}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            {!readOnly && (<Button disabled={loading} color="primary" size="sm" className="float-right">
              Realizar retiro
            </Button>)}
          </Col>
        </Row>
      </div>
    </Form>
  }</>
)

AccountForm.propTypes = {
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  errors: PropTypes.shape({
    name: PropTypes.shape({
      type: PropTypes.string
    })
  }),
}

AccountForm.defaultProps = {
  loading: false,
  readOnly: false,
  onSubmit: noop,
  errors: {}
}

export default AccountForm
