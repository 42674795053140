import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  NavItem,
  Container,
  Media
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faWrench } from '@fortawesome/free-solid-svg-icons'
import { logout } from 'redux/actions/session'

const AdminNavbar = ({ user, logout, history }) => (
  <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
    <Container fluid>
      <Link
        className="h4 mb-0 text-uppercase d-none d-lg-inline-block"
        to="/"
      >
        Inicio
      </Link>
      <Nav className="align-items-center d-none d-md-flex">
        <UncontrolledDropdown nav>
          <DropdownToggle className="pr-0 d-flex align-items-center" nav caret>
            <Media className="align-items-center">
              <Media className="ml-2 d-none d-lg-block">
                <span className="mb-0 text-sm font-weight-bold">
                  Bienvenido {user.name}
                </span>
              </Media>
            </Media>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem className="noti-title" header tag="div">
              <h6 className="text-overflow m-0">Bienvenido!</h6>
            </DropdownItem>
            <DropdownItem to="/admin/me" tag={Link}>
              <i className="ni ni-single-02" />
              <span>Mi Perfil</span>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={e => logout(e, history)}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span>Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <NavItem>
          <a
            href="https://emkode.atlassian.net/servicedesk/customer/portal/10"
            target="_blank"
            title="Soporte técnico"
            type="button"
            color="primary"
            className="btn btn-primary btn-sm ml-2"
          >
            <FontAwesomeIcon icon={faWrench} />
          </a>
        </NavItem>
      </Nav>
    </Container>
  </Navbar>
)

AdminNavbar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  logout: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

AdminNavbar.defaultProps = {
  user: {
    name: ''
  }
}

const mapStateToProps = ({ session: { user }}) => ({ user })
const mapDispatchToProps = dispatch => ({
  logout: (e, history) => {
    e.preventDefault()
    dispatch(logout(history))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminNavbar))
