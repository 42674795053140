import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import noop from 'lodash/noop'
import ReactLoading from 'react-loading'
import { Link } from 'react-router-dom'
import {
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  FormText,
  Button,
} from 'reactstrap'
import { formatCurrency } from 'utils/numberFormat'

const LandNoteForm = props => {
  const {
    register,
    errors,
    clearError,
    setError,
    land,
    category,
    categories,
    lands,
    loadingLands,
    loadingCategories,
    loadCategories,
    changeModalCategory,
    closeModal,
    edit,
    supplierId
  } = props

  return (
    <div className="pl-lg-4">
      <Input name="id" type="hidden" innerRef={register} />
      <Input name="note" type="hidden" innerRef={register} />
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-land">
              Lote
          </Label>
            {get(lands, 'length', 0) === 0 && !loadingLands && !edit && (
              <div className="text-sm text-muted mt-2">Este proveedor no trabaja en ningún lote, agrégalo a alguno o <Link to="/admin/lands/new" className="text-underline" onClick={closeModal}>crea uno nuevo</Link>.</div>
            )}
            {loadingLands && (
              <div className="text-sm text-muted mt-2">
                <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-2 mb-2" />
                Cargando Lotes
            </div>
            )}
            {!edit && get(lands, 'length', 0) > 0 && <Input
              className="form-control-alternative"
              id="input-land"
              autoComplete="off"
              placeholder="Lote"
              type="select"
              name="land"
              innerRef={register}
              onChange={e => {
                loadCategories(e.target.value, supplierId)
              }}
            >
              {[<option key={'emptyValue'}>Seleccione una opción</option>]
                .concat(lands
                  .map(land => <option value={land.id} key={land.id}>{land.number}</option>)
                )
              }
            </Input>}
            {edit && land && land.id && (
              <div>
                <Button
                  tag={Link}
                  to={`/admin/lands/${land.id}`}
                  className="text-sm btn btn-sm btn-outline-primary mt-2"
                >
                  {get(land, 'street', '')} {get(land, 'number', '')}
                </Button>
              </div>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-category">
              Categoría
          </Label>
            {get(categories, 'length', 0) === 0 && !loadingCategories && !edit && land !== undefined && (
              <div className="text-sm text-muted mt-2">
                No hay categorías disponibles para agregar de este lote o ya se excedió el límite, puedes editar los conceptos actuales o <Link to={`/admin/lands/${land}`} className="text-underline" onClick={closeModal}>añadir una nueva a ese lote</Link>.
            </div>
            )}
            {loadingCategories && !edit && (
              <div className="text-sm text-muted mt-2">
                <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-2 mb-2" />
                Cargando Categorías
            </div>
            )}
            {/* !edit && get(categories, 'length', 0) > 0 && land !== undefined && */ <Input
              className="form-control-alternative"
              id="input-category"
              autoComplete="off"
              placeholder="Categoría"
              type="select"
              name="category"
              innerRef={register}
              hidden={
                !(!edit && get(categories, 'length', 0) > 0 && land !== undefined)
              }
              onChange={e => {
                changeModalCategory(e.target.value);
              }}
            >
              {[<option key={'emptyValue'} value={''}>Seleccione una opción</option>]
                .concat(categories
                .map(categoryItem => <option value={categoryItem.id} key={categoryItem.id}>{categoryItem.description} - {categoryItem.name}</option>)
                )
              }
            </Input>}
            {edit && !loadingCategories && category && category.id && land !== undefined && (
              <div>
                <Button
                  tag={Link}
                  to={`/admin/categories/${category.id}`}
                  className="text-sm btn btn-sm btn-outline-primary mt-2"
                >
                  {get(category, 'name', '')}
                </Button>
              </div>
            )}
            {edit && loadingCategories && (
              <div className="text-sm text-muted mt-2">
                <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-2 mb-2" />
                Cargando información de Categoría
            </div>
            )}
            {!edit && land === undefined && (
              <div className="text-sm text-muted mt-2">Seleccione un lote para cargar sus categorías.</div>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-amount">
              Importe
          </Label>
            <Input
              className="form-control-alternative"
              disabled={land === undefined || category === undefined || category === null || category === '' || loadingCategories || loadingLands}
              id="input-amount"
              autoComplete="off"
              placeholder="Importe"
              type="text"
              name="amount"
              innerRef={register({
                required: true,
                pattern: /^\d+(\.\d)?/,
              })}
              onChange={e => {
                const value = e.target.value
                // eslint-disable-next-line eqeqeq
                const currentCategory = categories.find(cat => cat.id == get(category, 'id', category))
                const currentLimit = currentCategory ? currentCategory.currentLimit : 0;

                if (value <= currentLimit && value.match(/^\d+(\.\d+)?$/)) {
                  clearError('amount')
                } else if (!value.match(/^\d+(\.\d+)?$/)) {
                  setError('amount', 'pattern')
                } else {
                  setError('amount', 'limit', currentLimit)
                }
              }}
            />
            {errors.amount && errors.amount.type === 'limit' && (
              <FormText color="primary" className="mt-2">
                La cantidad máxima a gastar de esa categoría en ese lote es de {formatCurrency({ amount: errors.amount.message })}.
            </FormText>
            )}
            {errors.amount && errors.amount.type === 'pattern' && (
              <FormText color="primary" className="mt-2">
                Ingrese un importe válido.
            </FormText>
            )}
            {errors.amount && errors.amount.type === 'required' && (
              <FormText color="primary" className="mt-2">
                Ingrese un importe.
            </FormText>
            )}
            {errors.amount && errors.amount.type === 'serverError' && (
              <FormText color="primary" className="mt-2">
                {errors.amount.message}
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-description">
              Descripción
          </Label>
            <Input
              className="form-control-alternative"
              disabled={land === undefined || category === undefined || category === null || category === '' || loadingCategories || loadingLands}
              id="input-description"
              autoComplete="off"
              placeholder="Descripción"
              type="text"
              name="description"
              innerRef={register}
            />
            {errors.description && (
              <FormText color="primary" className="mt-2">
                Favor de proporcionar una descripción válida
            </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

LandNoteForm.propTypes = {
  category: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ]),
  land: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ]),
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  lands: PropTypes.arrayOf(PropTypes.shape({
    street: PropTypes.string,
    number: PropTypes.string,
    id: PropTypes.number,
  })),
  edit: PropTypes.bool,
  loadingLands: PropTypes.bool,
  loadingCategories: PropTypes.bool,
  loadCategories: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    amount: PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ])
    })
  }),
  setError: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
}

LandNoteForm.defaultProps = {
  edit: false,
  loadCategories: noop,
  loadLands: noop,
  errors: {},
  categories: [],
  lands: [],
}

export default LandNoteForm
