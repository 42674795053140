import {
  LOAD_MODEL_CATEGORIES_START,
  LOAD_MODEL_CATEGORIES_ERROR,
  LOAD_MODEL_CATEGORIES_SUCCESS,
  MODEL_CATEGORY_FETCH_START,
  MODEL_CATEGORY_FETCH_SUCCESS,
  MODEL_CATEGORY_FETCH_ERROR,
  MODEL_CATEGORY_CREATE_START,
  MODEL_CATEGORY_CREATE_SUCCESS,
  MODEL_CATEGORY_CREATE_ERROR,
  OPEN_DELETE_MODEL_CATEGORY_MODAL,
  CLOSE_DELETE_MODEL_CATEGORY_MODAL,
  DELETE_MODEL_CATEGORY_START,
  DELETE_MODEL_CATEGORY_SUCCESS,
  DELETE_MODEL_CATEGORY_ERROR,
  MODEL_CATEGORY_UPDATE_START,
  MODEL_CATEGORY_UPDATE_SUCCESS,
  MODEL_CATEGORY_UPDATE_ERROR,
  OPEN_ADD_CATEGORY_TO_MODEL_MODAL,
  CLOSE_ADD_CATEGORY_TO_MODEL_MODAL,
  OPEN_EDIT_MODEL_CATEGORY_MODAL,
  CLOSE_EDIT_MODEL_CATEGORY_MODAL,
} from '../actions/modelCategories'
import { getPagination } from 'utils/pagination'

const defaultForm = {
  category: {},
  model: {},
  limit: 0,
}
const defaultPagination = getPagination()
export default (state = {
  modelCategories: [],
  loading: false,
  openedRemoveCategoryFromModelModal: false,
  openedAddCategoryToModelModal: false,
  openedEditCategoryModelModal: false,
  form: {...defaultForm},
  pagination: {...defaultPagination},
}, action) => {
  switch (action.type) {
  case MODEL_CATEGORY_CREATE_START:
    return {
      ...state,
      loading: true
    }
  case MODEL_CATEGORY_CREATE_SUCCESS:
    return {
      ...state,
      loading: false
    }
  case MODEL_CATEGORY_CREATE_ERROR:
    return {
      ...state,
      loading: false
    }
  case OPEN_ADD_CATEGORY_TO_MODEL_MODAL:
    return {
      ...state,
      form: {...defaultForm, model: action.payload },
      openedAddCategoryToModelModal: true
    }
  case CLOSE_ADD_CATEGORY_TO_MODEL_MODAL:
    return {
      ...state,
      form: {...defaultForm},
      openedAddCategoryToModelModal: false
    }
  case OPEN_EDIT_MODEL_CATEGORY_MODAL:
    return {
      ...state,
      openedEditCategoryModelModal: true,
      form: action.payload,
    }
  case CLOSE_EDIT_MODEL_CATEGORY_MODAL:
    return {
      ...state,
      openedEditCategoryModelModal: false,
      form: {...defaultForm}
    }
  case OPEN_DELETE_MODEL_CATEGORY_MODAL:
    return {
      ...state,
      openedRemoveCategoryFromModelModal: true,
      selectedModelCategory: action.payload,
    }
  case CLOSE_DELETE_MODEL_CATEGORY_MODAL:
    return {
      ...state,
      openedRemoveCategoryFromModelModal: false,
      selectedModelCategory: null
    }
  case DELETE_MODEL_CATEGORY_START:
    return {
      ...state,
      deletingModelCategory: true,
    }
  case DELETE_MODEL_CATEGORY_SUCCESS:
    return {
      ...state,
      deletingModelCategory: false,
      form: {...defaultForm},
      modelCategories: state.modelCategories.filter(u => u.id !== state.selectedModelCategory.id),
      openedRemoveCategoryFromModelModal: false,
    }
  case DELETE_MODEL_CATEGORY_ERROR:
    return {
      ...state,
    }
  case MODEL_CATEGORY_UPDATE_START:
    return {
      ...state,
      updatingModelCategory: true,
    }
  case MODEL_CATEGORY_UPDATE_SUCCESS:
    return {
      ...state,
      updatingModelCategory: false,
      form: {...defaultForm},
      openedEditCategoryModelModal: false,
    }
  case MODEL_CATEGORY_UPDATE_ERROR:
    return {
      ...state,
    }
  case LOAD_MODEL_CATEGORIES_ERROR:
    return {
      ...state,
      modelCategories: [],
      error: action.payload
    }
  case LOAD_MODEL_CATEGORIES_START:
    return {
      ...state,
      modelCategories: [],
      loading: true
    }
  case LOAD_MODEL_CATEGORIES_SUCCESS:
    return {
      ...state,
      modelCategories: action.payload.results,
      loading: false,
      pagination: {
        ...action.pagination,
        total: action.payload.totalCount,
      },
    }
  case MODEL_CATEGORY_FETCH_START:
    return {
      ...state,
      loadingModelCategory: true,
      form: defaultForm
    }
  case MODEL_CATEGORY_FETCH_SUCCESS:
    return {
      ...state,
      loadingModelCategory: false,
      form: action.payload
    }
  case MODEL_CATEGORY_FETCH_ERROR:
    return {
      ...state,
      loadingModelCategory: false,
      form: action.payload
    }
  default:
    return state
  }
}
