import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import ReactDatetime from 'react-datetime'
import moment from 'moment'
import numeral from 'numeral'

const TransactionForm = ({
  onSubmit,
  loading,
  register,
  errors,
  readOnly,
  transactions,
  getValues,
}) => (
  <Form onSubmit={onSubmit} noValidate autoComplete="off">
    <Input name="id" type="hidden" innerRef={register({
      required: true,
      validate: value => value !== '0' && value !== ''
    })} />
    {errors.id && (
      <h3 className="text-danger">Seleccione una factura</h3>
    )}
    <Input
      id="type"
      type="hidden"
      name="type"
      value="2"
      innerRef={register({ required: true })}
    />
    <div className="pl-lg-4">
      <Row>
        <Col md="6">
          <FormGroup>
            <Input
              id="amount"
              autoComplete="off"
              placeholder="Monto"
              type="text"
              disabled={true}
              name="amount"
              innerRef={register({
                required: true,
                pattern: /^\d+(\.\d)?/,
              })}
            />
            {errors.amount && errors.amount.type === 'required' && (
              <FormText color="primary">
                Ingrese un monto
              </FormText>
            )}
            {errors.amount && errors.amount.type === 'pattern' && (
              <FormText color="primary">
                Ingrese sólo dígitos
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Input
              id="cuenta"
              placeholder="Cuenta..."
              type="select"
              name="account"
              innerRef={register({
                required: true,
                validate: value => {
                  const expenseAmount = getValues()['amount'];
                  if (value === 'Seleccione una opción') {
                    return 'Seleccione una cuenta válida';
                  }

                  let amount = value && transactions.find(acc => acc.id === parseInt(value));
                  amount = amount && numeral(amount.amount)
                  let difference = 0;
                  if (amount) {
                    difference = amount.difference(expenseAmount)
                  }
                  return (amount &&
                    amount.subtract(expenseAmount).value() >= 0
                  ) || `El monto sobrepasa la cuenta seleccionada por ${difference} `;
                }
              })}
            >
              {[<option key={'emptyValue'}>Seleccione una opción</option>]
                .concat(transactions
                  .map(transaction => <option value={transaction.id} key={transaction.id}>{transaction.name}</option>)
                )
              }
            </Input>
            {errors.account && (
              <FormText color="primary">
                Seleccione una cuenta
              </FormText>
            )}
            {errors.account && errors.account.message}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Input
              id="description"
              placeholder="Descripción..."
              rows="3"
              type="textarea"
              name="description"
              innerRef={register({ required: true })}
            />
            {errors.description && (
              <FormText color="primary">
                Ingrese una descripción
              </FormText>
            )}
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                className="form-control-alternative"
                id="date"
                timeFormat={false}
                closeOnSelect={true}
                inputProps={{
                  autoComplete: 'off',
                  placeholder: 'Fecha',
                  name: 'date',
                  ref: register({
                    required: true,
                    validate: value => moment(value, 'DD/MM/YYYY').isValid()
                  }),
                }}
              />
              {errors.date && errors.date.type === 'required' && (
                <FormText color="primary">
                  Seleccione una fecha.
                </FormText>
              )}
              {errors.date && errors.date.type === 'validate' && (
                <FormText color="primary">
                  Ingrese una fecha válida o seleccione una en el calendario.
                </FormText>
              )}
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Input
              id="input-invoice-voucher"
              placeholder="Subir voucher"
              type="file"
              accept=".pdf"
              name="invoice_voucher"
              innerRef={register}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          {!readOnly && (<Button disabled={loading} color="primary" size="sm" className="float-right">
            Realizar pago
          </Button>)}
        </Col>
      </Row>
    </div>
  </Form>
)

TransactionForm.propTypes = {
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  errors: PropTypes.shape({
    name: PropTypes.shape({
      type: PropTypes.string
    })
  }),
}

TransactionForm.defaultProps = {
  loading: false,
  readOnly: false,
  onSubmit: noop,
  errors: {}
}

export default TransactionForm
