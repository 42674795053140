import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import ReactLoading from 'react-loading'
import { faThLarge, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { createBlock } from 'redux/actions/blocks'
import { loadProjects } from 'redux/actions/projects'
import BlockForm from 'components/Blocks/form'
import BackButton from 'components/BackButton'

const NewBlock = ({ createBlock, history, loadProjects, loading, loadingBlock, projects }) => {
  const [hasFetchedProjects, setHasFetchedProjects] = useState(false)
  const { register, handleSubmit, errors } = useForm()

  useEffect(() => {
    if (!hasFetchedProjects) {
      loadProjects()
    }
    setHasFetchedProjects(true)
  }, [loadProjects, projects, hasFetchedProjects, setHasFetchedProjects])

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faThLarge} className="mr-1"/> Nueva Manzana<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to="/admin/blocks"
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                      Regresar a Lista
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loadingBlock && (
                <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
              )}
              <BlockForm
                onSubmit={handleSubmit(form => createBlock(form, history))}
                register={register}
                errors={errors}
                loading={loading}
                loadingBlock={loadingBlock}
                projects={projects}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({ blocks: { form, loading: loadingBlock }, projects: { projects, loading } }) => ({
  form,
  loading,
  loadingBlock,
  projects
})

const mapDispatchToProps = dispatch => ({
  loadProjects: () => dispatch(loadProjects()),
  createBlock: (values, history) => dispatch(createBlock(values, history))
})

NewBlock.propTypes = {
  form: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  projects: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  loading: PropTypes.bool,
  loadingBlock: PropTypes.bool,
  createBlock: PropTypes.func.isRequired,
  loadProjects: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
}

NewBlock.defaultProps = {
  loading: false,
  loadingBlock: false,
  form: {
    description: '',
    name: '',
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewBlock))
