import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import SupplierContactForm from 'components/SupplierContacts/form'
import { updateSupplierContact, closeEditSupplierContactModal } from 'redux/actions/supplierContacts'

const EditModal = ({
  isOpen,
  closeModal,
  updateSupplierContact,
  loading,
  form,
}) => {
  const { register, reset, handleSubmit, errors } = useForm()

  useEffect(() => {
    reset(form)
  }, [form, reset])

  return (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Actualizar Contacto de Proveedor</ModalHeader>
      <Form onSubmit={handleSubmit(updateSupplierContact)} noValidate autoComplete="off">
        <ModalBody>
          <SupplierContactForm
            register={register}
            errors={errors}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Actualizar Contacto
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

EditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.shape({
    supplier: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
    name: PropTypes.string,
    job: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  closeModal: PropTypes.func.isRequired,
  updateSupplierContact: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  categories: { categories, loading: loadingCategories },
  supplierContacts: { supplierContacts: currentCategories, updatingSupplierContact, openedEditSupplierContactModal, form }
}) => ({
  categories: currentCategories && categories && categories.filter(category => !currentCategories.find(current => current.category.id === category.id)),
  isOpen: openedEditSupplierContactModal,
  form,
  loading: updatingSupplierContact,
  loadingCategories: loadingCategories
})

const mapDispatchToProps = dispatch => ({
  updateSupplierContact: info => dispatch(updateSupplierContact(info)),
  closeModal: () => dispatch(closeEditSupplierContactModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditModal))
