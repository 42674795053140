import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBillWave, faTrash, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'

import {
  Card,
  CardHeader,
  Table,
  Container,
  Button,
  Row,
  Col,
  UncontrolledTooltip
} from 'reactstrap'
import { loadShoppers, openDeleteShopperModal } from 'redux/actions/shoppers'
import { hasDependencies } from './utils'
import PaginationComponent from 'react-reactstrap-pagination'

class Shoppers extends Component {
  componentDidMount() {
    this.props.loadInfo()
  }

  render() {
    const { shoppers, loading, openDeleteShopperModal, loadInfo, pagination } = this.props

    if (loading) {
      return (
        <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Compradores
                  </h3>
                  <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Compradores
                  <Button tag={Link} to="/admin/shoppers/new" className="float-right text-sm btn btn-sm btn-outline-primary btn-sm">
                    <FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
                    Nuevo Comprador
                    <FontAwesomeIcon icon={faPlus} className="ml-2" />
                  </Button>
                </h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">Nombre</th>
                    <th scope="col" className="text-center">Apellido Paterno</th>
                    <th scope="col" className="text-center">Apellido Materno</th>
                    <th scope="col" className="text-center">Lotes Comprados</th>
                  </tr>
                </thead>
                <tbody>
                  {shoppers.map(shopper => {
                    const id = `shopper_${shopper.id}`
                    const containerId = `container_${shopper.id}`
                    return (
                      <tr key={shopper.id} id={containerId}>
                        <th scope="row" className="text-center mb-0 text-sm">
                          <Button
                            tag={Link}
                            to={`/admin/shoppers/${shopper.id}`}
                            className="btn btn-sm btn-outline-primary mr-0"
                          >
                            {shopper.name}
                          </Button>
                          <br/>
                          <Button
                            tag={Link}
                            color="primary"
                            to={`/admin/shoppers/${shopper.id}/edit`}
                            size="sm"
                            className="mr-2 mt-2"
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                          {hasDependencies(shopper) && <UncontrolledTooltip boundariesElement={containerId} placement="top" target={id} >Este comprador no se puede eliminar por que tiene lotes asociados a el.</UncontrolledTooltip>}
                          <span id={id}>
                            <Button
                              color="warning"
                              onClick={() => openDeleteShopperModal(shopper)}
                              size="sm"
                              className="mt-2"
                              disabled={hasDependencies(shopper)}
                            >
                              <FontAwesomeIcon className="mr-0" icon={faTrash} />
                            </Button>
                          </span>
                        </th>
                        <td className="border-left mb-0 text-sm">
                          {shopper.lastName}
                        </td>
                        <td className="border-left mb-0 text-sm">
                          {shopper.mLastName}
                        </td>
                        <td className="border-left mb-0 text-sm">
                          {shopper.lands.length}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            {pagination.total > 0 && <PaginationComponent
              totalItems={pagination.total}
              pageSize={pagination.perPage}
              onSelect={page => loadInfo({...pagination, current: page})}
              firstPageText="<<"
              previousPageText="<"
              nextPageText=">"
              lastPageText=">>"
              defaultActivePage={pagination.current}
            />}
          </Col>
        </Row>
      </Container>
    )
  }
}

Shoppers.propTypes = {
  loadInfo: PropTypes.func.isRequired,
  openDeleteShopperModal: PropTypes.func.isRequired,
  shoppers: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}),
}

Shoppers.defaultProps = {
  shoppers: [],
  permissions: {},
}

const mapStateToProps = ({ shoppers }) => ({
  shoppers: shoppers.shoppers,
  loading: shoppers.loading,
  pagination: shoppers.pagination,
})

const mapDispatchToProps = dispatch => ({
  loadInfo: pagination => dispatch(loadShoppers(pagination)),
  openDeleteShopperModal: shopper => dispatch(openDeleteShopperModal({ shopper, source: 'list' })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Shoppers)
