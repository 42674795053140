import React, { useEffect } from 'react'
import get from 'lodash/get'
import useForm from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import ShopperBalanceForm from 'components/ShopperBalances/form'
import { updateShopperBalance, closeEditShopperBalanceModal } from 'redux/actions/shopperBalances'

const EditModal = ({
  isOpen,
  closeModal,
  updateShopperBalance,
  loading,
  form,
}) => {
  const { register, handleSubmit, errors, reset } = useForm()

  useEffect(() => {
    reset({ ...form, shopper: get(form, 'shopper.id') })
  }, [form, reset])

  return (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Actualizar Movimiento de Comprador</ModalHeader>
      <Form onSubmit={handleSubmit(updateShopperBalance)} noValidate autoComplete="off">
        <ModalBody>
          <ShopperBalanceForm
            register={register}
            errors={errors}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Actualizar Movimiento
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

EditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.shape({
    amount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    date: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
    ]),
    info: PropTypes.string,
    isCharge: PropTypes.bool,
    id: PropTypes.number,
    shopper: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
  }),
  closeModal: PropTypes.func.isRequired,
  updateShopperBalance: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  shopperBalances: { updatingShopperBalance, openedEditShopperBalanceModal, form }
}) => ({
  form,
  isOpen: openedEditShopperBalanceModal,
  loading: updatingShopperBalance,
})

const mapDispatchToProps = dispatch => ({
  updateShopperBalance: info => dispatch(updateShopperBalance(info)),
  closeModal: () => dispatch(closeEditShopperBalanceModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditModal))
