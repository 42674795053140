import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import ReactLoading from 'react-loading'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { formatCurrency } from 'utils/numberFormat';
import AccountLogTable from 'components/Transactions/account-log'

import { loadAccountLog } from 'redux/actions/transactions'

const TransactionsDetailModal = (props) => {
  const {
    isOpen,
    toggle,
    data,
    accountLog,
    loadingAccountLog,
    loadAccountLog,
    depositsPagination,
    withdrawalsPagination,
  } = props;

  const accountId = get(data, 'account.id')

  useEffect(() => {
    if (isOpen && accountId) {
      loadAccountLog(accountId, 'deposits')
      loadAccountLog(accountId, 'withdrawals')
    }
  }, [isOpen, accountId, loadAccountLog])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>{get(data, 'account.name')} ({ formatCurrency({amount: get(data, 'account.amount', 0)})})</ModalHeader>
      <ModalBody>
        {loadingAccountLog && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} />}
        {!loadingAccountLog && <>
          <Row>
            <Col>
              <AccountLogTable
                accountId={accountId}
                accountLog={accountLog}
                depositsPagination={depositsPagination}
                withdrawalsPagination={withdrawalsPagination}
                loadAccountLog={loadAccountLog}
              />
            </Col>
          </Row>
        </>}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>Cerrar</Button>
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = ({ transactions }) => {
  return {
    data: transactions.transactionData,
    accountLog: transactions.accountLog,
    loadingAccountLog: transactions.loadingAccountLog,
    depositsPagination: transactions.depositsPagination,
    withdrawalsPagination: transactions.withdrawalsPagination,
  }
}

const mapDispatchToProps = dispatch => ({
  loadAccountLog: (accountId, type, pagination) => dispatch(loadAccountLog(accountId, type, pagination)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsDetailModal)
