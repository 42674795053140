import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import fileDownload from 'js-file-download'
import {
Card,
CardHeader,
CardBody,
CardText,
Container,
Button,
Input,
Row,
Col,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import ReportsWithDatesForm from 'components/Reports/reportWithDatesForm'

import instance from '../../axios'
import { generateNotesAndExpensesReport } from 'redux/actions/reports'

const Reports = (props) => {

  const [projects, setProjects] = useState([]);
  const [error, setError] = useState('');

  const {
    loadingReport,
    generateNotesAndExpensesReport,
    error: errors,
    generateReportOnDates,
    selectedPanel,
  } = props

  let project = React.createRef();

  useEffect(() => {
    instance.get('/projects')
      .then(({ data }) => {
        setProjects(data)
      })
      .catch(err => console.error(err))
  }, []);

  const getLandsReport = () => {
    if (project.current.value > 0) {
      setError('');
      instance.get(`/report/lands/${project.current.value}`, { responseType: 'arraybuffer' })
        .then(({ data }) => {
          fileDownload(data, 'file.xlsx')
        })
        .catch(err => console.error(err))
    } else {
      setError('Seleccione un proyecto');
    }
  }

  const getPaymentsReport = () => {
    instance.get('/report/payments/', { responseType: 'arraybuffer' })
      .then(({ data }) => {
        fileDownload(data, 'file.xlsx')
      })
      .catch(err => console.error(err))
  }

  const getCategoriesReport = () => {
    instance.get('/report/categories/', { responseType: 'arraybuffer' })
      .then(({ data }) => {
        fileDownload(data, 'file.xlsx')
      })
      .catch(err => console.error(err))
  }

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <Row hidden>
        <Col>
          <Card className="shadow">
            <CardHeader className="border-0">
              <h3 className="mb-0">
                Reporte de estatus de lotes por proyecto
              </h3>
            </CardHeader>
            <CardBody>
              <CardText>
                Obtener un reporte sobre el estatus de lotes por proyecto.
                Muestra de cada lote, si ya fue vendido o no, cuánto se ha gastado del presupuesto y cual es el límite superior del presupuesto.
                Si el lote fue vendido entonces también muestra de cuanto es la deuda que aún tiene que cubrir el comprador y cuánto ha pagado.
              </CardText>
              {error && <small className="form-text text-primary">{error}</small>}
              {<Input
                className="form-control-alternative"
                id="input-report-lands"
                autoComplete="off"
                placeholder="Proyecto"
                type="select"
                name="project"
                innerRef={project}
              >
                {[<option key={'emptyValue'}>Seleccione una opción</option>]
                  .concat(projects
                    .filter(project => project && project.id !== undefined)
                    .map(project => <option value={project.id} key={project.id}>{project.name}</option>)
                  )
                }
              </Input>}
              <Button
                color="primary"
                onClick={() => getLandsReport()}
              >
                Generar
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row hidden>
        <Col>
          <Card className="shadow">
            <CardHeader className="border-0">
              <h3 className="mb-0">
                Reporte de pagos semanal
              </h3>
            </CardHeader>
            <CardBody>
              <CardText>
                Obtiene todas las notas que se hayan capturado durante
                los últimos 7 días. Muestra concepto, monto, nombre del proveedor y lote.
              </CardText>
              <Button
                color="primary"
                onClick={() => getPaymentsReport()}
              >
                Generar
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row hidden>
        <Col>
          <Card className="shadow">
            <CardHeader className="border-0">
              <h3 className="mb-0">
                Reporte de categorías por lote
              </h3>
            </CardHeader>
            <CardBody>
              <CardText>
                Obtiene todos los lotes y cuánto
                se ha pagado por categoría de cada uno de los lotes. Las columnas
                de este reporte son lote, categoría y monto.
              </CardText>
              <Button
                color="primary"
                onClick={() => getCategoriesReport()}
              >
                Generar
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader className="border-0">
              <h3 className="mb-0">
                Reporte de notas y gastos no pagados
              </h3>
            </CardHeader>
            <CardBody>
              <CardText>
                Genera un reporte excel con las notas y los gastos que no se han pagado.
              </CardText>
              <Button
                color="primary"
                size="sm"
                type="button"
                disabled={loadingReport}
                onClick={() => generateNotesAndExpensesReport()}
              >
                <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
                Generar reporte
              </Button>
              {errors && errors.report && selectedPanel === 1 && <p className="text-danger">
                Ocurrió un error al generar el reporte, favor de reintentarlo
              </p>}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader className="border-0">
              <h3 className="mb-0">
                Reporte de notas y gastos por fecha
              </h3>
            </CardHeader>
            <CardBody>
              <CardText>
                Genera un reporte excel con las notas y los gastos en las fechas proporcionadas
              </CardText>
              {errors && errors.report && selectedPanel === 2 && <p className="text-danger">
                Ocurrió un error al generar el reporte, favor de reintentarlo
              </p>}
              <div>
                <ReportsWithDatesForm
                  onSubmit={generateReportOnDates}
                  loadingReport={loadingReport}
                  errors={errors}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

Reports.propTypes = {
  loadingReport: PropTypes.bool,
  generateNotesReport: PropTypes.func,
  errors: PropTypes.shape({}),
}

Reports.defaultProps = {
  loadingReport: false,
  errors: {},
  selectedPanel: 0,
}

const mapStateToProps = ({ reports }) => {
  return {...reports}
}

const mapDispatchToProps = dispatch => ({
  generateNotesAndExpensesReport: () => dispatch(generateNotesAndExpensesReport({ billed: false }, 1)),
  generateReportOnDates: (formData) => dispatch(generateNotesAndExpensesReport({...formData}, 2)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
