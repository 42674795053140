import { toast } from 'react-toastify'
import instance from '../../axios'
import sanitizeValues from 'utils/sanitizeValues'
import { getPagination } from 'utils/pagination'

export const LOAD_MODEL_CATEGORIES_START = 'LOAD_MODEL_CATEGORIES_START'
export const LOAD_MODEL_CATEGORIES_SUCCESS = 'LOAD_MODEL_CATEGORIES_SUCCESS'
export const LOAD_MODEL_CATEGORIES_ERROR = 'LOAD_MODEL_CATEGORIES_ERROR'

export const MODEL_CATEGORY_CREATE_START = 'MODEL_CATEGORY_CREATE_START'
export const MODEL_CATEGORY_CREATE_SUCCESS = 'MODEL_CATEGORY_CREATE_SUCCESS'
export const MODEL_CATEGORY_CREATE_ERROR = 'MODEL_CATEGORY_CREATE_ERROR'

export const MODEL_CATEGORY_FETCH_START = 'MODEL_CATEGORY_FETCH_START'
export const MODEL_CATEGORY_FETCH_SUCCESS = 'MODEL_CATEGORY_FETCH_SUCCESS'
export const MODEL_CATEGORY_FETCH_ERROR = 'MODEL_CATEGORY_FETCH_ERROR'

export const MODEL_CATEGORY_UPDATE_START = 'MODEL_CATEGORY_UPDATE_START'
export const MODEL_CATEGORY_UPDATE_SUCCESS = 'MODEL_CATEGORY_UPDATE_SUCCESS'
export const MODEL_CATEGORY_UPDATE_ERROR = 'MODEL_CATEGORY_UPDATE_ERROR'

export const OPEN_ADD_CATEGORY_TO_MODEL_MODAL = 'OPEN_ADD_CATEGORY_TO_MODEL_MODAL'
export const CLOSE_ADD_CATEGORY_TO_MODEL_MODAL = 'CLOSE_ADD_CATEGORY_TO_MODEL_MODAL'

export const OPEN_DELETE_MODEL_CATEGORY_MODAL = 'OPEN_DELETE_MODEL_CATEGORY_MODAL'
export const CLOSE_DELETE_MODEL_CATEGORY_MODAL = 'CLOSE_DELETE_MODEL_CATEGORY_MODAL'

export const OPEN_EDIT_MODEL_CATEGORY_MODAL = 'OPEN_EDIT_MODEL_CATEGORY_MODAL'
export const CLOSE_EDIT_MODEL_CATEGORY_MODAL = 'CLOSE_EDIT_MODEL_CATEGORY_MODAL'

export const DELETE_MODEL_CATEGORY_START = 'DELETE_MODEL_CATEGORY_START'
export const DELETE_MODEL_CATEGORY_SUCCESS = 'DELETE_MODEL_CATEGORY_SUCCESS'
export const DELETE_MODEL_CATEGORY_ERROR = 'DELETE_MODEL_CATEGORY_ERROR'

export const openAddModelCategoryModal = payload => ({
  type: OPEN_ADD_CATEGORY_TO_MODEL_MODAL,
  payload
})

export const closeAddModelCategoryModal = () => ({
  type: CLOSE_ADD_CATEGORY_TO_MODEL_MODAL,
})

export const openEditModelCategoryModal = payload => ({
  type: OPEN_EDIT_MODEL_CATEGORY_MODAL,
  payload
})

export const closeEditModelCategoryModal = () => ({
  type: CLOSE_EDIT_MODEL_CATEGORY_MODAL,
})

export const openDeleteModelCategoryModal = payload => ({
  type: OPEN_DELETE_MODEL_CATEGORY_MODAL,
  payload
})

export const closeDeleteModelCategoryModal = () => ({
  type: CLOSE_DELETE_MODEL_CATEGORY_MODAL,
})

export const deleteModelCategory = modelCategory => dispatch => {
  dispatch({
    type: DELETE_MODEL_CATEGORY_START
  })

  return instance.delete(`/modelCategories/${modelCategory.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_MODEL_CATEGORY_SUCCESS,
        payload: data
      })

      toast(`Se ha eliminado a la categoría ${data.category.name} del modelo ${data.model.name}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      loadModelCategories({ model: data.model.id })(dispatch)
    })
    .catch(err => {
      dispatch({
        type: DELETE_MODEL_CATEGORY_ERROR,
        payload: err
      })
    })
}

export const fetchModelCategory = id => dispatch => {
  dispatch({
    type: MODEL_CATEGORY_FETCH_START
  })

  return instance.get(`/modelCategories/${id}`)
    .then(({ data }) => {
      dispatch({
        type: MODEL_CATEGORY_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: MODEL_CATEGORY_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateModelCategory = values => dispatch => {
  dispatch({
    type: MODEL_CATEGORY_UPDATE_START
  })

  const sanitized = sanitizeValues(values)

  return instance.patch(`/modelCategories/${sanitized.id}`, sanitized)
    .then(({ data }) => {
      dispatch({
        type: MODEL_CATEGORY_UPDATE_SUCCESS,
        payload: data
      })
      dispatch({
        type: CLOSE_ADD_CATEGORY_TO_MODEL_MODAL,
      })

      loadModelCategories({ model: data.model.id })(dispatch)
    })
    .catch(err => {
      dispatch({
        type: MODEL_CATEGORY_UPDATE_ERROR,
        payload: err
      })
    })
}

// eslint-disable-next-line no-unused-vars
export const createModelCategory = ({ id, ...values}) => dispatch => {
  dispatch({
    type: MODEL_CATEGORY_CREATE_START
  })

  const sanitized = sanitizeValues(values)

  return instance.post('/modelCategories', sanitized)
    .then(({ data }) => {
      dispatch({
        type: MODEL_CATEGORY_CREATE_SUCCESS,
        payload: data
      })
      dispatch({
        type: CLOSE_ADD_CATEGORY_TO_MODEL_MODAL,
      })
      loadModelCategories({ model: data.model.id })(dispatch)
    })
    .catch(err => {
      dispatch({
        type: MODEL_CATEGORY_CREATE_ERROR,
        payload: err
      })
    })
}

export const loadModelCategories = ({ model, category }, paginationOpts) => dispatch => {
  const pagination = getPagination(paginationOpts)
  let params = {}

  if (category) {
    params.where = { category }
  } else {
    params.where = { model }
  }

  dispatch({
    type: LOAD_MODEL_CATEGORIES_START
  })

  return instance.get(`/modelCategories`, {
    params
  }).then(({ data }) => {
      dispatch({
        type: LOAD_MODEL_CATEGORIES_SUCCESS,
        payload: data,
        pagination,
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_MODEL_CATEGORIES_ERROR,
        payload: err
      })
    })
}
