import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase, faPencilAlt, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
} from 'reactstrap'
import PropTypes from 'prop-types'
import { loadNotes } from 'redux/actions/notes'
import { fetchSupplier, openDeleteSupplierModal } from 'redux/actions/suppliers'
import { hasDependencies } from './utils'
import SupplierForm from 'components/Suppliers/form'
import DependenciesList from 'components/Suppliers/dependenciesList'
import BackButton from 'components/BackButton'

const SupplierDetail = ({ form, loadNotes, loading, history, match, openDeleteSupplierModal, hasDependencies, fetchSupplier, notes }) => {
  const { register, reset } = useForm()

  useEffect(() => {
    fetchSupplier(match.params.id)
    loadNotes(match.params.id)
  }, [fetchSupplier, loadNotes, match])

  useEffect(() => {
    reset(form)
  }, [form, reset])

  const id = `supplier_${form.id}`

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faSuitcase} className="mr-1"/> Detalle de Proveedor<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/suppliers/${match.params.id}/edit`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="mr-2"/>
                      Editar Proveedor
                    </Button>
                    {hasDependencies && (
                      <UncontrolledTooltip target={id}>
                        No se puede eliminar este proveedor porque tiene contactos, lotes o notas asociados a el.
                      </UncontrolledTooltip>
                    )}
                    <Button
                      color="warning"
                      disabled={hasDependencies}
                      onClick={() => openDeleteSupplierModal(form)}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2"/>
                      Borrar Proveedor
                      {hasDependencies && (
                        <FontAwesomeIcon
                          id={id}
                          icon={faInfoCircle}
                          className="ml-2"
                        />
                      )}
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loading && (
                <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />
              )}
              <SupplierForm
                readOnly
                history={history}
                register={register}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DependenciesList
        supplier={form}
        notes={notes}
        loading={loading}
      />
    </Container>
  )
}

const mapStateToProps = ({ suppliers: { form, loadingSupplier }, notes: { notes, loading: loadingNotes } }) => ({
  form,
  loading: loadingSupplier || loadingNotes,
  notes,
  hasDependencies: hasDependencies(form)
})

const mapDispatchToProps = dispatch => ({
  fetchSupplier: id => dispatch(fetchSupplier(id)),
  loadNotes: supplier => dispatch(loadNotes({ supplier })),
  openDeleteSupplierModal: supplier => dispatch(openDeleteSupplierModal({ supplier, source: 'detail' })),
})

SupplierDetail.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    name: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      email: PropTypes.string,
      job: PropTypes.string,
      ame: PropTypes.string,
      phone: PropTypes.string,
    })),
    lands: PropTypes.arrayOf(PropTypes.shape({
      area: PropTypes.number,
      block: PropTypes.number,
      cost: PropTypes.number,
      id: PropTypes.number,
      model: PropTypes.number,
      shopper: PropTypes.number,
      street: PropTypes.string,
    })),
    notes: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      id: PropTypes.number,
      invoice: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          id: PropTypes.number,
        }),
      ]),
      supplier: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          id: PropTypes.number,
        }),
      ]),
      fileUrl: PropTypes.string,
    }))
  }),
  loading: PropTypes.bool,
  openDeleteSupplierModal: PropTypes.func.isRequired,
  fetchSupplier: PropTypes.func.isRequired,
  loadNotes: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
  hasDependencies: PropTypes.bool.isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    invoice: PropTypes.shape({
      id: PropTypes.number
    }),
    supplier: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({}),
    ]),
    fileUrl: PropTypes.string,
    landNotes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      amount: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      land: PropTypes.oneOfType([
        PropTypes.shape({
          id: PropTypes.number,
          street: PropTypes.string,
          number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          area: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
        PropTypes.number,
      ]),
      note: PropTypes.oneOfType([
        PropTypes.shape({
          id: PropTypes.number,
        }),
        PropTypes.number,
      ])
    })),
  })),
}

SupplierDetail.defaultProps = {
  loading: false,
  form: {
    alias: '',
    fiscalName: '',
    rfc: '',
    turn: '',
    description: '',
    phone: '',
    email: '',
    clabe: '',
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierDetail))
