import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import { deleteShopperBalance, closeDeleteShopperBalanceModal } from 'redux/actions/shopperBalances'

const DeleteModal = ({ isOpen, closeModal, shopperBalance, deleteShopperBalance, isLoading }) => !shopperBalance ? null : (
  <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="warning">
    <ModalHeader toggle={closeModal}>Eliminar Movimiento de Comprador</ModalHeader>
    <ModalBody>
      Estás seguro de que quieres borrar este movimiento ?
    </ModalBody>
    <ModalFooter>
      <Button disabled={isLoading} color="warning" className="mr-1" onClick={() => deleteShopperBalance(shopperBalance)}>
        {isLoading && <ReactLoading type="spinningBubbles" color="#fff" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
        Eliminar
      </Button>
      <Button disabled={isLoading} color="primary" onClick={closeModal}>Cancelar</Button>
    </ModalFooter>
  </Modal>
)

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  shopperBalance: PropTypes.shape({}),
  closeModal: PropTypes.func.isRequired,
  deleteShopperBalance: PropTypes.func.isRequired,
}

const mapStateToProps = ({ shopperBalances: { openedRemoveBalanceFromShopperModal, selectedShopperBalance, deletingShopperBalance }}) => ({
  isOpen: openedRemoveBalanceFromShopperModal,
  shopperBalance: selectedShopperBalance,
  isLoading: deletingShopperBalance
})

const mapDispatchToProps = dispatch => ({
  deleteShopperBalance: info => dispatch(deleteShopperBalance(info)),
  closeModal: () => dispatch(closeDeleteShopperBalanceModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteModal))
