import { toast } from 'react-toastify'
import instance from '../../axios'
import sanitizeValues from 'utils/sanitizeValues'
import { fetchNote } from './notes'
import { getPagination } from 'utils/pagination'

export const LOAD_LAND_NOTES_START = 'LOAD_LAND_NOTES_START'
export const LOAD_LAND_NOTES_SUCCESS = 'LOAD_LAND_NOTES_SUCCESS'
export const LOAD_LAND_NOTES_ERROR = 'LOAD_LAND_NOTES_ERROR'

export const LAND_NOTE_FORM_CLEAR_CATEGORY = 'LAND_NOTE_FORM_CLEAR_CATEGORY'

export const LAND_NOTE_CREATE_START = 'LAND_NOTE_CREATE_START'
export const LAND_NOTE_CREATE_SUCCESS = 'LAND_NOTE_CREATE_SUCCESS'
export const LAND_NOTE_CREATE_ERROR = 'LAND_NOTE_CREATE_ERROR'

export const LAND_NOTE_FETCH_START = 'LAND_NOTE_FETCH_START'
export const LAND_NOTE_FETCH_SUCCESS = 'LAND_NOTE_FETCH_SUCCESS'
export const LAND_NOTE_FETCH_ERROR = 'LAND_NOTE_FETCH_ERROR'

export const LAND_NOTE_UPDATE_START = 'LAND_NOTE_UPDATE_START'
export const LAND_NOTE_UPDATE_SUCCESS = 'LAND_NOTE_UPDATE_SUCCESS'
export const LAND_NOTE_UPDATE_ERROR = 'LAND_NOTE_UPDATE_ERROR'

export const OPEN_ADD_NOTE_TO_LAND_MODAL = 'OPEN_ADD_NOTE_TO_LAND_MODAL'
export const CLOSE_ADD_NOTE_TO_LAND_MODAL = 'CLOSE_ADD_NOTE_TO_LAND_MODAL'

export const OPEN_DELETE_LAND_NOTE_MODAL = 'OPEN_DELETE_LAND_NOTE_MODAL'
export const CLOSE_DELETE_LAND_NOTE_MODAL = 'CLOSE_DELETE_LAND_NOTE_MODAL'

export const OPEN_EDIT_LAND_NOTE_MODAL = 'OPEN_EDIT_LAND_NOTE_MODAL'
export const CLOSE_EDIT_LAND_NOTE_MODAL = 'CLOSE_EDIT_LAND_NOTE_MODAL'

export const DELETE_LAND_NOTE_START = 'DELETE_LAND_NOTE_START'
export const DELETE_LAND_NOTE_SUCCESS = 'DELETE_LAND_NOTE_SUCCESS'
export const DELETE_LAND_NOTE_ERROR = 'DELETE_LAND_NOTE_ERROR'

export const PUSH_LAND_NOTE_FORM = 'PUSH_LAND_NOTE_FORM'
export const DELETE_LAND_NOTE_FORM = 'DELETE_LAND_NOTE_FORM'
export const CLEAN_LAND_NOTE_FORM = 'CLEAN_LAND_NOTE_FORM'


export const openAddLandNoteModal = (form, projectId, supplierId) => {
  const isNew = Object.keys(form).length === 0 && form.constructor === Object

  return {
    type: OPEN_ADD_NOTE_TO_LAND_MODAL,
    payload: { form, projectId, isNew, supplierId }
  }
}

export const closeAddLandNoteModal = () => ({
  type: CLOSE_ADD_NOTE_TO_LAND_MODAL,
})

export const openEditLandNoteModal = (form, projectId, supplierId) => {
  return {
    type: OPEN_EDIT_LAND_NOTE_MODAL,
    payload: { form, projectId, supplierId }
  }
}

export const closeEditLandNoteModal = () => ({
  type: CLOSE_EDIT_LAND_NOTE_MODAL
})

export const openDeleteLandNoteModal = payload => ({
  type: OPEN_DELETE_LAND_NOTE_MODAL,
  payload
})

export const closeDeleteLandNoteModal = () => ({
  type: CLOSE_DELETE_LAND_NOTE_MODAL,
})

export const deleteLandNote = landNote => dispatch => {
  dispatch({
    type: DELETE_LAND_NOTE_START
  })

  return instance.delete(`/landNotes/${landNote.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_LAND_NOTE_SUCCESS,
        payload: data
      })

      dispatch(fetchNote(landNote.note.id))

      toast(`Se ha eliminado la nota ${data.note.id} del lote ${data.land.number} ${data.land.street}`, {
        position: toast.POSITION.TOP_CENTER,
      })

      loadLandNotes({ note: data.note.id })(dispatch)
    })
    .catch(err => {
      dispatch({
        type: DELETE_LAND_NOTE_ERROR,
        payload: err
      })
    })
}

export const fetchLandNote = id => dispatch => {
  dispatch({
    type: LAND_NOTE_FETCH_START
  })

  return instance.get(`/landNotes/${id}`)
    .then(({ data }) => {
      dispatch({
        type: LAND_NOTE_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: LAND_NOTE_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateLandNote = values => dispatch => {
  dispatch({
    type: LAND_NOTE_UPDATE_START
  })

  const sanitized = sanitizeValues(values)

  return instance.patch(`/landNotes/${sanitized.id}`, sanitized)
    .then(({ data }) => {
      dispatch({
        type: LAND_NOTE_UPDATE_SUCCESS,
        payload: data
      })

      loadLandNotes({ note: data.note.id })(dispatch)
      dispatch(fetchNote(data.note.id))
    })
    .catch(err => {
      dispatch({
        type: LAND_NOTE_UPDATE_ERROR,
        payload: err
      })
      dispatch({
        type: CLOSE_EDIT_LAND_NOTE_MODAL
      })
    })
}

export const pushLandNoteForm = data => dispatch => {
  dispatch({ type: CLOSE_ADD_NOTE_TO_LAND_MODAL })
  dispatch({
    type: PUSH_LAND_NOTE_FORM,
    payload: data
  })
}

export const deleteLandNoteForm = noteLand => {
  return {
    type: DELETE_LAND_NOTE_FORM,
    payload: noteLand
  }
}

export const cleanLandNoteForm = () => ({
  type: CLEAN_LAND_NOTE_FORM
})

// eslint-disable-next-line no-unused-vars
export const createLandNote = ({ id, ...values }) => dispatch => {
  dispatch({ type: LAND_NOTE_CREATE_START })

  const sanitized = sanitizeValues(values)

  return instance.post('/landNotes', sanitized)
    .then(({ data }) => {
      dispatch({
        type: LAND_NOTE_CREATE_SUCCESS,
        payload: data
      })
      dispatch({ type: CLOSE_ADD_NOTE_TO_LAND_MODAL })
      dispatch(fetchNote(values.note))
      loadLandNotes({ note: data.note.id })(dispatch)
    })
    .catch(err => {
      let msg = err.toString();
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (err.response.data.incorrectAmount) {
          msg = err.response.data.incorrectAmount;
        }
      }
      dispatch({
        type: LAND_NOTE_CREATE_ERROR,
        payload: msg
      })
    })
}

export const loadLandNotes = ({ land, note, category }, paginationOpts) => dispatch => {
  const pagination = getPagination(paginationOpts)
  let params = {
    limit: pagination.perPage,
    skip: pagination.skip,
  }

  if (note) {
    params.where = { note }
  } else if (land) {
    params.where = { land }
  } else if (category) {
    params.where = { category }
  }

  dispatch({
    type: LOAD_LAND_NOTES_START
  })

  return instance.get(`/landNotes`, {
    params
  })
    .then(({ data }) => {
      dispatch({
        type: LOAD_LAND_NOTES_SUCCESS,
        payload: data,
        pagination,
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_LAND_NOTES_ERROR,
        payload: err
      })
    })
}
