import {
  LOAD_LAND_NOTES_START,
  LOAD_LAND_NOTES_ERROR,
  LOAD_LAND_NOTES_SUCCESS,
  LAND_NOTE_FORM_CLEAR_CATEGORY,
  LAND_NOTE_FETCH_START,
  LAND_NOTE_FETCH_SUCCESS,
  LAND_NOTE_FETCH_ERROR,
  LAND_NOTE_CREATE_START,
  LAND_NOTE_CREATE_SUCCESS,
  LAND_NOTE_CREATE_ERROR,
  OPEN_DELETE_LAND_NOTE_MODAL,
  CLOSE_DELETE_LAND_NOTE_MODAL,
  DELETE_LAND_NOTE_START,
  DELETE_LAND_NOTE_SUCCESS,
  DELETE_LAND_NOTE_ERROR,
  LAND_NOTE_UPDATE_START,
  LAND_NOTE_UPDATE_SUCCESS,
  LAND_NOTE_UPDATE_ERROR,
  OPEN_ADD_NOTE_TO_LAND_MODAL,
  CLOSE_ADD_NOTE_TO_LAND_MODAL,
  OPEN_EDIT_LAND_NOTE_MODAL,
  CLOSE_EDIT_LAND_NOTE_MODAL,
  PUSH_LAND_NOTE_FORM,
  DELETE_LAND_NOTE_FORM,
  CLEAN_LAND_NOTE_FORM,
} from '../actions/landNotes'
import { getPagination } from 'utils/pagination'

const defaultForm = {
  note: {},
  land: null,
  category: null,
  amount: 0,
}
const defaultPagination = getPagination()
let currentNoteLands = []


export default (state = {
  landNotes: [],
  total: 0,
  loading: false,
  openedRemoveNoteFromLandModal: false,
  openedAddNoteToLandModal: false,
  openedEditLandNoteModal: false,
  hasLimitError: false,
  form: { ...defaultForm },
  supplierId: null,
  pagination: {...defaultPagination},
}, action) => {
  switch (action.type) {
    case LAND_NOTE_CREATE_START:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LAND_NOTE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case LAND_NOTE_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case OPEN_ADD_NOTE_TO_LAND_MODAL:
      return {
        ...state,
        form: action.payload.form,
        openedAddNoteToLandModal: true,
        projectId: parseInt(action.payload.projectId),
        supplierId: parseInt(action.payload.supplierId),
        isNew: action.payload.isNew,
        hasLimitError: false,
      }
    case CLOSE_ADD_NOTE_TO_LAND_MODAL:
      return {
        ...state,
        form: { ...defaultForm },
        openedAddNoteToLandModal: false
      }
    case OPEN_EDIT_LAND_NOTE_MODAL:
      return {
        ...state,
        openedEditLandNoteModal: true,
        form: action.payload.form,
        projectId: parseInt(action.payload.projectId),
        supplierId: parseInt(action.payload.supplierId),
        hasLimitError: false,
      }
    case CLOSE_EDIT_LAND_NOTE_MODAL:
      return {
        ...state,
        openedEditLandNoteModal: false,
        form: { ...defaultForm }
      }
    case OPEN_DELETE_LAND_NOTE_MODAL:
      return {
        ...state,
        openedRemoveNoteFromLandModal: true,
        selectedLandNote: action.payload,
      }
    case CLOSE_DELETE_LAND_NOTE_MODAL:
      return {
        ...state,
        openedRemoveNoteFromLandModal: false,
        selectedLandNote: null
      }
    case DELETE_LAND_NOTE_START:
      return {
        ...state,
        deletingLandNote: true,
      }
    case DELETE_LAND_NOTE_SUCCESS:
      return {
        ...state,
        deletingLandNote: false,
        form: { ...defaultForm },
        landNotes: state.landNotes.filter(u => u.id !== state.selectedLandNote.id),
        openedRemoveNoteFromLandModal: false,
      }
    case DELETE_LAND_NOTE_ERROR:
      return {
        ...state,
      }
    case LAND_NOTE_UPDATE_START:
      return {
        ...state,
        updatingLandNote: true,
      }
    case LAND_NOTE_UPDATE_SUCCESS:
      return {
        ...state,
        updatingLandNote: false,
        form: { ...defaultForm },
        openedEditLandNoteModal: false,
      }
    case LAND_NOTE_UPDATE_ERROR:
      return {
        ...state,
      }
    case LAND_NOTE_FORM_CLEAR_CATEGORY:
      return {
        ...state,
        form: { ...state.form, category: null, land: null }
      }
    case LOAD_LAND_NOTES_ERROR:
      return {
        ...state,
        landNotes: [],
        error: action.payload
      }
    case LOAD_LAND_NOTES_START:
      return {
        ...state,
        landNotes: [],
        loading: true
      }
    case LOAD_LAND_NOTES_SUCCESS:
      return {
        ...state,
        landNotes: action.payload.results,
        loading: false,
        pagination: {
          ...action.pagination,
          total: action.payload.totalCount,
        }
      }
    case LAND_NOTE_FETCH_START:
      return {
        ...state,
        loadingLandNote: true,
        form: defaultForm
      }
    case LAND_NOTE_FETCH_SUCCESS:
      return {
        ...state,
        loadingLandNote: false,
        form: action.payload
      }
    case LAND_NOTE_FETCH_ERROR:
      return {
        ...state,
        loadingLandNote: false,
        form: action.payload
      }
    case PUSH_LAND_NOTE_FORM:
      currentNoteLands = state.landNotes
      currentNoteLands.push(action.payload)
      return {
        ...state,
        landNotes: currentNoteLands
      }
    case DELETE_LAND_NOTE_FORM:
      currentNoteLands = state.landNotes
      currentNoteLands.splice(action.payload, 1)
      return {
        ...state,
        landNotes: currentNoteLands,
      }
    case CLEAN_LAND_NOTE_FORM:
      currentNoteLands = []
      return {
        ...state,
        landNotes: currentNoteLands,
      }
    default:
      return state
  }
}
