import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useForm from 'react-hook-form'
import ReactLoading from 'react-loading'
import get from 'lodash/get'
import ReactDatetime from 'react-datetime'

import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import moment from 'moment'
import 'moment/locale/es'

const tomorrow = moment().add(1, 'day')

const Expenseform = props => {
  const {
    readOnly,
    loading,
    suppliers,
    supplier,
    works,
    work,
    neodatas,
    onChangeWork,
    form,
    onSubmit,
    transactions
  } = props

  const supplierList = get(suppliers, 'suppliers')

  const { register, errors, handleSubmit, reset } = useForm()

  const date = get(form, 'date') ? moment(get(form, 'date'), 'DD/MM/YYYY', true).utc() : Date.now()
  useEffect(() => {
    const neodata = get(form, 'neodata')
    const work = get(neodata, 'work')
    reset({
      payment_method: '0',
      ...form,
      neodata: get(neodata, 'id'),
      work,
      supplier: supplier || "-1"
    })
  }, [form, reset, supplier, supplierList, transactions])


  return (
    <Form noValidate autoComplete="off">
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label className="form-control-label" htmlFor="date">Fecha</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  {!readOnly &&
                    <ReactDatetime
                      className="form-control-alternative"
                      timeFormat={false}
                      closeOnSelect={true}
                      locale='es'
                      utc
                      dateFormat="DD/MM/YYYY"
                      defaultValue={date}
                      isValidDate={current => current.isBefore(tomorrow)}
                      inputProps={{
                        autoComplete: 'off',
                        placeholder: 'Fecha',
                        name: 'date',
                        disabled: readOnly || form.status === 'paid',
                        ref: register({
                          required: form.status !== 'paid'
                        }),
                      }}
                    />
                  }
                  {readOnly &&
                    <Input
                      name='date'
                      innerRef={register}
                      disabled
                      className='pl-3 form-control-alternative'
                    />
                  }
                </InputGroup>
                {errors.date && errors.date.type === 'required' && (
                  <FormText color="primary">
                    Seleccione una fecha.
                  </FormText>
                )}
                {errors.date && errors.date.type === 'validate' && (
                  <FormText color="primary">
                    Ingrese una fecha válida o seleccione una en el calendario.
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label className="form-control-label" htmlFor="supplier">Proveedor <span className="text-danger">*</span></Label>
                {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
                {!suppliers.loading && suppliers.suppliers &&
                  <Input
                    className="form-control-alternative"
                    id="supplier"
                    autoComplete="off"
                    type="select"
                    name="supplier"
                    disabled={readOnly || form.status === 'paid'}
                    innerRef={register({
                      required: form.status !== 'paid',
                    })}
                  >
                    {[<option key={'emptyValue'} value="-1">Seleccione una opción</option>]
                      .concat(suppliers.suppliers
                        .map(supplier => <option value={supplier.id} key={supplier.id}>{supplier.alias.trim() ? supplier.alias : supplier.fiscalName}</option>)
                      )
                    }
                  </Input>
                }
                {errors.supplier && (
                  <FormText color="primary">
                    Seleccione un proveedor
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="form-control-label" htmlFor="payment-method">Forma de pago</Label>
                {!works.loading && works.works && <Input
                  className="form-control-alternative"
                  id="payment-method"
                  autoComplete="off"
                  type="select"
                  name="payment_method"
                  disabled={readOnly || form.status === 'paid'}
                  innerRef={register({
                    required: form.status !== 'paid',
                    validate: value => form.status === 'paid' || value !== '0',
                  })}
                >
                  <option value="0">Seleccione una opción</option>
                  <option value="1">Efectivo</option>
                  <option value="2">Cheque</option>
                  <option value="3">Transferencia</option>
                  <option value="4">Tarjeta</option>
                </Input>}
                {errors.payment_method && (
                  <FormText color="primary">
                    Seleccione un metodo de pago valido.
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="form-control-label" htmlFor="account">Cuenta <span className="text-danger">*</span></Label>
                {loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
                {!transactions.loading && transactions.transactions &&
                  <Input
                    className="form-control-alternative"
                    id="account"
                    autoComplete="off"
                    type="select"
                    name="account"
                    disabled={readOnly || form.status === 'paid'}
                    innerRef={register({
                      required: true,
                    })}
                  >
                    {[<option key={'emptyValue'} value="">Seleccione una opción</option>]
                      .concat(transactions.transactions
                        .map(transaction => <option value={transaction.id} key={transaction.id}>{transaction.name}</option>)
                      )
                    }
                  </Input>
                }
                {errors.account && (
                  <FormText color="primary">
                    Seleccione una cuenta
                  </FormText>
                )}
              </FormGroup>
            </Col>
            {get(form, 'id') &&
              <Col md={3}>
                <FormGroup>
                  <Label className="form-control-label">Status</Label>
                  <p>{form.status === 'pending' ? 'Pendiente' : 'Pagado'}</p>
                </FormGroup>
              </Col>
            }

            <Col md="3">
              <FormGroup>
                <Label className="form-control-label" htmlFor="folio">Folio</Label>
                <Input
                  type="text"
                  disabled={readOnly}
                  name="folio"
                  className="form-control-alternative"
                  innerRef={register}
                  placeholder="Puede omitir 'F-', escriba solo el número."
                />
                {errors.folio && (
                  <FormText color="primary">
                    Ingrese el folio del gasto
                  </FormText>
                )}
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label className="form-control-label" htmlFor="work">Obra <span className="text-danger">*</span></Label>
                {works.loading && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
                {!works.loading && works.works && <Input
                  className="form-control-alternative"
                  id="work"
                  autoComplete="off"
                  defaultValue={work}
                  type="select"
                  name="work"
                  disabled={readOnly || form.status === 'paid'}
                  onChange={e => onChangeWork(e.target.value)}
                  innerRef={register({
                    required: true,
                  })}
                >
                  {[<option key={'emptyValue'} value="">Seleccione una opción</option>]
                    .concat(works.works
                      .map(work => <option value={work.id} key={work.id}>{work.name}</option>)
                    )
                  }
                </Input>}
                {errors.work && (
                  <FormText color="primary">
                    Seleccione una obra
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label className="form-control-label" htmlFor="neodata">Neodata <span className="text-danger">*</span></Label>
                {neodatas.length === 0 && <Input disabled value="Selecciona una obra" />}
                {neodatas.length > 0 &&
                  <Input
                    className="form-control-alternative"
                    id="neodata"
                    autoComplete="off"
                    type="select"
                    name="neodata"
                    disabled={readOnly || form.status === 'paid'}
                    innerRef={register({
                      required: form.status !== 'paid',
                    })}
                  >
                    {[<option key={'emptyValue'}>Seleccione una opción</option>]
                      .concat(neodatas
                        .map(neodata => <option value={neodata.id} key={neodata.id}>{neodata.name}</option>)
                      )
                    }
                  </Input>
                }
                {/* {get(form, 'id') && <>
                  <br />
                  <Button className="text-sm btn btn-sm btn-outline-primary">{get(neodata, 'name', '')}</Button>
                </>} */}
                {errors.neodata && (
                  <FormText color="primary">
                    Seleccione una Neodata
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="form-control-label" htmlFor="total">Total <span className="text-danger">*</span></Label>
                <Input
                  type="text"
                  disabled={readOnly || form.status === 'paid'}
                  name="total"
                  className="form-control-alternative text-right"
                  innerRef={register({
                    required: true
                  })}
                // value={formatCurrency({ amount: get(totales, 'total', 0) })}
                />
                {errors.total && errors.total.type === 'required' && (
                  <FormText color="primary">
                    Ingrese el total
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label className="form-control-label" htmlFor="description">Descripción</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  className="form-control-alternative"
                  placeholder="Descripción"
                  innerRef={register}
                  disabled={readOnly || form.status === 'paid'}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
              <Label className="form-control-label" htmlFor="authNumber">Número de autorización / Cheque</Label>
                <Input
                  type="text"
                  name="authNumber"
                  placeholder="Puede omitir 'A-', escriba solo el número."
                  className="form-control-alternative"
                  innerRef={register}
                  disabled={readOnly}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <hr className="my-4" />
      {!readOnly && <Button disabled={loading} color="primary" onClick={handleSubmit(info => onSubmit(info, 'resume'))}>Guardar y Resumen</Button>}
      {!readOnly && <Button disabled={loading} color="primary" onClick={handleSubmit(info => onSubmit(info, 'new'))}>Guardar y Nuevo gasto</Button>}
    </Form>
  )
}


Expenseform.propTypes = {
  errors: PropTypes.shape({
    supplier: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  suppliers: PropTypes.shape({}),
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  register: PropTypes.func,
  onSubmit: PropTypes.func,
}

Expenseform.defaultProps = {
  loading: false,
  loadingInvoice: false,
  readOnly: false,
  suppliers: {},
  works: [],
  neodatas: [],
  errors: {},
  form: {}
}


export default Expenseform
