import React, { useEffect } from 'react'
import get from 'lodash/get'
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { faThLarge, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container
} from 'reactstrap'
import PropTypes from 'prop-types'
import { fetchBlock, updateBlock } from 'redux/actions/blocks'
import { loadProjects } from 'redux/actions/projects'
import BlockForm from 'components/Blocks/form'
import BackButton from 'components/BackButton'

const EditBlock = ({ fetchBlock, updateBlock, form, loadingProjects, loadingBlock, history, match, loadProjects, projects }) => {
  const { register, reset, handleSubmit, errors, setValue } = useForm()
  useEffect(() => {
    fetchBlock(match.params.id)
    loadProjects()
  }, [fetchBlock, match, loadProjects])

  useEffect(() => {
    reset(form)
  }, [form, reset])

  useEffect(() => {
    if (projects.length > 0 && get(form, 'project.id') !== undefined) {
      setValue('project', get(form, 'project.id'))
    }
  }, [projects, form, setValue])

  return (
    <Container className="pt-2 pt-md-5 pt-lg-6" fluid>
      <BackButton />
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">
                    <FontAwesomeIcon icon={faThLarge} className="mr-1"/> Editar Manzana<br/>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/projects/${get(form, 'project.id')}`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                      Regresar a Lista
                    </Button>
                    <Button
                      tag={Link}
                      color="primary"
                      to={`/admin/blocks/${match.params.id}`}
                      size="sm"
                      className="mt-2"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2"/>
                      Cancelar
                    </Button>
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loadingBlock && <ReactLoading type="spinningBubbles" color="#000" height={30} width={30} className="mt-3" />}
              <BlockForm
                onSubmit={handleSubmit(info => updateBlock(info, history))}
                history={history}
                register={register}
                errors={errors}
                projects={projects}
                loadingBlock={loadingBlock}
                loading={loadingProjects}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = ({ blocks: { form, loadingBlock }, projects: { projects, loading: loadingProjects } }) => ({
  form,
  loadingBlock,
  loadingProjects,
  projects,
})

const mapDispatchToProps = dispatch => ({
  fetchBlock: id => dispatch(fetchBlock(id)),
  loadProjects: () => dispatch(loadProjects()),
  updateBlock: (values, history) => dispatch(updateBlock(values, history)),
})

EditBlock.propTypes = {
  form: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  projects: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  loadingBlock: PropTypes.bool,
  loadingProjects: PropTypes.bool,
  fetchBlock: PropTypes.func.isRequired,
  loadProjects: PropTypes.func.isRequired,
  updateBlock: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
  }).isRequired,
}

EditBlock.defaultProps = {
  loadingBlock: false,
  loadingProjects: false,
  form: {
    description: '',
    name: '',
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditBlock))
