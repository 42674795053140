import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class UserHeader extends Component {
  render() {
    const { user } = this.props
    return (
      <Container className="d-flex align-items-center mb-6" fluid>
        <Row>
          <Col md="10">
            <h1 className="display-2 mt-6">Bienvenido {user.name}</h1>
            <p className="mt-0 mb-5">
              Utiliza los links en el menú para navegar en las funcionalidades de la aplicación
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
}

UserHeader.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
}

UserHeader.defaultProps = {
  user: {
    name: ''
  }
}

const mapStateToProps = ({ session: { user }}) => ({ user })
export default connect(mapStateToProps)(UserHeader)
