import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactLoading from 'react-loading'
import ModelCategoryForm from 'components/ModelCategories/form'
import { createModelCategory, closeAddModelCategoryModal } from 'redux/actions/modelCategories'
import loadCategories from 'components/HOC/loadCategories'

const CreateModal = ({
  isOpen,
  closeModal,
  createModelCategory,
  loading,
  loadingCategories,
  form,
  categories
}) => {
  const { register, handleSubmit, errors, reset } = useForm()
  useEffect(() => {
    reset({ model: get(form, 'model.id', get(form, 'model')) })
  }, [form, reset])

  return (
    <Modal isOpen={isOpen} fade={false} toggle={closeModal} className="info">
      <ModalHeader toggle={closeModal}>Añadir Categoría a Modelo</ModalHeader>
      <Form onSubmit={handleSubmit(createModelCategory)} noValidate autoComplete="off">
        <ModalBody>
          <ModelCategoryForm
            register={register}
            errors={errors}
            loading={loadingCategories}
            categories={categories}
            closeModal={closeModal}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading || get(categories, 'length', 0) === 0} color="primary" className="mr-1" type="submit">
            {loading && <ReactLoading type="spinningBubbles" color="#000" height={10} width={10} className="d-inline-block mr-4 ml-2" />}
            Añadir Categoría
          </Button>
          <Button disabled={loading} color="warning" onClick={closeModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

CreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  loadingCategories: PropTypes.bool,
  form: PropTypes.shape({
    model: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ]),
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number
  })),
  closeModal: PropTypes.func.isRequired,
  createModelCategory: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  categories: { categories, loading: loadingCategories },
  modelCategories: { modelCategories: currentCategories, loading: loadingModelCategories, openedAddCategoryToModelModal, form }
}) => ({
  categories: currentCategories && categories && categories.filter(category => !currentCategories.find(current => current.category.id === category.id)),
  isOpen: openedAddCategoryToModelModal,
  form,
  loading: loadingModelCategories,
  loadingCategories: loadingCategories
})

const mapDispatchToProps = dispatch => ({
  createModelCategory: info => dispatch(createModelCategory(info)),
  closeModal: () => dispatch(closeAddModelCategoryModal()),
})

export default withRouter(loadCategories(connect(mapStateToProps, mapDispatchToProps)(CreateModal), {perPage: 1000}))
