import { toast } from 'react-toastify'
import instance from '../../axios'
import fileDownload from 'js-file-download'
import getFormAsFormData from 'utils/getAsFormData'
import { getPagination } from 'utils/pagination'

export const LOAD_INVOICES_START = 'LOAD_INVOICES_START'
export const LOAD_INVOICES_SUCCESS = 'LOAD_INVOICES_SUCCESS'
export const LOAD_INVOICES_ERROR = 'LOAD_INVOICES_ERROR'

export const INVOICE_CREATE_START = 'INVOICE_CREATE_START'
export const INVOICE_CREATE_SUCCESS = 'INVOICE_CREATE_SUCCESS'
export const INVOICE_CREATE_ERROR = 'INVOICE_CREATE_ERROR'

export const INVOICE_EDIT_START = 'INVOICE_EDIT_START'
export const INVOICE_EDIT_SUCCESS = 'INVOICE_EDIT_SUCCESS'
export const INVOICE_EDIT_ERROR = 'INVOICE_EDIT_ERROR'

export const INVOICE_FETCH_START = 'INVOICE_FETCH_START'
export const INVOICE_FETCH_SUCCESS = 'INVOICE_FETCH_SUCCESS'
export const INVOICE_FETCH_ERROR = 'INVOICE_FETCH_ERROR'

export const INVOICE_UPDATE_START = 'INVOICE_UPDATE_START'
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS'
export const INVOICE_UPDATE_ERROR = 'INVOICE_UPDATE_ERROR'

export const OPEN_DELETE_INVOICE_MODAL = 'OPEN_DELETE_INVOICE_MODAL'
export const CLOSE_DELETE_INVOICE_MODAL = 'CLOSE_DELETE_INVOICE_MODAL'

export const OPEN_PAY_INVOICE_MODAL = 'OPEN_PAY_INVOICE_MODAL'
export const CLOSE_PAY_INVOICE_MODAL = 'CLOSE_PAY_INVOICE_MODAL'

export const DELETE_INVOICE_START = 'DELETE_INVOICE_START'
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS'
export const DELETE_INVOICE_ERROR = 'DELETE_INVOICE_ERROR'

export const LOAD_ACCOUNTS_SUCCESS = 'LOAD_ACCOUNTS_SUCCESS'

export const UPDATE_INVOICE_START = 'UPDATE_INVOICE_START'
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS'

export const openDeleteInvoiceModal = payload => ({
  type: OPEN_DELETE_INVOICE_MODAL,
  payload
})

export const closeDeleteInvoiceModal = () => ({
  type: CLOSE_DELETE_INVOICE_MODAL,
})

export const openPayInvoiceModal = payload => ({
  type: OPEN_PAY_INVOICE_MODAL,
  payload
})

export const closePayInvoiceModal = () => ({
  type: CLOSE_PAY_INVOICE_MODAL,
})

export const deleteInvoice = ({ invoice, source, history }) => dispatch => {
  dispatch({
    type: DELETE_INVOICE_START
  })

  return instance.delete(`/invoices/${invoice.id}`)
    .then(({ data }) => {
      dispatch({
        type: DELETE_INVOICE_SUCCESS,
        payload: data
      })

      toast('Se ha eliminado la factura', {
        position: toast.POSITION.TOP_CENTER,
      })

      if (source === 'detail') {
        history.push('/admin/invoices')
      }
    })
    .catch(err => {
      dispatch({
        type: DELETE_INVOICE_ERROR,
        payload: err
      })
    })
}

export const fetchInvoice = id => dispatch => {
  dispatch({
    type: INVOICE_FETCH_START
  })

  return instance.get(`/invoices/${id}`)
    .then(({ data }) => {
      dispatch({
        type: INVOICE_FETCH_SUCCESS,
        payload: data
      })
    })
    .catch(err => {
      dispatch({
        type: INVOICE_FETCH_ERROR,
        payload: err
      })
    })
}

export const updateInvoice = (values, history) => dispatch => {
  dispatch({
    type: INVOICE_UPDATE_START
  })

  return instance.patch(`/invoices/${values.id}`, values)
    .then(({ data }) => {
      dispatch({
        type: INVOICE_UPDATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/invoices/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: INVOICE_UPDATE_ERROR,
        payload: err
      })
    })
}

export const createInvoice = (values, history) => dispatch => {
  dispatch({
    type: INVOICE_CREATE_START
  })

  const formData = getFormAsFormData(values)

  return instance.post('/invoices', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
    .then(({ data }) => {
      dispatch({
        type: INVOICE_CREATE_SUCCESS,
        payload: data
      })

      history.push(`/admin/invoices/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: INVOICE_CREATE_ERROR,
        payload: err
      })
    })
}

export const editInvoice = (id, values, history) => dispatch => {
  dispatch({
    type: INVOICE_EDIT_START
  })

  const formData = getFormAsFormData(values)

  return instance.patch(`/invoices/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }).then(({ data }) => {
      dispatch({
        type: INVOICE_EDIT_SUCCESS,
        payload: data
      })
      // dispatch(fetchInvoice(id))
      history.push(`/admin/invoices/${data.id}`)
    })
    .catch(err => {
      dispatch({
        type: INVOICE_EDIT_ERROR,
        payload: err
      })
    })
}

export const loadInvoices = pagination => dispatch => {

  pagination = getPagination(pagination)
  const sort = [
    {date: "DESC"},
  ]
  dispatch({
    type: LOAD_INVOICES_START
  })

  return instance.get('/invoices', {
    params: {
      limit: pagination.perPage,
      skip: pagination.skip,
      sort: JSON.stringify(sort),
    }
  }).then(({ data }) => {
      instance.get('/accounts').then(({ data: accountList }) => {
        dispatch({
          type: LOAD_INVOICES_SUCCESS,
          payload: data,
          pagination,
        });
        dispatch({
          type: LOAD_ACCOUNTS_SUCCESS,
          payload: accountList,
        })
      })
    })
    .catch(err => {
      dispatch({
        type: LOAD_INVOICES_ERROR,
        payload: err
      })
    })
}

export const downloadFile = (invoiceId, fileType) => dispatch => {
  return instance.get(`file/invoice/${fileType}/${invoiceId}`, {
    responseType: 'arraybuffer',
  }).then(({ data }) => fileDownload(data, invoiceId + '_Voucher.pdf'))
    .catch(err => console.error(err))
}

export const updateSingleField = (invoiceId, fieldName, newValue) => dispatch => {
  dispatch({
    type: UPDATE_INVOICE_START
  })

  const data = {
    [`${fieldName}`]: newValue
  }

  return instance.patch(`/invoices/${invoiceId}`, data)
    .then(response => {
      dispatch({
        type: UPDATE_INVOICE_SUCCESS,
        payload: {
          id: invoiceId,
          newValue,
          field: fieldName,
        }
      })
    })
    .catch(error => {

    })
}
