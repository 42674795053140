import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faTrash, faPen, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import moment from 'moment'

import {
  Table,
  Button,
  UncontrolledTooltip,
} from 'reactstrap'

import { formatCurrency } from 'utils/numberFormat'

const TableList = props => {

  return (
    <Table className="align-items-center table-compact" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col" className="text-center">Llegada</th>
          <th scope="col" className="text-center">Número</th>
          <th scope="col" className="text-center">Proveedor</th>
          <th scope="col" className="text-center">Subtotal</th>
          <th scope="col" className="text-center">IVA</th>
          <th scope="col" className="text-center">Total</th>
          <th scope="col" className="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {props.notes && props.notes.length > 0 && props.notes.map(note => {
          const hasInvoice = get(note, 'invoice.id', get(note, 'invoice'), null) !== null
          const id = `note_${note.id}`
          return (
            <tr key={note.id}>
              <td className="border-left text-sm text-center">
                {note.arrivalDate ? moment(note.arrivalDate).utc().format('DD/MMM/YYYY') : '---'}
              </td>
              <td className="border-left text-sm text-center">{note.number}</td>
              <td className="border-left text-sm text-center">
                <Button
                  tag={Link}
                  to={`/admin/suppliers/${note.supplier.id}`}
                  className="btn btn-sm btn-outline-primary mr-0"
                >
                  {note.supplier.alias}
                </Button>
              </td>
              <td className="border-left text-sm text-right">
                {formatCurrency({ amount: note.subtotal })}
              </td>
              <td className="border-left text-sm text-right">
                {formatCurrency({ amount: note.iva })}
              </td>
              <td className="border-left text-sm text-right">
                {formatCurrency({ amount: note.total })}
              </td>
              <th scope="row" className="border-left text-center text-sm" id={`${id}_container`}>
                <Button
                  tag={Link}
                  size="sm"
                  to={`/admin/notes/${note.id}`}
                >
                  <FontAwesomeIcon icon={faFileAlt} />
                </Button>
                {!props.readOnly &&
                  <span>
                    <Button
                      tag={Link}
                      color="primary"
                      size="sm"
                      to={`/admin/notes/${note.id}/edit`}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                    {hasInvoice && (
                      <UncontrolledTooltip target={id} boundariesElement={`${id}_container`} >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="mr-2"
                        />
                        No se puede eliminar esta nota porque tiene una factura asociada a ella.
                    </UncontrolledTooltip>
                    )}
                    <span id={id}>
                      <Button
                        color="warning"
                        disabled={hasInvoice}
                        onClick={() => props.openDeleteNoteModal(note)}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </span>
                  </span>
                }
              </th>
            </tr>
          )
        })}
        {(!props.notes || props.notes.length === 0) && <tr>
          <td colSpan="7">
            <h2 className="text-center">SIN NOTAS</h2>
          </td>
        </tr>}
      </tbody>
    </Table>
  )
}


TableList.propTypes = {
  openDeleteNoteModal: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape({}))
}

TableList.defaultProps = {
  notes: [],
  permissions: {},
}


export default TableList
