const getFormAsFormData = (values) => {
  const fileFormData = new FormData();
  const formKeys = Object.keys(values);
  let fileToAppend = null;

  formKeys.forEach(key => {
    let formVal = values[key];
    if (formVal instanceof FileList) {
      if (formVal.length > 0) {
        fileToAppend = key;
      }
    } else {
      fileFormData.append(key, formVal);
    }
  });

  if (fileToAppend !== null) {
    fileFormData.append(fileToAppend, values[fileToAppend][0]);
  }
  return fileFormData;
}

export default getFormAsFormData
